/*******************************************************************************
  설  명 : 게시판 내용보기
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ViewUpdateComponent } from '@page/board/board-view/view-update/view-update.component';
import { AuthService } from '@app/service/auth.service';

import { SBoardService } from '@app/service/board.service';

import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-board-view',
  templateUrl: './board-view.component.html',
  styleUrls: ['./board-view.component.scss']
})
export class BoardViewComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public params: any = {
    type: 'list',
    board_id: 'notice',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };

  public boardList = [];
  public boardData: any = {};
  public memberInfo: any = {};
  public replyList: any = [];
  public isLogIn$: Observable<boolean>;
  public isLogIn: boolean;
  public totalCount: number;
  public boardId: 'notice';
  public memCheck: boolean = false;

  public form: FormGroup;
  public formErrors = {
    contents: ''
  };

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      board_seq: [ '0', [Validators.required] ],
      board_id: [ 'notice', [Validators.required] ],
      board_index: [ '', [Validators.required] ],
      email: [ '', [Validators.required] ],
      contents: [ '', [Validators.required] ],
      user_id: [ '', [Validators.required] ],
      user_name: [ '', [Validators.required] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute, 
    private authService: AuthService,
    private toastrService: ToastrService,
    private sBoardService: SBoardService,
    private cookieService: CookieService,
    private domSanitizer: DomSanitizer,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,

  ) {
    this.buildForm();

    this.isLogIn$ = this.authService.isLoggedIn;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });

  }

  /*******************************************************************************
    설  명 : 데이터 로딩, 파라미터 설정 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    if ( this.isLogIn ) {
      this.form.patchValue({
        email: this.memberInfo.email,
        user_id: this.memberInfo.id,
        user_name: this.memberInfo.name
      });
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.board_seq = ( typeof params.board_seq == 'undefined' || params.board_seq == '' ) ? '' : params.board_seq;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      if( this.params.board_seq == '') {
        this.toastrService.error( '게시물이 존재하지 않습니다.', '게시판' );

        this.router.navigate(
        ['/board/list'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

        return false;
      }

      this.getBoardInfo( this.params.board_id );
    });

  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 게시판 아이디
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo( board_id ) {
    this.sBoardService.getBoardInfoId( board_id ).then( response => {
      this.boardInfo = response.data;

      this.getBoardView( board_id, this.params.board_seq );
      this.getBoardList();
    });
  }
  /*******************************************************************************
  설  명 : 게시판 리스트 정보 가져오기
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.sBoardService.getBoardList( this.params ).then( response => {
      this.boardList = response.data;
      this.boardId = this.boardList[0].board_id;
      this.boardInfo.total_count = response.total;
      this.totalCount = parseInt( response.total );
      this.getComment();

      this.deleteParams();
    });
  }

  /*******************************************************************************
  설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 게시판 상세보기 (is_notice여부에 따른 a태그 사용)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  view( row: any ) {
    this.params.board_seq = row.board_seq;

    this.router.navigate(
    ['/board/view'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 게시글 정보 가져오기
    입력값 : 게시판 아이디, 게시판 코드
    리턴값 : 없음
  *******************************************************************************/
  getBoardView( board_id, board_seq){
    let hitCookie = this.cookieService.get( board_id + board_seq);

    if( hitCookie == '' ) {
      this.cookieService.set( board_id + board_seq, 'true' );
      this.params.viewCheck = 1;
    } else {
      this.params.viewCheck = 0;
    }

    this.sBoardService.getBoardView( board_id, board_seq, this.params.viewCheck ).then( response => {
      if( response.ResultCode ){
        
        this.boardData = response.data;
        this.boardData.upload = [];
        this.boardData.upload = response.upload;  // 업로드파일 있는지 컬럼하고 비교 필요

        this.form.patchValue({
          board_seq: this.boardData.board_seq,
          board_index: this.boardData.board_index
        });

        // 그룹 표시
        if( this.boardData.group_id == '전체' || this.boardData.group_id == '' || this.boardData.group_id == null) {
        } else {
          this.boardData.subject = '[' + this.boardData.group_id + '] ' + this.boardData.subject;
        }

        // new 표시
        if( this.boardData.new_check_date <= this.boardInfo.new_date ) {
          this.boardData.subject = this.boardData.subject;
        } // new_check_date 컬럼확인

      } else {
        this.toastrService.error( response.ResultMessage, '게시글 조회' );

        let url: any = '';
        if( board_id == 'question' ) url = '/mypage/myqna';
        else url = '/board/list';

        this.router.navigate(
          [url],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.params,
            queryParamsHandling: '', // remove to replace all query params by provided
          });
      }
    });
  }


  /*******************************************************************************
    설  명 : 게시판 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  toBoardList() {
    if ( this.params.board_id == 'question' ) {
      this.params.type = '';

      this.router.navigate(
      ['/mypage/myqna'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    } else if ( this.params.board_id == 'society' ) {
      this.params.type = 'gallery';

      this.router.navigate(
      ['/board/gallery'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }else if ( this.params.board_id == 'society_news' ) {
      this.params.board_id = 'society_news';
      this.params.type = 'list';

      this.router.navigate(
      ['/board/list'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    } else {
      this.params.type = '';

      this.router.navigate(
      ['/board/list'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  /*******************************************************************************
    설  명 : 게시판 상세보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  boardView() {
    this.router.navigate(
      ['/board/view'],
      {
        relativeTo: this.activatedRoute,
    });
  }

  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getSafeHtml( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  /*******************************************************************************
    설  명 : 답글달기
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  reply() {
    this.params.type = '';
    this.params.board_seq = this.boardData.board_seq; 
    this.params.contents = this.boardData.contents; 
    this.params.subject = this.boardData.subject; 
    this.params.group_id = this.boardData.group_id; 
    this.params.reply = true; 

    this.router.navigate(
    ['/mypage/myqna/write'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 댓글 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getComment() {
    this.sBoardService.getComment( this.boardData.board_index ).then( response => {
      if ( response.ResultCode ) {
        this.replyList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 댓글 입력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setComment() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.sBoardService.setComment( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success('댓글이 입력되었습니다.');
          this.getComment();
        } else {
          this.toastrService.error('댓글입력이 실패했습니다.');
        }
      });
    }

  }

  /*******************************************************************************
    설  명 : 댓글 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteComment(row) {
    this.sBoardService.deleteComment( row.comment_index ).then( response => {
      if ( response.ResultCode ) {
        this.toastrService.success('댓글이 입력되었습니다.');
        this.getComment();
      } else {
        this.toastrService.error('댓글입력이 실패했습니다.');
      }
    });
  }

  /*******************************************************************************
    설  명 : 댓글 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  updateComment(row) {
    const modalRef = this.modalService.open(ViewUpdateComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.mem_no = this.memberInfo.mem_no;
    
    modalRef.result.then((result) => {
      this.getComment();
    }, (reason) => {
    });

  }

  /*******************************************************************************
    설  명 : 게시글 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    delete() {
      if( confirm("게시글을 삭제하시겠습니까?") ) {
        this.sBoardService.deleteBoard( this.boardInfo.board_id, this.params.board_seq ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '게시글 삭제' );
  
            this.router.navigate(
            ['/board/gallery'],
            {
              relativeTo: this.activatedRoute,
              queryParams: this.params,
              queryParamsHandling: '', // remove to replace all query params by provided
            });
  
          } else {
            this.toastrService.error( response.ResultMessage, '게시글 삭제' );
          }
        });
      }
    }
  
    /*******************************************************************************
      설  명 : 글 저장/수정
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    write() {
      this.router.navigate(
      ['/board/add'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    }

  /*******************************************************************************
    설  명 : 삭제 가능 여부 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    authCheck() {
      // 관리자 권한일 경우 삭제 가능
      if( this.memberInfo.level >= 80 ) {
        return true;
      }
  
      // 작성자의 경우
      if( this.memberInfo.mem_no == this.boardData.writer ) return true;
  
      return false;
    }
  
  
}
