<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>
  
  <div class="mypage-contents">

  <form [formGroup]="boardWriteForm" (ngSubmit)="submit()">

    <input type="hidden" formControlName="board_id" />

    <div class="section">
      <div class="section-header">
        <div class="title page-title">1:1문의</div>
      </div>
      
      <table class="info-table">
      <colgroup>
        <col style="width: 160px">
        <col>
      </colgroup>  
      <tbody>
      <tr>
        <th><label for="radio">문의유형</label></th>
        <td>
	        <div class="radio pc-view" *ngFor="let group of groupTab">   
            <label class="radioSelect">{{group}} 
            <input type="radio" class="group_id" formControlName="group_id" [value]="group" [ngClass]="{formErrors:formErrors['group_id']}">
            <span class="checkmark"></span>
            </label>
	        </div>
          <div *ngIf="formErrors['group_id']" class="error-message error-message-radio">{{formErrors['group_id']}}</div>

	        <div class="mobile-view">
	          <select formControlName="group_id" [ngClass]="{formErrors:formErrors['group_id']}">
	            <option value="">문의 종류 선택</option>
	            <option *ngFor="let group of groupTab" [value]="group">{{group}}</option>
	          </select>
	        </div>
        </td>
      </tr>    
      <tr>
        <th><label for="col2">제목</label></th>
        <td><input type="text" id="subject" formControlName="subject" placeholder="제목을 입력해주세요!" />
        <span *ngIf="formErrors.subject" class="alert alert-danger">{{formErrors.subject}}</span>
        </td>
      </tr>
      <tr>
        <th class="vt"><label for="col3">내용</label></th>
        <td>
          <textarea id="contents" formControlName="contents" placeholder="문의 내용을 입력해주세요!"></textarea>
          <span *ngIf="formErrors.contents" class="alert alert-danger">{{formErrors.contents}}</span>
        </td>
      </tr> 
      </tbody>
      </table>    
      
		  <div class="btn-area">
		   <a routerLink="/mypage/myqna" class="basic-btn" >취소</a>
		   <button *ngIf="this.params.reply == false" type="submit" class="basic-btn red">문의하기</button>
		   <button *ngIf="this.params.reply == true" type="submit" class="basic-btn red">답글</button>
		  </div>          
    </div> 
  </form>
  </div>   
</div>

<app-footer></app-footer>

