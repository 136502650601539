<div class="modal-header">
  <h4 class="modal-title fl">구매후기 신고하기</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="guide">
      <div class="f12 fb">{{ data.id.length > 4 ? data.id.substr(0,4) + '****' : data.id.substr(0,2) + '****' }}</div><br/>
      <div [innerHTML]="data.comment | safe: 'html'"></div>
    </div>

    <div class="guide mt20">
      <ul>
        <li *ngFor="let row of reasonList">
          <label>
            <input type="radio" formControlName="reason" [value]="row.common_code" />
            <span class="checkmark"></span> {{row.common_name}}
          </label>
        </li>
      </ul>
    </div>
    <div *ngIf="formErrors['reason']" class="error-message">{{formErrors['reason']}}</div>

    <textarea class="inputbox" placeholder="내용입력" [ngClass]="{formErrors: formErrors['comment']}" formControlName="comment"></textarea>
    <div *ngIf="formErrors['comment']" class="error-message">{{formErrors['comment']}}</div>

    <div class="section">
      <div class="title">주의사항</div>
      <div>
        <div class="guide-txt">
          <p class="dot">·</p><p class="dot1">이 구매후기가 신고사유에 해당하는지 다시 한번 확인하시기 바랍니다.</p>
          <p class="dot">·</p><p class="dot1">신고기능은 구매후기 작성자에게 피해를 줄 수 있습니다.</p>
          <p class="dot">·</p><p class="dot1">1회 신고 시 자동으로 블라인드 처리 되며, 3회 신고 누적 시 표시가 제한됩니다.</p>
        </div>
      </div>
    </div>

    <div class="btn-area">
      <button class="btn1" (click)="activeModal.dismiss()">취소</button>
      <button class="btn1 red" (click)="submit()">확인</button>
    </div>
  </form>

</div>
