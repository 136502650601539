import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SProductService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 카테고리 리스트 가져오기
  getCategoryList( code: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getCategoryList'
      }, {
        categoryCode: code
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드 리스트 가져오기
  getBrandList( code: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getBrandList'
      }, {
        categoryCode: code
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 브랜드기준 카테고리 리스트 가져오기
  getBrandCategoryList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getBrandCategoryList'
      }, {
        brandSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사이즈 리스트 가져오기
  getSizeList( code: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getSizeList'
      }, {
        categoryCode: code
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 색상 리스트 가져오기
  getColorList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getColorList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 리스트 가져오기
  getProductList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getProductList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 상품 상세정보 가져오기
  getProductDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getProductDetail'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 찜한 상품 리스트 가져오기
  getKeepProductList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getKeepProductList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 찜한 상품 리스트 가져오기
  getKeepProductPageList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getKeepProductPageList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 찜페이지 찜상품 개별 삭제하기
  setDeleteKeep( product_seq:any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'setDeleteKeep'
      }, {
        product_seq:product_seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 찜페이지 찜상품 전체 삭제하기
  setAllDeleteKeep(): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'setAllDeleteKeep'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품 찜 여부 정보 가져오기
  getKeepProductInfo( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getKeepProductInfo'
      }, {
        productSeq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 상품 찜하기
  setKeepProductSave( product_seq_arr: any, keep_yn: boolean ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'setKeepProductSave'
      }, {
        productSeqArr: product_seq_arr,
        keepYn: keep_yn
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }  

  // 배송료 가져오기
  getConfigDelivery(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'config',
        version: '1.0',
        action: 'getConfig'
      }, {
        group: 'DELIVERY'
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 검색어 저장
  setSearchTextSave( searchText: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'setSearchTextSave'
      }, {
        searchText: searchText
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 검색어 조회
  getSearchText(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getSearchText'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
    
  // 구매후기 리스트 가져오기
  getReview( seq: any, reviewSort: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getReview'
      }, {
        product_seq: seq,
        review_sort: reviewSort
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  } 

  // 상품문의 저장
  setProductQuestion( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'setProductQuestion'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 상품문의 리스트 가져오기
  getProductQuestion( seq: any, isMy: any, state: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getProductQuestion'
      }, {
        product_seq: seq,
        isMy: isMy,
        state: state
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  } 

  // 구매후기 신고
  setOrderReviewReport( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'setOrderReviewReport'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }  

}
