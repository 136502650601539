<header class="modal-header">
  <h4 class="modal-title fl">공유하기</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</header>

<section class="modal-body">
  <ul class="sns-btn">
    <li><button class="kakaotalk" (click)="shareUrl('kakaotalk')"></button></li>
    <li><button class="facebook" (click)="shareUrl('facebook')"></button></li>
    <li><button class="twitter" (click)="shareUrl('twitter')"></button></li>
    <li><button class="kakaostory" (click)="shareUrl('kakaostory')"></button></li>
    <li><button class="band" (click)="shareUrl('band')"></button></li>
  </ul>
</section>
