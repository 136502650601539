/*******************************************************************************
  설  명 : 회사소개 - 회사소개 탭
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { BasicService } from '@app/service/basic.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public shopView = ''; //매장 사진 view

  public storeSeq: any;
  public storeName: any;

  public offlineStoreList: any = [];

  public isMobileApp: Boolean = false;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public router: Router,
    private location: Location,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private basicService: BasicService
  ) {
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( params => {
      this.storeSeq = ( params.storeSeq === undefined ) ? '' : params.storeSeq;
      this.storeName = ( params.storeName === undefined ) ? '' : params.storeName;

      this.getStoreListOff();
    });

    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

  /*******************************************************************************
    설  명 : 오프라인 매장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getStoreListOff() {
    await this.basicService.getStoreListOff({}).then( response => {
      if( response.ResultCode ) {
        this.offlineStoreList = response.data;

        if( this.storeSeq !== '' ) {
          setTimeout(() => {
            this.scrollToElement('store' + this.storeSeq);
            this.shopView = this.storeName;
          }, 0);
        }
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    }, error => {
      this.toastrService.error( error, '');
    });
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 이동
  *******************************************************************************/
  scrollToElement(element) : void {
    (document.getElementById( element ) as HTMLElement).scrollIntoView();
  }

}
