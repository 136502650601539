/*******************************************************************************
  설  명 : 마이페이지 - 회원탈퇴 성공
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mypage-out-success',
  templateUrl: './mypage-out-success.component.html',
  styleUrls: ['./mypage-out-success.component.scss']
})
export class MypageOutSuccessComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/




  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor() { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

}
