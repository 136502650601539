<app-header></app-header>

<div class="inner">
  <div class="page-header">
    <div class="page-title">고객센터</div>
  </div>
  
  <div class="board-link clearfix">
    <div>
      <div>자주하는 질문</div>
      <div>상품·배송·교환 등 궁금한 분야의<br/>질문들을 검색해 보세요.</div>
      <a class="cp" (click)="goBoard('faq')">더보기</a> 
    </div>
    <div>
      <div>1:1 문의하기</div>
      <div>자세한 문의사항은 1:1문의하기로<br/>문의해주세요.</div>
      <a routerLink="/mypage/myqna">더보기</a> 
    </div>    
  </div>
  
  <div class="board">
    <div class="board-header clearfix">
      <div class="board-title">자주하는 질문<span class="red"> TOP5</span></div>
      <a (click)="goBoard('faq')" class="basic-btn sm-btn cp">더보기</a>
    </div>
    
	  <table class="list-table faq-table">
	  <colgroup class="pc-view">
	    <col style="width: 7%">
	    <col style="width: 11%">
	    <col style="width: 81%">
	  </colgroup>    
	
	  <thead>
	  <tr>
	    <th>번호</th>
	    <th>분류</th>
	    <th>제목</th>
	  </tr>    
	  </thead>
	  
	  <tbody> <!-- viewDown class 사용-->
		<tr *ngFor="let row of boardFaqListLimit" id="tr_{{row.board_seq}}">
		  <td (click)="viewDown(row)">{{row.board_seq}}</td>
		  <td (click)="viewDown(row)">{{row.group_id}}</td>
		  <td class="tl title" (click)="viewDown(row)">{{row.subject}}</td>
		</tr>
      </tbody>
	  </table> 
  </div>	  
  
	<div class="board">
	  <div class="board-header clearfix">
	    <div class="board-title">공지사항</div>
	    <a routerLink="/board/list" class="basic-btn sm-btn">더보기</a>
	  </div>
	  
	  <table class="list-table notice-table">
	  <colgroup class="pc-view">
	    <col style="width: 7%">
	    <col style="width: 71%">
	    <col style="width: 11%">
	    <col style="width: 11%">
	  </colgroup>    
	
	  <thead>
	  <tr>
	    <th>번호</th>
	    <th>제목</th>
	    <th>등록일</th>
	    <th>조회수</th>
	  </tr>    
	  </thead>
	  
	  <tbody>
			<tr *ngFor='let row of boardList' [ngClass]="{important:row.is_notice==1}">
				<td *ngIf="row.is_notice==1" class="red" >중요</td>
				<td *ngIf="row.is_notice==1" class="tl title important">
					<a href="javascript:;" [innerHTML]="row.subject" (click)="view(row)"></a>
					<div class="mobile-view important">{{row.write_date.substr(0,10)}}</div>
					<div class="mobile-view">{{row.write_date.substr(0,10)}}</div>
				</td>
				<td>{{row.write_date.substr(0,10)}}</td>
				<td>{{row.hit}}</td>
			</tr>
			<tr *ngFor='let row of boardNoticeListLimit' [ngClass]="{important:row.is_notice==1}">
				<td *ngIf="row.is_notice==1" class="red" >중요</td>
				<td *ngIf="row.is_notice==0" >{{row.board_seq}}</td>
				<td *ngIf="row.is_notice==1" class="tl title important">
					<a href="javascript:;" [innerHTML]="row.subject" (click)="view(row)"></a>
					<div class="mobile-view important">{{row.write_date.substr(0,10)}}</div>
				</td>
				<td *ngIf="row.is_notice==0" class="tl title">
					<a href="javascript:;" [innerHTML]="row.subject" (click)="view(row)"></a>
					<div class="mobile-view">{{row.write_date.substr(0,10)}}</div>
				</td>
				<td>{{row.write_date.substr(0,10)}}</td>
				<td>{{row.hit}}</td>
			</tr>
			<tr *ngIf="boardNoticeListLimit.length < 1">
				<td colspan="6" class="center">내용이 없습니다</td>
			</tr>
	  </tbody>
	  </table> 
	</div>     
  
</div>  

<app-footer></app-footer>