import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { SBoardService } from '@app/service/board.service';

@Component({
  selector: 'app-mypage-myqna',
  templateUrl: './mypage-myqna.component.html',
  styleUrls: ['./mypage-myqna.component.scss']
})
export class MypageMyqnaComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  private subscription: Subscription = new Subscription;

  public boardInfo: any = {};
  public memberInfo: any = {};
  public openRow: any = [];

  public params: any = {
    type: 'list',
    board_id: 'question',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: '',
    board_seq: ''
  }
  
  public linecnt: any = [];
  public boardList = [];

  public totalCount: number;

  public detailView: any = false; // 모바일 문의내용보기

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
		private router: Router,
    private activatedRoute: ActivatedRoute,
		private sBoardService: SBoardService,
		private authService: AuthService,
  ) { }
  
  /*******************************************************************************
    설  명 : 데이터 로딩 처리 수정자: 2/10 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.subscription = this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'question' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '' ) ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      this.getBoardInfo( this.params.board_id );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기 수정자: 2/10 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.sBoardService.getBoardInfoId( board_id ).then( response => {
      if( response.data == null ) {
        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/main'] );
        return false;
      }

      this.boardInfo = response.data;

      if( this.params.type == '' ){
        switch( this.boardInfo.lvt_default ){
          default: case 'L': this.params.type = 'list';
        }
      } // 게시판 내용 부분 faq, question, list로 분류한 뒤 요청에 따라 불러옴.

      if( this.params.type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');

      this.getBoardList();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 정보 가져오기 수정자: 2/10 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.sBoardService.getBoardList( this.params ).then( response => {
      this.boardList = response.data;
      this.boardInfo.total_count = response.total;
      this.totalCount = parseInt( response.total );

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리 수정자: 2/10 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.openRow.map( rows => {
      $("#child_" + rows).remove();
    });    
    this.openRow = [];

    this.params.no = page;

    this.router.navigate(
    ['/mypage/myqna'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상세보기 페이지 변동없이 내려보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  viewDown( row: any ) {
    let child = $("#child_"+ row.board_seq);

    if( child.length < 1 ) {
      let node = '<tr id="child_'+ row.board_seq +'" class="viewdown"><td colspan="6" style="padding: 30px 0px 40px 0px;line-height: 150%;" class="tl">'+ row.contents_nl2br +'</td></tr>';
      $("#tr_" + row.board_seq).after( node );
    } else {
      $("#child_" + row.board_seq).remove();
    }
  }
  
  /*******************************************************************************
    설  명 : 글 쓰기 권한
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  authWriteCheck(): boolean {
    if( this.boardInfo.auth_write <= this.memberInfo.level ) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 상세보기 수정자:2/16 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  view( row: any ) {
    this.params.board_seq = row.board_seq;

    this.router.navigate(
    ['/board/view'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
