<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<section class="contents">

  <div class="main-img">
    <img src="/assets/images/sena_intro.jpg">
    <div class="title">세나정품등록</div>
  </div>

  <div class="section inner">
    <div class="icon">
      <li style="background-position: calc(-45px * 7 ) 0;"></li>
      <li style="background-position: calc(-45px * 8 ) 0;"></li>
      <li style="background-position: calc(-45px * 9 ) 0;"></li>
      <li style="background-position: calc(-45px * 10) 0;"></li>
      <li style="background-position: calc(-45px * 11) 0;"></li>
      <li style="background-position: calc(-45px * 12) 0;"></li>
    </div>

    <article class="article">
      <div class="title">세나정품등록</div>
      <form [formGroup]="form">
        <div>
          <p><span>*</span>성명</p>
          <input type="text" formControlName="registerName" placeholder="" [ngClass]="{formErrors:formErrors['registerName']}" />
          <div *ngIf="formErrors['registerName']" class="error-message-contact">{{formErrors['registerName']}}</div>
        </div>
        <div>
          <p><span>*</span>연락처</p>
          <input type="text" formControlName="registerHp" placeholder="공백은 제외하고 입력" [ngClass]="{formErrors:formErrors['registerHp']}" />
          <div *ngIf="formErrors['registerHp']" class="error-message-contact">{{formErrors['registerHp']}}</div>
        </div>
        <div>
          <p>이메일</p>
          <input type="text" formControlName="registerEmail" placeholder="" [ngClass]="{formErrors:formErrors['registerEmail']}" />
        </div>
        <div>
          <p><span>*</span>구매일자</p>
          <input type="date" formControlName="buyDate" placeholder="" [ngClass]="{formErrors:formErrors['buyDate']}" />
          <div *ngIf="formErrors['buyDate']" class="error-message-contact">{{formErrors['buyDate']}}</div>
        </div>
        <div>
          <p><span>*</span>세나시리얼번호</p>
          <input type="text" formControlName="serialNo" placeholder="" [ngClass]="{formErrors:formErrors['serialNo']}"  />
          <div *ngIf="formErrors['serialNo']" class="error-message-contact">{{formErrors['serialNo']}}</div>
        </div>
        <div>
          <input type="checkbox" id="개인정보동의" formControlName="agreement">
          <label for="개인정보동의">
            <a routerLink="/company/policy/privacy">개인정보취급방침</a> 에 동의합니다.
          </label>
        </div>
        <button type="submit" (click)="submit()">등록하기</button>
      </form>

      <p class="mt20">* 세나 AS 기간을 추가로 연장하기 위하여 반드시 구매일 30일 이내에 세나 코리아에 정품 등록 해주십시오. 추가로 AS 1~2년 연장 가능합니다.</p>

      <a href="https://www.senakorea.kr/support/product-registration/" target="_blank" >
        <img src="/assets/images/sena_btn.png" class="mt20">
      </a>

    </article>
  </div>

</section>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>
