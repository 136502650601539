import { Injectable } from '@angular/core';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 비밀번호 찾기 임시비밀번호 문자 발송
  sendSMS_directsend( hp: any, num: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'sms',
        version: '1.0',
        action: 'sendSMS_directsend'
      }, {
        hp: hp,
        num: num
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

}