/*******************************************************************************
  설  명 : 배송지 관리 모달
  생성일 : 
  생성자 : 
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SMemberService } from '@app/service/member.service';

import { MypageDestinationAddComponent } from '@page/mypage/mypage-destination/mypage-destination-add/mypage-destination-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '885',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-destination',
  templateUrl: './mypage-destination.component.html',
  styleUrls: ['./mypage-destination.component.scss']
})
export class MypageDestinationComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() choice: any = '';

  public addressList: any = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private sMemberService: SMemberService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getMemberAddressList();
  }
  
  /*******************************************************************************
    설  명 : 주문자 배송지 정보 가져오기
  *******************************************************************************/  
  getMemberAddressList() {
    this.sMemberService.getMemberAddressList().then(response => {
      if(response.ResultCode) {
        this.addressList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 라디오 버튼 기본 체크
  *******************************************************************************/
  setisChecked(index: number) {
    this.addressList.forEach((obj, key) => {
      if( index === key ) {
        obj.isChecked = true;
      } else {
        obj.isChecked = false
      }
    });
  }

  /*******************************************************************************
    설  명 : 체크 여부
  *******************************************************************************/
  getIsChecked(item: any) {
    if( item.isChecked == 1 ) return true;
    else return false;
  }
  
  /*******************************************************************************
    설  명 : 배송지 추가/수정/삭제
  *******************************************************************************/
  openDestinationAdd( item: any ) {
    const modalRef = this.modalService.open(MypageDestinationAddComponent, options);

    modalRef.componentInstance.item = item;

    modalRef.result.then((result) => {
      //배송지 목록 다시 가져오기
      if( result ) {
        this.getMemberAddressList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 기본 배송지로 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberDefaultAddressSave() {
    let row = this.addressList.filter(item => item.isChecked == 1 );
    
    if( row.length < 1 ) {
      this.toastrService.error( '기본배송지로 설정할 주소를 선택하시기 바랍니다.', '');
    } else {
      if( confirm('선택한 주소를 기본배송지로 설정하시겠습니까?') ) {
        this.sMemberService.setMemberDefaultAddressSave( row[0].seq ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getMemberAddressList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 배송지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberAddressDelete( seq: number ) {
    if( confirm('해당 주소를 삭제하시겠습니까?') ) {
      this.sMemberService.setMemberAddressDelete( seq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getMemberAddressList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 배송지 선택
  *******************************************************************************/
  addressChoice() {
    let row = this.addressList.filter(item => item.isChecked == 1 );

    if( row.length < 1 ) {
      this.toastrService.error( '배송지를 선택하시기 바랍니다.', '');
    } else {
      this.activeModal.close( row[0] );
    }
  }
    
}
