import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare let Kakao;


@Component({
  selector: 'app-product-share',
  templateUrl: './product-share.component.html',
  styleUrls: ['./product-share.component.scss']
})


export class ProductShareComponent implements OnInit {

  public url: any;
  public img: any;
  public title: any;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if( typeof Kakao !== "undefined" && !Kakao.isInitialized() ) {
      Kakao?.init('7194fc0a6697ef357a530524f4c87e3f');
    }

  }

  shareUrl(item) {
    let link: any;
    switch (item) {
      case 'facebook':
        link = `http://www.facebook.com/sharer/sharer.php?u=${this.url}`
        break;
      case 'kakaostory':
        link = `https://story.kakao.com/share?url=${this.url}`
        break;
      case 'kakaotalk':
        if( typeof Kakao !== "undefined" && Kakao.isInitialized()  ) {
          Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
              title: this.title,
              description: 'Check out this content!',
              imageUrl: this.img,
              link: {
                webUrl: this.url,
                mobileWebUrl: this.url
              }
            },
            buttons: [
              {
                title: '웹으로 보기',
                link: {
                  webUrl: this.url,
                  mobileWebUrl: this.url
                }
              },
              // {
              //   title: '앱으로 보기',
              //   link: {
              //     webUrl: this.url,
              //     mobileWebUrl: this.url
              //   }
              // }
            ]
          });
        } else {
          console.error("Kakao is not initialized or undefined.");
        }
        break;
      case 'band':
        link = `http://band.us/plugin/share?route=${this.url}`;
        break;
      case 'twitter':
        link = `https://twitter.com/intent/tweet?url=${this.url}`;
        break;
    }

    if( item !== 'kakaotalk' ) {
      window.open(link, '_system');
    }
  }

}
