<ng-template #notLoggedInInfo></ng-template>

<div class="header pc-view">
  <!-- <div id="headerSociety1" class="header-member-society" *ngIf="memberInfo.isSociety==undefined || memberInfo.isSociety==0">
    <div class="inner">
      <div class="txt">바이크마트 동호회 혜택을 받으세요!</div>
      <div class="btns">
        <a routerLink="/guide/society-benefit/society/info" class="link-btn">바마동호회란?</a>
        <a routerLink="/guide/society-benefit/society" class="link-btn">동호회 혜택</a>
        <a routerLink="/company/society" class="link-btn">동호회 등록</a>
        <a routerLink="/mypage/society" class="link-btn">동호회 회원등록</a>
      </div>
    </div>
    <button class="header-member-society-close" (click)="closeSociety('headerSociety1')">&times;</button>
  </div>
  <div id="headerSociety2" class="header-member-society" *ngIf="memberInfo.isSociety>0">
    <div class="inner">
      <div class="txt">{{memberInfo?.society_name}} 동호회 회원님 환영합니다.</div>
      <div class="btns">
        <a routerLink="/mypage/society/info" class="link-btn1">내 동호회 보기</a>
      </div>
      <button class="header-member-society-close" (click)="closeSociety('headerSociety2')">&times;</button>
    </div>
  </div> -->
  <div class="header1">
    혜택 필요하지 않으신가요?<a routerLink="/guide/membership/grade">바이크마트 회원등급안내</a>
  </div>
  <div class="header2">
    <div class="logo-area inner clearfix">
      <div class="logo">
        <a routerLink="/"><img src="/assets/images/logo2.png" alt="바이크마트 로고"></a>
      </div>
      <div class="search-wrap">
        <div class="search-box">
          <input type="text" class="input-box" placeholder="최대 15%할인! 회원등급을 확인하세요!" name="searchText" [(ngModel)]="searchText" (keypress)="searchList($event)" autocomplete="off" />
          <button type="button" class="search-btn" (click)="goProductSearch()"><img src="/assets/images/icon_search2.png" /></button>
          <div class="search-list">
            <div class="lately">
              <ul>최근 검색어
                <li *ngIf="searchTextList.length < 1">최근 검색어 내역이 없습니다.</li>
                <li *ngFor="let item of searchTextList" (click)="searchText=item;goProductSearch();">{{item}}</li>
              </ul>
            </div>
            <div class="popular">
              <ul>주간 인기검색어
                <li *ngIf="popularSearchText.length < 1">인기 검색 내역이 없습니다.</li>
                <li *ngFor="let item of popularSearchText; let i = index" (click)="searchText=item.text; goProductSearch();"><span>{{i + 1}}</span>{{item.text}}</li>
              </ul>
              <div class="update-date">{{popularUpdateDate}} 업데이트</div>
            </div>
          </div>
        </div>
      </div>
      <ul class="util-ul clearfix" *ngIf="isLogin$ | async as isLoggedIn; else notLoggedIn">
        <li><a class="cp" (click)="setLogout()">로그아웃</a></li>
        <li><a routerLink="/mypage/dashboard">마이페이지</a></li>
        <li><a routerLink="/customer/center">고객센터</a></li>
        <li><a routerLink="/mypage/nonmember/form">비회원 주문조회</a></li>
      </ul>
      <ng-template #notLoggedIn>
        <ul class="util-ul clearfix">
          <li><a routerLink="/member/join" class="cred">회원가입</a></li>
          <li><a routerLink="/member/login">로그인</a></li>
          <li><a routerLink="/customer/center">고객센터</a></li>
          <li><a routerLink="/mypage/nonmember/form">비회원 주문조회</a></li>
        </ul>
      </ng-template>
    </div>
    <div class="inner clearfix">
      <ul class="menu-ul clearfix">
        <li class="tot">
          <div>전체카테고리</div>
          <ul class="tot-category">
            <li *ngFor="let item1 of totalCategory">
              <a routerLink="/product/list" [queryParams]="{categoryCode: item1.value}">{{item1.label}}</a>
              <ul *ngIf="item1.child.length > 0" class="depth1">
                <li *ngFor="let item2 of item1.child">
                  <a routerLink="/product/list" [queryParams]="{categoryCode: item2.value}">{{item2.label}}</a>
                  <ul *ngIf="item2.child.length > 0" class="depth2">
                    <li *ngFor="let item3 of item2.child">
                      <a routerLink="/product/list" [queryParams]="{categoryCode: item3.value}">{{item3.label}}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li><a class="cp" (click)="goPage('new')">신상품</a></li>
        <li><a class="cp" (click)="goPage('best')">베스트</a></li>
        <li><a routerLink="/event" [queryParams]="{seq: 6}">리퍼존</a></li>
        <li><a routerLink="/event" [queryParams]="{seq: 361}">아울렛</a></li>
      </ul>
      <ul class="menu-ul menu1 clearfix">
        <li><a routerLink="/event">이벤트</a></li>
        <!-- <li><a routerLink="/event/coupon">쿠폰이벤트</a></li>  -->
        <li><a class="cp" (click)="goBoard('notice')">공지사항</a></li>
        <li><a class="cp" (click)="goPage('bestReview')">리뷰</a></li>
      </ul>
      <ul class="menu-ul menu1 clearfix">
        <li><a href="https://bikeweb.bikemart.co.kr/" target="_blank">오토바이</a></li>
        <li><a routerLink="/brand">브랜드</a></li>
        <li><a routerLink="/society/community">동호회</a></li>   
<!--
        시행 하게 되면 주석 살릴 것
        <li><a routerLink="/event" [queryParams]="{seq: 6}">배달템</a></li>
        <li><a routerLink="/event" [queryParams]="{seq: 361}">시즌특가</a></li> -->
      </ul>
      <ul class="util-ul1 clearfix">
        <!-- <li><a href="https://bikeweb.bikemart.co.kr/" target="_blank" class="bike"></a></li> -->
        <li>
          <mat-icon title="바마쿠폰"  routerLink="/mypage/coupon" class="material-icons-outlined mr10" [matBadge]="bamaCouponCnt" matBadgeColor="accent" matBadgeSize="small" style="font-size:35px">loyalty</mat-icon>
          <mat-icon title="장바구니"  routerLink="/cart" class="material-icons-outlined" [matBadge]="cartBadgeCnt" matBadgeColor="accent" matBadgeSize="small" style="font-size:35px">shopping_cart</mat-icon>
<!--        <a routerLink="/cart" class="cart"></a>  -->
        </li>
        <!-- <li><a routerLink="/mypage/dashboard" class="mypage"></a></li> -->
      </ul>
    </div>
  </div>
</div>

<!-- 모바일 header -->
<div class="mheader mobile-view">
  <!-- <div id="headerSocietyM1" class="header-member-society" *ngIf="memberInfo.isSociety==undefined || memberInfo.isSociety==0">
    <div class="inner">
      <div class="btns">
        <a routerLink="/guide/society-benefit/society/info" class="link-btn">바마동호회란?</a>
        <a routerLink="/guide/society-benefit/society" class="link-btn">동호회 혜택</a>
        <a routerLink="/company/society" class="link-btn">동호회 등록</a>
        <a routerLink="/mypage/society" class="link-btn">동호회 회원등록</a>
      </div>
    </div>
    <button class="header-member-society-close" (click)="closeSociety('headerSocietyM1')">&times;</button>
  </div>
  <div id="headerSocietyM2" class="header-member-society" *ngIf="memberInfo.isSociety>0">
    <div class="inner">
      <div class="txt">{{memberInfo?.society_name}} 동호회 회원님 환영합니다.</div>
      <div class="btns">
        <a routerLink="/mypage/society/info" class="link-btn1">내 동호회 보기</a>
      </div>
      <button class="header-member-society-close" (click)="closeSociety('headerSocietyM2')">&times;</button>
    </div>
  </div> -->
  <div class="header1 clearfix">
    <div class="menu-open" (click)="menuOpen=true"></div>
    <div class="logo">
      <a routerLink="/"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
    </div>
    <a routerLink="/mypage/dashboard" class="mypage"></a>
    <mat-icon
      title="장바구니"
      routerLink="/cart"
      class="material-icons-outlined mr10 fr mt10 "
      [matBadge]="cartBadgeCnt"
      matBadgeColor="accent"
      matBadgeSize="small"
      style="font-size:23px"
    >
    shopping_cart
    </mat-icon>
    <mat-icon
      title="바마쿠폰"
      routerLink="/mypage/coupon"
      class="material-icons-outlined mr10 fr mt10"
      [matBadge]="bamaCouponCnt"
      matBadgeColor="accent"
      matBadgeSize="small"
      style="font-size:23px"
    >
     loyalty
    </mat-icon>
 <!--   <a routerLink="/cart" class="cart"></a> -->
    <a href="https://bikeweb.bikemart.co.kr/" target="_blank" class="bike"></a>
  </div>
  <div class="header2">
	  <div class="search-box">
      <input type="search" class="input-box" placeholder="" name="searchText" [(ngModel)]="searchText" (keypress)="searchList($event)" />
      <button type="button" class="search-btn" (click)="goProductSearch()"></button>
	  </div>
  </div>
  <div class="header3">
    <ul>
      <li><a routerLink="/" class="cred">홈</a></li>
      <li><a class="cp" (click)="goPage('new')">신상품</a></li>
      <li><a class="cp" (click)="goPage('best')">베스트</a></li>
      <li><a routerLink="/event" [queryParams]="{seq: 6}">리퍼존</a></li>
      <li><a routerLink="/event" [queryParams]="{seq: 361}">아울렛</a></li>
      <li><a routerLink="/society/community">동호회</a></li>
      <li class="cb"><a routerLink="/event">이벤트</a></li>
      <!-- <li><a routerLink="/event/coupon">쿠폰이벤트</a></li>  -->
      <li><a routerLink="/brand">브랜드</a></li>
      <li><a routerLink="/guide/membership/grade">회원등급안내</a></li>
    </ul>
  </div>

  <div class="menu" [ngClass]="{open:menuOpen}">
    <div class="menu-header">
      <div class="clearfix">
        <!-- <div class="user"><a routerLink="/member/login" class="login">로그인이 필요합니다.</a></div> -->
        <div class="user">
  				<a *ngIf="isLogin$ | async as isLoggedIn; else notLoggedIn1">{{memberInfo?.name}}님 환영합니다.
            &nbsp;&nbsp;<span (click)="setLogout()">로그아웃</span></a>
            <ng-template #notLoggedIn1>
              <a routerLink="/member/login" class="login">로그인/회원가입</a>
            </ng-template>
        </div>
        <button class="close-btn" (click)="menuOpen=false"></button>
      </div>
	    <div class="mileage">
	      <a routerLink="/mypage/mileage" (click)="menuOpen=false">
	        <p class="fl"><span class="red">MY</span>마일리지</p>
	        <p class="fr"><span class="red">{{remainedMileage | number}}</span>M</p>
	      </a>
	    </div>
    </div>
    <ul class="quick-menu clearfix">
      <li><a routerLink="/mypage/dashboard" (click)="menuOpen=false">나의정보</a></li>
      <li><a routerLink="/mypage/order" (click)="menuOpen=false">구매내역</a></li>
      <li><a class="cp" (click)="alertMessage('준비중입니다.', '쿠폰함')">쿠폰함</a></li>
      <li><a routerLink="/mypage/mileage" (click)="menuOpen=false">마일리지</a></li>
      <li><a routerLink="/cart" (click)="menuOpen=false">장바구니</a></li>
      <li><a routerLink="/mypage/favorite" (click)="menuOpen=false">찜리스트</a></li>
      <li><a href="https://bikeweb.bikemart.co.kr/" target="_blank">오토바이</a></li>
      <li><a routerLink="/customer/center" (click)="menuOpen=false">고객센터</a></li>
    </ul>
    <div class="event-menu"><a routerLink="/event" (click)="menuOpen=false">이벤트 바로가기</a></div>
    <!-- <div class="event-menu"><a routerLink="/event/coupon" (click)="menuOpen=false">쿠폰이벤트 바로가기</a></div> -->
    <ul class="category clearfix">
      <li [ngClass]="{active:mcategoryIndex==1}" (click)="mcategoryIndex=1">라이더용품</li>
      <li [ngClass]="{active:mcategoryIndex==2}" (click)="mcategoryIndex=2">오토바이 부품</li>
    </ul>
    <ul class="category-list clearfix" *ngIf="mcategoryIndex == 1">
      <ng-container *ngFor="let row of categoryList">
        <li>
          <a (click)="goLink('/product/list', 'category', row.category_code);menuOpen=false">
            <span class="img">
              <img [src]="getImageLink(row.category_name)">
            </span>{{row.category_name}}
          </a>
        </li>
      </ng-container>
    </ul>
    <ul class="category-list clearfix" *ngIf="mcategoryIndex == 2">
      <ng-container *ngFor="let row of bikePartsList">
        <li>
          <a (click)="goLink('/product/list', 'category', row.category_code);menuOpen=false">
            <span class="img">
              <img [src]="getImageLink(row.category_name)">
            </span>{{row.category_name}}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

