<app-header></app-header>

<div class="main-banner pc-view">
  <swiper [config]="SwiperConfigInterface_banner" [(index)]="bannerIndex" (indexChange)="changeSwiperIndex($event)">
	  <div *ngFor="let item of eventList">
      <ng-container *ngIf="item.event_link">
        <a routerLink="{{item.event_link}}">
          <img [src]="item.image_url" alt="{{item.event_name}}" />
        </a>
      </ng-container>
      <ng-container *ngIf="!item.event_link">
        <a routerLink="/event" [queryParams]="{seq: item.seq}">
          <img [src]="item.image_url" alt="{{item.event_name}}" />
        </a>
      </ng-container>
    </div>
  </swiper>
  <div class="inner">
    <div class="swiper-pagination-custom">
      <ng-container *ngFor="let item of eventList; let i = index">
        <a routerLink="/event" [queryParams]="{seq: item.seq}" (mouseover)="bannerIndex = i;">{{item.event_name}}</a>
      </ng-container>
    </div>
    <div *ngIf="eventList.length > 0" class="all-view" (click)="allView = !allView;"><div class="rect"></div><div class="rect"></div><div class="rect"></div><div class="rect"></div></div>
  </div>
  <div class="all-banner" [ngClass]="{view:allView}">
	  <div *ngFor="let item of eventList">
      <ng-container *ngIf="item.event_link">
        <a routerLink="{{item.event_link}}">
          <img [src]="item.image_url" alt="{{item.event_name}}" />
        </a>
      </ng-container>
      <ng-container *ngIf="!item.event_link">
        <a routerLink="/event" [queryParams]="{seq: item.seq}">
          <img [src]="item.image_url" alt="{{item.event_name}}" />
        </a>
      </ng-container>      
    </div>
  </div>
</div>

<!-- 모바일 메인배너 -->
<div class="mmain-banner mobile-view">
  <swiper [config]="SwiperConfigInterface_mbanner" [(index)]="bannerIndex" (indexChange)="changeSwiperIndex($event)">
	  <div *ngFor="let item of eventList">
      <ng-container *ngIf="item.event_link">
        <a routerLink="{{item.event_link}}">
          <img [src]="item.image_url" alt="{{item.event_name}}" />
        </a>
      </ng-container>
      <ng-container *ngIf="!item.event_link">
        <a routerLink="/event" [queryParams]="{seq: item.seq}">
          <img [src]="item.image_url" alt="{{item.event_name}}" />
        </a>
      </ng-container>
    </div>
  </swiper>
</div>

<!-- <div class="black-b pc-view clearfix">
  <a routerLink="/product/list" class="title">
    <p>블랙비자칼</p>
    <p>Black B Jackal</p>
  </a>
  <div class="right">
    <a routerLink="/product/list">
      <img alt="바지 블랙비자칼 블랙비자칼 BBJ-JEDI-X 라이딩진 청바지 보호대파우치" src="/assets/images/2311306_1.jpg">
    </a>
    <a routerLink="/product/info?productSeq=230811">
      <img alt="바지 블랙비자칼 BBJ-KIMO WARM 기모웜 청바지" src="/assets/images/217777_1.jpg">
    </a>
    <a routerLink="/product/info?productSeq=242803">
      <img alt="바지 블랙비자칼 BBJ JEDI R2 라이딩진 청바지" src="/assets/images/214105_1.jpg">
    </a>
  </div>
</div> -->

<!-- <div class="youtube pc-view">
	<a href="https://www.youtube.com/channel/UCtNpANEiwnZEcdV3DbDMP9A" class="clearfix">
	  <div>
      <p>Official YouTube</p>
      <p>유튜브에서도 바이크마트를 만나보세요.<br>각종 이벤트 소식과 상품 정보까지!</p>
    </div>
    <img src="/assets/images/youtube_character.png">
	</a>
</div> -->

<!-- <div class="short-banner inner pc-view">
  <a routerLink="#" class="banner">
    <div>공식대리점</div>  
  </a>
  <div class="goods-list clearfix">
    <app-goods-card *ngFor="let row of senaList" [productInfo]="row"></app-goods-card>
  </div>
  <div class="cb"></div>
</div> -->

<ng-container *ngIf="superSaleList.length > 0">
  <div class="new section mt40">
    <div class="bg"></div>
    <div class="title">{{superSaleList[0].sEventName}}</div>
    <div *ngIf="isLogIn===false" class="sub-title cred">로그인(회원가입)하셔서 슈퍼세일 가격을 확인하세요!</div>
    <div class="supersale-category">
      <ul class="clearfix">
        <li [ngClass]="{on: eventCategoryCode == '' }" (click)="setSuperSaleEventCategory('')">전체보기</li>
        <li [ngClass]="{on: eventCategoryCode == '001001' }" (click)="setSuperSaleEventCategory('001001')">헬멧</li>
        <li [ngClass]="{on: eventCategoryCode == '001003' }" (click)="setSuperSaleEventCategory('001003')">자켓</li>
        <li [ngClass]="{on: eventCategoryCode == '001005' }" (click)="setSuperSaleEventCategory('001005')">팬츠</li>
        <li [ngClass]="{on: eventCategoryCode == '001004' }" (click)="setSuperSaleEventCategory('001004')">부츠</li>
        <li [ngClass]="{on: eventCategoryCode == '001006' }" (click)="setSuperSaleEventCategory('001006')">장갑</li>
        <li [ngClass]="{on: eventCategoryCode == '001014' }" (click)="setSuperSaleEventCategory('001014')">블루투스</li>
        <li [ngClass]="{on: eventCategoryCode == '999999' }" (click)="setSuperSaleEventCategory('999999')">기타</li>
      </ul>
    </div> 
    <div class="goods-list inner clearfix">
      <app-goods-card *ngFor="let row of superSaleList" [productInfo]="row"></app-goods-card>
    </div> 
  </div>
</ng-container>

<ng-container *ngIf="rewardSaleList.length > 0">
  <div class="new section mt40">
    <div class="bg"></div>
    <div class="title">{{rewardSaleList[0].sEventName}}</div>
    <div class="goods-list inner clearfix">
      <app-goods-card *ngFor="let row of rewardSaleList" [productInfo]="row"></app-goods-card>
    </div> 
  </div>
</ng-container>

<ng-container *ngIf="best100List.length > 0">
  <div class="best section mt40" [ngClass]="{more:bestGoodsMore}">
    <div class="bg"></div>
    <div class="title">베스트 100</div>
    <div class="goods-list inner clearfix">
      <app-goods-card *ngFor="let row of best100List | slice:0:( !bestGoodsMore ? 20 : best100List.length)" [productInfo]="row"></app-goods-card>
    </div>  
    <div class="more" (click)="bestGoodsMore = !bestGoodsMore;">{{ !bestGoodsMore ? '펼쳐보기' : '닫아두기' }}</div>
  </div>
</ng-container>

<ng-container *ngIf="newList.length > 0">
  <div class="new section mt40">
    <div class="bg"></div>
    <div class="title">새로 나왔어요!</div>
    <div class="goods-list inner clearfix">
      <app-goods-card *ngFor="let row of newList" [productInfo]="row"></app-goods-card>
    </div> 
  </div>
</ng-container>

<ng-container *ngIf="honey365List.length > 0">
  <div id="honey365" class="single section mt40 inner" [ngClass]="{more:singleGoodsMore}">
    <img src="/assets/images/section_honeyget.jpg" />

    <ul class="classification clearfix">
      <li *ngFor="let row of honey365CategoryList" [ngClass]="{active:row.category_seq == honey365Selected}" (click)="setHoney365CategorySelected(row.category_seq)">{{row.category_name}}<span>({{row.total_count | number}})</span></li>
      <!-- <li (click)="setAllHoney365BrandSelected()">전체보기<span>({{honey365BrandList.length | number}})</span></li> -->
    </ul>
    <ul class="classification clearfix">
      <li *ngFor="let row of honey365BrandList" [ngClass]="{active:row.brand_seq == honey365Selected}" (click)="setHoney365BrandSelected(row.brand_seq)">{{row.brand_name}}<span>({{row.total_count | number}})</span></li>
    </ul>
    <div class="goods-list clearfix">
      <app-goods-card *ngFor="let row of honey365List | slice:0:( !singleGoodsMore ? 12 : honey365List.length)" [productInfo]="row"></app-goods-card>
    </div>
    <div class="more" (click)="singleGoodsMore = !singleGoodsMore;">{{ !singleGoodsMore ? '펼쳐보기' : '닫아두기' }}</div>
  </div>
</ng-container>

<ng-container *ngIf="monthSoldList.length > 0">
  <div class="md section mt40">
    <div class="bg"></div>
    <div class="title">이달의 BEST</div>
    <div class="goods-list inner clearfix">
      <app-goods-card *ngFor="let row of monthSoldList" [productInfo]="row"></app-goods-card>
    </div> 
  </div>
</ng-container>

<div class="brand section mt40">
  <div class="bg"></div>
  <div class="title">브랜드</div>
  <ul class="inner clearfix">
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'agv'}" target="_blank">
        <img src="/assets/images/brand/agv.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'alpinestars'}" target="_blank">
        <img src="/assets/images/brand/alpinestars.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'arai'}" target="_blank">
        <img src="/assets/images/brand/arai.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'arlenness'}" target="_blank">
        <img src="/assets/images/brand/arlenness.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'bbj'}" target="_blank">
        <img src="/assets/images/brand/bbj.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'berik'}" target="_blank">
        <img src="/assets/images/brand/berik.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'bornfree'}" target="_blank">
        <img src="/assets/images/brand/bornfree.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'chatterbox'}" target="_blank">
        <img src="/assets/images/brand/chatterbox.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'dainese'}" target="_blank">
        <img src="/assets/images/brand/dainese.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'eleone'}" target="_blank">
        <img src="/assets/images/brand/eleone.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'five'}" target="_blank">
        <img src="/assets/images/brand/five.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'gravity'}" target="_blank">
        <img src="/assets/images/brand/gravity.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'hjc'}" target="_blank">
        <img src="/assets/images/brand/hjc.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'komine'}" target="_blank">
        <img src="/assets/images/brand/komine.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'kuryakyn'}" target="_blank">
        <img src="/assets/images/brand/kuryakyn.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'macna'}" target="_blank">
        <img src="/assets/images/brand/macna.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'maxlauf'}" target="_blank">
        <img src="/assets/images/brand/maxlauf.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'sena'}" target="_blank">
        <img src="/assets/images/brand/sena.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'sidi'}" target="_blank">
        <img src="/assets/images/brand/sidi.png">
      </a>
    </li>
    <li>
      <a routerLink="/product/list" [queryParams]="{searchText: 'ulcear'}" target="_blank">
        <img src="/assets/images/brand/ulcear.png">
      </a>
    </li>
  </ul>
</div>

<div class="notice mobile-view">
  <div class="notice-header clearfix">
    <p>공지사항</p>
    <a routerLink="/board/list">더보기</a>
  </div>
  <ul class="clearfix">
    <li *ngFor='let row of boardList'>
      <a>
        <p class="fl" (click)="view(row)">{{row.subject}}</p>
        <p class="fr" (click)="view(row)">{{row.write_date.substr(0,10)}}</p>
      </a>
    </li>
  </ul>
</div>

<app-footer></app-footer>

<!-- 팝업 //-->
<section *ngFor="let row of popupList">
  <ng-container *ngIf="getPopupToday(row.board_seq, row.layer_popup)">
  <div id="popup{{row.board_seq}}">
    <div class="popup-layer" [ngStyle]="{'width':row.popup_width+'px', 'max-height':'100%', 'left':changePopupLayerStyle(row, 'screen_x')+'px', 'top':changePopupLayerStyle(row, 'screen_y')+'px'}" [ngClass]="{hidden: row.is_popup_preview == false}">
      <div class="popup-header"><span></span>알려드립니다!</div>
      <!-- <div class="popup-preview-contents" [innerHTML]="row.contents | safe: 'html'"></div> -->
      <div class="popup-preview-contents">
        <ng-container *ngIf="row.popup_link; else notLink">
          <a [href]="row.popup_link" target="_blank">
            <img [src]="row.upload_file" [alt]="row.subject" />
          </a>
        </ng-container>
        <ng-template #notLink>
          <img [src]="row.upload_file" [alt]="row.subject" />
        </ng-template>
      </div>

      <!-- 오늘 더 이상 열지 않기 처리 //-->
      <div class="popup-button-area">
        <div class="fl">
          <label class="popup-not-today">
            <input type="checkbox" name="checkBtn" (click)="setPopupToday(row)" /> 오늘 더 이상 보지 않음
          </label>
        </div>
        <div class="fr">
          <button type="button" class="btn btn-default f12" (click)="row.is_popup_preview=false">창닫기</button>
        </div>
        <div class="cb"></div>
      </div>
    </div>
  </div>
  </ng-container>
</section>