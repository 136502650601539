<div class="mypage-header">
    <div class="header1">
      <!-- <div class="name"><span>{{memberInfo.name}}</span>({{memberInfo.grade_name}}) 님 환영합니다!</div> -->
      <div class="name"><span>{{memberInfo.name}}</span>님 환영합니다!!!!!</div>
      <div class="mileage clearfix">
        <a routerLink="/guide/society-benefit/society">동호회 혜택보기 ></a>
      </div>
    </div>
    <ul class="header2">
      <li>
        <p>동호회 대표자</p>
        <a class="repName">{{repName}}</a>
      </li>
      <li>
        <p>등록 회원수</p>
        <a class="repName">{{memberCount}}</a>
      </li>
      <li>
        <p>동호회 점수</p>
        <a class="repName">{{societyScore}}</a>
      </li>
    </ul>
    
  </div>
  