    <div class="goods-card">
      <div class="sold-out">[관리자] 일시품절</div>
      <a routerLink="/product/info" [queryParams]="{productSeq: productInfo.seq, eventSeq: productInfo.event_seq}" [target]="linkTarget">
        <div class="goods-img">
          <img [src]="productInfo.thumbnail_url" alt="{{productInfo.product_name | nohtml}}" />
        </div>
        <div class="goods-info">
          <p class="code">상품통합번호 : {{productInfo.product_seq}}</p>
          <p class="pr-text" [innerHTML]="productInfo.comment"></p>
          <p class="name" [innerHTML]="productInfo.product_name | safe:'html'"></p>
          <p class="price">
            <!-- <span>{{productInfo.consumer_price | number}}원</span> -->
            <span class="sale" *ngIf="comparePrice(productInfo.display_price, productInfo.consumer_price)">{{ (productInfo.display_price - productInfo.consumer_price) / productInfo.display_price | percent }}</span>
            <span>{{ productInfo.consumer_price | number }}원</span>
            <del *ngIf="comparePrice(productInfo.display_price, productInfo.consumer_price)">{{ productInfo.display_price | number }}원</del>
          </p>
          <ng-container *ngIf="isLogin$ | async as login; else notLogin">
            <p class="mileage">적립 <span>{{productInfo.mileage | number}}</span> M</p>
          </ng-container>
          <ng-template #notLogin>
            <p class="mileage mileage-guide">로그인 후 혜택을 확인하세요.</p>
          </ng-template>
        </div>
      </a>
      <!-- <div class="left-area">
        <ul>
          <li class="active"><img src="/assets/images/simg_01.png"></li>
          <li><img src="/assets/images/simg_02.png"></li>
          <li><img src="/assets/images/simg_03.png"></li>
          <li><img src="/assets/images/simg_04.png"></li>
          <li><img src="/assets/images/simg_05.png"></li>
          <li><img src="/assets/images/simg_06.png"></li>
          <li><img src="/assets/images/simg_01.png"></li>
          <li><img src="/assets/images/simg_02.png"></li>
        </ul>
      </div> -->
      <!-- <div class="right-area" *ngIf="sizeList.length > 0">
        <ul>
          <li class="soldout">38</li>
          <li class="soldout">39</li>
          <li>40</li>
          <li>41</li>
          <li>42</li>
          <li>43</li>
          <li>44</li>
          <li>45</li>
          <li>46</li>
          <li *ngFor="let item of sizeList; let i = index" [ngStyle]="{'background-color': colorList[i], 'color': ( colorList[i] == '#000000' ) ? '#fff' : '#000' }">{{item}}</li>
        </ul>
      </div> -->
    </div>