/*******************************************************************************
  설  명 : 게시판 리스트
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { SBoardService } from '@app/service/board.service';

@Component({
  selector: 'app-board-list',
  templateUrl: './board-list.component.html',
  styleUrls: ['./board-list.component.scss']
})
export class BoardListComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public memberInfo: any = {};
  public openRow: any = [];

  public params: any = {
    type: 'gallery',
    board_id: 'society',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };

  public linecnt: any = [];
  public groupTab: any = [];

  public boardList = [];
  public totalCount: number;

  public baseURL: string = config.baseUrl;

  public maxSize: any = 10;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private sBoardService: SBoardService,
    private toastrService: ToastrService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '' ) ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;


      this.getBoardInfo( this.params.board_id );
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.sBoardService.getBoardInfoId( board_id ).then( response => {
      if( response.data == null ) {
        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/main'] );
        return false;
      }

      this.boardInfo = response.data;

      if( this.params.type == '' ){
        switch( this.boardInfo.lvt_default ){
          default: case 'L': this.params.type = 'list';
        }
      } // 게시판 내용 부분 faq, question, list로 분류한 뒤 요청에 따라 불러옴.

      if( this.params.type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');

      if( this.boardInfo.lvt_group == '1' ) {
        this.boardInfo.group = '전체|' + this.boardInfo.group;
        this.groupTab = this.boardInfo.group.split('|');
      }
      
      this.getBoardList();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.sBoardService.getBoardList( this.params ).then( response => {
      this.boardList = response.data;
      this.boardInfo.total_count = response.total;
      this.totalCount = parseInt( response.total );
      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 게시판 상세보기 (is_notice여부에 따른 a태그 사용)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  view( row: any ) {
    this.params.board_seq = row.board_seq;

    this.router.navigate(
    ['/board/view'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getBoardList();
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.openRow.map( rows => {
      $("#child_" + rows).remove();
    });
    this.openRow = [];

    this.params.no = page;

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadList( group_id ) {
    $('.viewdown').remove(); // 생성된 게시판 상세보기 tr 태그를 지우고 분류탭 별 초기화
    this.params.group_id = group_id;

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상세보기 페이지 변동없이 내려보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  viewDown( row: any ) {
    let child = $("#child_"+ row.board_seq);

    if( child.length < 1 ) {
      let node = '<tr id="child_'+ row.board_seq +'" class="viewdown"><td colspan="6" style="padding: 30px 0px 40px 0px;line-height: 150%;" class="tl">'+ row.contents_nl2br +'</td></tr>';
      $("#tr_" + row.board_seq).after( node );

      this.openRow.push(row.board_seq);
    } else {
      $("#child_" + row.board_seq).remove();
    }
  }

  /*******************************************************************************
    설  명 : 모바일 자주하는 질문 검색조건 select 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  groupSelect(event) {
    $('.viewdown').remove(); // 생성된 게시판 상세보기 tr 태그를 지우고 분류탭 별 초기화

    this.params.group_id = event.target.value;

    this.getBoardList();
  }

}