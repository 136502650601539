import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SOrderService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 주문 리스트 가져오기
  getOrderList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 주문 상세 정보 가져오기
  getOrderDetail( orderSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderDetail'
      }, {
        seq: orderSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 최근 주문내역 리스트 가져오기
  getRecentOrderList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getRecentOrderList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 비회원 주문 정보 가져오기
  getNotLoginOrderDetail( seq: number, mem_no: number ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getNotLoginOrderDetail'
      }, {
        seq: seq,
        mem_no: mem_no
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 비회원 주문 조회
  confirmNonMember( form: any): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'confirmNonMember'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 결제폼 생성을 위한 데이터 가져오기
  getFormValue( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'kginicis',
        version: '1.0',
        action: 'getFormValue'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 주문/결제정보 임시 저장 데이터 삭제하기
  setOrdertmpDetele( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'kginicis',
        version: '1.0',
        action: 'setOrdertmpDetele'
      }, {
        tmpSeq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 무통장 은행리스트 가져오기
  getBankAccountList( ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'company',
        version: '1.0',
        action: 'getBankAccountList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 고객이 보유한 바마쿠폰 리스트 
  getBamaCouponList( data:any): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMemberCouponList'
      }, {cartList: data}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }


  // 무통장입금 결제정보 저장
  setOrderSave( formData: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderSave'
      }, formData
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 주문취소
  setOrderCancel( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderCancel'
      }, {
        seq: seq
      } 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 환불계좌 가져오기
  getRefundBank(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getRefundBank'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 주문취소
  setRefundSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setRefundSave'
      }, form.value 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 주문취소
  setExchangeSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setExchangeSave'
      }, form.value 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 취소/교환/반품 조회 리스트 불러오기
  getCancelOrder( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getCancelOrder'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 구매후기등록
  setOrderReview( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderReview'
      }, form.value 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  
  // 수취확인
  setOrderReceived( order_seq ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderReceived'
      }, {
        order_seq: order_seq
      } 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 구매확정
  setOrderPurchaseConfirm( order_seq ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderPurchaseConfirm'
      }, {
        order_seq: order_seq
      } 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 취소/교환/반품 조회 리스트 불러오기
  getState(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getState'
      }, []
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 마이페이지 현황판 가져오기
  getOrderStatus(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderStatus'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 현금영수증 신청
  setCashReceipt( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setCashReceipt'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 교환/반품 신청철회
  setCancelCancel( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setCancelCancel'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 신용결제 여부 판단
  isInicis( order_seq: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'isInicis'
      }, {
        order_seq: order_seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 현금결제 여부 판단
  checkOrderPayCash( order_seq: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'checkOrderPayCash'
      }, {
        order_seq: order_seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 마이페이지 구매후기 리스트 가져오기
  getOrderReviewList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderReviewList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 마이페이지 구매후기 삭제
  setDeleteOrderReview( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setDeleteOrderReview'
      }, {
        seq: seq
      } 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 마이페이지 구매후기 이미지 삭제
  setOrderReviewImageDelete( seq: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderReviewImageDelete'
      }, {
        seq: seq
      } 
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 메인페이지 구매후기 리스트 가져오기
  getMainOrderReviewList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getMainOrderReviewList'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

}
