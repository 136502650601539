<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-society-header class="pc-view"></app-mypage-society-header>
  
  <div class="mypage-contents">
    <div class="section order-list">
      <div class="title page-title">내 동호회 
        <ng-container *ngIf="societyInfo.rep_mem_no === memberInfo.mem_no">
            <span class="fr">승인여부 버튼을 클릭하여 승인 여부를  변경 할 수 있습니다.</span>
        </ng-container>
      </div>

	    <div class="mypage-search-area form-inline">
        <div style="width:60% !important">
          <a style="font-size:15px !important">{{societyInfo.society_name}}</a>
        </div>  
        <div style="width:40%;text-align:right !important" >
          <button *ngIf="societyInfo.rep_mem_no !== memberInfo.mem_no" 
          type="button" 
          class="basic-btn sm-btn" 
          style="width:120px !important" 
          (click)="delSocietyMember(societyMemberList.mem_no)">동호회탈퇴</button>
        </div>
	    </div>

      <table class="list-table mb50">
      <colgroup>
        <col style="width: 25%">
        <col style="width: 35%">
        <col style="width: 15%">
        <!-- <col style="width: 15%"> -->
        <col style="width: 35%">
      </colgroup>    
  
      <thead>
      <tr>
        <th>아이디</th>
        <th>회원성명</th>
        <th>등록일</th>
        <th></th>
        
      </tr>
      </thead>
      
        <tbody>
          <ng-container>
            <tr *ngFor="let row of societyMemberList;let j = index">
              <td class="info">{{row.id}}</td>
              <td class="info">{{row.name}}</td>
              <td class="date">{{row.reg_date}}</td>

              <!-- <td>
                <ng-container *ngIf="societyInfo.rep_mem_no === memberInfo.mem_no && row.is_approval == '1' && row.mem_no !=societyInfo.rep_mem_no ">
                  <a class="app-btn sm-btn round cp" (click)="appSocietyMember(row.mem_no)"><span class="app-color">승인</span></a>
                </ng-container>
                <ng-container *ngIf="societyInfo.rep_mem_no === memberInfo.mem_no && row.is_approval != '1' && row.mem_no !=societyInfo.rep_mem_no ">
                  <a class="noapp-btn sm-btn round cp" (click)="appSocietyMember(row.mem_no)"><span class="app-color">미승인</span></a>
                </ng-container>
              </td> -->
              <td>
                <ng-container *ngIf="societyInfo.rep_mem_no === memberInfo.mem_no && row.mem_no !=societyInfo.rep_mem_no">
                  <a class="basic-btn sm-btn round cp" (click)="delSocietyMember(row.mem_no)"><span class="red">탈퇴처리</span></a>
                </ng-container>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="societyMemberList.length < 1">
            <tr>
              <td colspan="4">내용이 없습니다</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
		        
    </div>
         
  </div>   
</div>

<app-footer></app-footer>