<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<ul class="menu clearfix" *ngIf="isMobileApp === false">
  <li><a routerLink="/company/info">회사소개</a></li>
  <li><a routerLink="/company/history">회사연혁</a></li>
  <li><a routerLink="/company/society">동호회등록</a></li>
  <li><a routerLink="/company/supply">입점안내</a></li>
	<li><a routerLink="/company/story">창업스토리</a></li>
	<li><a routerLink="/company/contact">가맹문의</a></li>
  <li><a routerLink="/company/policy/service" class="on">이용약관</a></li>
</ul>

<ul class="menu menu-mobile clearfix" *ngIf="isMobileApp === true">
  <li><a routerLink="/mobile/company/info">회사소개</a></li>
  <li><a routerLink="/mobile/company/history">회사연혁</a></li>
  <li><a routerLink="/mobile/company/society">동호회등록</a></li>
  <li><a routerLink="/mobile/company/supply">입점안내</a></li>
	<li><a routerLink="/mobile/company/story">창업스토리</a></li>
	<li><a routerLink="/mobile/company/contact">가맹문의</a></li>
  <li><a routerLink="/mobile/company/policy/service" class="on">이용약관</a></li>
</ul>

<div style="margin-top: 74px" *ngIf="isMobileApp === true"></div>

<section class="contents">

	<ul class="sub-menu clearfix" *ngIf="isMobileApp === false">
	  <li><a routerLink="/company/policy/service" class="on">쇼핑몰이용약관</a></li>
	  <li><a routerLink="/company/policy/purchase/agency">구매대행약관</a></li>
	  <li><a routerLink="/company/policy/privacy">개인정보취급방침</a></li>
	</ul>

  <ul class="sub-menu clearfix" *ngIf="isMobileApp === true">
	  <li><a routerLink="/mobile/company/policy/service" class="on">쇼핑몰이용약관</a></li>
	  <li><a routerLink="/mobile/company/policy/purchase/agency">구매대행약관</a></li>
	  <li><a routerLink="/mobile/company/policy/privacy">개인정보취급방침</a></li>
	</ul>

  <div class="section inner">
    <div class="section-header clearfix">
      <div class="title">쇼핑몰이용약관</div>
      <div class="date">현행 시행 일자 : 2013년 6월 3일</div>
      <div class="line"></div>
    </div>

    <div class="terms">
      <div class="clearfix">
	      <p>제1장 총칙</p>
	      <ul>
	          <li>제1조 목적</li>
	          <li>제2조 정의</li>
	          <li>제3조 약관의 명시와 개정</li>
	          <li>제4조 서비스의 제공 및 변경</li>
	          <li>제5조 서비스의 이용 및 중단</li>
	      </ul>
	      <p>제2장 회원가입계약</p>
	      <ul>
	          <li>제6조 회원가입</li>
	          <li>제7조 회원 탈퇴 및 자격 상실 등</li>
	          <li>제8조 회원에 대한 통지</li>
	      </ul>
	      <p>제3장 구매계약 및 대금결재 </p>
	      <ul>
	          <li>제9조 구매신청</li>
	          <li>제10조 계약의 성립</li>
	          <li>제11조 지급방법</li>
	      </ul>
	      <p>제4장 배송, 취소, 환불 </p>
	      <ul>
	          <li>제12조 수신확인통지·구매신청 변경 및 취소</li>
	          <li>제13조 배송</li>
	          <li>제14조 환급, 반품 및 교환</li>
	      </ul>
	      <p>제5장 쇼핑몰과 이용자의 의무사항</p>
	      <ul>
	          <li>제15조 개인정보보호</li>
	          <li>제16조 "쇼핑몰"의 의무</li>
	          <li>제17조 회원의 ID 및 비밀번호에 대한 의무와 보안정책</li>
	          <li>제18조 이용자의 의무</li>
	      </ul>
	      <p>제6장 기타</p>
	      <ul>
	          <li>제19조 연결"쇼핑몰"과 피 연결"쇼핑몰" 간의 관계</li>
	          <li>제20조 저작권의 귀속 및 이용제한</li>
	          <li>제21조 분쟁해결</li>
	          <li>제22조 재판권 및 준거법</li>
	      </ul>
      </div>
      <pre>
제1장 총칙


제1조(목적)

이 약관은 (주)바이크마트 회사가 운영하는 오토바이 인터넷 쇼핑몰 Bikemart.co.kr(이하 "쇼핑몰"이라 한다)에서 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이버몰과 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.


제2조(정의)

① "쇼핑몰" 이란 Bikemart.co.kr가 재화 또는 용역을 이용자에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 재화 또는 용역을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
② "이용자"란 "쇼핑몰"에 접속하여 이 약관에 따라 "쇼핑몰"이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
③ 회원''이라 함은 "쇼핑몰"에 개인정보를 제공하여 회원등록을 한 자로서, "쇼핑몰"의 정보를 지속적으로 제공받으며, "쇼핑몰"이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
④ 비회원''이라 함은 회원에 가입하지 않고 "쇼핑몰"이 제공하는 서비스를 이용하는 자를 말합니다.


제3조 (약관의 명시와 개정)

① "쇼핑몰"은 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편주소 등) 등을 이용자가 알 수 있도록 Bikemart.co.kr 의 초기 서비스화면(전면)에 게시합니다.
② "쇼핑몰"은 약관의 규제 등에 관한 법률, 전자거래기본법, 전자 서명법, 정보통신망 이용촉진 등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
③ "쇼핑몰"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 쇼핑몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
④ "쇼핑몰"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지 기간 내에 ''쇼핑몰"에 송신하여 "쇼핑몰"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령
또는 상관례에 따릅니다.


제4조(서비스의 제공 및 변경)

① "쇼핑몰"은 다음과 같은 업무를 수행합니다.
1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
2. 구매계약이 체결된 재화 또는 용역의 배송
3. 기타 "쇼핑몰"이 정하는 업무

② "쇼핑몰"은 재화의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화·용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화·용역의 내용 및 제공일자를 명시하여 현재의 재화·용역의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다.

③ "쇼핑몰"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화의 품절 또는 기술적 사양의 변경등의 사유로 변경할 경우에는 "쇼핑몰"은 이로 인하여 이용자가 입은 손해를 배상합니다.
단, "쇼핑몰"에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.

④ 고객이 고의 또는 과실에 의하여 위법하게 쇼핑몰에 손해를 가한 경우에 쇼핑몰은 당해 고객에게 그 손해배상책임을 물을 수 있습니다. 보증상품의 품질 및 상품에 관한 당사의 보증은 상품 인도시 제공되는 상품 인도서에 기재된 제품보증서의 기재에 의합니다.

제5조(서비스의 이용 및 중단)

① 서비스 이용시간은 쇼핑몰의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴(1일 24시간)을 원칙으로 하며 정기점검 등의 필요로 인하여 본 쇼핑몰이 정한 날 또는 시간은 그러하지 아니합니다.

② "쇼핑몰"은 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.

③ 제2항에 의한 서비스 중단의 경우에는 "쇼핑몰"은 제8조에 정한 방법으로 이용자에게 통지합니다.

④ "쇼핑몰"은 제2항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단 "쇼핑몰"에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.


제2장 회원가입계약


제6조(회원가입)

① 이용자는 "쇼핑몰"이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.

② "쇼핑몰"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
 1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "쇼핑몰"의 회원 재 가입 승낙을 얻은 경우에는 예외로 한다.
 2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
 3. 기타 회원으로 등록하는 것이 "쇼핑몰"의 기술상 현저히 지장이 있다고 판단되는 경우

③ 회원가입계약의 성립시기는 "쇼핑몰"의 승낙이 회원에게 도달한 시점으로 합니다.

④ 회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 "쇼핑몰"에 대하여 그 변경사항을 알려야 합니다.


제7조(회원 탈퇴 및 자격 상실 등)

① 회원은 "쇼핑몰"에 언제든지 탈퇴를 요청할 수 있으며 "쇼핑몰"은 즉시 회원탈퇴를 처리합니다.

② 회원이 다음 각 호의 사유에 해당하는 경우, "쇼핑몰"은 회원자격을 제한 및 정지시킬 수 있습니다.
 1. 가입 신청 시에 허위 내용을 등록한 경우
 2. "쇼핑몰"을 이용하여 구입한 재화·용역 등의 대금, 기타 "쇼핑몰"이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
 3. 다른 사람의 "쇼핑몰" 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
 4. "쇼핑몰"을 이용하여 법령과 이 약관이 금지하거나 공서 양속에 반하는 행위를 하는 경우

③ "쇼핑몰"이 회원 자격을 제한·정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "쇼핑몰"은 회원자격을 상실시킬 수 있습니다.

④ "쇼핑몰"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.


제8조(회원에 대한 통지)

① "쇼핑몰"이 회원에 대한 통지를 하는 경우, 회원이 "쇼핑몰"에 제출한 전자우편 주소로 할 수 있습니다.

② "쇼핑몰"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "쇼핑몰" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.


제3장 구매계약 및 대금결재


제9조(구매신청)

"쇼핑몰"이용자는 "쇼핑몰"상에서 이하의 방법에 의하여 구매를 신청합니다.
 1. 성명, 주소, 전화번호 입력
 2. 재화 또는 용역의 선택
 3. 결제방법의 선택
 4. 이 약관에 동의한다는 표시(예, 마우스 클릭)


제10조 (계약의 성립)

① "쇼핑몰"은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하지 않는 한 승낙합니다.
 1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
 2. 미성년자가 담배, 주류등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
 3. 기타 구매신청에 승낙하는 것이 "쇼핑몰" 기술상 현저히 지장이 있다고 판단하는 경우

② "쇼핑몰"의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.


제11조(지급방법)

① 쇼핑몰"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 하나로 할 수 있습니다.
 1. 계좌이체
 2. 신용카드결제
 3. 온라인 무통장 입금
 4. 전자화폐에 의한 결제
 5. 수령시 대금지급 등

② 전항의 결재 방법 중 신용카드에 의한 대금지불은 고객이 직접 인터넷 쇼핑몰 상에서 결재대행회사에 카드 번호 등의 정보를 입력하게 되며 무통장 입금에 필요한 정보(구좌번호)는 대금 난에 기재되어 고객의 최대한의 편의를 돕는다.


제4장 배송, 취소, 환불


제12조(수신확인통지·구매신청 변경 및 취소)

① "쇼핑몰"은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.

② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있습니다.

③ "쇼핑몰"은 배송전 이용자의 구매신청 변경 및 취소 요청이 있는 때에는 지체없이 그 요청에 따라 처리합니다.


제13조(배송)

"쇼핑몰"은 이용자가 구매한 재화에 대해 배송 수단, 수단별 배송 비용 부담자, 수단별 배송 기간 등을 명시합니다.
만약 "쇼핑몰"의 고의·과실로 약정 배송 기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상합니다.


제14조(환급, 반품 및 교환)

① "쇼핑몰"은 이용자가 구매 신청한 재화 또는 용역이 품절 등의 사유로 재화의 인도 또는 용역의 제공을 할 수 없을 때에는 지체없이 그 사유를 이용자에게 통지하고, 사전에 재화 또는 용역의 대금을 받은 경우에는 대금을 받은 날부터 3일 이내에, 그렇지 않은 경우에는 그 사유 발생 일로부터 3일 이내에 계약해제 및 환급절차를 취합니다.

② 다음 각 호의 경우에는 "쇼핑몰"은 배송된 재화일지라도 재화를 반품 받은 다음 영업일 이내에 이용자의 요구에 따라 즉시 환급, 반품 및 교환 조치를 합니다. 다만 그 요구기한은 배송된 날로부터 20일 이내로 합니다.
 1. 배송된 재화가 주문내용과 상이하거나 "쇼핑몰"이 제공한 정보와 상이할 경우
 2. 배송된 재화가 파손, 손상되었거나 오염되었을 경우
 3. 재화가 광고에 표시된 배송 기간보다 늦게 배송된 경우
 4. 방문 판매 등에 관한 법률 제18조에 의하여 광고에 표시하여야 할 사항을 표시하지 아니한 상태에서 이용자의 청약이 이루어진 경우

③ 제2항에 따라 상품 또는 용역의 구매가 취소된 경우 그 반환에 필요한 일체의 비용은 쇼핑몰이 부담합니다.
단, 고객의 단순변심으로 인한 구매취소의 경우에는 고객이 배송비 또는 거래금액의 10% 위약금을 부담하셔야 합니다.

④ 고객의 단순 변심을 인한 청약 철회일 경우 재화등의 공급일 받는 날로 부터 7일이내에 할 수 있으며 소비자는 다음 각호에 해당하는 경우에는 통신판매업자의 의사에 반하여 청약 철회등을 할 수 없습니다.
 1. 소비자에게 책임 있는 사유로 재화등이 멸실 또는 훼손된 경우
 2. 소비자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
 3. 시간의 경과에 의하여 재화등의 가치가 현저히 감소한 경우
 4. 복제가 가능한 재화등의 포장을 훼손한 경우
 5. 소비자의 주문에 의하여 개별적으로 생산되는 재화 등 청약철회를 인정할 경우 통신판매업자에게 회복할수 없는 중대한 피해가 예상되는 경우


제5장 쇼핑몰과 이용자의 의무사항


제15조(개인정보보호)

① "쇼핑몰"은 이용자의 정보 수집시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.
다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.
 1. 성명
 2. 주민등록번호(회원의 경우)
 3. 주소
 4. 전화번호
 5. 희망ID(회원의 경우)
 6. 비밀번호(회원의 경우)

② "쇼핑몰"이 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.

③ 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 "쇼핑몰"이 집니다. 다만, 다음의 경우에는 예외로 합니다.
 1. 배송 업무상 배송 업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우
 2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우

④ "쇼핑몰"이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받는 자, 제공목적 및 제공할 정보의 내용)등 정보 통신망 이용촉진 등에 관한 법률 제16조제3항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.

⑤ 이용자는 언제든지 "쇼핑몰"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 "쇼핑몰"은 이에대해 지체없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "쇼핑몰"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.

⑥ "쇼핑몰"은 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.

⑦ "쇼핑몰" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.


제16조("쇼핑몰"의 의무)

① "쇼핑몰은 법령과 이 약관이 금지하거나 공서 양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화·용역을 제공하는 데 최선을 다하여야 합니다.

② "쇼핑몰"은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.

③ "쇼핑몰"이 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.

④ "쇼핑몰"은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.


제17조( 회원의 ID 및 비밀번호에 대한 의무와 보안정책)

① 제15조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.

② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.

③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "쇼핑몰"에 통보하고 "쇼핑몰"의 안내가 있는 경우에는 그에 따라야 합니다.

④ 고객의 신용카드 정보는 카드회사와 연결된 대행 전문회사에만 기록됩니다.
bikemart.co.kr 인터넷 쇼핑몰은 고객의 신용카드 정보를 bikemart.co.kr 인터넷 쇼핑몰에 기록하지 않습니다.

⑤ 고객께서 카드번호를 입력할 단계에서는 bikemart.co.kr 인터넷 쇼핑몰이 아닌 결제대행회사에 연결되며 따라서 bikemart.co.kr 인터넷 쇼핑몰에서 정보가 누설될 가능성은 없습니다.

⑥ 보안을 위하여 안전한 128비트 암호화 알고리즘(국제적으로 공인된 베리사인 Verisign SSL 알고리즘)을 사용합니다.


제18조(이용자의 의무)

① 이용자는 다음 행위를 하여서는 안됩니다.
 1. 신청 또는 변경 시 허위내용의 등록
 2. "쇼핑몰"에 게시된 정보의 변경
 3. "쇼핑몰"이 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
 4. "쇼핑몰" 기타 제3자의 저작권 등 지적재산권에 대한 침해
 5. "쇼핑몰" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
 6. 외설 또는 폭력적인 메시지·화상·음성 기타 공서 양속에 반하는 정보를 쇼핑몰에 공개 또는 게시하는 행위

② 전항 각 호의 정보 기타 쇼핑몰이 몰 운영상 부 적절하다고 판단한 정보가 몰에 게시되거나 몰과 링크 된 곳에 게시된 경우, 쇼핑몰은 이용자 기타 정보의 게시를 행한 자의 승낙 없이 쇼핑몰에 게재된 당해 정보를 삭제하거나 몰에 확장된 링크를 절단할 수 있습니다.
단, 쇼핑몰은 이러한 정보의 삭제링크의 절단 등을 할 의무를 지지 않습니다.


제6장 기타


제19조(연결"쇼핑몰"과 피 연결"쇼핑몰" 간의 관계)

① 상위 "쇼핑몰"과 하위 "쇼핑몰"이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨) 방식 등으로 연결된 경우, 전자를 연결 "쇼핑몰"(웹사이트)이라고 하고 후자를 피 연결 "쇼핑몰"(웹사이트)이라고 합니다.

② 연결 "쇼핑몰"은 피 연결 "쇼핑몰"이 독자적으로 제공하는 재화·용역에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결 "쇼핑몰"의 사이트에서 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.


제20조(저작권의 귀속 및 이용제한)

① "쇼핑몰"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "쇼핑몰"에 귀속합니다.
② 이용자는 "쇼핑몰"을 이용함으로써 얻은 정보를 "쇼핑몰"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.


제21조(분쟁해결)

① "쇼핑몰"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리 기구를 설치·운영합니다.

② "쇼핑몰"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.

③ "쇼핑몰"과 이용자간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래 분쟁조정위원회의 조정에 따를 수 있습니다.


제22조(재판권 및 준거법)

① "쇼핑몰"과 이용자간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.

② "쇼핑몰"과 이용자간에 제기된 전자거래 소송에는 한국 법을 적용합니다.
      </pre>
    </div>
  </div>
</section>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>
