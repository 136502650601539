<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
    
  <div class="mypage-contents">
    <div class="guide">
      <div class="title">안내사항</div>
				<p class="dot">·</p><p class="dot1">회원 탈퇴 시 적립된 마일리지는 즉시 삭제 처리되며, 재가입하셔도 복원되지 않습니다. 게시판 이용이 가능합니다.</p>
				<p class="dot">·</p><p class="dot1">회원탈퇴 후에도 바이크마트에 재가입하실 수 있습니다 단, 기존 ID사용은 불가능하고, 신규 ID로만 가입이 가능합니다.</p>
				<p class="dot">·</p><p class="dot1">탈퇴 후 재가입시 기존 ID 로 사용하던 거래정보, 쿠폰, 마일리지, 회원정보, 상품정보 등은 복원 되지 않습니다.</p>
    </div>    
  
    <div class="section">
      <div class="section-header">
        <div class="title page-title">회원탈퇴</div>
      </div>
      
      <form [formGroup]="memberOutForm" (ngSubmit)="submit()">
        <table class="info-table">
        <colgroup>
          <col style="width: 160px">
          <col>
        </colgroup>  

          <tbody>
            <tr>
              <th><label>아이디</label></th>
              <td><input type="text" formControlName="id" [readonly]="true" />
              <span *ngIf="formErrors.id" class="error-msg">{{formErrors.id}}</span>
              </td>
            </tr>
            <tr>
              <th><label for="col1">이름</label></th>
              <td><input type="text" id="col1" placeholder="이름" formControlName="name" />
              <span *ngIf="formErrors.name" class="error-msg">{{formErrors.name}}</span>
              </td>
            </tr>
            <tr>
              <th><label for="col2">비밀번호</label></th>
              <td>
                <input type="password" id="col2" placeholder="비밀번호" formControlName="pw" />
                <span *ngIf="formErrors.pw" class="error-msg">{{formErrors.pw}}</span>
              </td>
            </tr> 
          </tbody>
        </table>
        
        <div class="btn-area">
          <a routerLink="/" class="basic-btn">취소</a>
          <button type="submit" class="basic-btn red">탈퇴</button>
        </div>
      </form>          
    </div>
  </div>    
</div>

<app-footer></app-footer>  