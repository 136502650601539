<app-header></app-header>

<ul class="path clearfix inner pc-view">
  <li *ngIf="productData.category_name1"><span>{{productData.category_name1}}</span></li>
  <li *ngIf="productData.category_name2"><span>{{productData.category_name2}}</span></li>
  <li *ngIf="productData.category_name3"><span>{{productData.category_name3}}</span></li>
</ul>

<div class="goods-info clearfix inner">
  <div class="left-area">
    <div class="img">
      <!-- <img [src]="productData.thumbnail_url" alt="{{productData.product_name}}" /> -->
      <lib-ngx-image-zoom
      [thumbImage]="thumbImage"
      [fullImage]="fullImage"
    ></lib-ngx-image-zoom>
    </div>
    <a class="btn-zoom" (click)="showMessage('상품 이미지에 마우스를 올려주세요.', 'info')">확대보기</a>
    <a
      *ngIf="productData.category_name2 == '헬멧' || productData.category_name2 == '자켓' || productData.category_name2 == '장갑'"
      class="btn-size"
      (click)="guideView = productData.category_name2"
    >사이즈 고르는 법</a>
  </div>

  <div class="right-area">
    <div class="info1">
      <div class="share cp" [ngClass]="{on:favorite}" (click)="setShare()"></div>
      <div class="favorite cp" [ngClass]="{on:favorite}" (click)="setKeepProductSave()"></div>
      <div class="prev-text" [innerHTML]="productData.comment"></div>
      <div class="name"><span>{{productData.product_name | nohtml}}</span></div>
      <div class="next-text" [innerHTML]="productData.product_overview"></div>
      <div class="code"><span>상품번호 : </span>{{productData.seq}}</div>
      <ng-container *ngIf="productData.is_mach == '1' && productData.is_soldout==false;">
        <div class="mach">오늘출발 : 15:00 마감</div>
      </ng-container>
      <ng-container *ngIf="productData.brand_name == 'HJC'; else notHJC1">
        <div class="price clearfix">
          <div>판매가격</div>
          <div>{{productData.unit_price | number}} 원</div>
        </div>
      </ng-container>
      <ng-template #notHJC1>
        <div class="price clearfix">
          <div>판매가격</div>
          <div *ngIf="isLogin$ | async as isLoggedIn; else notLoggedIn1"><span>{{productData.unit_price | number}} 원</span></div>
          <ng-template #notLoggedIn1>
            <ng-container *ngIf="productData.iEventSeq > 0 && productData.unit_price !== productData.consumer_price; else notEvent1">
              <div><span>{{productData.unit_price | number}} 원</span></div>
            </ng-container>
            <ng-template #notEvent1>
              <div>{{productData.unit_price | number}} 원</div>
            </ng-template>
          </ng-template>
        </div>
        <div class="price clearfix">
          <div>이벤트&회원가</div>
          <div *ngIf="isLogin$ | async as isLoggedIn; else notLoggedIn2">{{productData.consumer_price | number}} 원</div>
          <ng-template #notLoggedIn2>
            <ng-container *ngIf="productData.iEventSeq > 0 && productData.unit_price !== productData.consumer_price; else notEvent2">
              <div>{{productData.consumer_price | number}} 원</div>
            </ng-container>
            <ng-template #notEvent2>
              <div><span class="before-login" (click)="memberSale()">로그인 후 회원가를 확인하세요</span></div>
            </ng-template>
          </ng-template>
        </div>
      </ng-template>
    </div>
    <div class="info2">
      <ul class="clearfix">
        <li>
          <div>배송비</div>
          <div>
            {{( delivery.delivery_use_yn == '1' ) ? (delivery.delivery_amt | number) : (productData.delivery_price | number)}} 원
            <span *ngIf="delivery.delivery_free_condition > 0">( {{delivery.delivery_free_condition | number}} 원 이상 무료배송 )</span>
          </div>
        </li>
        <li>
          <ng-container *ngIf="productData.brand_name == 'HJC'; else notHJC2">
            <div>마일리지</div><div>{{productData.mileage | number}} M</div>
          </ng-container>
          <ng-template #notHJC2>
            <ng-container *ngIf="isLogin$ | async as isLoggedIn; else notLoggedIn3">
              <div>마일리지</div><div>{{productData.mileage | number}} M</div>
            </ng-container>
            <ng-template #notLoggedIn3>
              <div>마일리지</div><div class="cp tdu" (click)="memberSale()">로그인 후 혜택을 확인하세요.</div>
            </ng-template>
          </ng-template>
        </li>
        <li><div>무이자</div><div class="cp tdu red" (click)="goFreeInterest()">무이자할부 안내</div></li>
        <li><div>브랜드</div><div>{{productData.brand_name}}</div></li>
        <li><div>품절안내</div>
          <div *ngIf="productData.is_soldout==true"><span class="cred">일시품절</span></div>
          <div *ngIf="productData.is_soldout==false"><span class="cred">재고가 실시간 반영되지 않아 품절될수도 있습니다.</span></div>
        </li>
        <li><div>교환/반품</div><div>상품 발송 후 5일 내에 고객센터 문의 (이후 불가, 일부 상품 제외, 왕복 배송비 고객 부담)</div></li>
        <li><div>주의사항</div><div>개인 PC, 모바일 등의 해상도에 따라  실물과 색상이 다르게 보일 수 있습니다.</div></li>
        <li><div></div><div class="cred">제주/도서산간 주문 시 추가 배송비가 발생됩니다.</div></li>
        <li><div></div><div class="cred">마감 이후 결제된 오늘 출발 제품은 다음 영업일에 발송됩니다.</div></li>
        <li *ngIf="productData.kc_number">
          <div>인증정보</div>
          <div style="padding: 3px 0;height: 50px;">
            <a class="cp" (click)="openCert()">
              <div style="width: 50px; height: 100%;">
                <img src="/assets/images/kc.jpg" style="height: 100%;">
              </div>
              <div>
                <p>본 상품은 안전확인 대상품목으로 해당품목의 안전기준에 적합합니다.</p>
                <p style="font-size:22px;">인증번호 : {{productData.kc_number}}</p>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <div class="option clearfix pc-view">
      <div class="title">기본옵션<span> (필수)</span></div>
      <div *ngIf="productData.is_soldout==false" class="option-select">
        <ul>
          <ng-container *ngIf="colorList.length > 0">
						<div class="select-box color-box">
							<a class="select-title cp" (click)="selectbox_pulldown($event, 'color')">
								<span class="select-span">{{topPropertyColor}}</span>
								<i class="material-icons selectbox-arrow">&#xe5cf;</i>
							</a>
							<ul class="selectbox-ul">
								<li class="cp" *ngFor="let row of colorList" (click)="selectedOption($event)">{{row}}</li>
							</ul>
						</div>
          </ng-container>
          <div class="select-box" *ngIf="colorList.length > 0 && sizeList.length < 1">
            <a class="select-title cp readonly">
              <span class="select-span">사이즈</span>
              <i class="material-icons selectbox-arrow">&#xe5cf;</i>
            </a>
          </div>
          <div class="select-box size-box" *ngIf="sizeList.length > 0">
            <a class="select-title cp" (click)="selectbox_pulldown($event, 'size')">
              <span class="select-span">{{topPropertySize}}</span>
              <i class="material-icons selectbox-arrow">&#xe5cf;</i>
            </a>
            <ul class="selectbox-ul">
              <li class="cp" *ngFor="let row of sizeList" [ngClass]="{clight: row.is_soldout == '1'}" (click)="selectProduct(row)">{{row.display_size_name ? row.display_size_name : '단품'}} {{getDisplayDifferencePrice(row)}} {{row.is_soldout == '1' ? '(일시품절)' : ''}}</li>
            </ul>
          </div>
          <div class="select-box" *ngIf="propertyList.length < 1">
            <span>현재 판매중인 옵션이 없습니다.</span>
          </div>
        </ul>
      </div>
      <div *ngIf="productData.is_soldout==true" class="option-select">
        <span>현재 일시품절 상품입니다.</span>
      </div>
    </div>

    <div class="option clearfix pc-view" *ngIf="giftList.length > 0">
      <div class="title">추가옵션 (선택)</div>
      <div class="option-select">
        <!-- <select (change)="selectGift($event)">
          <option value="" disabled>사은품</option>
          <option *ngFor="let row of giftList" [value]="row.product_freegift_seq">{{row.product_name | nohtml}}</option>
        </select> -->
        <div class="select-box gift-box">
          <a class="select-title cp" (click)="selectbox_pulldown($event, 'gift')">
            <span class="select-span">{{topGift}}</span>
            <i class="material-icons selectbox-arrow">&#xe5cf;</i>
          </a>
          <ul class="selectbox-ul">
            <li class="cp" *ngFor="let row of giftList" (click)="selectGift(row)">{{row.product_name | nohtml}}</li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="productData.is_soldout==false" class="buy-area pc-view">
      <ul *ngFor="let product of productList; let i = index">
        <li>
          <div class="name">{{product.product_name | nohtml}} {{ product.display_color_name ? '/ ' + product.display_color_name : '' }} {{ product.display_size_name ? '/ ' + product.display_size_name : '' }}</div>
          <div *ngIf="product.isGift == 1" class="qty" style="width: 91px;"></div>
          <div *ngIf="product.isGift == 0" class="qty">
            <button class="btn-minus" (click)="clickQty(product, -1)">&#xe15b;</button>
              <input type="text" [value]="product.qty" [(ngModel)]="product.qty" (keyup)="changeQty(product, $event)" (change)="changeQty(product, $event)" (blur)="isNumber(product, $event)" maxlength="3" />
            <button class="btn-plus" (click)="clickQty(product, +1)">&#xe145;</button>
          </div>
          <div *ngIf="productData.brand_name == 'HJC'" class="price">{{product.unit_price * product.qty | number}}<span>원</span></div>
          <div *ngIf="productData.brand_name != 'HJC'" class="price">{{product.consumer_price * product.qty | number}}<span>원</span></div>
          <button class="btn-del" (click)="deleteProduct(i)">삭제</button>
        </li>
      </ul>
      <div class="total clearfix">
        <div>총 상품금액</div>
        <div>{{totalPrice | number}}<span>원</span></div>
      </div>
      <div class="form-inline" style="align-items: flex-start;">
        <div class="btn-area" style="width: calc(100% - 285px);">
          <!-- <a class="btn1">대량구입</a> -->
          <ng-container *ngIf="productData.is_voucher != '1'; ">
            <a class="btn1 dark cp" (click)="setCartSave()">장바구니</a>
          </ng-container>
          <a class="btn1 red cp" (click)="setOrder()">바로구매</a>
        </div>
        <ng-container *ngIf="productData.is_voucher != '1'; ">
          <app-naverpay></app-naverpay>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<div class="goods-info1">
  <div class="menu" [ngClass]="{fixed:fixedMenu==true}">
	  <ul class="inner">
	    <li class="on"><a class="cp" (click)="scrollToElement(detail)">상세설명</a></li>
	    <li *ngIf="productData.is_review == '1'"><a class="cp" (click)="scrollToElement(review)">구매후기<span>{{reviewCount}}</span></a></li>
	    <!-- <li><a class="cp" (click)="scrollToElement(Inquiry)">상품문의<span>{{questionCount}}</span></a></li> -->
	    <li><a class="cp" (click)="scrollToElement(order)">주문/결제안내</a></li>
	    <li><a class="cp" (click)="scrollToElement(check)">입금확인/배송안내</a></li>
	    <li><a class="cp" (click)="scrollToElement(exchange)">교환반품안내</a></li>
	    <li><a class="cp" (click)="scrollToElement(repair)">A/S안내</a></li>
	  </ul>
  </div>
  <div class="relation-goods pc-view">
    <div class="title inner clearfix">
      <div>함께 보면 좋은 상품</div>
      <div>
        <button class="btn-prev" (click)="setRelationPage(-1)"></button>
        <div class="page"><span>{{ relationTotalPage > 0 ? relationPageNo : 0 }}</span> / {{relationTotalPage}}</div>
        <button class="btn-next" (click)="setRelationPage(1)">></button>
      </div>
    </div>
    <ul class="inner clearfix">
      <li *ngFor="let item of relationList | slice:((relationPageNo-1)*relationPageRow):(relationPageRow*relationPageNo)">
        <a routerLink="/product/info" [queryParams]="{productSeq: item.product_seq}" target="_blank">
          <div class="img"><img [src]="item.thumbnail_url" alt="{{item.product_name | nohtml}}" /></div>
          <div class="title">{{item.product_name | nohtml}}</div>
          <!-- <div class="price">{{item.consumer_price | number}} <span>원</span></div> -->
          <div class="price">
            <del *ngIf="comparePrice(item, 'parseInt(item.display_price) > parseInt(item.consumer_price)')">{{ item.display_price | number }}원</del>
            <span>{{ item.consumer_price | number }}원</span>
            <span class="sale" *ngIf="comparePrice(item, 'parseInt(item.display_price) > parseInt(item.consumer_price)')">{{ (item.display_price - item.consumer_price) / item.display_price | percent }}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div class="info-area inner clearfix">
	  <div #detail class="left-area">
      <div class="product-info-caution" *ngIf="productData.brandCaution && productData.caution_position == 'U'" [innerHTML]="productData.brandCaution | safe:'html'"></div>
			<!-- 상세설명 -->
			<div class="detail-explanation">
        <div [innerHTML]="productData.contents | safe:'html'"></div>
      </div>

      <div class="product-info-caution" *ngIf="productData.categoryCaution" [innerHTML]="productData.categoryCaution | safe:'html'"></div>
      <div class="product-info-caution" *ngIf="productData.brandCaution && productData.caution_position == 'D'" [innerHTML]="productData.brandCaution | safe:'html'"></div>

			<!-- 구매후기 -->
      <div #review class="review section">
        <ng-container *ngIf="productData.is_review == '1'" >
          <div class="title">구매후기 <span> {{reviewCount}}</span></div>
          <div class="average-grade">
            <div class="left-div">
              <div class="title">평균별점</div>
              <div class="img">
                <div class="img-grade-active pc-view" [ngStyle]="{'width': setAverageGarde()}"></div>
                <div class="img-grade-active mobile-view" [ngStyle]="{'width': setMobileAverageGarde()}"></div>
                <div class="img-grade-back"></div>
              </div>
              <div class="txt pc-view"><span class="bold">{{reviewAverageGrade}}</span> / 5</div>
              <div class="txt mobile-view"><span class="bold">{{reviewAverageGrade}}</span></div>
            </div>
            <div class="right-div">
              <div class="title">별점비율</div>
              <ul class="clearfix">
                <li *ngFor='let key of [5,4,3,2,1]'>
                  <div class="div1">{{getRatio(key)}}%</div>
                  <div class="rate-back">
                    <div class="rate pc-view" [ngStyle]="{'height': reviewCal(key)}"></div>
                    <div class="rate1 mobile-view" [ngStyle]="{'width': reviewMobileCal(key)}"></div>
                  </div>
                  <div class="div2">{{key}}점</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="sort clearfix">
            <ul>
              <li [ngClass]="{active:review_sort==4}" (click)="review_sort=4;getReview();">베스트순</li>
              <li [ngClass]="{active:review_sort==1}" (click)="review_sort=1;getReview();">최신순</li>
              <li [ngClass]="{active:review_sort==2}" (click)="review_sort=2;getReview();">평점 높은순</li>
              <li [ngClass]="{active:review_sort==3}" (click)="review_sort=3;getReview();">평점 낮은순</li>
            </ul>
            <!-- <select>
              <option>전체평점</option>
            </select> -->
          </div>

          <ul class="review-list">
            <li *ngFor="let row of reviewList | slice:((reviewPageNo-1)*reviewPageRow):(reviewPageRow*reviewPageNo)" class="clearfix">
              <div class="left-review">
                <div class="img">
                  <div class="img-grade-active" [ngStyle]="{'width': setGarde(row)}"></div>
                  <div class="img-grade-back"></div>
                  <div class="grade-cnt">{{row.grade}}</div>
                </div>
                <div class="writer clearfix">
                  <p *ngIf="row.best_yn == 1" class="review-best mr5">BEST</p>
                  <!-- <p *ngIf="row.id">{{ row.id.length > 4 ? row.id.substr(0,4) + '****' : row.id.substr(0,2) + '****' }}</p> -->
                  <p *ngIf="row.name">{{ row.name.substr(0,1) + '**' }}</p>
                  <p *ngIf="row.review_date">{{row.review_date}}</p>
                  <p class="cp" (click)="setOrderReviewReport(row)">신고</p>
                </div>
                <div class="review-txt" [innerHTML]="row.comment | safe: 'html'"></div>
              </div>
              <div *ngIf="row.imageCount > 0" class="right-review">
                <ul class="pic-list clearfix fr">
                  <li *ngFor="let file of getReviewImageList(row)" class="cp" (click)="setGuideView('reviewImage', file.url)">
                    <img *ngIf="file.thumbnail_url" [src]="file.thumbnail_url" />
                    <img *ngIf="!file.thumbnail_url" [src]="file.url" />
                    <!-- <button class="del-btn"></button> -->
                  </li>
                </ul>
              </div>
              <div *ngIf="row.img_link != '' && row.img_link != null && row.img_link != undefined" class="right-review">
                <ul class="pic-list clearfix fr">
                    <img [src]="row.img_link"  style="width:100px;height:100px;border:1px solid #e1e1e1;margin-right:10px;"/>
                </ul>
              </div>
            </li>
            <li *ngIf="reviewList.length < 1">
              <div>내용이 없습니다</div>
            </li>
          </ul>

          <div class="pagination-area pc-view">
            <ngb-pagination
              [(page)]="reviewPageNo"
              [pageSize]="reviewPageRow"
              [collectionSize]="reviewCount"
              [boundaryLinks]="true"
              (pageChange)="setReviewPage($event)"
              [maxSize]="10"
            >
              <ng-template ngbPaginationFirst>처음</ng-template>
              <ng-template ngbPaginationLast>마지막</ng-template>
              <ng-template ngbPaginationPrevious>이전</ng-template>
              <ng-template ngbPaginationNext>다음</ng-template>
            </ngb-pagination>
          </div>
        </ng-container>
      </div>

			<!-- 상품문의 -->
	    <!-- <div #Inquiry class="qna section">
	      <div class="title">상품문의<span> {{questionCount}}</span></div>
	      <div class="qna-header clearfix">
	        <button type="button" (click)="qnaWrite()">문의하기</button>
          <div class="right">
            <div [ngClass]="{active:isMy==1}" (click)="isMyQuestion('1')">내 문의보기</div>
            <div [ngClass]="{active:isMy==2}" (click)="isMyQuestion('2')">전체 문의보기</div>
            <select (change)="setState($event)">
              <option value="">답변상태</option>
              <option [value]="1">검토중</option>
              <option [value]="2">답변완료</option>
            </select>
          </div>
	      </div>

        <table class="list-table pc-view">
        <colgroup>
          <col style="width: 8%">
          <col style="width: 11%">
          <col style="width: 50%">
          <col style="width: 16%">
          <col style="width: 15%">
        </colgroup>

        <thead>
        <tr>
          <th>번호</th>
          <th>답변상태</th>
          <th>제목</th>
          <th>문의자</th>
          <th>등록일</th>
        </tr>
        </thead>

        <tbody>
          <tr *ngFor="let row of questionList | slice:((questionPageNo-1)*questionPageRow):(questionPageRow*questionPageNo)" id="tr_{{row.seq}}" (click)="view(row)">
            <td>{{row.seq}}</td>
            <td *ngIf="row.state == 0">검토중</td>
            <td *ngIf="row.state == 1" class="red">답변완료</td>
            <td class="tl">
              <a *ngIf="row.secret == '0'">문의 드립니다.</a>
              <a *ngIf="row.secret == '1'" class="lock">문의 드립니다.</a>
            </td>
            <td *ngIf="row.id != null">{{row.id.substr(0, 3)}}***{{row.id.substr(-2)}}</td>
            <td *ngIf="row.id == null">비회원</td>
            <td>{{row.question_date}}</td>
          </tr>
          <tr *ngIf="questionList.length < 1">
            <td colspan="5">내용이 없습니다</td>
          </tr>
        </tbody>
        </table>

        <ul class="qna-list mobile-view">
          <li *ngFor="let row of questionAddList;let i=index;" id="tr_{{row.seq}}">
            <div class="list clearfix" >
              <div class="state" *ngIf="row.state == 0">검토중</div>
              <div class="state red" *ngIf="row.state == 1">답변완료</div>
              <div class="title">
		            <div class="writer clearfix">
                  <p *ngIf="row.id != null">{{row.id.substr(0, 3)}}***{{row.id.substr(-2)}}</p>
                  <p *ngIf="row.id == null">비회원</p>
		              <p>{{row.question_date}}</p>
		            </div>
		            <div *ngIf="row.secret == '0'">문의합니다.</div>
		            <div *ngIf="row.secret == '1'" class="lock">문의합니다.</div>
	            </div>
              <button class="view-btn" [ngClass]="{view:mqnaView==i}" (click)="mqnaDetail(row, i)"></button>
              <div *ngIf="row.length < 1">
                <p>내용이 없습니다</p>
              </div>
            </div>
            <ul class="qna-detail" [ngClass]="{view:mqnaView==i}" >
              <li>
                <div class="txt">
                </div>
              </li>
              <li>
                <div class="answer clearfix">
                  <p>답변</p>
                  <p>{{row.reply_date}}</p>
                </div>
                <div class="txt">
                  {{row.comment}}{{i}}
                </div>
              </li>
            </ul>
          </li>
        </ul>

        <div class="qna-more mobile-view" (click)="addRow()">문의글 더보기</div>

				<div class="pagination-area pc-view">
				  <ngb-pagination
            [(page)]="questionPageNo"
            [pageSize]="questionPageRow"
            [collectionSize]="questionCount"
            [boundaryLinks]="true"
            (pageChange)="setQuestionPage($event)"
				    >
				    <ng-template ngbPaginationFirst>처음</ng-template>
				    <ng-template ngbPaginationLast>마지막</ng-template>
				    <ng-template ngbPaginationPrevious>이전</ng-template>
				    <ng-template ngbPaginationNext>다음</ng-template>
				  </ngb-pagination>
				</div>

	    </div> -->

	    <!-- 주문/결제안내 -->
      <div #order class="section">
        <div class="title">주문/결제안내</div>
        <div *ngIf="productGuide.length > 0" [innerHTML]="productGuide[0].contents | safe: 'html'"></div>
      </div>

      <!-- 입금확인/배송안내 -->
      <div #check class="section">
        <div class="title">입금확인/배송안내</div>
        <div *ngIf="productGuide.length > 0" [innerHTML]="productGuide[1].contents | safe: 'html'"></div>
      </div>

      <!-- 교환반품안내 -->
      <div #exchange class="section">
        <div class="title">교환반품안내</div>
        <div *ngIf="productGuide.length > 0" [innerHTML]="productGuide[2].contents | safe: 'html'"></div>
      </div>

      <!-- A/S안내 -->
      <div #repair class="section">
        <div class="title">A/S안내</div>
        <div *ngIf="productGuide.length > 0" [innerHTML]="productGuide[3].contents | safe: 'html'"></div>
      </div>
	  </div>

	  <div *ngIf="productData.is_soldout==false" class="right-area" [ngClass]="{fixed:fixedOrder==true}">
	    <div class="selection">
		    <div class="option" [ngClass]="{view:openOrderbox==true}">
		      <p class="title">기본옵션</p>
          <ng-container *ngIf="colorList.length > 0">
						<div class="select-box color-box mt10">
							<a class="select-title cp" (click)="selectbox_pulldown($event, 'color')">
								<span class="select-span">{{bottomPropertyColor}}</span>
								<i class="material-icons selectbox-arrow">&#xe5cf;</i>
							</a>
							<ul class="selectbox-ul">
								<li class="cp" *ngFor="let row of colorList" (click)="selectedOption($event)">{{row}}</li>
							</ul>
						</div>
          </ng-container>
          <div class="select-box" *ngIf="colorList.length > 0 && sizeList.length < 1">
            <a class="select-title cp readonly">
              <span class="select-span">사이즈</span>
              <i class="material-icons selectbox-arrow">&#xe5cf;</i>
            </a>
          </div>
          <div class="select-box size-box" *ngIf="sizeList.length > 0">
            <a class="select-title cp" (click)="selectbox_pulldown($event, 'size')">
              <span class="select-span">{{bottomPropertySize}}</span>
              <i class="material-icons selectbox-arrow">&#xe5cf;</i>
            </a>
            <ul class="selectbox-ul">
              <li class="cp" *ngFor="let row of sizeList" [ngClass]="{clight: row.is_soldout != '0'}" (click)="selectProduct(row)">{{row.display_size_name ? row.display_size_name : '단품'}} {{getDisplayDifferencePrice(row)}} {{row.is_soldout == '0' ? '' : '(품절)'}}</li>
            </ul>
          </div>
          <div class="mt10" *ngIf="propertyList.length < 1">
            <span>현재 판매중인 옵션이 없습니다.</span>
          </div>
		    </div>
        <div class="option" [ngClass]="{view:openOrderbox==true}" *ngIf="giftList.length > 0">
          <p class="title">추가옵션 (선택)</p>
          <select (change)="selectGift($event)">
            <option value="" disabled>사은품</option>
            <option *ngFor="let row of giftList" [value]="row.product_freegift_seq">{{row.product_name | nohtml}}</option>
          </select>
        </div>

        <div class="between"></div>
        <div class="selected cleardix" *ngFor="let product of productList; let i = index">
          <div class="name">{{product.product_name | nohtml}} {{ product.display_color_name ? '/ ' + product.display_color_name : '' }} {{ product.display_size_name ? '/ ' + product.display_size_name : '' }}</div>
          <div class="qty-area">
            <div *ngIf="product.isGift == 1" class="qty"></div>
	          <div *ngIf="product.isGift == 0" class="qty">
	            <button class="btn-minus" (click)="clickQty(product, -1)">&#xe15b;</button>
                <input type="text" [value]="product.qty" [(ngModel)]="product.qty" readonly="readonly"/>
              <button class="btn-plus" (click)="clickQty(product, +1)">&#xe145;</button>
	          </div>
	          <!-- <button class="coupon">쿠폰적용</button> -->
          </div>
          <div class="price-area">
            <div class="price">{{product.consumer_price * product.qty | number}}<span>원</span></div>
            <button class="btn-del" (click)="deleteProduct(i)">삭제</button>
          </div>
        </div>

        <div class="btn-area" *ngIf = "!openOrderbox">
          <a class="btn1 cart-btn cp" (click)="openOrderbox = true">장바구니</a>
          <a class="btn1 buy-btn cp" (click)="openOrderbox = true">구매하기</a>
        </div>

        <div class="btn-area" *ngIf = "openOrderbox">
          <div class="price clearfix"  [ngClass]="{view:openOrderbox==true}"><div>총 상품금액</div><div>{{totalPrice | number}}<span>원</span></div></div>
          <!-- <a class="btn1 bulk-btn mobile-view">대량구입</a> -->
          <a class="btn1 cart-btn cp" (click)="setCartSave()">장바구니</a>
          <a class="btn1 buy-btn cp" (click)="setOrder()">구매하기</a>
          <app-naverpay nPay_btnID="nPay_A"></app-naverpay>
        </div>
	    </div>
      <div class="spread-btn mobile-view" (click)="openOrderbox = !openOrderbox">
        <img src="/assets/images/icon_spread.png" [ngClass]="{open: openOrderbox == true }" />
      </div>
    </div>
	</div>
</div>

<div class="guide" [ngClass]="{view:guideView !== ''}">
	<div class="inner" [ngClass]="{view:guideView=='헬멧'}">
	  <img src="/assets/images/shop/helmet_size_guide.png" alt="헬멧 사이즈 안내" />
	</div>
  <div class="inner" [ngClass]="{view:guideView=='자켓'}">
	  <img src="/assets/images/shop/jacket_size_guide1.jpg" alt="자켓 사이즈 안내1" />
    <img src="/assets/images/shop/jacket_size_guide2.jpg" alt="자켓 사이즈 안내2" />
    <img src="/assets/images/shop/jacket_size_guide3.jpg" alt="자켓 사이즈 안내3" />
	</div>
  <div class="inner" [ngClass]="{view:guideView=='장갑'}">
	  <img src="/assets/images/shop/glove_size_guide.jpg" alt="장갑 사이즈 안내" />
	</div>
	<div class="inner" [ngClass]="{view:guideView=='reviewImage'}">
	  <img [src]="reviewImageURL" alt="구매후기 이미지 원본보기" style="max-width: 100%;" />
	</div>
  <div class="close" (click)="guideView=''"><img src="/assets/images/icon_x1.png"></div>
</div>

<app-footer></app-footer>