<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  
  <div class="mypage-contents">
    <div class="guide">
      <div class="title">안내사항</div>
        <p class="dot">·</p><p class="dot1">회원 탈퇴 시 적립된 마일리지는 즉시 삭제 처리되며, 재가입하셔도 복원되지 않습니다. 게시판 이용이 가능합니다.</p>
        <p class="dot">·</p><p class="dot1">회원탈퇴 후에도 바이크마트에 재가입하실 수 있습니다 단, 기존 ID사용은 불가능하고, 신규 ID로만 가입이 가능합니다.</p>
        <p class="dot">·</p><p class="dot1">탈퇴 후 재가입시 기존 ID 로 사용하던 거래정보, 쿠폰, 마일리지, 회원정보, 상품정보 등은 복원 되지 않습니다.</p>
    </div>    
  
    <div class="section">
      <div class="section-header">
        <div class="title page-title">회원탈퇴</div>
      </div>
      
      <div class="complete-div">
        <p class="txt1">회원탈퇴 완료</p>
        <p class="txt2">감사합니다.</p>     
      </div> 
      
      <div class="btn-area">
        <a routerLink="/" class="basic-btn">확인</a>
      </div>          
    </div>
  </div>    
</div>

<app-footer></app-footer>
