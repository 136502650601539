<app-header></app-header>

<div class="event-page inner">
  <div class="event1-20230310">
    <ul class="ranking">
      <li *ngFor="let member of orderRankList">
        <div class="id">{{member.name}}</div>
        <div class="amt">{{member.pay_amt}}</div>
      </li>
    </ul>
  </div>
  <!-- <div class="event1">
    <div class="event-title"></div>
    <div class="info">즐거운 쇼핑 되셨나요?<br class="mobile-only" /> 구매왕 구매 가격을 보고 구매왕에 도전해보세요!</div>
    <div class="event-area clearfix">
      <div class="top10">
        <div class="title clearfix"><span>구매왕 TOP10</span><span></span></div>
        <ul class="ranking">
          <li *ngFor="let member of orderRankList">
            <div class="id">{{member.name}}님</div>
            <div class="amt">{{member.pay_amt}}원</div>
          </li>
        </ul>
      </div>
      <div class="list">
        <div class="title clearfix"><span>사은품 LIST</span><span></span></div>
      </div>
    </div>
    <div class="notice">
      <div>유의사항을 확인해주세요.</div>
      <ul>
        <li>1. 구매순위는 실시간으로 업데이트 됩니다.</li>
        <li>2. 구매왕 top10은 3월 23일 23시 59분에 마감됩니다.</li>
        <li>3. 사은품은 04월 07일부터 발송됩니다.</li>
        <li>4. 발송된 상품은 고객님들과의 신뢰를 위해서 인증샷은 필수입니다.</li>
        <li>5. 재고환경에 따라 색상은 변경 될 수 있습니다.</li>
        <li>6. 사이즈 재고에 따라 불가피하게 고객님의 사이즈와 상이할 수 있습니다.</li>
        <li><span class="space"></span>(구매왕 고객님은 바이크마트에서 유선으로 연락드립니다!)</li>
        <li>7. 구매금액 반영시간은 03월 23일 23시 59분에 마감되지만</li>
        <li><span class="space"></span>교환ㆍ반품 및 입금기한에 따라 순위가 변동될 수 있으며</li>
        <li><span class="space"></span><span class="accent">2주 후인 04월 06일에 최종순위가 결정</span>됩니다.</li>
        <li>8. 해당이벤트는 <span class="accent">온라인에서만 진행되는 이벤트</span>입니다.</li>
        <li>9. <span class="accent">네이버페이로 구매해주신 고객님께서는 순위반영까지 시간이 소요될 수 있으니 참고부탁드립니다.</span></li>
      </ul>
    </div>
  </div> -->
</div>

<app-footer></app-footer>