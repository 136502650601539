import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event3-prize',
  templateUrl: './event3-prize.component.html',
  styleUrls: ['./event3-prize.component.scss']
})
export class Event3PrizeComponent implements OnInit {

  @Input() name: any;
  @Input() mileage: any;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

}
