import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StoryComponent } from '@page/company/company-story/story/story.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

const options: NgbModalOptions = {
  backdrop: true,
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown modal-story'
};

@Component({
  selector: 'app-company-story',
  templateUrl: './company-story.component.html',
  styleUrls: ['./company-story.component.scss']
})
export class CompanyStoryComponent implements OnInit {

  public isMobileApp: Boolean = false;

  public modalRef:any = null;

  constructor(
    public router: Router,
    private location: Location,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

  showStory(site: 'daejeon' | 'ulsan' | 'pohang' | 'gyeonggi_seobu') {
    const modalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: false,
      size: 'xl',
      centered: true,
      windowClass: this.isMobileApp ? 'modal-fadeInDown modal-story modal-mobile' : 'modal-fadeInDown modal-story'
    };

    console.log(modalOptions)

    // const tmp = {...options};
    this.modalRef = this.modalService.open(StoryComponent, modalOptions);
    this.modalRef.componentInstance.site = site;

    this.modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

}
