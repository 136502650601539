import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class NaverPayService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 네이버페이 결제
  naverpay( params ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'naverpay',
        version: '1.0',
        action: 'naverpay'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }


}
