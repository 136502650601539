<div class="modal-header">
  <h4 class="modal-title fl">반품신청</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="orderno">
    <div><span>주문번호</span>{{orderSeq}}</div>
    <div><span>주문일자</span>{{orderDate}}</div>
  </div>
  
  <form [formGroup]="form">
  <div class="section">
    <div class="title">반품상품 선택</div>
    <table class="list-table">
    <colgroup>
      <col style="width: 70%">
      <col style="width: 30%">
    </colgroup>    
  
    <thead>
    <tr>
      <th>상품정보</th>
      <th>신청수량</th>
    </tr>    
    </thead>
    
    
    <tbody>
      <ng-container *ngFor= "let row of rowDetail; let i = index">
        <tr *ngFor="let details of row.product; let j = index;">
          <td>
          <div class="goods clearfix">
            <div class="img"><img src="{{row.thumbnail_url}}"></div>
            <div class="info">
              <p class="name">{{details.product_name}}</p>
              <p class="size">색상 : {{details.display_color_name}} / 사이즈 : {{details.display_size_name ? details.display_size_name : '단품'}} / {{details.qty}}개</p>  
              <p class="red">반품/교환 가능한 수량 {{details.qty - details.used_req_qty}}개</p>
              <p class="price">{{details.total_amt | number}}원</p>  
            </div>
          </div>
          </td>
          <td class="tc">
            <select class="selected" (change)="selectedQty($event, details)">
              <option value="">선택</option>
              <option *ngFor="let a of makeFakeArray(details);let i = index" [value]="i+1">{{i+1}}</option>
            </select>
          </td>
        </tr>
      </ng-container>
    </tbody>
    </table>  
  </div>

  <div class="section">
    <div class="title">반품 사유 선택</div>
    <select class="reason" formControlName="req_reason" [ngClass]="{formErrors:formErrors['req_reason']}" (change)="selectedOptionReason($event)">
      <option value="">선택</option>
      <option [value]="1">배송오류</option>
      <option [value]="2">상품불량</option>
      <option [value]="3">단순변심</option>
      <option [value]="4">기타</option>
    </select>
    <span *ngIf="formErrors['req_reason']" class="error-message ml10">{{formErrors['req_reason']}}</span>
    <textarea formControlName="comment" placeholder="반품과 관련하여 하고 싶은 말을 남겨주세요. (최대 100자)"></textarea>

    <div class="title">반품 방식 선택</div>
    <select class="reason" formControlName="req_type" [ngClass]="{formErrors:formErrors['req_type']}" (change)="selectedOptionType($event)">
      <option value="">선택</option>
      <option *ngFor="let item of retTypeList" [value]="item.common_code">{{item.common_name}}</option>
    </select>
    <span *ngIf="formErrors['req_type']" class="error-message ml10">{{formErrors['req_type']}}</span>

    <ng-container *ngIf="form.controls.req_reason.value == '3' || form.controls.req_reason.value == '4'">
      <div class="title">배송비 지급 방식 선택</div>
      <select [ngClass]="{formErrors:formErrors['req_pay_type']}" formControlName="req_pay_type" class="reason" (change)="selectedOptionPayType($event)">
        <option value="">선택</option>
        <option *ngFor="let item of retPayTypeList" [value]="item.common_code" [attr.disabled]="(item.common_code == '2000' && isEnclose === true) ? isEnclose : null">{{item.common_name}}</option>
      </select>
      <span *ngIf="formErrors['req_pay_type']" class="error-message ml10">{{formErrors['req_pay_type']}}</span>
      <p *ngIf="req_amt_master < delivery.delivery_amt" class="red">주문금액이 배송비보다 적은 경우 배송비를 택배에 동봉하거나 판매자에게 송금하셔야 합니다.</p>
    </ng-container>

    <div class="title">첨부파일</div>
		<div>
      <input type="file" [ngClass]="{formErrors:formErrors['upload']}" class="file-input form-control" (change)="handleUploadFileChanged($event)" >
      <span *ngIf="formErrors['upload']" class="error-message">{{formErrors['upload']}}</span>
      <div class="progress mt5 mb5">
        <div class="progress-bar"></div>
      </div>
      <div class="mt5">
      ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 50MB 제한, 가로*세로 : 1000*1000픽셀 (필히 등록)
      </div>
      <div class="file-div" *ngIf="imageList.length > 0">
        <ul class="file-ul clearfix">
          <li class="fl" *ngFor="let row of imageList">
            <div class="upload-item" >
              <div class="upload-image">
                <img [src]="row.filepath" />
              </div>
              <div class="upload-filename"><a href="{{row.filepath}}" target="_blank" title="{{row.origin}}">{{row.origin_filename}}</a></div>
              <div class="upload-size">{{row.size}}KB
                <a class="cp ml5" (click)="imageDelete(row)">
                  <img src="/assets/images/del_ico.png"/>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
		</div>
  </div>
  
  <div class="section refund">
    <div class="title">환불 정보</div>
    <ul class="clearfix">
      <li>
        <div>취소상품 총 금액 합계</div>
        <div>{{req_amt_master + paid_delivery | number}} 원</div>
        <div class="clearfix"><p>취소상품합계</p><p>{{req_amt_master | number}} 원</p></div>
        <div class="clearfix"><p>배송비합계</p><p>{{paid_delivery | number}} 원</p></div>
        <!-- <div class="clearfix"><p>주문할인 취소금액</p><p>0원</p></div> -->
      </li>
      <li>
        <div>환불금액 차감내역</div>
        <div>{{deduction_amt_master | number}} 원</div>
        <div class="clearfix"><p>배송비 추가금액</p><p>{{deduction_amt_master | number}} 원</p></div>
        <!-- <div class="clearfix"><p>할인 변동금액</p><p>0 원</p></div> -->
        <div class="clearfix"><p>취소/반품비용 환불금 차감</p><p>0 원</p></div>
      </li>
      <li>
        <div>환불 예정금액</div>
        <div>{{real_ret_master | number}} 원</div>
      </li>      
    </ul>
  </div> 

  <div class="section">
    <div class="title">환불 계좌 정보</div>
    <table class="info-table">
    <colgroup>
      <col style="width: 160px">
      <col>
    </colgroup>    
    <tbody>
    <tr>
      <th>은행</th>
      <td>
        <input formControlName="bank" type="text" placeholder="은행을 입력해 주세요." id="col1" value="{{bankInfo.bank}}" [ngClass]="{formErrors:formErrors['bank']}"/>
        <span *ngIf="formErrors['bank']" class="error-message">{{formErrors['bank']}}</span>
      </td>
    </tr>
    <tr>
      <th>계좌번호</th>
      <td><input formControlName="account" placeholder="계좌번호를 입력해 주세요." type="text" id="col2" value="{{bankInfo.account}}" [ngClass]="{formErrors:formErrors['account']}"/>
        <span *ngIf="formErrors['account']" class="error-message">{{formErrors['account']}}</span>
      </td>
    </tr>
    <tr>
      <th >예금주</th>
      <td>
        <input formControlName="depositor" placeholder="예금주를 입력해 주세요." type="text" id="col3" value="{{bankInfo.depositor}}" [ngClass]="{formErrors:formErrors['depositor']}"/>
        <span *ngIf="formErrors['depositor']" class="error-message">{{formErrors['depositor']}}</span>
      </td>
    </tr>    
    </tbody>
    </table> 
  </div>
  </form>
  
  <div class="section">
    <div class="title">반품배송비 정보</div>
    <div class="div1">
      <div>
        반품배송비<span class="sign-equal">=</span>
        <span *ngIf="form.controls.req_reason.value == '1' || form.controls.req_reason.value == '2'" class="price">업체 부담</span>
        <span *ngIf="form.controls.req_reason.value == '' || form.controls.req_reason.value == '3' || form.controls.req_reason.value == '4'" class="price">{{pay_delivery | number}} 원</span>
      </div>
      <div *ngIf="form.controls.req_type.value == '1000'">
        <span class="red"><br>회수신청을 선택하셨으므로 업체에서 택배사에 접수를 대신 해드립니다.</span>
      </div>
      <div *ngIf="form.controls.req_type.value == '2000'">
        <span class="red"><br>직접발송을 선택하셨으므로 고객님께서 직접 택배사에 접수하셔서 선불로 발송해 주시기 바랍니다.</span>
      </div>
      <div *ngIf="form.controls.req_reason.value == '3' || form.controls.req_reason.value == '4'">
        <span *ngIf="form.controls.req_pay_type.value == '1000'" class="red"><br>배송비를 택배에 동봉하여 발송해 주시기 바랍니다.</span>
        <span *ngIf="form.controls.req_pay_type.value == '2000'" class="red"><br>배송비가 주문금액에서 차감됩니다.</span>
        <span *ngIf="form.controls.req_pay_type.value == '3000'" class="red"><br>배송비를 판매자에게 송금해 주시기 바랍니다.</span>
      </div>
    </div>
  </div>

  <div class="btn-area">
   <button class="btn1" (click)="activeModal.dismiss()">취소</button>
   <!-- <button class="btn1 red" (click)="submit()">반품신청 및 반품배송비 결제</button> -->
   <button class="btn1 red" (click)="submit()">반품신청</button>
  </div>

  <div class="section notice">
    <div class="title">반품관련공지</div>
    <div class="div1 info">
      * 송장수령일로 3일이내 상품만 접수 가능합니다.(교환/반품불가상품 해외오더상품은 제외입니다.)<br><br>
      * 상품처음상태그대로 이중포장 부탁드립니다.(텍제거/텍분리/박스훼손 기타등 상품은 접수 불가입니다.)<br>
    </div>
  </div>  
</div>


