/*******************************************************************************
  설  명 : 마이페이지 - 회원정보수정
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct, NgbDatepickerConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { SMemberService } from '@app/service/member.service';

import { MypageDestinationComponent } from '@page/mypage/mypage-destination/mypage-destination.component';

import * as moment from 'moment';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '1000',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-modify',
  templateUrl: './mypage-modify.component.html',
  styleUrls: ['./mypage-modify.component.scss']
})
export class MypageModifyComponent implements OnInit {
  @ViewChild('email2', {static: false}) inputEmail2: ElementRef;

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  pwConfirm: boolean = false; // 비밀번호 확인
  isGender = 'M'; // 성별
  isSms = 'Y'; // SMS 수신
  isKakao = 'Y';
  isBike = 'Y'; // bike 유무
  today: string;

  public memberInfo: any = {};

  errorResponse: ApiResponse;

  public confirmForm: FormGroup;
  public memberEditForm: FormGroup;

  private dateModel: NgbDateStruct;

  public formErrors = {};

  public email_array = [
    'naver.com',
    'gmail.com',
    'daum.net',
    'nate.com',
    'dreamwiz.comn',
    'yahoo.co.kr',
    'outlook.com',
    'hotmail.com',
  ];

  public region_A_list = [];
  public region_B_list = [];
  public region_B_originalList = [];

  public yearList = [];
  public monthList = [];
  public dayList = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    public memberService: SMemberService,
    public authService: AuthService,
    private calendar: NgbCalendar,
    config: NgbDatepickerConfig,
    private modalService: NgbModal,
  ) {
    this.buildForm();
    this.buildEditForm();
    config.minDate = { "year" : 1900, "month": 1, "day": 1};
    config.maxDate = { "year" : 2030, "month": 12, "day" : 31};
  }


  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.confirmForm = this.formBuilder.group({
      id: [ '', [Validators.required] ],
      pw: [ '', [Validators.required] ],
    });

    this.confirmForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.confirmForm, this.formErrors );
    })
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildEditForm(): void {
    const todayModel: NgbDateStruct = this.utilService.getDate('');

    this.memberEditForm = this.formBuilder.group({
      mem_no: [''],
      id: [ '', [Validators.required] ],
      pw: [ '', [Validators.maxLength(64), Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{6,20}$')] ],
      pw_check: [ '', [Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{6,20}$')] ],
      name: [ '', [Validators.required] ],
      hp1: [ '', [Validators.required] ],
      hp2: [ '', [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]{3,4}$')] ],
      hp3: [ '', [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]{4}$')] ],
      email1:[ '', [Validators.required, Validators.pattern('^[a-zA-Z0-9._-]*$')] ],
      email2:[ '', [Validators.required, Validators.pattern('^[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')] ],
      email3: [ '' ],
      region1:[ '', [Validators.required] ],
      region2:[ '', [Validators.required] ],
      gender: [ 'M', [Validators.required] ],
      birthdate1: [ '', [] ],
      birthdate2: [ '', [] ],
      birthdate3: [ '', [] ],
      email_yn: [ '0', [Validators.required] ],
      sms_yn: [ '0', [Validators.required] ],
      kakao_yn: [ '0', [Validators.required] ],
      bike_yn: [ '0' ],
    });

    this.memberEditForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memberEditForm, this.formErrors );
    })
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;

      // 간편가입자의 경우 비밀번호 검증 안하도록
      if( this.memberInfo.id.indexOf('kakao') > -1 || this.memberInfo.id.indexOf('naver') > -1 ) {
        this.memberService.pw_confirm = true;
        this.pwConfirm = true;

        this.getMemberInfo();
      }
    });

    this.confirmForm.patchValue({
      id: this.memberInfo.id
    });

    this.memberEditForm.patchValue({
      id: this.memberInfo.id
    });

    this.setBirthdayList();

    this.getRegion();
  }

  /*******************************************************************************
    설  명 : 생년월일 리스트 입력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setBirthdayList() {
    for (let i = 1940; i <= parseInt(moment().format('YYYY')); i++) {
      this.yearList.push(i.toString());
    }

    this.monthList = [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
    ];

    this.dayList = [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
      '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    ];
  }

  /*******************************************************************************
    설  명 : 지역리스트 가져오기
  *******************************************************************************/
  getRegion() {
    this.memberService.getRegion().then( response => {
      if( response.ResultCode ) {
        this.region_A_list = response.region_A;
        this.region_B_originalList = response.region_B;
      } else {
        this.region_A_list = [];
        this.region_B_originalList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday(obj: NgbInputDatepicker, check: boolean) {
    if( check ) {
      this.memberEditForm.patchValue({
        birthdate: this.calendar.getToday()
      });
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 사용자 정보 가져오기
  *******************************************************************************/
  getMemberInfo() {
    this.memberService.getMemberInfo().then(response => {
      if( response['ResultCode'] == true ) {
        let data = response['data'];

        let hp = data.hp.split('-');
        data.hp1 = hp[0];
        data.hp2 = hp[1];
        data.hp3 = hp[2];

        if( data.email != null ) {
            let emailArr = data.email.split('@');
            data.email1 = emailArr[0];
            data.email2 = emailArr[1];
            data.email3 = "";

          this.email_array.forEach( item => {
            if( item == emailArr[1] ) {
              data.email3 = emailArr[1];
              return;
            }
          });
        }

        if( data.birthdate != null ) {
          let birthdate = data.birthdate.split('-');
          data.birthdate1 = birthdate[0];
          data.birthdate2 = birthdate[1];
          data.birthdate3 = birthdate[2];
        }

        // 구군 리스트 
        this.region_B_list = this.region_B_originalList.filter(row => {
          return row.master_seq == data.region1
        });

        this.memberEditForm.patchValue( data );
      }
    })
    .catch(response => {
      this.toastrService.error('사용자 정보를 가져오는데 실패하였습니다.', '');
      this.utilService.handleFormSubmitError(response, null, null);
    });
  }

  setEmailChange(event, element) {
    this.memberEditForm.get(element).
    setValue(event.target.value);
    if( event.target.value == "" ) {
      if( element == "email2" ) this.inputEmail2.nativeElement.focus();
    }
  }


  /*******************************************************************************
    설  명 : 비밀번호 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public confirm() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.confirmForm, this.formErrors);
    if( this.confirmForm.valid ) {
      this.memberService.checkPwConfirm( this.confirmForm )
      .then(response => {
        if( response['ResultCode'] == true ) {
          this.memberService.pw_confirm = true;
          this.pwConfirm = true;

          this.getMemberInfo();
        } else {
          this.toastrService.error(response['ResultMessage'], '비밀번호 재확인 실패');
        }
      })
      .catch(response => {
        this.utilService.handleFormSubmitError(response, this.confirmForm, this.formErrors);
      });
    }
  }

   /*******************************************************************************
    설  명 : 회원정보수정 처리
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memberEditForm, this.formErrors);
    if( this.memberEditForm.valid) {
      this.memberService.setMemberSave( this.memberEditForm ).then(response => {
        if( response['ResultCode'] == true) {
          this.toastrService.success(response['ResultMessage'], '회원정보수정 성공');
          // this. router.navigate(['/mypage/dashboard']);
        } else {
          this.toastrService.error(response['ResultMessage'], '회원정보수정 실패');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.memberEditForm, this.formErrors);
      });
    }
  }
  /*******************************************************************************
    설  명 : 배송지관리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  destination() {
    const modalRef = this.modalService.open(MypageDestinationComponent, options);

    modalRef.componentInstance.choice = 'none';

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

  /*******************************************************************************
		설  명 : 시도 선택이벤트
	*******************************************************************************/
  changeSido(event) {
    this.memberEditForm.patchValue({region2: ''});

    this.region_B_list = this.region_B_originalList.filter(row => {
      return row.master_seq == event.target.value
    });
  }
}
