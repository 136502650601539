import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { SEventService } from '@app/service/event.service';

@Component({
  selector: 'app-event-coupon',
  templateUrl: './event-coupon.component.html',
  styleUrls: ['./event-coupon.component.scss']
})
export class EventCouponComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public activeTab: any = 1;

  public eventList: any = [];
  public eachEventList: any = [];
  public eventInfo: any = {};

  public eventContents: any = '';

  public event_total = 0;
  public event_total1 = 0;

  public search: any = {
    seq: '',
    pageNo: 1,
    pageRow: 12,
    sort: 1,
    coupon_code: ''
  };

  public search1: any = {
    seq: '',
    pageNo: 1,
    pageRow: 12,
    sort: 1
  };
  
  resultSort = 1; // 결과값 sort
  viewType = 1; // 상품조회 type

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sEventService: SEventService,
    private toastrService: ToastrService,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.search.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? 0 : params.seq;

      // 이벤트 정보 가져오기
      this.getEventDetail();

      // 이벤트 배너 리스트 가져오기
      this.getEventCategoryBannerList();
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventDetail() {
    this.sEventService.getEventDetail( this.search ).then( response => {
      if( response.ResultCode ) {
        this.eventInfo = response.data;

        this.search.coupon_code = this.eventInfo.coupon_code;
        console.log(this.search.coupon_code); 
        // 클릭된 이벤트 상품 리스트 가져오기
        this.getEachEventProductList();

        if( this.eventInfo.event_type == '1000' || this.eventInfo.event_type == '1001' ) this.activeTab = 0;
        else this.activeTab = 1;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 배너 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventCategoryBannerList() {
    this.sEventService.getEventCategoryBannerList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.eventList = response.data;
        this.event_total = response.count;

        this.eventList.forEach(item => {
          if( item.seq == this.search.seq ) this.eventContents = item.contents;
        });
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 페이지로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage1(page) {
    this.search1.pageNo = page;

    this.getEventCategoryBannerList();
  }

  loadPage(page) {
    this.search.pageNo = page;

    this.getEachEventProductList();
  }

  /*******************************************************************************
    설  명 : 클릭된 이벤트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEachEventProductList() {
    this.sEventService.getEachEventProductList( this.search ).then(response => {
      if ( response.ResultCode ) {
     
        this.eachEventList = response.data;
        this.event_total1 = response.count;
        console.log(this.search);
        console.log(this.eachEventList);
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : ngif 크기비교 int로 변환
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  comparePrice(a, b) {
    let c = parseInt(a);
    let d = parseInt(b);
    if ( c > d) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabSetValue( value: any ) {
    this.activeTab = value;
  }
  
  /*******************************************************************************
    설  명 : 이벤트 페이지 이동
  *******************************************************************************/
  setEventPage( seq: any ) {
    this.search.seq = seq;

    this.router.navigate(['/event/coupon'], {
      relativeTo: this.activatedRoute,
      queryParams: {seq: this.search.seq}
    })
  }
}
