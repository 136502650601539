/*******************************************************************************
  설  명 : 휴면회원 탈회 전 복원 여부 확인 요청
  생성일 : 2025-03-03
  생성자 : 권세창
*******************************************************************************/
import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

import { ApiResponse } from '@app/service/api-response';
import { AuthService } from '@app/service/auth.service';
import { config } from '@app/service/config.service';
import { SMemberService } from '@app/service/member.service';
import { UrlService } from '@app/service/url.service';
import { UtilService } from '@app/service/util.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-member-dormant-check',
  templateUrl: './member-dormant-check.component.html',
  styleUrls: ['./member-dormant-check.component.scss']
})
export class MemberDormantCheckComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  errorResponse: ApiResponse;
  public memberForm: FormGroup;
  public memberFormErrors = {  
    id: '',
    pw: ''
  };

  public previousOrgUrl: any = '';
  public previousUrl: any = '';
  public previousParams: any = {};
  public retUrl: any = '';
  public isInAppBrowser: any;

  private subscription: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
    private router: Router,
    private sMemberService: SMemberService,
    private toastrService: ToastrService,
    private urlService: UrlService,
    private utilService: UtilService,
  ) {
    // http로 접속 시 https로 리다이렉트
    if( location.hostname.indexOf('localhost') < 0 && location.protocol == 'http:' ) {
      window.location.href = location.href.replace('http:', 'https:');
    }

    this.buildForm();

    this.activatedRoute.queryParams.subscribe(params => {
      if( params.retUrl !== undefined ) this.retUrl = params.retUrl;
      if( params.isApp !== undefined ) this.isInAppBrowser = params.isApp;
    });
  }

  buildForm(): void {
    this.memberForm = this.formBuilder.group({
      id:[ '', [Validators.required] ],
      pw:[ '', [Validators.required] ]
    });

    this.memberForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memberForm, this.memberFormErrors );
    });

  }


  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /*******************************************************************************
    설  명 : 회원정보 복원 동의 처리 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public checkSubmit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memberForm, this.memberFormErrors);

    if( this.memberForm.valid ) {
      this.sMemberService.setMemberDormantCheck( this.memberForm )
      .then(response => {
        if( response['ResultCode'] == true ) {

          const domain: any = location.hostname.replace('shop.','');
          this.toastrService.success(response['ResultMessage'], ''); 

          // 성공 후 페이지 이동
          window.location.href = "https://www.bikemart.co.kr";
                
        } else {
          this.toastrService.error(response['ResultMessage'], '');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.memberForm, this.memberFormErrors);
      });
    }
  }


  /*******************************************************************************
    설  명 : 메인페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goMain() {
    if( this.retUrl === 'https://shop.bikemart.co.kr' ) {
      window.location.href = this.retUrl;
    } else {
      this.router.navigate(
      ['/main'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    }
  }
}
