<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<div class="grade-page">
  <div class="grade-header">
    <div class="title1">MEMBERSHIP</div>
    <div class="title2"><span>바이크마트 회원등급안내</span></div>
    <div class="txt">바이크마트 공식몰(본사매장,온라인)에서만 제공되는<br/>새로워진 회원등급혜택</div>
  </div>
  <div class="grade-table">
    <ul class="clearfix">
      <li class="th">
        <div>회원등급</div>
        <div>회원등급기준</div>
        <div>최대 할인율</div>
      </li>
      <li class="grade-f">
        <div>Family</div>
        <div>
          <span class="bold">신규회원 가입시</span><br/>패밀리등급 적용
        </div>
        <div>
          최대 <span class="rate">7</span>% 할인
        </div>
      </li>
      <li class="grade-t">
        <div>Titanium</div>
        <div>
          누적 구매금액<br/><span class="bold">20만원 이상</span>
        </div>
        <div>
          최대 <span class="rate">8</span>% 할인
        </div>
      </li>
      <li class="grade-g">
        <div>Gold</div>
        <div>
          누적 구매금액<br/><span class="bold">50만원 이상</span>
        </div>
        <div>
          최대 <span class="rate">10</span>% 할인
        </div>
      </li>
      <li class="grade-d">
        <div>Diamond</div>
        <div>
          누적 구매금액<br/><span class="bold">70만원 이상</span>
        </div>
        <div>
          최대 <span class="rate">13</span>% 할인
        </div>
      </li>
      <li class="grade-v">
        <div>Vip</div>
        <div>
          누적 구매금액<br/><span class="bold">100만원 이상</span>
        </div>
        <div>
          최대 <span class="rate">15</span>% 할인
        </div>
      </li>
    </ul>
  </div>
  <div class="footer-txt">
    상품별로 할인요율이 상이할 수 있습니다.<br/>
    1년 이내 구매이력이 없는 경우에는 등급이 조정될 수 있습니다.<br/>
    오픈마켓 및 네이버페이로 결제된 주문건은 구매누적금액에 포함되지않습니다.
  </div>
</div>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>
