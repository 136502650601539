<app-header class="pc-view"></app-header>

<div class="inner">
  <div class="page-header">
    <div class="page-title">주문완료</div>
    <ul class="step clearfix pc-view">
      <li>장바구니</li>
      <li>주문/결제</li>
      <li class="active">완료</li>
    </ul>  
    <a routerLink="" class="close-btn mobile-view"></a>
  </div>

  <div class="complete-div">
    <div class="txt1">주문이 완료 되었습니다.</div>
    <div class="txt2">주문해 주셔서 감사합니다.</div>
    
    <div class="payment-info clearfix">
      <p class="title">결제정보</p>
      <ul>
        <li>
          <p>결제금액</p>
          <p class="red">{{getPayAmt(orderInfo) | number}} 원</p>
        </li>
        <ng-container *ngIf="orderInfo.order_status == '0' && orderInfo.account_seq">
          <li>
            <p>결제방법</p>
            <p>무통장입금</p>
          </li>
          <li>
            <p>입금은행</p>
            <p>{{orderInfo.bank_name}}</p>
          </li>
          <li>
            <p>입금계좌</p>
            <p>{{orderInfo.bank_account}}</p>
          </li>
          <li>
            <p>입금자명</p>
            <p>{{orderInfo.receipt_name}}</p>
          </li>
        </ng-container>
        <ng-container *ngFor="let item of payList">
          <ng-container *ngIf="item.pay_method != '0006'">
          <li>
            <p>결제방법</p>
            <p>{{item.pay_method_name}}</p>
          </li>
          <li *ngIf="item.pay_method == '0001'">
            <p>카드종류</p>
            <p>{{item.card_name1}} ({{item.card_no}})</p>
          </li>
          <li *ngIf="item.pay_method == '0001'">
            <p>승인번호</p>
            <p>{{item.app_no}}</p>
          </li>
          <li>
            <p>주문번호</p>
            <p>{{item.oid}}</p>
          </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>  
  </div>  

  <div class="btn-area">
    <a routerLink="/main" class="btn1">메인</a>
    <a class="btn1 red cp" (click)="goMypageOrder()">주문정보보기</a>
  </div>    
  
  <div class="guide">
		<p class="dot">·</p><p class="dot1">입금확인은 오전 10시/오후 2시 30분에 합니다. 입금확인 된 주문건에 한해 당일 배송작업을 합니다.</p>
		<p class="dot">·</p><p class="dot1">가능한 한 주문하신 날로부터 7일이내에 선택하신 은행으로 송금해 주시기 바랍니다.</p>
		<p class="dot">·</p><p class="dot1">입금확인 대조 작업에서 7일내 입금 되지 않은 주문은 자동주문취소 처리되므로 따로 주문취소하지 않으셔도 됩니다.</p>
		<p class="dot">·</p><p class="dot1">입금은행변경 및 입금자명과 실 입금자명이 만일 다를 경우 고객센터(1544-5022)로 꼭 연락주시기 바랍니다.</p>
		<p class="dot">·</p><p class="dot1">현금영수증 발행 관련 문의는 고객센터 1544-5022로 문의해 주십시오.</p>
		<p class="dot">·</p><p class="dot1">바마에서 발송하는 CJ대한통운택배 배송상품의 배송이 완료되면, 주문시 작성한 수령인 휴대폰 번호로 송장번호를 전송하여 확인이 가능하며,	 송장번호 배송조회는 쇼핑몰 메인상단 및 하단, 마이페이지 > 구매내역/배송조회 또는 CJ대한통운택배 홈페이지에서 확인하시기 바랍니다.</p>
		<p class="dot">·</p><p class="dot1">입점업체 배송상품은 CJ대한통운택배 이외의 타택배사에서 배송될 수 있습니다.</p>
		<p class="dot">·</p><p class="dot1">구매하신 상품의 교환 및 반품은 상품수령일 부터 5일 이내에 도착한 상품에 한하며 상품의 상태를 확인 한 후에 교환 및 반품이 진행됩니다.</p>  
  </div>
</div>   

<app-footer></app-footer>