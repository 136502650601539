import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { AuthService } from '@app/service/auth.service';

@Component({
  selector: 'app-goods-card1',
  templateUrl: './goods-card1.component.html',
  styleUrls: ['./goods-card1.component.scss']
})
export class GoodsCard1Component implements OnInit {

  @Input() productInfo: any;
  public isLogin$: Observable<boolean>;
  public colorList: any = [];
  public sizeList: any = [];
  public thumbnailUrl: string;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private authService: AuthService,
  ) {
    this.isLogin$ = this.authService.isLoggedIn;
  }

  ngOnInit(): void {
    // 초기 이미지 URL 설정 (thumbnail_url_shack이 있으면 우선 사용)
    this.thumbnailUrl = this.productInfo?.thumbnail_url_shack || this.productInfo?.thumbnail_url;

    if (this.productInfo?.color) {
      this.colorList = this.productInfo.color.split(',');
    }

    if (this.productInfo?.size) {
      this.sizeList = this.productInfo.size.split(',');
    }
  }

  linkTarget = this.deviceDetectorService.isMobile() ? "_self" : "_blank";

  /*******************************************************************************
    설명: ngIf 크기 비교 (int 변환 후 비교)
    입력값: a, b
    리턴값: boolean (a > b)
  *******************************************************************************/
  comparePrice(a: any, b: any): boolean {
    let c = parseInt(a, 10);
    let d = parseInt(b, 10);
    return c > d;
  }

  /*******************************************************************************
    설명: 이미지 로딩 실패 시 대체 이미지 설정
    입력값: event (이미지 로딩 실패 이벤트)
    리턴값: 없음
  *******************************************************************************/
    useBackupImage(event: Event): void {
      const imgElement = event.target as HTMLImageElement;
    
      // 이미 대체 이미지를 설정한 경우 더 이상 실행하지 않음
      if (imgElement.dataset.fallback) {
        return;
      }
    
      // `thumbnail_url_shack`에서 `thumbnail_url`로 변경
      if (this.thumbnailUrl === this.productInfo?.thumbnail_url_shack) {
        this.thumbnailUrl = this.productInfo?.thumbnail_url|| 'https://shop.bikemart.co.kr/assets/images/logo.png';
        imgElement.src = this.thumbnailUrl;
      } else {
        // 두 번째 실패 시 기본 대체 이미지로 변경
        imgElement.dataset.fallback = 'true'; // 무한 루프 방지
        imgElement.src = 'assets/images/logo.png';
      }
    }

}
