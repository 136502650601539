<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>
  
  <div class="mypage-contents">
    <div class="section">
      <div class="section-header">
        <div class="title page-title">1:1 문의하기</div>
      </div>
  
  <!-- 모바일 상세보기 -->
  <div class="detail mobile-view">
    <table class="list-table">
      <colgroup>
        <col style="width: 25%">
        <col style="width: 75%">
      </colgroup>    
      <tbody>
          <tr>
            <td class="state red">답변완료</td>
            <td class="tl">
              <div>{{boardcontent.group_id}}</div>
              <div class="name">{{boardcontent.subject}}</div> 
              <div>{{boardcontent.write_date}}</div>
            </td>
          </tr>
          <tr class="qna-detail">
            <td colspan="2">
              <div class="txt">
            </div>  
            <div class="ans">
              <div class="div1 clearfix">
                <span class="red">답변</span>
                <span>{{boardcontent.reply_date}}</span>
              </div>
              <div class="div2"></div>
              <div class="txt">
                {{boardcontent.contents_nl2br}}
              </div>
            </div>          
            </td>
          </tr> 
      </tbody>
      </table> 
    <button class="basic-btn view-btn mobile-view" (click)="goList()">목록</button>       
  </div> 
</div>    
         
</div>   
</div>
<app-footer></app-footer>
