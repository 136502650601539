/*******************************************************************************
  설  명 : 회원 로그인
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

import { ApiResponse } from '@app/service/api-response';
import { AuthService } from '@app/service/auth.service';
import { config } from '@app/service/config.service';
import { SMemberService } from '@app/service/member.service';
import { UrlService } from '@app/service/url.service';
import { UtilService } from '@app/service/util.service';

import { MemberDormantTransformComponent } from '@page/member/member-login/member-dormant-transform/member-dormant-transform.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-member-login',
  templateUrl: './member-login.component.html',
  styleUrls: ['./member-login.component.scss']
})
export class MemberLoginComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 1/26 수정 변수 선언
  errorResponse: ApiResponse;
  public memberForm: FormGroup;
  public memberFormErrors = {
    id: '',
    pw: ''
  };

  public previousOrgUrl: any = '';
  public previousUrl: any = '';
  public previousParams: any = {};
  public retUrl: any = '';
  public isInAppBrowser: any;

  private subscription: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private router: Router,
    private sMemberService: SMemberService,
    private toastrService: ToastrService,
    private urlService: UrlService,
    private utilService: UtilService,
  ) {
    // http로 접속 시 https로 리다이렉트
    if( location.hostname.indexOf('localhost') < 0 && location.protocol == 'http:' ) {
      window.location.href = location.href.replace('http:', 'https:');
    }

    this.buildForm();

    let check = this.cookieService.check('saveId');
    if( check ) {
      this.memberForm.patchValue({
        id: this.cookieService.get('saveId'),
        save_id: true
      });
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if( params.retUrl !== undefined ) this.retUrl = params.retUrl;
      if( params.isApp !== undefined ) this.isInAppBrowser = params.isApp;
    });
  }

  buildForm(): void {
    this.memberForm = this.formBuilder.group({
      id:[ '', [Validators.required] ],
      pw:[ '', [Validators.required] ],
      save_id:[ true ]
    });

    this.memberForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memberForm, this.memberFormErrors );
    });

  }

  login_naver(): void {
    let params = {
      client_id: config.oauth_naver_client_id,
      redirect_uri: location.origin + "/api/auth/callback-login-naver.php",
      response_type: "code",
    };

    let redirect_uri = ( this.previousUrl && this.previousUrl.indexOf('/login') == -1 ) ? this.previousUrl : "/main";
    this.cookieService.set( 'redirect_uri', redirect_uri, 1, '/', location.hostname, true, 'None' );

    this.cookieService.set( 'isInAppBrowser', this.isInAppBrowser, 1, '/', location.hostname, true, 'None' );

    let query = $.param( params );

    window.open(
      `https://nid.naver.com/oauth2.0/authorize?${ query.toString() }`,
      "_blank",
      "resizable,scrollbars,status"
    );
  }

  login_kakao(): void {
    let params = {
      client_id: config.oauth_kakao_client_id,
      redirect_uri: location.origin + "/api/auth/callback-login-kakao.php",
      response_type: "code",
    };

    let redirect_uri = ( this.previousUrl && this.previousUrl.indexOf('/login') == -1 ) ? this.previousUrl : "/main";
    this.cookieService.set( 'redirect_uri', redirect_uri, 1, '/', location.hostname, true, 'None' );

    this.cookieService.set( 'isInAppBrowser', this.isInAppBrowser, 1, '/', location.hostname, true, 'None' );

    let query = $.param( params );

    window.open(
      `https://kauth.kakao.com/oauth/authorize?${ query.toString() }`,
      "_blank",
      "resizable,scrollbars,status"
    );
  }

  oauthLoggedIn(response: any) {
    if (response["ResultCode"] ) {
      console.log('체크1');
      this.authService.login(response);
      this.toastrService.success(response["ResultMessage"], "");

      console.log('체크2');

      if( this.retUrl !== '' ) {
        window.location.href = this.retUrl + '?token=' + response.token;
        console.log( window.location.href);
      } else {
        let redirect_uri = this.previousUrl ? this.previousUrl : "/main";

        this.router.navigate(
          [
            redirect_uri,
          ],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.previousParams,
            queryParamsHandling: "",
          }
        );
      }
    }
    else {
      if (response["dormant"] ) {
        this.openMemberDormantTransform(response["dormant_data"] );
      }
      else {
        this.toastrService.error(response["ResultMessage"], "");
      }
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    window['memberLogin'] = {
      component: this,
      zone: this.ngZone,
      oauthLoggedIn: (result: any) => this.oauthLoggedIn(result)
    }

    this.subscription = this.urlService.previousUrl$.subscribe(url => {
      if( url !== null ) {
        this.previousOrgUrl = url;

        let urlArray: any = url.split('?');

        this.previousUrl = urlArray[0];

        if( this.previousUrl.indexOf('login') > -1 || this.previousUrl.indexOf('find') > -1 || this.previousUrl.indexOf('join') > -1 ) {
          this.previousUrl = '/main';
        }

        if( urlArray[1] ) {
          let params = urlArray[1].split('&');
          params.forEach(item => {
            let data = item.split('=');
            this.previousParams[ data[0] ] = data[1];
          });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /*******************************************************************************
    설  명 : 로그인 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public memberSubmit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memberForm, this.memberFormErrors);

    if( this.memberForm.valid ) {
      this.sMemberService.setLogin( this.memberForm )
      .then(response => {
        if( response['ResultCode'] == true ) {
          this.authService.login(response);

          const domain: any = location.hostname.replace('shop.','');
          if( this.memberForm.get('save_id').value == true ) {
            this.cookieService.delete('saveId', '/', domain, true, 'None');
            this.cookieService.set('saveId', this.memberForm.get('id').value, 0, '/', domain, true, 'None');
          } else {
            this.cookieService.delete('saveId', '/', domain, true, 'None');
          }

          this.toastrService.success(response['ResultMessage'], '');

          if( this.retUrl !== '' ) {
            window.location.href = this.retUrl + '?token=' + response.token;
          } else {
            let redirect_uri = ( this.previousUrl && this.previousUrl.indexOf('/login') == -1 ) ? this.previousUrl : "/main";
            this.router.navigate(
              [
                redirect_uri,
              ],
              {
                relativeTo: this.activatedRoute,
                queryParams: this.previousParams,
                queryParamsHandling: "",
              }
            );
          }
        } else {
          if( response.dormant === true ) {
            // 일반회원으로 전환 안내
            this.openMemberDormantTransform( response.dormant_data );
          } else {
            this.toastrService.error(response['ResultMessage'], '');
          }
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.memberForm, this.memberFormErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 휴면회원 전환 안내
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openMemberDormantTransform( data: any ) {
    const modalRef = this.modalService.open(MemberDormantTransformComponent, options);

    modalRef.componentInstance.dormant_data = data;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 메인페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goMain() {
    if( this.retUrl === 'https://bikeweb.bikemart.co.kr' ) {
      window.location.href = this.retUrl;
    } else {
      this.router.navigate(
      ['/main'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    }
  }
}
