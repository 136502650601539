<app-header></app-header>

<div class="event-page inner">

  <!-- <ul class="event-header clearfix">
    <li [ngClass] ="{active:search.seq==''}" (click)="setEventPage('')">전체<br class="mobile-view"> 이벤트</li>
    <ng-container *ngFor="let row of eventList">
      <li *ngIf="row.event_type === '3000'" [ngClass] ="{active:row.seq==search.seq}" (click)="setEventPage(row.seq)">{{row.event_name}}</li>
    </ng-container>
  </ul> -->

  <!-- 전체이벤트 -->
  <div class="event-contents total" [ngClass]="{view:search.seq==''}">
    <div class="tot-banner">
      <img src="/assets/images/event_top01.png" alt="이벤트배너" class="pc-view">
      <img src="/assets/images/event_topm01.png" alt="이벤트배너" class="mobile-view">
    </div>
    <ul class="events clearfix">
      <ng-container *ngFor="let row of eventList">
        <li *ngIf="row.event_type === '3000'" class="cp" (click)="setEventPage(row.seq)">
          <div class="img"><img [src]="row.image_url" alt="{{row.event_name}}"></div>
          <div class="name">{{row.event_name}} <div class="state right">진행중</div></div>
          <!-- <div class="state">진행중</div> -->
        </li>
      </ng-container>
    </ul>

    <div class="pagination-area pc-view">
      <ngb-pagination
        [(page)]="search1.pageNo"
        [pageSize]="search1.pageRow"
        [collectionSize]="event_total"
        (pageChange)="loadPage1($event)"
        [boundaryLinks]="true"
        [maxSize]="10"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </div>

    <div class="more mobile-view">더보기</div>
  </div>

  <!-- 이벤트페이지 -->
  <div *ngIf="eventInfo.event_type == '1000' || eventInfo.event_type == '1001'" class="event-contents event-contents1 event" [ngClass]="{view:search.seq != ''}">
    <!-- <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
      <mat-tab label="이벤트 내용">

        <ng-template mat-tab-label>
          <div style="
          font-size: 16px;
          border: 1px solid #ccc;
          width: 600px;
          height: 48px;
          line-height: 48px;
          position: absolute;
          ">이벤트 내용
          </div>
        </ng-template> -->

        <!-- <ng-container *ngIf="activeTab == 0"> -->
          <div class="tot-banner" style="margin-top: 5px;">
            <p class="pc-view center" [innerHTML]="eventContents | safe:'html'"></p>
            <p class="mobile-view center" [innerHTML]="eventContents | safe:'html'"></p>
          </div>
        <!-- </ng-container>
      </mat-tab>
    </mat-tab-group> -->
  </div>
  <div *ngIf="eventInfo.event_type == '3000'" class="event-contents event-contents2 event" [ngClass]="{view:search.seq != ''}">
    <!-- <mat-tab-group [selectedIndex]="activeTab" (selectedIndexChange)="tabSetValue($event)">
      <mat-tab label="이벤트 내용">

        <ng-template mat-tab-label>
          <div style="
          font-size: 16px;
          border: 1px solid #ccc;
          width: 600px;
          height: 48px;
          line-height: 48px;
          position: absolute;
          ">이벤트 내용
          </div>
        </ng-template>

        <ng-container *ngIf="activeTab == 0">
          <div class="tot-banner" style="margin-top: 5px;">
            <p class="pc-view center" [innerHTML]="eventContents | safe:'html'"></p>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="이벤트 상품">

        <ng-template mat-tab-label>
          <div style="
          font-size: 16px;
          border: 1px solid #ccc;
          width: 600px;
          height: 48px;
          line-height: 48px;
          position: absolute;
          ">이벤트 상품
          </div>
        </ng-template> -->

        <!-- <ng-container *ngIf="activeTab == 1"> -->
          <div class="tot-banner event-contents-image" style="margin-top: 5px;">
            <p class="center" [innerHTML]="eventContents | safe:'html'"></p>
          </div>
          <div class="tot-banner" style="margin-top: 5px;">
            <ng-container *ngFor="let row of eventList">
              <img *ngIf="row.event_type === '3000'" [src]="row.image_url" alt="{{row.event_name}}" class="" [ngClass]="{view:row.seq==search.seq}">
            </ng-container>
          </div>
          <div class="result clearfix">
            <div class="cnt pc-view"><span>{{event_total1}}</span>개의 상품이 있습니다. (메뉴 검색)</div>
            <ul class="sort clearfix">
              <li [ngClass]="{active:search.sort==1}" (click)="search.sort = 1; getEachEventProductList();">추천순</li>
              <li [ngClass]="{active:search.sort==2}" (click)="search.sort = 2; getEachEventProductList();">최신순</li>
              <li [ngClass]="{active:search.sort==3}" (click)="search.sort = 3; getEachEventProductList();">높은가격순</li>
              <li [ngClass]="{active:search.sort==4}" (click)="search.sort = 4; getEachEventProductList();">낮은가격순</li>
            </ul>
            <div class="view-btn mobile-view" [ngClass]="{type1:viewType==2,type2:viewType==3}" (click)="viewType==3?viewType=1:viewType=viewType+1"></div>
          </div>

          <div class="goods-list inner clearfix">
            <div class="goods-card cp" [ngClass]="{type1:viewType==2,type2:viewType==3}" *ngFor='let row of eachEventList'>
              <div class="sold-out">[관리자] 일시품절</div>
              <a routerLink="/product/info" [queryParams]="{productSeq: row.product_seq, eventSeq: row.event_seq}" target="_blank">
                <div class="goods-img">
                  <img [src]="row.thumbnail_url" alt="{{row.product_name | nohtml}}" />
                </div>
                <div class="goods-info">
                  <p class="code">상품통합코드 : {{row.product_seq}}</p>
                  <p class="pr-text" [innerHTML]="row.comment"></p>
                  <p class="name" [innerHTML]="row.product_name | safe: 'html'"></p>
                  <p class="price">
                    <span class="sale" *ngIf="comparePrice(row.unit_price, row.consumer_price)">{{ (row.unit_price - row.consumer_price) / row.unit_price | percent }}</span>
                    <span>{{ row.consumer_price | number }}원</span>
                    <del *ngIf="comparePrice(row.unit_price, row.consumer_price)">{{ row.unit_price | number }}원</del>
                  </p>
                  <p class="mileage">적립 <span>{{row.mileage | number}}</span> M</p>
                </div>
              </a>
            </div>
          </div>

          <div class="pagination-area">
            <ngb-pagination
              [(page)]="search.pageNo"
              [pageSize]="search.pageRow"
              [collectionSize]="event_total1"
              (pageChange)="loadPage($event)"
              [boundaryLinks]="true"
              [maxSize]="10"
              >
              <ng-template ngbPaginationFirst>처음</ng-template>
              <ng-template ngbPaginationLast>마지막</ng-template>
              <ng-template ngbPaginationPrevious>이전</ng-template>
              <ng-template ngbPaginationNext>다음</ng-template>
            </ngb-pagination>
          </div>
        <!-- </ng-container>
      </mat-tab>
    </mat-tab-group> -->
  </div>

</div>

<app-footer></app-footer>