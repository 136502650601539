import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { SEventService } from '@service/event.service';

@Component({
  selector: 'app-event1',
  templateUrl: './event1.component.html',
  styleUrls: ['./event1.component.scss']
})
export class Event1Component implements OnInit {

  public orderRankList = [];

  constructor(
    private toastrService: ToastrService,
    private eventService: SEventService,
  ) { }

  ngOnInit(): void {
    this.eventService.getEventOrder().then(response => {
      if (response.ResultCode) {
        this.orderRankList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

}
