<div class="modal-header">
  <h4 class="modal-title fl">배송지관리</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="title">배송지 목록<span>최대 5개까지 설정 가능</span></div>
  <table class="list-table">
  <colgroup>
    <col style="width: 5%">
    <col style="width: 15%">
    <col style="width: 50%">
    <col style="width: 15%">
    <col style="width: 15%">
  </colgroup>    

  <thead>
  <tr>
    <th></th>
    <th>받는사람</th>
    <th>주소</th>
    <th>연락처</th>
    <th>수정/삭제</th>
  </tr>    
  </thead>

  <tbody>
  <tr *ngFor="let item of addressList; let i = index;" (click)="setisChecked(i)">
    <td>
      <div class="radio">
        <label>
          <input type="radio" name="radio-d" [checked]="getIsChecked(item)" />
          <span class="checkmark"></span>
        </label>        
      </div>       
    </td>
    <td>{{item.recipient_name}}<div class="selected" *ngIf="item.isDefault==1">기본배송지</div></td>
    <td class="tl">[{{item.zipcode}}] {{item.address}} {{item.address_detail}}</td>
    <td>{{item.recipient_hp}}</td>
    <td>
      <button class="basic-btn sm-btn sm-btn1 mr5" (click)="openDestinationAdd(item)">수정</button>
      <button class="basic-btn sm-btn sm-btn1" (click)="setMemberAddressDelete(item.seq)">삭제</button>
    </td>
  </tr>
  </tbody>
  </table>
  <button class="small-btn" (click)="setMemberDefaultAddressSave()">기본배송지로 설정</button>
  <button class="small-btn silver" (click)="openDestinationAdd('')">배송지 추가</button>
  <div class="btn-area" [ngStyle]="{'display': choice}">
    <button class="btn1 red" (click)="addressChoice()">배송지 선택</button>
  </div>
</div>


