/*******************************************************************************
  설  명 : 마이페이지 - 찜리스트
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { SProductService } from '@app/service/product.service';

@Component({
  selector: 'app-mypage-favorite',
  templateUrl: './mypage-favorite.component.html',
  styleUrls: ['./mypage-favorite.component.scss']
})
export class MypageFavoriteComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public keepList: any = [];
  public keep_total = 0;

  public search: any = {
    pageNo: 1,
    pageRow: 12
  };

  public tabindex: number = 1;
  public page1: number = 1;
  public page2: number = 1;
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private sProductService: SProductService,
  ) {

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getKeepProductPageList();
  }

  /*******************************************************************************
    설  명 : 찜한 상품 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getKeepProductPageList() {
    this.sProductService.getKeepProductPageList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.keepList = response.data;
        this.keep_total = response.count;
      }
    });
  }

  /*******************************************************************************
    설  명 : 페이지로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage() {
    this.getKeepProductPageList();
  }

  /*******************************************************************************
    설  명 : 찜상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeleteKeep(row: any) {
    if ( confirm('찜 상품을 삭제하시겠습니까?') ) {
      this.sProductService.setDeleteKeep( row.product_seq ).then( response => {
        if ( response.ResultCode ){
          this.toastrService.success(response.ResultMessage, '');

          this.getKeepProductPageList();
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 찜상품 전체 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setAllDeleteKeep() {
    if( confirm("전체 삭제하시겠습니까?") ) {
      if( this.keepList.length > 0 ) {
        this.sProductService.setAllDeleteKeep().then( response => {
          if ( response.ResultCode ){
            this.toastrService.success(response.ResultMessage, '');

            this.getKeepProductPageList();
          }
        });
      } else {
        this.toastrService.error('삭제할 내역이 없습니다.', '');
      }
    }
  }
}
