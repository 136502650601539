/*******************************************************************************
  설  명 : 마이페이지 - 구매후기작성
  생성일 : 2021-06-10
  생성자 : 김종현
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { SOrderService } from '@app/service/order.service';

@Component({
  selector: 'app-mypage-review-add',
  templateUrl: './mypage-review-add.component.html',
  styleUrls: ['./mypage-review-add.component.scss']
})
export class MypageReviewAddComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() rowData: any;
  @Input() memberOrder: any;
  @Input() imageList: any;

  public title: any = '';

  public rowDetail: any;
  public rowDetailProduct: any;
  public gradeNum: any = 5;
  public totalAmt = 0;

  public refresh: boolean;

  public form: FormGroup;
  public formErrors: any = {};

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public baseURL = config.baseUrl;
  public uploadURL = config.apiImageUploadUrl;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private restful: RestfulService,
    private utilService: UtilService,
    private sOrderService: SOrderService,
    private toastrService: ToastrService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', []],
      order_seq: ['', []],
      product_seq: ['', []],
      detail: [[], []],
      grade: ['5', [Validators.required]],
      comment: ['', [Validators.required]],

      file: ['', []],
      upload: [[], []],
      files: [[], []],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    if( this.rowData.seq !== undefined ) {
      this.title = '수정';

      let tmp: any = [];
      tmp.push(this.rowData);
      this.rowDetail = tmp;

      this.gradeNum = this.rowData.grade;
      this.totalAmt = this.rowData.total_amt;

      this.form.patchValue( this.rowData );
      this.form.patchValue({
        upload: this.imageList
      });
    } else {
      this.title = '작성';

      if ( this.memberOrder === true ) {
        this.rowDetail = this.rowData.product;
        this.form.patchValue({
          order_seq: this.rowDetail[0].order_seq,
          detail: this.rowDetail,
          product_seq: this.rowDetail[0].product_seq
        });

        this.memberOrder === false;
      } else {
        this.rowDetail = this.rowData.product;
        this.form.patchValue({
          order_seq: this.rowData.seq,
          detail: this.rowDetail,
          product_seq: this.rowDetail[0].product_seq
        });
      }

      this.rowDetail.map( rows => {
        this.totalAmt += rows.amt * 1;
      });
    }


    // 업로드 허용 파일 설정
    var filetype: string[] = ['image'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: this.uploadURL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = true;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '');

        const files: any = this.form.controls.files.value;

        files.push({
          filename: response.filename,
          origin: response.origin_filename,
          size: response.size,
          filepath: response.url,
          url: this.baseURL + response.url,
          thumbnail_result: response.thumbnail_result,
          thumbnail_path: response.thumbnail_path,
          thumbnail: this.baseURL + response.thumbnail,
          thumbnail_org: response.thumbnail_org,
        });

        this.form.patchValue({
          file: '',
          files: files
        });

      } else {
        this.toastrService.error( response.message, '');
      }

      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 파일 총 업로드 갯수 체크
    let uploadValue: any = this.form.controls.upload.value;
    let filesValue: any = this.form.controls.files.value;
    if( parseInt(event.target.files.length) + parseInt(uploadValue.length) + parseInt(filesValue.length) > 5 ) {
      this.toastrService.warning( '사진 첨부는 총 5개까지만 가능합니다.', '');
      return false;
    }

    // 전체 허용 용량 검증
    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }
  
  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( file: any, index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  setDeleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.sOrderService.setOrderReviewImageDelete( upload.seq ).then( response => {
        if( response.ResultCode ) {
          let uploadData = this.form.controls.upload.value;
          uploadData.splice( index, 1 );
          this.form.patchValue(uploadData);

          this.refresh = true;
          // this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : x좌표 선택에 따른 별점 부여 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  grade(event) {
    if ( event.offsetX >= 200 && event.offsetX <= 250 ) {
      this.gradeNum = 5;
      this.form.patchValue({grade:this.gradeNum});
    } else if ( event.offsetX >= 150 && event.offsetX <= 199 ) {
      this.gradeNum = 4;
      this.form.patchValue({grade:this.gradeNum});
    } else if ( event.offsetX >= 100 && event.offsetX <= 149 ) {
      this.gradeNum = 3;
      this.form.patchValue({grade:this.gradeNum});
    } else if ( event.offsetX >= 50 && event.offsetX <= 99 ) {
      this.gradeNum = 2;
      this.form.patchValue({grade:this.gradeNum});
    } else if ( event.offsetX >= 0 && event.offsetX <= 49 ) {
      this.gradeNum = 1;
      this.form.patchValue({grade:this.gradeNum});
    }
  }

  /*******************************************************************************
    설  명 : 구매후기등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    
    if ( this.form.valid ) {
      if ( confirm('구매후기를 저장하시겠습니까?') ) { 
        this.sOrderService.setOrderReview( this.form ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage );
            this.refresh = true;
            this.setClose();
          } else {
            this.toastrService.error( response.ResultMessage );
          }
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 창닫기
  *******************************************************************************/ 
  setClose() {
    this.activeModal.close(this.refresh);
  }
  
}
