/*******************************************************************************
  설  명 : 마이페이지 - 비회원 조회 정보입력
  생성일 : 2021-06-11
  생성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { SOrderService } from '@app/service/order.service';

@Component({
  selector: 'app-mypage-nonmember-form',
  templateUrl: './mypage-nonmember-form.component.html',
  styleUrls: ['./mypage-nonmember-form.component.scss']
})
export class MypageNonmemberFormComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public isLogIn: boolean;
  public isLogin$: Observable<boolean>; // 로그인여부확인

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private sOrderService: SOrderService,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.buildForm();

    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;
    
    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });

    if( this.isLogIn === true ) {
      this.toastrService.error('비회원 전용 페이지입니다.');
      this.router.navigate([ '/main' ]);
    }    
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      o_name: [[], [Validators.required]],
      o_hp1:[ '010' ],
      o_hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{3,4}$')] ],
      o_hp3:[ '', [Validators.required, Validators.pattern('^[0-9]{4}$')] ],
      order_seq: [[], [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 비회원 주문내역 조회 페이지 연결
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goNonMemberMypage() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    
    if ( this.form.valid ) {
      this.sOrderService.confirmNonMember( this.form ).then( response => {
        if ( response.ResultCode ) {
          // this.toastrService.success('조회에 성공하였습니다.');

          this.router.navigate(
            [ '/mypage/nonmember/order' ],
            {
              relativeTo: this.activatedRoute, 
              queryParams: { seq: this.form.controls.order_seq.value, mem_no: response.data.mem_no, hp: response.data.hp },
              queryParamsHandling: ''
            }
          );
        } else {
          this.toastrService.error('주문 정보를 다시 확인해주세요.');
        }
      });
    }
  }
}
