import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'nohtml'
})
export class NoHtmlPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {
  }

  public transform(value: any) {
    return (value === undefined || value === null ) ? '' : value.replace(/<[^>]+>/g, '');
  }
}
