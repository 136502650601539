import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public isSetup: boolean = false;

  constructor(
    private restful: RestfulService
  ) {
  }

  getInitCMS():  Promise<any> {
    return this.restful.get({
        program: 'o2ozcms',
        service: 'common',
        version: '1.0',
        action: 'getInitCMS'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통코드 리스트 가져오기
  getCommonListCode( code: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonListCode'
      }, {
        code: code
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시판 리스트 가져오기
  getBoardList( params ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getBoardList'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 정보 가져오기
  getBoardView(board_id, board_seq, view_check ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getBoardView'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        view_check: view_check
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 로그인정보 가져오기
  getHomeMemberInfo(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMember'
      }, {
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 반품사유 리스트 가져오기
  getCommonReturnListCode( code: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonReturnListCode'
      }, {
        code: code
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 교환사유 리스트 가져오기
  getCommonExchangeListCode( code: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonExchangeListCode'
      }, {
        code: code
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
}
