/*******************************************************************************
  설  명 : 아이디 찾기 - 입력폼
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { SMemberService } from '@app/service/member.service';

@Component({
  selector: 'app-member-find-id',
  templateUrl: './member-find-id.component.html',
  styleUrls: ['./member-find-id.component.scss']
})
export class MemberFindIdComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @ViewChild('email2', {static: false}) inputEmail2: ElementRef;

  errorResponse: ApiResponse;
  public findIdForm: FormGroup;
  public formIdErrors = {
    'name': '',
    'hp1': '',
    'hp2': '',
    'hp3': '',
    'email1': '',
    'email2': '',
    'dir_email2': ''
  };

  public activeTab: number = 0;
  public emailReadonly: boolean = false;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    public memberService: SMemberService,
  ) {
    this.buildFindIdForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildFindIdForm(): void {
    this.findIdForm = this.formBuilder.group({
      name:[ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
      type: ['', []],
      hp1:[ '010' ],
      hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{3,4}$')] ],
      hp3:[ '', [Validators.required, Validators.pattern('^[0-9]{4}$')] ],
      email1:[ '', []],
      email2:[ '', []],
      emailSelected:[ '', [Validators.pattern('[a-z0-9.-]+\\.[a-z]{2,4}$')] ]
    });

    this.findIdForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.findIdForm, this.formIdErrors );
    });
  };


  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 아이디 찾기 처리
  *******************************************************************************/
  public submitId() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.findIdForm, this.formIdErrors);

    if( this.findIdForm.valid ) {
      this.memberService.setFindId( this.findIdForm ).then(response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '아이디 찾기');
          this.memberService.findIdResult = response.data;
          this.router.navigate(['/member/find/id/success']);
        } else {
          this.toastrService.error(response.ResultMessage, '아이디 찾기');
          this.router.navigate(['/member/find/id/fail']);
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.findIdForm, this.formIdErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 이메일 주소 선택시 readonly 및 직접입력칸에 이메일 주소 보내기 수정자: 3/2 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public selectEmail($event) {
    if( this.findIdForm.get('emailSelected').value == '' ) {
      this.findIdForm.get('email2').setValue('');
      this.emailReadonly = false;

      this.inputEmail2.nativeElement.focus();
    } else {
      this.findIdForm.get('email2').setValue( $event.target.value );
      this.emailReadonly = true;
    }
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabSetValue( value: any ) {
    this.activeTab = value;

    if( value === 0 ) {
      this.findIdForm.get('type').setValue('tel');

      this.findIdForm.get('hp2').setValidators( [Validators.required] );
      this.findIdForm.get('hp2').updateValueAndValidity();
      this.findIdForm.get('hp3').setValidators( [Validators.required] );
      this.findIdForm.get('hp3').updateValueAndValidity();
      this.findIdForm.get('email1').setValidators( [] );
      this.findIdForm.get('email1').updateValueAndValidity();
      this.findIdForm.get('email2').setValidators( [] );
      this.findIdForm.get('email2').updateValueAndValidity();
    } else {
      this.findIdForm.get('type').setValue('email');

      this.findIdForm.get('hp2').setValidators( [] );
      this.findIdForm.get('hp2').updateValueAndValidity();
      this.findIdForm.get('hp3').setValidators( [] );
      this.findIdForm.get('hp3').updateValueAndValidity();
      this.findIdForm.get('email1').setValidators( [Validators.required] );
      this.findIdForm.get('email1').updateValueAndValidity();
      this.findIdForm.get('email2').setValidators( [Validators.required] );
      this.findIdForm.get('email2').updateValueAndValidity();
    }
  }
    
}



