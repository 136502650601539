<div class="member-page">
    <div class="member-inner">
      <div class="logo">
        <a class="cp" (click)="goMain()"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
      </div>
  
      <form [formGroup]="memberForm" (ngSubmit)="checkSubmit()">
  
        <div class="title">회원정보 복원 동의<a routerLink="/guide/membership/grade" class="grade-guide">회원등급 혜택안내<span>></span></a></div>
          <input type="text" formControlName="id" class="login-input" size="30" maxlength="35" [ngClass]="{formErrors:memberFormErrors['id']}" placeholder="'아이디를 입력하세요'" autocomplete="off" [autofocus]="true" />
          <input type="password" formControlName="pw" class="login-input" size="20" maxLength="64" [ngClass]="{formErrors:memberFormErrors['pw']}" placeholder="비밀번호를 입력하세요" autocomplete="off" />
        <input type="submit" value="회원정보복원동의" class="basic-btn red" />
        <div class="memo">* 이메일에 전송받은 아이디와 패스워드를 입력 후 회원정보복원버튼을 클릭하세요.</div> 
        <div class="memo">* 회원정보가 복원되면 쇼핑몰로 자동 이동됩니다.</div> 
        <div class="memo">* 회원정보 복원뒤에는 반드시 비밀번호를 변경하여 주십시요.</div> 
      </form>
  
      <div class="member-footer">
        <ul class="clearfix">
          <li><a routerLink="/company/policy/service">이용약관</a></li>
          <li><a routerLink="/company/policy/privacy" class="bold">개인정보처리방침</a></li>
          <li><a routerLink="/customer/center">고객센터 1544-5022</a></li>
        </ul>
        <div>Copyright © 2008 <span class="bold">bikemart.co.kr</span> ALL RIGHTS RESERVED.</div>
      </div>
    </div>
  </div>
  