<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <div class="mypage-menu">
    <div class="title">비회원 주문조회</div>
    <div class="menu-list">
      <div class="menu">
        <div>쇼핑</div>  
        <ul>
          <li><a routerLink="/mypage/nonmember/form">구매내역/배송조회</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="mypage-contents">
    <div class="guide">
      <div class="title">안내사항</div>
      <p class="dot">·</p><p class="dot1">현금영수증은 현금결제 완료 후 15일 내에만 신청이 가능합니다.</p>
      <p class="dot">·</p><p class="dot1">구매확정 후 마일리지 적립 및 사용이 가능합니다. </p>
    </div>
    <div class="section order-list">
      <div class="page-header">
        <div class="title page-title mt20">구매내역/배송조회</div>  
	      <div class="right-btn pc-view">
          <button *ngIf="inicisSeq !== null" class="basic-btn mt20 round" style="width: 100px;" (click)="getReceipt()">매출전표(영수증)</button>
          <button class="basic-btn mt20 round" (click)="getOrderList()">주문내역서</button>
          <button *ngIf="checkCashReceipt(payCashInfo)" class="basic-btn mt20 round" (click)="goCashReceipt();">현금영수증 신청</button>
	      </div>  
        <a routerLink="" class="close-btn mobile-view"></a>
      </div>       

	    <table class="list-table pc-view">
	    <colgroup>
        <col style="width: 13%">
        <col style="width: 45%">
        <col style="width: 14%">
        <col style="width: 14%">
        <col style="width: 14%">
	    </colgroup>    
	
	    <thead>
	    <tr>
	      <th>주문일/주문번호</th>
	      <th>상품정보</th>
        <th>상태</th>
        <th>택배사정보</th>
        <th>확인/요청</th>
	    </tr>    
    </thead>
    
    <tbody>
      <tr *ngFor="let item of detailList; let i = index">
        <td *ngIf="i === 0" [attr.rowspan]="detailList.length" class="date">
          {{item.order_date}}<br/>{{item.seq}}
        </td>
        <td class="info">
          <div class="img cp" (click)="goProduct(item.product_seq)"><img [src]="item.thumbnail_url" alt="{{item.product_name}}" /></div>
          <div class="info1 cp">
            <p (click)="goProduct(item.product_seq)">{{item.product_name}}</p>
            <p class="option" *ngFor="let option of item.product" (click)="goProduct(item.product_seq)">색상 : {{option.display_color_name}} / 사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty}} 개
              / &nbsp;&nbsp;&nbsp;&nbsp; 반품,교환 가능한 수량 {{option.qty - option.used_req_qty}}
            </p>
            <!-- <div class="add-option">
              <div>추가옵션1 : </div>
            </div> -->
            <a class="basic-btn sm-btn round cp review" *ngIf='item.product[0].review_seq == "0" && item.master_order_status == 9' (click)="review(item)"><span class="red">구매후기작성</span></a>
          </div>
          </td>
          <td *ngIf="i === 0" [attr.rowspan]="detailList.length">
            {{orderInfo.order_status_name}}
          </td>
          <td *ngIf="i === 0" [attr.rowspan]="detailList.length">
            <ng-container *ngFor="let row of deliveryList">
              <span>{{row.delivery_company_name}}</span><br/>
              <a class="cp" (click)="delivery(row)">{{row.delivery_no}}</a><br/>
            </ng-container>
          </td>
          <td *ngIf="i === 0" [attr.rowspan]="detailList.length">
            <ng-container *ngIf="item.master_order_status == 0">
                <a class="basic-btn sm-btn round cp" (click)="cancel(item)"><span class="red">주문취소</span></a>
            </ng-container>
            <ng-container *ngIf="item.master_order_status == 8">
              <a class="basic-btn sm-btn round cp" (click)="received(item)"><span class="red">수취확인</span></a>
            </ng-container>
            <ng-container *ngIf="item.master_order_status == 9">
              <a class="basic-btn sm-btn round cp" (click)="return();">반품요청</a>    
              <a class="basic-btn sm-btn round cp" (click)="exchange();">교환요청</a>
            </ng-container>
          </td>
	      </tr>
	    </tbody>
	    </table> 

      <!-- 모바일 view --> 
      <div class="list-group mobile-view">
        <div class="date clearfix">
          <div>{{orderInfo.order_date}}</div>
          <div>주문번호 : {{orderInfo.seq}}</div>
        </div>
        <ul>
          <li>
            <div class="order-list1 clearfix">
              <div class="red">{{orderInfo.order_status_name}}</div>
              <button *ngIf="orderInfo.pay_method != null" class="basic-btn sm-btn round cp mr5" style="width: 90px;" (click)="goCashReceipt();">현금영수증 신청</button>
              <button *ngIf="inicisSeq != null" class="basic-btn sm-btn round cp mr5" style="width: 95px;" (click)="getReceipt()">매출전표(영수증)</button>
            </div>
            <div *ngFor="let item of detailList; let i = index" class=" info clearfix">
              <div class="img" (click)="goProduct(item.product_seq)"><img [src]="item.thumbnail_url"></div>
              <div class="info1 mb20" (click)="goProduct(item.product_seq)">
                <p class="info1-1">{{item.product_name}}</p>
                <p class="info1-2" *ngFor="let option of item.product">색상 : {{option.display_color_name}} / 사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty}} 개
                  / &nbsp;&nbsp;&nbsp;&nbsp; 반품,교환 가능한 수량 {{option.qty - option.used_req_qty}}
                </p>
                <p class="info1-3">{{getPayAmt(item) | number}}원</p>  
                <a class="basic-btn sm-btn round cp mb5 mt10 review1" *ngIf='item.product[0].review_seq == "0" && item.master_order_status == 9' (click)="review(item)"><span class="red">구매후기작성</span></a>
              </div>
              <div class="btn-div" *ngIf="item.master_order_status == 0 && i == detailList.length-1" >
                <a class="basic-btn sm-btn round cp mr5" (click)="cancel(item)"><span class="red">주문취소</span></a>  
              </div>
              <div class="btn-div" *ngIf="item.master_order_status == 8 && i == detailList.length-1" >
                <a *ngIf="item.product[0].order_status != 9" class="basic-btn sm-btn round cp" (click)="received(item)"><span class="red">수취확인</span></a>
              </div>
              <div class="btn-div" *ngIf="item.master_order_status == 9 && i == detailList.length-1" >
                <a class="basic-btn sm-btn round cp mb5" (click)="return();">반품요청</a>    
                <a class="basic-btn sm-btn round cp" (click)="exchange();">교환요청</a>
              </div>
            </div>
          </li>  
        </ul>  
        <div class="pay-method clearfix">
          <div class="bold">택배사정보</div>
          <ng-container *ngFor="let row of deliveryList">
            <p>{{row.delivery_company_name}}</p>
            <a class="cp" (click)="delivery(row)">{{row.delivery_no}}</a><br/>
          </ng-container> 
        </div>
      </div>	 

	    <div class="total-info">
	      <ul>
	        <li>
	          <div class="title">총 주문금액</div>
	          <div class="amt">{{total_order | number}}<span>원</span></div>
	        </li>
	        <li class="sign-plus">+</li>
	        <li>
	          <div class="title">배송비</div>
	          <div class="amt">{{total_delivery | number}}<span>원</span></div>
	        </li>
	        <li class="sign-minus">-</li>
	        <li>
	          <div class="title">할인금액</div>
	          <div class="amt red">{{discount_amt | number}}<span>원</span></div>
	        </li>
	        <li class="sign-equal">=</li>
	        <li class="tot-amt">
	          <div class="title">총 결제 금액</div>
	          <div class="amt">{{total_order - discount_amt*1 + total_delivery*1 | number}}<span>원</span></div>
	        </li>
	      </ul>
	    </div>         
	  </div>
  
    <!-- 주문자 정보 -->
    <div class="section">
      <div class="title">주문자 정보</div>   

      <table class="info-table info-table1">
      <colgroup>
        <col style="width: 160px">
        <col>
      </colgroup>  
      <tbody>
      <tr>
        <th>주문자 이름</th>
        <td>{{orderInfo.o_name}}</td>     
      </tr>
      <tr>  
        <th>입금자명</th>
        <td>{{orderInfo.o_name}}</td> 
      </tr>
      <tr>  
        <th>주문자 연락처</th>
        <td>{{orderInfo.o_hp}}</td> 
      </tr>
      <tr>  
        <th>주문자 이메일주소</th>
        <td>{{orderInfo.o_email}}</td>                            
      </tr>    
      </tbody>
      </table>        
    </div>  

    <!-- 배송지 정보 -->
    <div class="section">
      <div class="title">배송지 정보</div>   

      <table class="info-table info-table1">
      <colgroup>
        <col style="width: 160px">
        <col>
      </colgroup>  
      <tbody>
      <tr>
        <th>받는 사람 이름</th>
        <td>{{orderInfo.r_name}}</td>     
      </tr>
      <tr>  
        <th>연락처</th>
        <td>{{orderInfo.r_hp}}</td> 
      </tr>
      <tr>  
        <th>주소</th>
        <td>{{orderInfo.r_address}}{{orderInfo.r_address_detail}}</td> 
      </tr>
      <tr>  
        <th>배송시 요청사항</th>
        <td>{{orderInfo.r_comment}}</td>                            
      </tr> 
      </tbody>
      </table>        
    </div>   
    
    <!-- 결제 정보 무통장 -->
    <div class="section" *ngIf="orderInfo.order_status == 0 && orderInfo.account_seq">
      <div class="title">결제 정보</div>   
      <table class="info-table info-table1">
        <colgroup>
          <col style="width: 160px">
          <col>
        </colgroup>  
        <tbody>
        <tr>
          <th>결제 방법</th>
          <td>무통장 입금</td>     
        </tr>
        <tr>  
          <th>입금 은행</th>
          <td>{{orderInfo.bank}}</td> 
        </tr>
        <tr>  
          <th>입금 계좌</th>
          <td>{{orderInfo.bank_account}}</td> 
        </tr>
        <tr>  
          <th>입금자명</th>
          <td>{{orderInfo.receipt_name}}</td>                            
        </tr> 
        </tbody>
      </table>        
    </div>

    <!-- 결제 정보 카드 -->
    <ng-container *ngFor="let item of payList">
      <div class="section" *ngIf="item.pay_method == '0001'">
        <div class="title">결제 정보</div>   
        <table class="info-table info-table1">
          <colgroup>
            <col style="width: 160px">
            <col>
          </colgroup>  
          <tbody>
          <tr>
            <th>결제 방법</th>
            <td>{{item.pay_method_name}}</td>     
          </tr>
          <tr>  
            <th>결제 금액</th>
            <td>{{item.pay_amt | number}} 원</td> 
          </tr>
          <tr>  
            <th>카드 정보</th>
            <td>{{item.card_name1}}({{item.card_no}}){{item.installment_period}}개월 무이자할부</td> 
          </tr>
          <tr>  
            <th>결제일</th>
            <td>({{item.pay_date}})</td>                            
          </tr> 
          </tbody>
        </table>    
      </div>
    </ng-container>

  <div class="btn-area pc-view">
      <a routerLink="/" class="basic-btn">확인</a>
    </div>      
  </div>    
  
  
</div>

<app-footer></app-footer>