<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>
  
  <div class="mypage-contents">
    <div class="section">
      <div class="section-header">
        <div class="title page-title">찜 리스트</div>
      </div>
      
      <!-- <ul class="menu-tab clearfix">
        <li [ngClass]="{active:tabindex==1}" (click)="tabindex=1">쇼핑몰/캐주얼</li>  
        <li [ngClass]="{active:tabindex==2}" (click)="tabindex=2">구매대행</li>
      </ul> -->
      
      <div class="list" [ngClass]="{active:tabindex==1}">
        <div class="clearfix">
          <button class="basic-btn sm-btn round" (click)="setAllDeleteKeep()">전체삭제</button>
        </div>       
      
	      <ul class="clearfix" *ngIf="keepList.length > 0">
	        <li *ngFor="let row of keepList; let i = index;">
            <div class="img cp">
              <img [src]="row.thumbnail_url" routerLink="/product/info" [queryParams]="{productSeq: row.product_seq}">
              <div class="hover-bg pc-view"></div>
              <div class="hover-btn pc-view">
                <button class="red top" routerLink="/product/info" [queryParams]="{productSeq: row.product_seq}">상세화면<br/>바로가기</button>
                <button (click)="setDeleteKeep(row)">삭제</button>
              </div>	              
            </div>
		        <a routerLink="/product/info" [queryParams]="{productSeq: row.product_seq}">
		          <div class="name">{{row.product_name}}</div>
		          <div class="price">{{row.consumer_price | number}}<span>원</span></div>
	          </a>
            <div class="mbtn mobile-view">
              <button routerLink="/product/info" [queryParams]="{productSeq: row.product_seq}">상세화면</button>
              <button (click)="setDeleteKeep(row)">삭제</button>
            </div>	          
	        </li>
	      </ul>
        <ul class="clearfix center" *ngIf="keepList.length < 1">
          <div class="fb f18" style="padding: 150px 0 150px; border-bottom: 1px solid #e8e8e8; text-align: center;">찜한 상품이 없습니다.</div>
        </ul>
      
	      <div class="pagination-area">
	        <ngb-pagination
            [(page)]="search.pageNo"
            [pageSize]="search.pageRow"
	          [collectionSize]="keep_total"
	          [boundaryLinks]="true"
            (pageChange)="loadPage()"
	          >
	          <ng-template ngbPaginationFirst>처음</ng-template>
	          <ng-template ngbPaginationLast>마지막</ng-template>
	          <ng-template ngbPaginationPrevious>이전</ng-template>
	          <ng-template ngbPaginationNext>다음</ng-template>
	        </ngb-pagination>
	      </div>  
      
      </div>
      
      <div class="list" [ngClass]="{active:tabindex==2}">
        <div class="clearfix">
          <button class="basic-btn sm-btn round">전체삭제</button>
        </div>       
      
        <ul class="clearfix">
          <li *ngFor='let key of [1,2,3,4,5,6]'>
            <div class="img">
              <img src="/assets/images/168390_1.jpg">
              <div class="hover-bg pc-view"></div>
              <div class="hover-btn pc-view">
                <button class="red">상세화면<br/>바로가기</button>
                <button>삭제</button>
              </div>                
            </div>
            <a routerLink="/product/info">
              <div class="name">GRAVITY FLASH LIGHT 바람막이-GRAY</div>
              <div class="price">58,000<span>원</span></div>
            </a>
            <div class="mbtn mobile-view">
              <button>상세화면</button>
              <button>삭제</button>
            </div>              
          </li>
        </ul>          
      
        <div class="pagination-area">
          <ngb-pagination
            [(page)]="page1"
            [pageSize]="12"
            [collectionSize]="24"
            [boundaryLinks]="true"
            >
            <ng-template ngbPaginationFirst>처음</ng-template>
            <ng-template ngbPaginationLast>마지막</ng-template>
            <ng-template ngbPaginationPrevious>이전</ng-template>
            <ng-template ngbPaginationNext>다음</ng-template>
          </ngb-pagination>
        </div>  
      
      </div>      
   
    </div>    
         
  </div>   
</div>

<app-footer></app-footer>