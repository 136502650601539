<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <div class="mypage-menu">
    <div class="title">비회원 주문조회</div>
    <div class="menu-list">
	    <div class="menu">
	      <div>쇼핑</div>  
	      <ul>
	        <li><a routerLink="/mypage/nonmember/form">구매내역/배송조회</a></li>
	      </ul>
	    </div>
    </div>
  </div>
  <div class="mypage-contents">
    <div class="guide">
      <div class="title">안내사항</div>
      <p class="dot">·</p><p class="dot1">주문 시 작성한 주문자 성명 및 연락처를 동일하게 입력해 주셔야 검색이 가능합니다.</p>
      <p class="dot">·</p><p class="dot1">주문시 작성한 내용이 부정확하거나 기억나시지 않을 시엔 바이크마트 고객만족센터 Tel.1544-5022로 전화주시면 친절히 안내해 드리겠습니다.</p>
    </div>  
 
    <div class="section">
      <div class="page-header">
        <div class="title page-title">비회원 주문조회</div>  
        <a routerLink="" class="close-btn mobile-view"></a>
      </div>   

      <form [formGroup]="form">
        <table class="info-table info-table1">
        <colgroup>
          <col style="width: 160px">
          <col>
        </colgroup>  
        <tbody>
        <tr>
          <th><label for="o_name">주문자명</label></th>
          <td>
            <input type="text" [ngClass]="{formErrors: formErrors['o_name']}" formControlName="o_name" placeholder="주문자 성명" />
            <span *ngIf="formErrors['o_name']" class="red">주문자 성명을 입력해 주세요.</span>
          </td>
        </tr>    
        <tr>
          <th><label for="o_hp2">주문자 연락처</label></th>
          <td>
            <select formControlName="o_hp1" [ngClass]="{formErrors:formErrors['o_hp1']}" class="w80px">
              <option value="010">010</option>
              <option value="011">011</option>
              <option value="016">016</option>
              <option value="017">017</option>
              <option value="018">018</option>
              <option value="019">019</option>
            </select>
            <span class="mr5">-</span>
            <input type="text" [ngClass]="{formErrors: formErrors['o_hp2']}" formControlName="o_hp2" class="w80px" maxlength="4" placeholder="" />
            <span class="mr5">-</span>
            <input type="text" [ngClass]="{formErrors: formErrors['o_hp3']}" formControlName="o_hp3" class="w80px" maxlength="4" placeholder="" />
            <span *ngIf="formErrors['o_hp2'] || formErrors['o_hp3']" class="red">주문시 연락처를 입력해 주세요.</span>
          </td>
        </tr> 
        <tr>
          <th><label for="order_seq">주문번호</label></th>
          <td>
            <input type="text" [ngClass]="{formErrors: formErrors['order_seq']}" formControlName="order_seq" placeholder="주문 번호" />
            <span *ngIf="formErrors['order_seq']" class="red">주문번호를 입력해 주세요.</span>
            <br>
            <span class="fb">※ 주문번호는 주문 시 발송한 문자에서 확인하실 수 있습니다.</span>
          </td>
        </tr> 
        </tbody>
        </table>
      </form>
      
      <div class="btn-area">
        <a class="basic-btn red cp" (click)="goNonMemberMypage()">주문내역조회</a>
      </div>      
    </div>    
  </div>
</div>

<app-footer></app-footer>