<div class="modal-header">
  <h4 class="modal-title fl">관리자 답변</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div>
    <div style="width: calc(100%); height: 300px; padding: 15px; font-size: 14px;">{{cancelData.message}}</div>
  </div>   
</div>

