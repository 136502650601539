/*******************************************************************************
  설  명 : 마이페이지 - 취소/교환/반품 조회
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { SOrderService } from '@app/service/order.service';

import { MypageCancelReasonComponent } from '@page/mypage/mypage-cancel/mypage-cancel-reason/mypage-cancel-reason.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-cancel',
  templateUrl: './mypage-cancel.component.html',
  styleUrls: ['./mypage-cancel.component.scss']
})
export class MypageCancelComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 10,
    sdate: '',
    edate: '',
    searchdate_group: 999999999999,
    cancel_status: ''
  };

  public totalCount: number = 0;

  public cancelList: any = [];
  
  searchdate_group: any = [
    {title: '오늘', value: 0},
    {title: '1주일', value: -7},
    {title: '1개월', value: -30},
    {title: '3개월', value: -90},
    {title: '6개월', value: -180}
  ];  

  today: string;
  page = 1;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private calendar: NgbCalendar,
    private sOrderService: SOrderService,
    private modalService: NgbModal,

  ) { 
    this.today = moment().format('YYYY-MM-DD');
  }
  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCancelOrder();
  }
  
  /*******************************************************************************
   설  명 : 취소/교환/반품 조회 리스트 불러오기
   입력값 : 없음
   리턴값 : 없음
   *******************************************************************************/
  getCancelOrder() {
    this.sOrderService.getCancelOrder( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.cancelList = response.data;
        this.totalCount = this.cancelList.length;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 상태 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedStatus(event) {
    this.search.cancel_status = event.target.value;

    this.getCancelOrder();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else if( check == 2 ) {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }
  
  /*******************************************************************************
    설  명 : 검색 기간 버튼 클릭
    입력값 : 값
    리턴값 : 없음
  *******************************************************************************/
  onSearchDateClick( val ) {
    this.search.searchdate_group = val;

    let dateModel: NgbDateStruct;

    dateModel = {
      year: parseInt( moment().format('YYYY') ),
      month: parseInt( moment().format('MM') ),
      day: parseInt( moment().format('DD') )
    };

    this.search.edate = dateModel;

    dateModel = {
      year: parseInt( moment().add(val, 'day').format('YYYY') ),
      month: parseInt( moment().add(val, 'day').format('MM') ),
      day: parseInt( moment().add(val, 'day').format('DD') )
    };

    this.search.sdate = dateModel;
  }
  
  /*******************************************************************************
    설  명 : 리스트 상품 상세보기로 가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goProduct(row) {
    let seq = row.detail[0].product_seq;
    this.router.navigate(
      ['/product/info'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {productSeq: seq},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 주문상세보기로 가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( orderSeq ) {
    this.router.navigate(
    ['/mypage/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: { orderSeq: orderSeq },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
  설  명 : 페이지 선택 처리
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getCancelOrder();
  }

  /*******************************************************************************
    설  명 : 거절 사유 보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  reason( row: any ) {
    const modalRef = this.modalService.open(MypageCancelReasonComponent, options);

    modalRef.componentInstance.cancelData = row;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 신청 철회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goCancel(seq) {
    if (confirm('신청을 취소하시겠습니까?')) {
      this.sOrderService.setCancelCancel( seq ).then( response => {
        if ( response.ResultCode ){
          this.toastrService.success('신청이 정상적으로 취소되었습니다.');
          this.getCancelOrder();
        }
      });
    }
  }
}

