import { NgModule } from '@angular/core';
import {
  Router, Routes, RouterModule,ExtraOptions,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

import { MainComponent } from '@page/main/main.component';
// import { Main202208Component } from '@page/main/main202208/main202208.component';
import { ProductComponent } from '@page/product/product.component';
import { ProductInfoComponent } from '@page/product/product-info/product-info.component';
import { ProductQuestionComponent } from '@page/product/product-question/product-question.component';
import { ProductReviewComponent } from '@page/product/product-review/product-review.component';
import { EventComponent } from '@page/event/event.component';
import { EventCouponComponent } from '@page/event/event-coupon/event-coupon.component';
import { CartComponent } from '@page/cart/cart.component';
import { OrderComponent } from '@page/order/order.component';
import { OrderCompleteComponent } from '@page/order/order-complete/order-complete.component';
import { InipayCloseComponent } from '@page/order/inipay-close/inipay-close.component';

import { CompanyComponent } from '@page/company/company.component';
import { CompanyHistoryComponent } from '@page/company/company-history/company-history.component';
import { CompanySocietyComponent } from '@page/company/company-society/company-society.component';
import { CompanySupplyComponent } from '@page/company/company-supply/company-supply.component';
import { CompanyContactComponent } from '@page/company/company-contact/company-contact.component'
import { CompanyStoryComponent } from '@page/company/company-story/company-story.component';
import { ServiceComponent } from '@page/company/policy/service/service.component';
import { PurchaseAgencyComponent } from '@page/company/policy/purchase-agency/purchase-agency.component';
import { PrivacyComponent } from '@page/company/policy/privacy/privacy.component';

import { BoardListComponent } from '@page/board/board-list/board-list.component';
import { BoardGalleryComponent } from '@page/board/board-gallery/board-gallery.component';
import { BoardAddComponent } from '@page/board/board-add/board-add.component';
import { BoardViewComponent } from '@page/board/board-view/board-view.component';
import { BoardWriteComponent } from '@page/board/board-write/board-write.component';
import { CustomerCenterComponent } from '@page/customer-center/customer-center.component';
import { CustomerFaqComponent } from '@page/customer-center/customer-faq/customer-faq.component';

import { MemberLoginComponent } from '@page/member/member-login/member-login.component';
import { MemberDormantCheckComponent } from '@page/member/member-login/member-dormant-check/member-dormant-check.component';

import { MemberLogoutComponent } from '@page/member/member-logout/member-logout.component';
import { MemberFindIdComponent } from '@page/member/member-find-id/member-find-id.component';
import { MemberFindIdSuccessComponent } from '@page/member/member-find-id/member-find-id-success/member-find-id-success.component';
import { MemberFindIdFailComponent } from '@page/member/member-find-id/member-find-id-fail/member-find-id-fail.component';
import { MemberFindPwComponent } from '@page/member/member-find-pw/member-find-pw.component';
import { MemberFindPwSuccessComponent } from '@page/member/member-find-pw/member-find-pw-success/member-find-pw-success.component';
import { MemberFindPwFailComponent } from '@page/member/member-find-pw/member-find-pw-fail/member-find-pw-fail.component';
import { MemberJoinComponent } from '@page/member/member-join/member-join.component';
import { MemberJoinFormComponent } from '@page/member/member-join/member-join-form/member-join-form.component';
import { MemberJoinCompleteComponent } from '@page/member/member-join/member-join-complete/member-join-complete.component';

import { MypageComponent } from '@page/mypage/mypage.component';
import { MypageOrderComponent } from '@page/mypage/mypage-order/mypage-order.component';
import { MypageOrderDetailComponent } from '@page/mypage/mypage-order/mypage-order-detail/mypage-order-detail.component';
import { MypageCancelComponent } from '@page/mypage/mypage-cancel/mypage-cancel.component';
import { MypageMileageComponent } from '@page/mypage/mypage-mileage/mypage-mileage.component';
import { MypageCouponComponent } from '@page/mypage/mypage-coupon/mypage-coupon.component';
import { MypageFavoriteComponent } from '@page/mypage/mypage-favorite/mypage-favorite.component';
import { MypageReviewComponent } from '@page/mypage/mypage-review/mypage-review.component';
import { MemberOutComponent } from '@page/mypage/member-out/member-out.component';
import { MypageOutSuccessComponent } from '@page/mypage/member-out/mypage-out-success/mypage-out-success.component';
import { MypageNonmemberFormComponent } from '@page/mypage/mypage-nonmember-form/mypage-nonmember-form.component';
import { MypageNonmemberOrderComponent } from '@page/mypage/mypage-nonmember-order/mypage-nonmember-order.component';
import { MypageMyqnaComponent } from '@page/mypage/mypage-myqna/mypage-myqna.component';
import { MypageMyqnaWriteComponent } from '@page/mypage/mypage-myqna/mypage-myqna-write/mypage-myqna-write.component';
import { MypageMyqnaViewComponent } from '@page/mypage/mypage-myqna/mypage-myqna-view/mypage-myqna-view.component';
import { MypageModifyComponent } from '@page/mypage/mypage-modify/mypage-modify.component';
import { MypageCompanyChangeComponent } from '@page/mypage/mypage-company-change/mypage-company-change.component';
import { MypageOrderStatementComponent } from '@page/mypage/mypage-order/mypage-order-statement/mypage-order-statement.component';
import { MembershipGradeComponent } from '@app/page/guide/membership-grade/membership-grade.component';
import { SocietyBenefitComponent } from '@app/page/guide/society-benefit/society-benefit.component';
import { MypageSocietyComponent } from '@page/mypage/mypage-society/mypage-society.component';
import { MypageSocietyInfoComponent } from '@page/mypage/mypage-society-info/mypage-society-info.component';

import { Event1Component } from '@page/event/event1/event1.component';
import { Event2Component } from '@page/event/event2/event2.component';
import { Event3Component } from '@page/event/event3/event3.component';

import { AuthGuard } from '@app/service/auth.guard';
import { AuthLoginCheck } from '@app/service/auth.login.check';

import { BrandComponent } from './page/brand/brand.component';
import { SenaRegistrationComponent } from './page/company/sena-registration/sena-registration.component';

import { SocietyInfoComponent } from './page/guide/society-info/society-info.component';
import { SocietyCommunityComponent } from './page/society/society-community/society-community.component';


const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full', canActivate: [AuthLoginCheck] },
  { path: 'main', component: MainComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 오토바이용품 전문몰' } },
  // { path: 'main202208', component: Main202208Component, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 오토바이용품 전문몰' } },
  { path: 'product',
    children: [
      { path: 'list', component: ProductComponent, canActivate: [AuthLoginCheck] },
      { path: 'info', component: ProductInfoComponent, canActivate: [AuthLoginCheck] },
      { path: 'info/question/write', component: ProductQuestionComponent, canActivate: [AuthGuard] },
      { path: 'info/review/write', component: ProductReviewComponent, canActivate: [AuthGuard] },
    ]
  },
  { path: 'event', component: EventComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 이벤트' } },
  { path: 'event1', component: Event1Component, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 구매왕 이벤트' } },
  { path: 'event2', component: Event2Component, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 초성퀴즈 이벤트' } },
  { path: 'event3', component: Event3Component, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 카카오톡 플친 추가 이벤트' } },
  { path: 'event/coupon', component: EventCouponComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 쿠폰이벤트' } },

  { path: 'cart', component: CartComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 장바구니' } },
  { path: 'order', component: OrderComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 주문/결제' } },
  { path: 'order/inipay-close', component: InipayCloseComponent, canActivate: [AuthLoginCheck] },
  { path: 'order/complete', component: OrderCompleteComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 주문 완료' } },
  { path: 'company',
    children: [
      { path: 'info', component: CompanyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회사소개' } },
      { path: 'history', component: CompanyHistoryComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회사연혁' } },
      { path: 'society', component: CompanySocietyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 동호회 등록 신청' } },
      { path: 'supply', component: CompanySupplyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 입점안내' } },
      { path: 'story', component: CompanyStoryComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 창업스토리' } },
      { path: 'contact', component: CompanyContactComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 가맹문의' } },
      { path: 'policy/service', component: ServiceComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 이용약관' } },
      { path: 'policy/purchase/agency', component: PurchaseAgencyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 구매대행약관' } },
      { path: 'policy/privacy', component: PrivacyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 개인정보취급방침' } },
      { path: 'sena/registraion', component: SenaRegistrationComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 세나 정품등록' } },
    ]
  },
  { path: 'mobile',
    children: [
      { path: 'company/info', component: CompanyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회사소개' } },
      { path: 'company/history', component: CompanyHistoryComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회사연혁' } },
      { path: 'company/society', component: CompanySocietyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 동호회 등록 신청' } },
      { path: 'company/supply', component: CompanySupplyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 입점안내' } },
      { path: 'company/story', component: CompanyStoryComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 창업스토리' } },
      { path: 'company/contact', component: CompanyContactComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 가맹문의' } },
      { path: 'company/policy/service', component: ServiceComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 이용약관' } },
      { path: 'company/policy/purchase/agency', component: PurchaseAgencyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 구매대행약관' } },
      { path: 'company/policy/privacy', component: PrivacyComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 개인정보취급방침' } },
      { path: 'guide/membership/grade', component: MembershipGradeComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회원등급안내' } },
      { path: 'guide/society-benefit/society', component: SocietyBenefitComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 등록동호회혜택' } },
      { path: 'guide/society-benefit/society/info', component: SocietyInfoComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 등록동호회란?' } },
    ]
  },
  { path: 'board',
    children: [
      { path: 'list', component: BoardListComponent, canActivate: [AuthLoginCheck] },
      { path: 'view', component: BoardViewComponent, canActivate: [AuthLoginCheck] },
      { path: 'write', component: BoardWriteComponent, canActivate: [AuthGuard] },
      { path: 'gallery', component: BoardGalleryComponent, canActivate: [AuthLoginCheck] },
      { path: 'add', component: BoardAddComponent, canActivate: [AuthLoginCheck] },
    ]
  },
  { path: 'customer',
    children: [
      { path: 'center', component: CustomerCenterComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 고객센터' } },
      { path: 'faq', component: CustomerFaqComponent, canActivate: [AuthLoginCheck] },
    ]
  },
  { path: 'member',
    children: [
      { path: 'login', component: MemberLoginComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 로그인' } },
      { path: 'dormant/check', component: MemberDormantCheckComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회원정보복원 동의' } },
      { path: 'logout', component: MemberLogoutComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 로그아웃' } },
      { path: 'find/id', component: MemberFindIdComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 아이디찾기' } },
      { path: 'find/id/success', component: MemberFindIdSuccessComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 아이디찾기 완료' } },
      { path: 'find/id/fail', component: MemberFindIdFailComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 아이디찾기 실패' } },
      { path: 'find/pw', component: MemberFindPwComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 비밀번호찾기' } },
      { path: 'find/pw/success', component: MemberFindPwSuccessComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 비밀번호찾기 완료' } },
      { path: 'find/pw/fail', component: MemberFindPwFailComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 비밀번호찾기 실패' } },
      { path: 'join', component: MemberJoinComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회원가입' } },
      { path: 'join/form', component: MemberJoinFormComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회원가입 정보 입력' } },
      { path: 'join/complete', component: MemberJoinCompleteComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회원가입 완료' } },
    ]
  },
  { path: 'mypage',
    children: [
      { path: 'dashboard', component: MypageComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 마이페이지' } },
      { path: 'order', component: MypageOrderComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 구매내역/배송 조회' } },
      { path: 'order/detail', component: MypageOrderDetailComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 구매내역/배송 조회' } },
      { path: 'cancel', component: MypageCancelComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 반품/교환 조회' } },
      { path: 'mileage', component: MypageMileageComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 마일리지' } },
      { path: 'coupon', component: MypageCouponComponent, canActivate: [AuthGuard] },
      { path: 'favorite', component: MypageFavoriteComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 찜 리스트' } },
      { path: 'review', component: MypageReviewComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 구매후기' } },
      { path: 'nonmember/form', component: MypageNonmemberFormComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 비회원 주문 조회' } },
      { path: 'nonmember/order', component: MypageNonmemberOrderComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 비회원 구매내역/배송 조회' } },
      { path: 'myqna', component: MypageMyqnaComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 1:1문의' } },
      { path: 'myqna/write', component: MypageMyqnaWriteComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 1:1문의' } },
      { path: 'myqna/view', component: MypageMyqnaViewComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 1:1문의' } },
      { path: 'modify', component: MypageModifyComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 회원정보수정' } },
      { path: 'member/out', component: MemberOutComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 회원탈퇴' } },
      { path: 'member/out/success', component: MypageOutSuccessComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회원탈퇴 완료' } },
      { path: 'company/change', component: MypageCompanyChangeComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 업체회원전환/변경' } },
      { path: 'order/statement', component: MypageOrderStatementComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - ' } },
      { path: 'society', component: MypageSocietyComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 내동호회 등록 ' } },
      { path: 'society/info', component: MypageSocietyInfoComponent, canActivate: [AuthGuard], data: { title: '바이크마트 - 내 동호회 ' } },

    ]
  },
  { path: 'guide',
    children: [
      { path: 'membership/grade', component: MembershipGradeComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 회원등급안내' } },
      { path: 'society-benefit/society', component: SocietyBenefitComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 등록동호회혜택' } },
      { path: 'society-benefit/society/info', component: SocietyInfoComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 등록동호회란?' } },
    ]
  },
  { path: 'brand', component: BrandComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 브랜드' } },
  { path: 'society/community', component: SocietyCommunityComponent, canActivate: [AuthLoginCheck], data: { title: '바이크마트 - 동호회' } },
];

declare let window: any;

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {

  deviceInfo = null;

  constructor(
    private router: Router,
  ) {
    // 로딩 처리
    this.router.events.subscribe((event: Event) => {
      switch( true ) {
        case event instanceof NavigationStart: {
          $("#indicator").addClass("indicator");
          break;
        }
        case event instanceof NavigationEnd: {
          // 페이지 전환 시 최 상단으로 자동 스크롤
          $(window).scrollTop(0);

          // 네이버 프리미엄 로그 분석 스크립트 삽입
          if( !wcs_add ) var wcs_add = {};
          if( !_nasa ) var _nasa = {};

          if( window.wcs && getWcsCnv() > 0 ) _nasa["cnv"] = window.wcs.cnv(getWcsCnv(), "1");

          window.wcs_add = {wa:"s_16dbf405b55d"};

          if( window.wcs ) {
            window.wcs.inflow("bikemart.co.kr");
            window.wcs_do(_nasa);
          }
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          $("#indicator").removeClass("indicator");
          break;
        }

        default: {
          break;
        }
      }
    });

    function getWcsCnv() {
      // 전환 유형 설정
      switch(window.location.pathname) {
        case '/order/complete': return 1
        case '/member/join/complete': return 2
        case '/cart': return 3
        default: return 0
      }
    }
  };
}
