<app-header></app-header>
<div class="inner">
  <div class="page-header clearfix">
    <div class="page-title">{{boardInfo.board_name}}</div>
    <div class="search-area">
      <input type="text" [(ngModel)]="params.text" (keypress)="searchList($event)" placeholder="검색어를 입력하세요" />
      <button type="button" class="btn1" (click)="getBoardList()">검색</button>
    </div>
    <div class="fr w50p right mt20">
      <a *ngIf="authWriteCheck()" class="btn btn-default btn-small pc-only" (click)="write()">글쓰기</a>
    </div>
  </div>

<ul class="gallery-ul clearfix">
    <li *ngFor="let row of boardList" class="cp" (click)="view(row)">
      <div class="img">
        <img *ngIf="!row.upload_file" src="/assets/admin/images/board_img.png">
        <img *ngIf="row.thumbnail; else uploadFileTemplate" [src]="baseURL + row.thumbnail">
        <ng-template #uploadFileTemplate>
          <img [src]="row.upload_file">
        </ng-template>
      </div>
      <div class="title">
        <div class="clearfix">
          <p>{{row.writer_name}}</p>
          <p class="clearfix wdate"><span class="fl f11">{{row.write_date}}</span><span class="fr f11">조회수 : {{row.hit}}</span></p>
        </div>
        <p class="title1 clearfix">
          <span class="fl" [innerHTML]="row.subject"></span>
          <i *ngIf="row.uploadCount > 0" class="material-icons file-ico fl">&#xe226;</i>
          <span class="ico-firstfiber_new new-ico"></span>
        </p>
      </div>
    </li>
  </ul>

  <div class="pagination-area pc-view">
    <ngb-pagination
      [(page)]="params.no"
      [pageSize]="params.row"
      [maxSize]="maxSize"
      [collectionSize]="totalCount"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </div>

  <div class="pagination-area mobile-view">
    <ngb-pagination
      [(page)]="params.no"
      [pageSize]="params.row"
      [maxSize]="5"
      [collectionSize]="totalCount"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </div>

</div>
  <app-footer></app-footer>
