//const baseURL = "http://localhost:8089";
//const baseURL = location.hostname.indexOf('localhost') > -1 ? 'http://localhost:8094' : '//shop.bikemart.co.kr';
const baseURL = location.hostname.indexOf('localhost') > -1 ? '//shop.bikemart.co.kr' : '//shop.bikemart.co.kr';
const domain = "bikemart.co.kr";
const scmURL = "https://scm.bikemart.co.kr";

export const config = {
  production: false,
  baseUrl: baseURL,
  scmUrl: scmURL,
  cookieDomain: "." + domain,
  apiBaseUrl: baseURL + "/api",
  apiFileUploadUrl: baseURL + "/api/file/file_upload.php",
  apiImageUploadUrl: baseURL + "/api/file/image_upload.php",
  cookie_token: "bikeshoptoken",

  oauth_naver_client_id: "dsCQ97zq5MpVQO_WBVmJ",
  oauth_kakao_client_id: "da7b490b744585c60ebebe292b7ac476",

  mainRenewalOpenDate: "2022-10-04 09:00:00",
  summernoteConfig: {
    lang : 'ko-KR',
    placeholder: '',
    tabsize: 2,
    height: 350,
    uploadImagePath: '',
    toolbar: [
        // [groupName, [list of button]]
        ['misc', ['fullscreen', 'codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'link', 'video', 'hr']]
    ],
    fontNames: ['굴림', '굴림체', '돋움', '돋움체', 'Noto Sans KR', 'Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times', 'Impact', 'Lucida Grande', 'Tahoma', 'Times New Roman', 'Verdana'],
    fontNamesIgnoreCheck: [ '굴림', '굴림체', '돋움', '돋움체', 'Noto Sans KR' ]
  }

};
