/*******************************************************************************
  설  명 : 비밀번호 찾기 - 입력폼
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';

import { SMemberService } from '@app/service/member.service';
import { SmsService } from '@app/service/sms.service';

@Component({
  selector: 'app-member-find-pw',
  templateUrl: './member-find-pw.component.html',
  styleUrls: ['./member-find-pw.component.scss']
})
export class MemberFindPwComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public formSMS: FormGroup;
  public formErrors = {
    message: ''
  };

  public tmpAuthNum = 0;

  public isSubmit: boolean = false;
  public isAuth: boolean = false;

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.formSMS = this.formBuilder.group({
      id: ['', [Validators.required]],
      hp1: ['010', []],
      hp2: [ '', [Validators.required, Validators.pattern('^[0-9]{3,4}$')] ],
      hp3: [ '', [Validators.required, Validators.pattern('^[0-9]{4}$')] ],
      authNum: ['', [Validators.required]]
    });

    this.formSMS.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.formSMS, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private smsService: SmsService,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private memberService: SMemberService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

  ) {
    this.buildForm();

  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 임시비밀번호 발급
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authConfirm() {
    if( !this.formSMS.controls.hp1.value || !this.formSMS.controls.hp2.value || !this.formSMS.controls.hp3.value ) {
      this.toastrService.error('전화번호를 입력하시기 바랍니다.', '인증번호 받기');
    } else {
      this.isAuth = true;

      this.tmpAuthNum = Math.floor( (Math.random() * (10 - 1) + 1) * 100000 );
      
      let hp: any = this.formSMS.controls.hp1.value + this.formSMS.controls.hp2.value + this.formSMS.controls.hp3.value;

      this.smsService.sendSMS_directsend(hp, this.tmpAuthNum).then( response => {
        if ( response.data.ResultCode ) {
          this.toastrService.success('인증번호가 전송되었습니다.');
          this.isAuth = false;
        } else {
          this.toastrService.error(response.data.ResultMessage);
          this.isAuth = false;
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : submit
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.formSMS, this.formErrors);
    
    if( this.formSMS.valid ) {
      this.isSubmit = true;

      if( parseInt(this.formSMS.controls.authNum.value) === this.tmpAuthNum ) {
        this.memberService.setFindPw( this.formSMS ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success('임시번호 발급에 성공하였습니다.');
            this.memberService.findPwResult = response.tmpPw;
            this.router.navigate(['/member/find/pw/success']);
            this.isSubmit = false;
          } else {
            this.toastrService.error('고객정보가 없습니다. 관리자에게 문의주시기 바랍니다.');
            this.router.navigate(['/member/find/pw/fail']);
            this.isSubmit = false;
          }
        });
      } else {
        this.toastrService.error('인증번호가 일치 하지 않습니다.');
        this.isSubmit = false;
      }
    } else {
      this.utilService.showToast(this.formSMS, this.formErrors);
    }
  }
}
