/*******************************************************************************
  설  명 : 마이페이지 - 회원탈퇴
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ApiResponse } from '@app/service/api-response';

import { SMemberService } from '@app/service/member.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-member-out',
  templateUrl: './member-out.component.html',
  styleUrls: ['./member-out.component.scss']
})
export class MemberOutComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public memberInfo: any = {};

  errorResponse: ApiResponse;
  public memberOutForm: FormGroup;
  public formErrors = {
    id: '',
    name: '',
    pw: ''
  };



  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sMemberService: SMemberService,
    private toastrService: ToastrService
  ) { 
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm() : void {
    this.memberOutForm = this.formBuilder.group({
      id:[ '', [Validators.required] ],
      name:[ '', [Validators.required] ],
      pw:[ '', [Validators.required] ]
    });

    this.memberOutForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memberOutForm, this.formErrors );
    });

  }


  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
      this.memberOutForm.get('id').setValue(this.memberInfo.id);
    });
  }

  /*******************************************************************************
    설  명 : 회원탈퇴 처리
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memberOutForm, this.formErrors);
    if( this.memberOutForm.valid ) {
      this.sMemberService.setMemberOut( this.memberOutForm ).then(response => {
        if( response['ResultCode'] == true ) {
          this.toastrService.success(response['ResultMessage'], '회원탈퇴 성공');
          this.setLogout();
        } else {
          this.toastrService.error(response['ResultMessage'], '회원탈퇴 실패');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.memberOutForm, this.formErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  setLogout() {
    this.sMemberService.setLogout().then( response => {
      if( response['ResultCode'] == true ) {
        this.authService.logout('shop');
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    })
    .catch(response => {
    });
    
  }

}
