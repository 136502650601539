/*******************************************************************************
  설  명 : 상품 문의하기
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';

import { SProductService } from '@app/service/product.service';


@Component({
  selector: 'app-product-question',
  templateUrl: './product-question.component.html',
  styleUrls: ['./product-question.component.scss']
})
export class ProductQuestionComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() product_seq: any;
  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      product_seq: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      isSecret: [false],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private sProductService: SProductService,
  ) { 
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      product_seq: this.product_seq
    });
  }

  /*******************************************************************************
    설  명 : 비밀글 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSecret(event) {
    let checked = ( event.target.checked ) ? true : null;

    this.form.get('isSecret').setValue(checked);
  }

  /*******************************************************************************
    설  명 : 상품문의 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.sProductService.setProductQuestion( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage, '');
          this.activeModal.close();
        } else {
          this.toastrService.error(response.ResultMessage, '');
        }
      });
    }

  }


}
