<div class="modal-header">
  <h4 class="modal-title fl">미수취 신고</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<form [formGroup]="form">
  <div class="modal-body">
    <div class="goods clearfix">
      <div class="img"><img _ngcontent-lut-c124="" src="{{rowDetail[0].thumbnail_url}}"></div>
      <div class="info">
        <p class="name">{{rowDetail[0].product_name}}</p>
        <p class="info1" *ngFor="let row of rowDetail;">사이즈 : {{row.size_name}} / 색상 : {{row.color_name}} / {{row.qty}}개</p>  
        <p class="price">{{rowDetail[0].amt | number}}원</p>  
      </div>
    </div>
    <div>
      <select>
        <option>미수취 사유 선택</option>
        <option>미수취</option>
        <option>배송누락</option>
        <option>사이즈, 색상 등 다른 상품 잘못 배송</option>
        <option>기타</option>      
      </select>
      <textarea formControlName="comment" placeholder="사유를 입력해 주세요. (200자내)"></textarea>
    </div>   

    <div class="btn-area">
    <button class="btn1" (click)="activeModal.dismiss()">취소</button>
    <button class="btn1 red" (click)="submit()">미수취 신고</button>
    </div>
  </div>
</form>

