<div class="modal-header">
  <h4 class="modal-title fl">주문내역서</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <section id="print_area">
    <div class="fr w50p right">
      <br />
      <p>항상 바이크마트를 이용해 주셔서 감사합니다.</p>
      <p>고객님께서 주문하신 내역은 다음과 같습니다.</p>
    </div>
    <h4 class="modal-title"><img src="/assets/images/logo.png" [border]="0"></h4>
    <div class="mb35">
      <div class="fl">주문번호 : {{orderInfo.seq}}</div>
      <div class="fr">최종입금일자 : {{payDate}}</div>
    </div>
    <!-- <h1 style="text-align: center;">주문내역서</h1> -->
    <table class="list mt0" *ngIf="isLogIn">
      <caption class="sr-only">주문내역</caption>

      <colgroup>
        <col style="width:16%">
        <col style="width:35%">
        <col style="width:12%">
        <col style="width:7%">
        <col style="width:10%">
        <col style="width:10%">
        <col style="width:10%">
      </colgroup>
      
      <tbody>
        <tr>
          <th>브랜드/상품코드</th>
          <th>모델</th>
          <th>제품</th>
          <th>수량</th>
          <th>판매</th>
          <th>마일리지</th>
          <th>소계</th>
        </tr>
        <ng-container *ngFor="let i of detail; let ii = index">
          <tr *ngIf="i.order_amt > 0">
            <td class="center">
              {{i.children[0].brand_name}}<br>{{i.children[0].product_code}}<br><div *ngIf="i.out_date">발송 : {{i.out_date}}</div>
            </td>
            <td>
              <div class="mb10" [ngClass]="{'mt10': ji === 0}" *ngFor="let j of i.children; let ji = index">
                <ng-container *ngIf="j.qty > 0 && j.refundQty <= 0">
                  {{j.product_name}}<br>{{j.display_color_name || '단품'}} / {{j.display_size_name || '단품'}}<br>{{j.delivery_no ? j.delivery_company_name + '(' + j.delivery_no + ')' : ''}}
                </ng-container>
              </div>
            </td>
            <td>
              &nbsp;<a [href]="baseURL + '/product/info?productSeq=' + i.product_seq"><img [src]="i.thumbnail_url" [border]="0" width="55" height="55"></a>
            </td>
            <td class="center">
              <div *ngFor="let j of i.children" class="pb10 pt10">
                <ng-container *ngIf="j.qty > 0 && j.refundQty <= 0">
                  {{j.qty | number}}
                </ng-container>
              </div>
            </td>
            <td class="right">
              <div *ngFor="let j of i.children" class="pb10 pt10">
                <ng-container *ngIf="j.qty > 0 && j.refundQty <= 0">
                  {{(j.amt - j.discount_amt) | number}}
                </ng-container>
              </div>
            </td>
            <td class="right pr5">
              <div *ngFor="let j of i.children" class="pb10 pt10">
                <ng-container *ngIf="j.qty > 0 && j.refundQty <= 0">
                  {{j.mileage | number}}
                </ng-container>
              </div>
            </td>
            <td class="right pr5">
              <div *ngFor="let j of i.children" class="pb10 pt10">
                <ng-container *ngIf="j.qty > 0 && j.refundQty <= 0">
                  {{j.total_amt | number}}
                </ng-container>
              </div>
            </td>
          </tr>
        </ng-container>
        <tr>
          <th class="center">합계</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th class="right pr5">{{sumList.mileage | number}}</th>
          <th class="right pr5">{{sumList.sum | number}}</th>
        </tr>
        <tr>
          <th class="center">배송비</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th class="right pr5">{{orderInfo.delivery_price | number}}</th>
        </tr>
      </tbody>
      
      <!-- <tfoot>
      <tr>
        <td colspan="7">
          바마직배송은 바이크마트에서 직접 발송하는 상품이며 <br>
          업체발송은 수입업체에서 직접 발송하는 상품이며, 수입절차에 따라서 배송이 지연될 수도 있습니다.
	      </td>
      </tr>
      </tfoot> -->
      </table>

      <table class="list" *ngIf="!isLogIn">
        <caption class="sr-only">주문내역</caption>
  
        <colgroup>
          <col style="width:16%">
          <col style="width:35%">
          <col style="width:12%">
          <col style="width:7%">
          <col style="width:10%">
          <col style="width:10%">
          <col style="width:10%">
        </colgroup>
        
        <tbody>
          <tr>
            <th>브랜드/상품코드</th>
            <th>모델</th>
            <th>제품</th>
            <th>수량</th>
            <th>판매</th>
            <th>마일리지</th>
            <th>소계</th>
          </tr>
          <tr *ngFor="let i of detail">
            <td class="center">
                {{i.product[0].brand_name}}<br>{{i.product[0].product_code}}<br>발송 : {{i.order_date}}
            </td>
            <td>
              <div *ngFor="let j of i.product">
                {{j.product_name}}<br>COLOR : {{j.color_name}}<br>SIZE : {{j.size_name}}<br>{{j.delivery_name}}<br>{{j.delivery_no}}
              </div>
            </td>
            <td>
              &nbsp;<a [href]="baseURL + '/product/info?productSeq=' + i.product_seq"><img [src]="i.thumbnail_url" [border]="0" width="55" height="55"></a>
            </td>
            <td class="center">
              <div *ngFor="let j of i.product" class="pb20 pt20">
                {{j.qty | number}}
              </div>
            </td>
            <td class="right">
              <div *ngFor="let j of i.product" class="pb20 pt20">
                {{(j.amt - j.discount_amt) | number}}
              </div>
            </td>
            <td class="right pr5">
              <div *ngFor="let j of i.product" class="pb20 pt20">
                {{j.mileage | number}}
              </div>
            </td>
            <td class="right pr5">
              <div *ngFor="let j of i.product" class="pb20 pt20">
                {{j.total_amt | number}}
              </div>
            </td>
          </tr>
        </tbody>
        
        <tfoot>
        <tr>
          <th class="center">합계</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th class="right pr5">{{sumList.mileage | number}}</th>
          <th class="right pr5">{{sumList.sum | number}}</th>
        </tr>
        <tr>
          <th class="center">배송비</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th class="right pr5">{{orderInfo.delivery_price | number}}</th>
        </tr>
        <tr>
          <td colspan="7">
            바마직배송은 바이크마트에서 직접 발송하는 상품이며 <br>
            업체발송은 수입업체에서 직접 발송하는 상품이며, 수입절차에 따라서 배송이 지연될 수도 있습니다.
          </td>
        </tr>
        </tfoot>
      </table>

      <table class="list">
        <caption class="sr-only">제품 주문 정보</caption>
  
        <colgroup>
          <col style="width:13%">
          <col style="width:21%">
          <col style="width:13%">
          <col style="width:20%">
          <col style="width:13%">
          <col style="width:20%">
        </colgroup>
        
        <tbody>
          <tr>
            <th colspan="6">제품 주문 정보</th>
          </tr>
          <tr>
            <th>주문인</th>
            <td>{{orderInfo.o_name}}</td>
            <th>결제방법</th>
            <td colspan="3" class="left">{{orderInfo.bank}}({{orderInfo.bank_account}})</td>
          </tr>
          <tr>
            <th>등기번호</th>
            <td colspan="5" >{{orderInfo.delivery_no}}</td>
          </tr>
          <tr>
            <th>수취인 성명</th>
            <td>{{orderInfo.r_name}}</td>
            <th>수취인 전화</th>
            <td>{{orderInfo.r_hp}}</td>
            <th>휴대폰 등</th>
            <td></td>
          </tr>
          <tr>
            <th>수취인 주소</th>
            <td colspan="5">{{orderInfo.r_zipcode}} | {{orderInfo.r_address}} {{orderInfo.r_address_detaill}}</td>
          </tr>
          <tr>
            <th>배송요청사항</th>
            <td colspan="5">{{orderInfo.r_comment}} <br> </td>
          </tr>
        </tbody>
      </table>

      <table class="list">
        <caption class="sr-only">교환/반품 안내</caption>

        <colgroup>
          <col style="width:25%">
          <col style="width:75%">
        </colgroup>
        
        <tbody>
          <!-- <tr>
            <th>교환/반품접수</th>
            <th>신청사유</th>
          </tr>
          <tr>
            <td style="height:35px;">&nbsp;</td>
            <td>&nbsp;</td>
          </tr> -->
          <tr>
            <td colspan="2" style="line-height: 30px;">
              <strong>* 교환 및 반품에 관한 방법 및 비용 안내 *</strong>
              <ol>
                <li class="mb5">교환/반품 보내실 주소 : <strong> 대구광역시 동구 장등로 27 (주)바이크마트</strong></li>
                <li class="mb5">교환 및 반품에 따른 왕복택배비용은 고객부담입니다. 왕복 6,000원입니다. (단, 도서산간 추가 운임비 발생)</li>
                <li class="mb5">제품에 하자가 있을 경우 택배비용은 전액 바마부담입니다.</li>
                <li class="mb5">교환의 경우 실시간 재고상황이 변동되므로 고객센터로 문의해 주시길 바랍니다.</li>
                <li class="mb5">교환/반품시 CJ택배를 통해서 바마착불로 제품을 보내셔야 하며</li>
                <li>교환택배비용은 박스동봉 또는 입금해 주셔야 합니다.</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>

    <div class="mt10" style="text-align: center;">
      항상 바이크마트를 이용해 주셔서 감사합니다.<br>www.bikemart.co.kr
    </div>
  </section>
  <div class="btn-area">
    <button class="btn1 mr5" (click)="print()">출력</button>
    <button class="btn1" (click)="activeModal.dismiss()">닫기</button>
  </div>
</div>
