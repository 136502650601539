/*******************************************************************************
  설  명 : 쇼핑몰 메인(2022.08)
  생성일 : 
  생성자 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as $ from 'jquery';
import 'jqueryui';

import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { SEventService } from '@app/service/event.service';
import { SBoardService } from '@app/service/board.service';
import { SOrderService } from '@app/service/order.service';
import { SEOService } from '@app/service/seo.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-main202208',
  templateUrl: './main202208.component.html',
  styleUrls: ['./main202208.component.scss']
})
export class Main202208Component implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // public SwiperConfigInterface_banner: SwiperConfigInterface = {
  //   loop: true,
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: false,
  //   },
  //   slidesPerView: 1,
  //   spaceBetween: 0,
  //   navigation: {
  //     nextEl: ".swiper-button-next-main",
  //     prevEl: ".swiper-button-prev-main",
  //   },    
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: "fraction"
  //   },      
  // };

  // public SwiperConfigInterface_mbanner: SwiperConfigInterface = {
  //   loop: true,
  //   autoplay: {
  //     delay: 3000,
  //     disableOnInteraction: false,
  //   },
  //   slidesPerView: 1,
  //   spaceBetween: 0,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: "fraction"
  //   }, 
  // };
  
  // public SwiperConfigInterface_mevent: SwiperConfigInterface = {
  //   loop: true,
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: false,
  //   },
  //   slidesPerView: "auto",
  //   navigation: {
  //     nextEl: ".swiper-button-next-eventm",
  //     prevEl: ".swiper-button-prev-eventm",
  //   },
  // };

  // public SwiperConfigInterface_recommend: SwiperConfigInterface = {
  //   loop: true,
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: false,
  //   },
  //   slidesPerView: "auto",
  //   loopedSlides: 3,
  //   spaceBetween: 10,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true,
  //   },   
  // };  

  // public SwiperConfigInterface_review: SwiperConfigInterface = {
  //   loop: true,
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: false,
  //   },
  //   slidesPerView: "auto",
  //   spaceBetween: 25,
  //   navigation: {
  //     nextEl: ".swiper-button-next-review",
  //     prevEl: ".swiper-button-prev-review",
  //   },
  // };

  public SwiperConfigInterface_instagram: SwiperConfigInterface = {
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",
    spaceBetween: 25,
    navigation: {
      nextEl: ".swiper-button-next1",
      prevEl: ".swiper-button-prev1",
    },
  };

  customProtoType = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    // dots: false,
    navSpeed: 700,
    // navText: ['<div class="owlNavButton owlNavPrev" />', '<div class="owlNavButton owlNavNext" />'],
    autoplay: true,
    // responsive: {
    //   0: {items: 1},
    //   400: {items: 1},
    //   740: {items: 1},
    //   940: {items: 1}
    // },
    // nav: true
}

  customOptions: OwlOptions = {
    ...this.customProtoType,
    dots: false,
    navText: ['<div class="owlNavButton owlNavPrev" />', '<div class="owlNavButton owlNavNext" />'],
    responsive: {
      0: {items: 1},
      400: {items: 1},
      740: {items: 1},
      940: {items: 1}
    },
    nav: true
  }
  
  customOptionsBanner: OwlOptions = {
    ...this.customProtoType,
    dots: true,
    responsive: {
      0: {items: 1},
      400: {items: 1},
      740: {items: 1},
      940: {items: 1}
    },
    nav: false
  }

  customOptionsReview: OwlOptions = {
    ...this.customProtoType,
    dots: true,
    responsive: {
      0: {items: 2},
      400: {items: 2},
      740: {items: 2},
      940: {items: 2}
    },
    nav: false
  }

  customOptionsReview_m: OwlOptions = {
    ...this.customProtoType,
    dots: true,
    responsive: {
      0: {items: 1},
      400: {items: 1},
      740: {items: 1},
      940: {items: 1}
    },
    nav: false
  }
 
    allView: boolean = false; //main banner 모두보기
    bestGoodsMore: boolean = false; //베스트 상품펼치기
    newGoodsMore: boolean = false; // 신상품 펼치기
    bestGoodsMore1: boolean = false; //카테고리별 베스트 상품펼치기
    singleGoodsMore: boolean = false; //365꿀득템존 상품펼치기
    honey365Selected: any = '';
  
    public baseURL = config.baseUrl;
  
    public search = {};
  
    public eventList: any = [];
    public senaList: any = [];
    public best100List: any = [];
    public honey365CategoryList: any = [];
    public honey365BrandList: any = [];
    public honey365OriginalList: any = [];
    public honey365List: any = [];
    public newList: any = [];
    public monthSoldList: any = [];
    public superSaleList: any = [];
    public rewardSaleList: any = [];
    public boardList: any = [];
    public popupList: any = [];
    public reviewImageList: any = [];
    public reviewTextList: any = [];
  
    // mobile 공지사항
    public params: any = {
      type: 'list',
      board_id: 'notice',
      group_id: '쇼핑몰',
      row: 5,
      no: 1,
      group: 'subject',
      text: ''
    };
  
  categorybestCategoryCodeArray = ['001001', '001003', '001005', '001004', '001006', '001014']
  categorybestCategoryNameArray = ['헬멧', '자켓', '바지', '부츠', '장갑', '블루투스']

  toggleBN = 'B'; // 베스트:B 신상품:N
  toggleBest = 0; // 카테고리별 베스트

  eventImageList = {
    three: [],
    subA: {event_link: '', image_url: ''},
    subB: {event_link: '', image_url: ''},
    main: [],

    three_m: [],
    subA_m: {event_link: '', image_url: ''},
    subB_m: {event_link: '', image_url: ''},
    main_m: [],

    youtube: [],

    thisweek: [],
    thisweek_m: [],

    bestProduct: [],
    newProduct: [],
    bestProduct_m: [],
    newProduct_m: [],

    categorybest: {
      length: 0,
      tab001001: [],
      tab001003: [],
      tab001005: [],
      tab001004: [],
      tab001006: [],
      tab001014: []
    },
    categorybestSelect: []
  }

  linkTarget = this.deviceDetectorService.isMobile() ? "_self" : "_blank"
  isDragging = false

  goPageEmitterFunc = this.goPageEmitter.bind(this);

    /*******************************************************************************
      설  명 : 생성자
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private toastrService: ToastrService,
      private globals: Globals,
      private sEventService: SEventService,
      private sBoardService: SBoardService,
      public seoService: SEOService,
      private deviceDetectorService: DeviceDetectorService,
      private sOrderService: SOrderService
    ) {
      this.seoService.updateTitle('바이크마트 - 오토바이용품 전문몰');
    }
  
    /*******************************************************************************
      설  명 : 데이터 로딩 처리
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    ngOnInit(): void {
      // 이벤트 배너 가져오기
      this.getEventBannerList();
  
      // 팝업 정보 가져오기
      this.getBoardPopupList();
  
      // 메인 게시판 정보
      this.getMainBoardList();
  
      // 슈퍼세일 상품 정보
      this.getSuperSaleProductList();
  
      // 보상세일 상품 정보
      this.getRewardSaleProductList();
      
      // 새로나왔어요
      this.getProductNewList();
  
      // 베스트100
      this.getProductBest100List();
  
      // 꿀365 카테고리
      this.getProduct365CategoryList()
  
      // 꿀365 브랜드
      this.getProduct365BrandList();
  
      // 꿀365 상품
      this.getProduct365List();
  
      // 최근 한달 내 가장 많이 팔린 상품
      this.getProductMonthSoldList();

      // 베스트 리뷰 데이터 가져오기
      this.getMainOrderReviewList();

      // this.SwiperConfigInterface_recommend.loopedSlides = 3;

      // 202208 리뉴얼 이벤트 이미지 가져오기
      this.getEventImageList()
    }
  
    /*******************************************************************************
      설  명 : 이벤트 배너 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getEventBannerList() {
      this.sEventService.getEventBannerList().then( response => {
        if( response.ResultCode ) {
          this.eventList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  
    /*******************************************************************************
      설  명 : 베스트100 상품 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getProductBest100List() {
      this.sEventService.getEventProductList( { event_display_type: '2010' } ).then( response => {
        if( response.ResultCode ) {
          this.best100List = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  
    /*******************************************************************************
      설  명 : 이벤트 상품 브랜드 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getProduct365BrandList() {
      this.sEventService.getEventProductBrandList( { event_display_type: '2020' } ).then( response => {
        if( response.ResultCode ) {
          this.honey365BrandList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  
    /*******************************************************************************
      설  명 : 이벤트 상품 브랜드 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getProduct365CategoryList() {
      this.sEventService.getEventProductCategoryList( { event_display_type: '2020' } ).then( response => {
        if( response.ResultCode ) {
          this.honey365CategoryList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
    
    /*******************************************************************************
      설  명 : 365꿀득템존 상품 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getProduct365List() {
      this.sEventService.getEventProductList( { event_display_type: '2020' } ).then( response => {
        if( response.ResultCode ) {
          this.honey365OriginalList = response.data;
  
          this.honey365List = this.honey365OriginalList;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  
    /*******************************************************************************
      설  명 : 새로 나온 상품 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getProductNewList() {
      this.sEventService.getEventProductList( { event_display_type: '2030' } ).then( response => {
        if( response.ResultCode ) {
          this.newList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  
    /*******************************************************************************
      설  명 : 최근 한달 가장 많이 팔린 상품 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getProductMonthSoldList() {
      this.sEventService.getEventProductList( { event_display_type: '2040' } ).then( response => {
        if( response.ResultCode ) {
          this.monthSoldList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  
    /*******************************************************************************
      설  명 : 슈퍼세일 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getSuperSaleProductList() {
      this.sEventService.getSuperSaleProductList( { event_type: '1000' } ).then( response => {
        if( response.ResultCode ) {
          this.superSaleList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  
    /*******************************************************************************
      설  명 : 보상세일 리스트 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getRewardSaleProductList() {
      this.sEventService.getSuperSaleProductList( { event_type: '1001' } ).then( response => {
        if( response.ResultCode ) {
          this.rewardSaleList = response.data;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
      
    /*******************************************************************************
      설  명 : 365 꿀득템존 카테고리 선택 시
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    setHoney365CategorySelected( categorySeq: number ) {
      this.honey365Selected = categorySeq;
  
      this.honey365List = this.honey365OriginalList.filter(item => item.category_seq === categorySeq);
    }
  
    /*******************************************************************************
      설  명 : 365 꿀득템존 브랜드 선택 시
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    setHoney365BrandSelected( brandSeq: number ) {
      this.honey365Selected = brandSeq;
  
      this.honey365List = this.honey365OriginalList.filter(item => item.brand_seq === brandSeq);
    }
    
    /*******************************************************************************
      설  명 : 365 꿀득템존 브랜드 전체선택 시
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    setAllHoney365BrandSelected() {
      this.honey365List = this.honey365OriginalList;
      this.honey365Selected = '';
    }
  
    /*******************************************************************************
      설 명 : 게시판 리스트 정보 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getMainBoardList() {
      this.sBoardService.getMainBoardList( this.params ).then( response => {
        this.boardList = response.data;
      });
    }
    
    /*******************************************************************************
      설  명 : 베스트 리뷰 리스트 불러오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getMainOrderReviewList() {
      this.sOrderService.getMainOrderReviewList().then( response => {
        if ( response.ResultCode ) {
          this.reviewImageList = response.data?.image;
          this.reviewTextList = response.data?.text;
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  
    /*******************************************************************************
      설  명 : 팝업 리스트 정보 가져오기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getBoardPopupList() {
      this.sBoardService.getBoardListLimit( {board_id: 'popup', limit: '0'} ).then( response => {
        if( response.ResultCode ) {
  
          if( response.data.length > 0 ) {
            response.data.forEach( row => {
              if( this.getPopupToday(row.board_seq) ) {
                if( row.layer_popup == true ) {
                  row.is_popup_preview = true;
  
                  this.popupList.push(row);
                } else {
                  let url = this.baseURL + '/api/popup/popup.php?board_id=' + row.board_id + '&board_seq=' + row.board_seq;
  
                  window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width='+row.popup_width+', height='+(parseInt(row.popup_height)+60)+', left='+row.screen_x+', top=' + row.screen_y );
                }
              }
            });
  
            setTimeout( ()=> {
              $(function() {
                $(".popup-layer").draggable();
              });
            }, 0);            
          }
        }
      });
    }
    
    /*******************************************************************************
      설  명 : 팝업 오늘 더 이상 보지 않기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    getPopupToday( boardSeq ) {
      let data: any = this.globals.load( 'popup' + boardSeq );
  
      if( data !== false ) {
        data = JSON.parse( data );
  
        let now: any = moment();
        data = moment( data ).format('YYYY-MM-DD hh:mm:ss');
  
        if( now.diff( data, 'hours' ) < 24 ) return false;
        else return true;
      } else return true;
    }
    
    /*******************************************************************************
      설  명 : 팝업 오늘 더 이상 보지 않기
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    setPopupToday( row ) {
      this.globals.save( 'popup' + row.board_seq, moment().format('YYYY-MM-DD hh:mm:ss') );
  
      row.is_popup_preview = false;
    }
    
    /*******************************************************************************
      설  명 : 게시판 상세보기 (is_notice여부에 따른 a태그 사용) 수정자: 2/8 김종현
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    view( row: any ) {
      this.params.board_seq = row.board_seq;
  
      this.router.navigate(
      ['/board/view'],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }

  /*******************************************************************************
    설  명 : 이미지 가져오기
  *******************************************************************************/
  getEventImageList() {
    const getEventImageListApi = (eventCode: any) => {
      return this.sEventService.getEventImageList({
        event_display_type: eventCode
      }).then(res => {
        if(res.ResultCode) return {
          data: res.data,
          data_m: res.mData
        }
      })
    }
  
    const getEventProductListApi = (eventCode: any) => {
      return this.sEventService.getEventProductList({
        event_display_type: eventCode
      }).then(res => {
        if(res.ResultCode) return res.data
      })
    }

    Promise.all([4000, 4001, 4002, 4010, 4011, 'youtube', 1000].map(item => getEventImageListApi(item))).then((res: any) => {
      const trimUrl = (arr: any) => arr[0] || null;
      ['', '_m'].forEach((item, index) => {
        for(let i = 0; i < 3; i++) this.eventImageList[`three${item}`][i] = trimUrl(res[i][`data${item}`])
        this.eventImageList[`three${item}`] = this.eventImageList[`three${item}`].filter((item: any) => item)
        this.eventImageList[`subA${item}`] = trimUrl(res[3][`data${item}`])
        this.eventImageList[`subB${item}`] = trimUrl(res[4][`data${item}`])
        if(!index) this.eventImageList.youtube = res[5][`data${item}`]
        this.eventImageList[`main${item}`] = res[6][`data${item}`]
      })
    })

    // Promise.all([4030, 4040, 2010, 2030].map(item => getEventProductListApi(item))).then(res => {
    //   this.eventImageList.thisweek = res[0]
    //   for(let i = 0; i < res[0].length / 2; i++) this.eventImageList.thisweek_m[i] = [res[0][i * 2] || {}, res[0][i * 2 + 1] || {}]
    //   this.eventImageList.categorybest.length = res[1].length
    //   res[1].forEach((item: any) => this.eventImageList.categorybest[`tab${item.category_code.slice(0, 6)}`].push(item))
    //   this.eventImageList.categorybestSelect = this.eventImageList.categorybest[`tab${this.categorybestCategoryCodeArray[0]}`]
    //   this.eventImageList.bestProduct = res[2]
    //   for(let i = 0; i < res[2].length / 2; i++) this.eventImageList.bestProduct_m[i] = [res[2][i * 2] || {}, res[2][i * 2 + 1] || {}]
    //   this.eventImageList.newProduct = res[3]
    //   for(let i = 0; i < res[3].length / 2; i++) this.eventImageList.newProduct_m[i] = [res[3][i * 2] || {}, res[3][i * 2 + 1] || {}]
    // })
    Promise.all([4030, 4040, 2010, 2030].map(item => getEventProductListApi(item))).then(res => {
      this.eventImageList.thisweek = res[0];
    
      for (let i = 0; i < res[0].length / 2; i++) {
        this.eventImageList.thisweek_m[i] = [res[0][i * 2] || {}, res[0][i * 2 + 1] || {}];
      }
    
      this.eventImageList.categorybest.length = res[1].length;
    
      res[1].forEach((item: any) => {
        const key = `tab${item.category_code.slice(0, 6)}`;
    
        // 🛠 categorybest 객체 내 key가 없으면 배열로 초기화
        if (!this.eventImageList.categorybest[key]) {
          this.eventImageList.categorybest[key] = [];
        }
    
        this.eventImageList.categorybest[key].push(item);
      });
    
      // 🛠 categorybestSelect 초기화 확인
      const firstCategory = `tab${this.categorybestCategoryCodeArray[0]}`;
      this.eventImageList.categorybestSelect = this.eventImageList.categorybest[firstCategory] || [];
    
      this.eventImageList.bestProduct = res[2];
    
      for (let i = 0; i < res[2].length / 2; i++) {
        this.eventImageList.bestProduct_m[i] = [res[2][i * 2] || {}, res[2][i * 2 + 1] || {}];
      }
    
      this.eventImageList.newProduct = res[3];
    
      for (let i = 0; i < res[3].length / 2; i++) {
        this.eventImageList.newProduct_m[i] = [res[3][i * 2] || {}, res[3][i * 2 + 1] || {}];
      }
    });
    

  }

  // 베스트, 신상품 바로가기
  goPageEmitter(event: any) {
    this.toggleBN = event == 'best' ? 'B' : 'N'; // 베스트:B 신상품:N
    (document.getElementsByClassName('best-new')[this.deviceDetectorService.isMobile() ? 1 : 0] as HTMLElement).scrollIntoView()
  }

  // 카테고리별 베스트 선택
  setCategorybestItem(categoryCode: any) {
    this.eventImageList.categorybestSelect = this.eventImageList.categorybest[`tab${categoryCode}`]
  }

  // 가격 비교
  comparePrice(item: any, condition: any) {
    if( !item || item === undefined ) return;

    if( eval(condition) ) return true;
    else return false;
  }
}