/*******************************************************************************
  설  명 : 레이아웃 - 헤더
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import { config } from '@app/service/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() goPageEmitter: any;

  // 현재 일시
  public nowDate: any = moment();

  // 메인 리뉴얼 오픈 일시
  public renewDate: any = moment(config.mainRenewalOpenDate);

  // 메인 리뉴얼 표시 여부
  public isRenew: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isRenew = ( this.renewDate.diff(this.nowDate) < 0 ) ? true : false;
  }

}
