<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>
  
  <div class="mypage-contents">
    <div class="section order-list">
      <div class="title page-title">반품/교환 조회</div>

      <div class="mypage-search-area form-inline">
        <span class="bold pc-view">기간</span>

        <div class="btn-group ml10 mr10">
          <button *ngFor="let item of searchdate_group" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchdate_group==item.value}" (click)="onSearchDateClick(item.value)">{{item.title}}</button>
        </div>

        <input type="text" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small pc-view" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker" [readonly]="true" (click)="d1.toggle()" />
        <ng-template #footerTemplate>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 1)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
        </ng-template>
        <span class="pl5 pr5 pc-view">~</span>
        <input type="text" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small ml5  pc-view" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" [readonly]="true" (click)="d2.toggle()" />
        <ng-template #footerTemplate2>
          <hr class="my-0">
          <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 2)">오늘</button>
          <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
        </ng-template>
        
        <select (change)="selectedStatus($event)" class="form-control form-control-small ml10" >
	        <option value='' ng-selected="true">전체상태</option> <!-- selected 자동선택 기능 해결해야함-->
	        <option value="3">요청대기</option>
	        <option value="1">승인</option>
	        <option value="2">거절</option>
	      </select>
	      <button type="button" class="basic-btn sm-btn pc-view" (click)="getCancelOrder()">조회</button>

      </div>

      <table class="list-table pc-view">
      <colgroup>
        <col style="width: 10%">
        <col style="width: 14%">
        <col style="width: 62%">
        <col style="width: 14%">
      </colgroup>    
  
      <thead>
      <tr>
        <th>신청번호</th>
        <th>신청일자</th>
        <th>상품정보/상품금액</th>
        <th>상태</th>
      </tr>    
      </thead>
      
      <tbody>
        <ng-container *ngFor="let row of cancelList;let i = index">
          <tr *ngFor="let detail of cancelList[i].detail;let j = index">
            <td *ngIf="j === 0" class="date" [attr.rowspan]="row.detail.length">
              {{row.seq}}
              <a class="basic-btn sm-btn round cp" (click)="goDetail(row.order_seq)">주문상세보기</a>
              <a class="basic-btn sm-btn round cp" (click)="goCancel(row.seq)">신청취소</a>
            </td>
            <td *ngIf="j === 0" class="date" [attr.rowspan]="row.detail.length">
              {{row.req_date}}
            </td>
            <td class="info">
              <div class="img cp" (click)="goProduct(row)"><img src="{{detail.thumbnail_url}}"></div>
              <div class="info1 cp" (click)="goProduct(row)">
                <p class="name">{{detail.product_name}}</p>
                <p class="size">색상 : {{detail.display_color_name}} / 사이즈 : {{detail.display_size_name ? detail.display_size_name : '단품'}}</p>
                <p class="red" *ngIf="row.reg_gbn == 2000">반품요청 수량 : {{detail.req_qty}}개</p>
                <p class="red" *ngIf="row.reg_gbn == 3000">교환요청 수량 : {{detail.req_qty}}개</p>
                <div class="add-option" *ngIf="row.event_seq != '0'"></div>
                <p class="price" *ngIf="row.reg_gbn == 2000">반품요청 금액 : {{detail.req_amt | number}} 원</p>
              </div>
            </td>
            <td *ngIf="row.reg_gbn == 2000 && row.approval_yn == null">반품대기중</td>      
            <td *ngIf="row.reg_gbn == 3000 && row.approval_yn == null">교환대기중</td>
            <td *ngIf="row.reg_gbn == 2000 && row.approval_yn == '1'">반품승인
              <a *ngIf="row.message != null" class="basic-btn sm-btn round cp" (click)="reason(row)">승인답변보기</a>
            </td>      
            <td *ngIf="row.reg_gbn == 3000 && row.approval_yn == '1'">교환승인
              <a *ngIf="row.message != null" class="basic-btn sm-btn round cp" (click)="reason(row)">승인답변보기</a>
            </td> 
            <td class="red1" *ngIf="row.reg_gbn == 2000 && row.approval_yn == '2'">반품거절
              <a *ngIf="row.message != null" class="basic-btn sm-btn round cp" (click)="reason(row)">거절답변보기</a>
            </td>      
            <td class="red1" *ngIf="row.reg_gbn == 3000 && row.approval_yn == '2'">교환거절
              <a *ngIf="row.message != null" class="basic-btn sm-btn round cp" (click)="reason(row)">거절답변보기</a>
            </td>      
          </tr>
        </ng-container>
        <tr *ngIf="cancelList.length < 1">
          <td colspan="4">내용이 없습니다</td>
        </tr>
      </tbody>
      </table>
      
      <!-- 모바일 view --> 
      <ng-container *ngFor="let row of cancelList;let i = index">
        <div class="list-group mobile-view">
          <div class="date clearfix">
            <div>{{row.req_date}}</div>
            <a routerLink="/mypage/order/detail" class="" (click)="goDetail(row.order_seq)">주문상세보기</a>
          </div>
          <ul>
            <li>
              <div class="clearfix">
                <div *ngIf="row.reg_gbn == 2000 && row.approval_yn == null">반품대기중</div>      
                <div *ngIf="row.reg_gbn == 3000 && row.approval_yn == null">교환대기중</div>
                <div *ngIf="row.reg_gbn == 2000 && row.approval_yn == '1'">반품승인</div>      
                <div *ngIf="row.reg_gbn == 3000 && row.approval_yn == '1'">교환승인</div> 
                <div class="red1" *ngIf="row.reg_gbn == 2000 && row.approval_yn == '2'">반품거절</div>      
                <div class="red1" *ngIf="row.reg_gbn == 3000 && row.approval_yn == '2'">교환거절</div>   
              </div>
              <ng-container *ngFor="let detail of cancelList[i].detail;let j = index">
                <div class="info clearfix">
                  <div class="img" (click)="goProduct(row)"><img src="{{detail.thumbnail_url}}"></div>
                  <div class="info1" (click)="goProduct(row)">
                    <p class="name">{{detail.product_name}}</p>
                    <p class="size">색상 : {{detail.display_color_name}} / 사이즈 : {{detail.display_size_name ? detail.display_size_name : '단품'}}</p>
                    <p class="red" *ngIf="row.reg_gbn == 2000">반품요청 수량 : {{detail.req_qty}}개</p>
                    <p class="red" *ngIf="row.reg_gbn == 3000">교환요청 수량 : {{detail.req_qty}}개</p>
                    <div class="add-option" *ngIf="row.event_seq != '0'"></div>
                    <p class="price" *ngIf="row.reg_gbn == 2000">반품요청 금액 : {{detail.req_amt | number}} 원</p> 
                  </div>         
                </div>
              </ng-container>
            </li>
          </ul>  
        </div>
      </ng-container>

      <!-- 모바일 view -->      
      
      <div class="pagination-area">
        <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [collectionSize]="totalCount"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </div>             
    </div>
         
  </div>   
</div>

<app-footer></app-footer>