/*******************************************************************************
  설  명 : 장바구니 - 상품에 쿠폰 적용
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import {
  NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions,
  NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';
import { element } from 'protractor';

@Component({
  selector: 'app-product-coupon',
  templateUrl: './product-coupon.component.html',
  styleUrls: ['./product-coupon.component.scss']
})

export class ProductCouponComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() info_product;
  @Input() info_coupon_list;
  @Input() total_productprice; //총합 상품가격
  //@Input() use_coupon;
  @Input() total_sale;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 라디오 버튼 클릭시 쿠폰 사용/해제
  *******************************************************************************/
  setCoupon(product, coupon) {
    let _this = this.info_product[this.info_product.findIndex(element => element.seq == product.seq)];
    let alreay_use = this.info_product.findIndex(element => element.coupon_code == coupon.code) != -1 ? 1 : 0;
    if(coupon) { //쿠폰을 사용하려고 하는데...
      //let already_exist = this.use_coupon.findIndex(element => element.choice_coupon == coupon.code) != -1 ? 1 : 0;
      if(_this.coupon_code == coupon.code) { //이미 같은 쿠폰이 적용된 경우
        return false;
      } else if(alreay_use) { //이미 사용한 쿠폰인 경우
        alert('다른 상품에 적용중인 쿠폰입니다.');
        return false;
      } else { //사용할 수 있는 쿠폰인 경우
        /*
        this.use_coupon.push({
          choice_product : product.seq,
          choice_coupon : coupon.code
        });*/
        //this.info_product[this.info_product.findIndex(element => element.seq == product.seq)].coupon_code = coupon.code;
        _this.coupon_code = coupon.code;
        if(coupon.method) {
          //this.info_product[this.info_product.findIndex(element => element.seq == product.seq)].coupon_sale = product.price * product.num * (coupon.sale / 100);
          _this.coupon_sale = product.price * product.num * (coupon.sale / 100);
        } else {
          _this.coupon_sale = coupon.sale > (product.price * product.num) ? (product.price * product.num) : coupon.sale;
        }
      }
    } else { //쿠폰을 사용하지 않았을 때
      /*
      let already_exist = this.use_coupon.findIndex(element => element.choice_product == product.seq) != -1 ? 1 : 0;
      if(already_exist) { //해당 상품이 목록에 있을 경우
        this.use_coupon.splice(already_exist, 1);
        this.info_product[this.info_product.findIndex(element => element.seq == product.seq)].coupon_code = '';
        this.info_product[this.info_product.findIndex(element => element.seq == product.seq)].coupon_sale = 0;
      } else { //목록에 없을 경우
        return false;
      }*/
      _this.coupon_code = '';
      _this.coupon_sale = 0;
    }

    /*
    if( coupon && this.use_coupon.findIndex(element => element.choice_coupon == coupon.code) != -1 ) {
      alert('다른 상품에 사용한 쿠폰입니다.');
    } else {
      if(this.use_coupon.findIndex(element => element.choice_product == product.seq)) {}
      this.use_coupon.push({
        choice_product : product.seq,
        choice_coupon : coupon.code || ''
      });
      if(coupon.method) {
        this.info_product[this.info_product.findIndex(element => element.seq == product.seq)].sale = product.price * product.num * (coupon.sale / 100 || 0);
        //this.total_sale += product.price * product.num * coupon.sale;
      } else {
        this.info_product[this.info_product.findIndex(element => element.seq == product.seq)].sale = (coupon.sale || 0);
        //this.total_sale += coupon.sale;
      }
    }*/

    this.total_sale = 0; //쿠폰적용 총할인가 재계산
    this.info_product.forEach((element, index) => {
      this.total_sale += element.coupon_sale;
    });
  }



}
