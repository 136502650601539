import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { UtilService } from '@app/service/util.service';
import { CompanyService } from '@app/service/company.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sena-registration',
  templateUrl: './sena-registration.component.html',
  styleUrls: ['./sena-registration.component.scss']
})
export class SenaRegistrationComponent implements OnInit {

  public form: FormGroup;
  public formErrors = {};

  public contactList: any = [];
  public isMobileApp: Boolean = false;

  constructor(
    public router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private companyService: CompanyService,
    private toastrService: ToastrService,
  ) {
    this.form = this.formBuilder.group({
      registerName: ['', [Validators.required]],
      registerHp: ['', [Validators.required, Validators.pattern('^[0-9+-]{9,16}$')] ],
      registerEmail: [null, [Validators.pattern('^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$')] ],
      buyDate: ['', [Validators.required]],
      serialNo: ['', [Validators.required]],
      agreement: [false, [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  ngOnInit(): void {
    this.getContact();

    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

  /*******************************************************************************
    설  명 : 가맹/입점문의 데이터 가져오기
  *******************************************************************************/
  getContact() {
    this.companyService.getContact( {} ).then( response => {
      if( response.ResultCode ) {
        this.contactList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }

  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      if( this.form.value.agreement === false ) {
        this.toastrService.error('개인정보취급방침에 동의하셔야 등록이 가능합니다.', '세나정품등록');
      } else {
        this.companyService.setRegistrationSena(this.form).then( response => {
          if (response.ResultCode) {
            this.toastrService.success(response.ResultMessage);

            this.form.patchValue({
              registerName: '',
              registerHp: '',
              registerEmail: '',
              buyDate: '',
              serialNo: '',
              agreement: false
            });

          } else {
            this.toastrService.error('세나정품 등록에 실패하였습니다.', '세나정품등록');
          };
        });
      }
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '세나정품등록');
    }
  }

}