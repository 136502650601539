/*******************************************************************************
  설  명 : 주문하기 - 완료
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { SOrderService } from '@app/service/order.service';
import { SCartService } from '@app/service/cart.service';

declare const kakaoPixel : any;

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderCompleteComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isLogIn$: Observable<boolean>;
  public isLogIn: boolean;

  public detailList: any = [];
  public orderInfo: any = {};
  public payList: any = [];
  public info_product: any = []; // 주문상품 정보

  public params: any = {
    orderSeq: '',
    memNo: '',
    tmpSeq: ''
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute,
    private toastrService: ToastrService,
    private globals: Globals,
    private authService: AuthService,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
  ) {
    this.isLogIn$ = this.authService.isLoggedIn;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 장바구니 데이터에도 isChecked 초기화
    // let data: any = this.globals.load('bikemart_cart');
    // let cart: any = ( data !== false ) ? data : [];
    // let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

    // cartList.forEach(item => {
    //   delete item.isChecked;
    // });

    // this.globals.save( 'bikemart_cart', cartList );

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.orderSeq = ( typeof params.orderSeq == 'undefined' || params.orderSeq == '' ) ? '' : params.orderSeq;
      this.params.memNo = ( typeof params.memNo == 'undefined' || params.memNo == '' ) ? '' : params.memNo;
      this.params.tmpSeq = ( typeof params.tmpSeq == 'undefined' || params.tmpSeq == '' ) ? '' : params.tmpSeq;

      if( this.params.orderSeq === '' ) {
        this.toastrService.error('잘못된 접근입니다.', '');
        this.router.navigate(
          [ '/main' ],
          {
            relativeTo: this.activatedRoute,
            queryParams: {},
            queryParamsHandling: ''
          }
        );
      } else {
        if( this.isLogIn ) {
          this.getOrderDetail();
        } else {
          this.getNotLoginOrderDetail();
        }
      }

      // 주문/결제 정보 임시저장 테이블 삭제
      // if( this.params.tmpSeq !== '' ) {
      //   this.sOrderService.setOrdertmpDetele( this.params.tmpSeq ).then( response => {
      //     if( response.ResultCode ) {
      //       // this.toastrService.success( response.ResultMessage, '');
      //     } else {
      //       this.toastrService.error( response.ResultMessage, '');
      //     }
      //   });
      // }

      // let orderList = JSON.parse( sessionStorage.getItem('bikemart_order') );

      // if( !orderList || orderList === null ) {
      //   this.toastrService.error('주문정보를 찾을 수 없습니다.', '');
      //   this.router.navigate(
      //     [ '/main' ],
      //     {
      //       relativeTo: this.activatedRoute,
      //       queryParams: {},
      //       queryParamsHandling: ''
      //     }
      //   );
      // }

      // this.info_product = orderList;

      this.setDeleteSelectCart();
    });

    if( typeof kakaoPixel !== "undefined" )
      kakaoPixel('4510288775974009382').purchase()
  }

  /*******************************************************************************
    설  명 : 페이지 아웃 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnDestroy() {
    // 스토리지에 담은 주문정보 삭제
    sessionStorage.removeItem( 'bikemart_order' );
  }

  /*******************************************************************************
    설  명 : 회원용 주문 상세 내역 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderDetail() {
    this.sOrderService.getOrderDetail( this.params.orderSeq ).then( response => {
      if( response.ResultCode ) {
        this.orderInfo = response.data;
        this.detailList = response.detail;
        this.payList = response.pay;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 비회원용 주문 상세 내역 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getNotLoginOrderDetail() {
    this.sOrderService.getNotLoginOrderDetail( this.params.orderSeq, this.params.memNo ).then( response => {
      if( response.ResultCode ) {
        this.orderInfo = response.data;
        this.detailList = response.detail;
        this.payList = response.pay;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 총 결제금액 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPayAmt( info: any ) {
    return parseInt(info.order_amt) + parseInt(info.delivery_price);
  }

  /*******************************************************************************
    설  명 : 주문완료 상품 장바구니에서 삭제
  *******************************************************************************/
  setDeleteSelectCart() {
    // 회원인 경우 DB에서 삭제
    // if( this.isLogIn ) {
    //   let tmp = this.info_product;
    //   tmp = tmp.map( rows => { return rows.cart_seq });

    //   if( tmp.length === 1 && tmp[0] === undefined ) {
    //     // 처리안함
    //   } else {
    //     this.sCartService.deleteCartRow( tmp ).then( response => {
    //       if( response.ResultCode ) {
    //         // this.toastrService.success(response.ResultMessage, '삭제되었습니다.' );
    //         this.sCartService.setCartBadge( response.data || 0 );
    //       } else {
    //         this.toastrService.error(response.ResultMessage, '삭제가 되지 않았습니다.' );
    //       }
    //     });
    //   }

    // // 비회원인 경우 쿠키에서 삭제
    // } else {
      let data: any = this.globals.load('bikemart_cart');
      let cart: any = ( data !== false ) ? data : [];
      let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

      // 장바구니에서 삭제
      let tmp = [];
      cartList.forEach(item => {
        if( item.isChecked == true || item.isChecked == 1 ) {
          // 제외
        } else {
          tmp.push(item);
        }
      });

      this.globals.save( 'bikemart_cart', tmp );

      this.sCartService.setCartBadge( tmp.length || 0 );
    // }
  }

  /*******************************************************************************
    설  명 : 주문정보 보기
  *******************************************************************************/
  goMypageOrder() {
    if ( this.isLogIn ) {
      this.router.navigate(
        [ '/mypage/order' ],
        {
          relativeTo: this.activatedRoute,
          queryParams: {},
          queryParamsHandling: ''
        }
      );
    } else {
      this.router.navigate(
        [ '/mypage/nonmember/order' ],
        {
          relativeTo: this.activatedRoute,
          queryParams: { seq: this.params.orderSeq, mem_no: this.params.memNo },
          queryParamsHandling: ''
        }
      );
    }

  }
}