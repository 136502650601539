<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header></app-mypage-header>
  
  <div class="mypage-contents">
    <div class="section order-list">
      <div class="section-header">
	      <div class="title">최근 주문내역<span>최근 주문 3건(1개월 내)</span></div>
	      <div class="right-btn fr">
	        <a routerLink="/mypage/order" class="basic-btn sm-btn">더보기</a>
	      </div>
      </div>
      <table class="list-table pc-view">
      <colgroup>
        <col style="width: 13%">
        <col style="width: 45%">
        <col style="width: 14%">
        <col style="width: 14%">
        <col style="width: 14%">
      </colgroup>    
  
      <thead>
      <tr>
        <th>주문일/주문번호</th>
        <th>상품정보</th>
        <th>주문금액</th>
        <th>상태</th>
        <th>확인/요청</th>
      </tr>    
      </thead>
      
      <tbody>
        <ng-container *ngFor="let row of orderList; let i = index">
          <tr *ngFor="let details of row.detail;let j = index">
            <td *ngIf="j === 0" [attr.rowspan]="row.detail.length" class="date" >
              {{row.order_date}}<br/>{{row.seq}}
              <a class="basic-btn sm-btn round cp" (click)="goDetail(row.seq)">주문상세보기</a>
            </td>
            <td class="info">
              <div class="img cp" (click)="goProduct(details)"><img src="{{details.thumbnail_url}}"></div>
              <div class="info1 cp">
                <p (click)="goProduct(details)">{{details.product_name | nohtml}}</p>
                <ng-container *ngFor= "let rowDetail of details.product;let k = index;">
                  <p *ngIf="rowDetail.qty > 0 && rowDetail.refundQty == null" class="option" (click)="goProduct(details)">색상 : {{rowDetail.display_color_name}} / 사이즈 : {{rowDetail.display_size_name ? rowDetail.display_size_name : '단품'}} / {{rowDetail.qty}}개 / &nbsp;&nbsp;&nbsp;&nbsp; 반품,교환 가능한 수량 {{rowDetail.qty - rowDetail.used_req_qty}}</p>
                </ng-container>
                <div class="add-option" (click)="goProduct(details)">
                  <!-- <div *ngIf="row.event_seq != 0">추가옵션1 : </div> -->
                </div>
                <!-- <p class="amt">{{details.sum_total_amt | number}}원</p> -->
                <a class="basic-btn sm-btn round cp review" *ngIf="details.product[0].review_seq == '0' && row.order_status == 9" (click)="review(details)"><span class="red">구매후기작성</span></a>
              </div>
            </td>
            <td *ngIf="j === 0" [attr.rowspan]="row.detail.length">
              <p class="order_amt">{{getPayAmt(row) | number}} 원</p>
            </td>
            <td *ngIf="j === 0" [attr.rowspan]="row.detail.length">
              {{row.order_status_name}}
              <!-- <p *ngIf="row.out_date">(발송일자 : {{row.out_date}})</p> -->
            </td>
            <td *ngIf="j === 0" [attr.rowspan]="row.detail.length">
              <ng-container *ngIf="row.order_status == 0">
                <a class="basic-btn sm-btn round cp" (click)="cancel(row)"><span class="red">주문취소</span></a>
              </ng-container>
              <ng-container *ngIf="row.order_status == 8 && row.receipt_confirm == '0'">
                <a class="basic-btn sm-btn round cp" (click)="received(row)"><span class="red">수취확인</span></a>
              </ng-container>
              <ng-container *ngIf="row.order_status == 8 && row.receipt_confirm == '1'">
                <a class="basic-btn sm-btn round cp" (click)="purchaseConfirm(row)"><span class="red">구매확정</span></a>
                <ng-container *ngIf="row.order_date.substr(0,10) >= '2021-09-12'">
                  <a class="basic-btn sm-btn round cp" (click)="return(row)">반품요청</a>
                  <a class="basic-btn sm-btn round cp" (click)="exchange(row, i)">교환요청</a>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="orderList.length < 1">
          <tr>
            <td colspan="5">내용이 없습니다</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      
      <!-- 최근 주문내역  mobile --> 
      <ul class="list-ul mobile-view">
        <ng-container *ngFor="let row of orderList; let i = index">
          <li>
            <div class="order-list1 clearfix">
              <div class="div1 red">{{row.order_status_name}}</div>
              <div class="div2 ml3">(주문일자 : {{row.order_date}})</div>
              <div class="div3">{{getPayAmt(row) | number}} 원</div>
            </div>
            <ng-container *ngFor="let details of row.detail;let j = index">
              <div class=" info clearfix" >
                <div class="img" (click)="goProduct(details)"><img src="{{details.thumbnail_url}}"></div>
                <div class="info1">
                  <p class="info1-1" (click)="goProduct(details)">{{details.product_name | nohtml}}</p>
                  <ng-container *ngFor= "let rowDetail of details.product;let k = index;">
                    <p *ngIf="rowDetail.qty > 0 && rowDetail.refundQty == null" class="info1-2" (click)="goProduct(details)">색상 : {{rowDetail.display_color_name}} / 사이즈 : {{rowDetail.display_size_name ? rowDetail.display_size_name : '단품'}} / {{rowDetail.qty}}개 / 반품,교환 가능한 수량 {{rowDetail.qty - rowDetail.used_req_qty}}</p>
                  </ng-container>
                  <div class="add-option">
                    <!-- <div *ngIf="row.event_seq != 0">추가옵션1 : </div> -->
                  </div>
                  <!-- <p class="info1-3">{{details.sum_total_amt | number}}원</p>   -->
                  <a class="basic-btn sm-btn round cp mb5 review1" *ngIf="details.product[0].review_seq == '0' && row.order_status == '9'" (click)="review(details)"><span class="red">구매후기작성</span></a>
                </div>
              </div>
              <ng-container *ngIf="j == row.detail.length-1">
                <div class="btn-div" *ngIf="row.order_status == 0">
                  <a class="basic-btn sm-btn round cp mr5 mt10" (click)="cancel(row)"><span class="red">주문취소</span></a>  
                </div>
                <div class="btn-div" *ngIf="row.order_status == 8 && row.receipt_confirm == '0'">
                  <a class="basic-btn sm-btn round cp mb5" (click)="received(row)"><span class="red">수취확인</span></a>
                </div>
                <div class="btn-div" *ngIf="row.order_status == 8 && row.receipt_confirm == '1'">
                  <a class="basic-btn sm-btn round cp mb5" (click)="purchaseConfirm(row)"><span class="red">구매확정</span></a>
                  <div class="btn-div" *ngIf="row.order_date.substr(0,10) >= '2021-09-12'">
                    <!-- <a routerLink="#" class="basic-btn sm-btn round mr5" (click)="notReceived(details)">미수취신고</a> -->
                    <a class="basic-btn sm-btn round cp mb5 mr5" (click)="return(row)">반품요청</a>    
                    <a class="basic-btn sm-btn round cp" (click)="exchange(row, i)">교환요청</a>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </li>
        </ng-container>
      </ul>    
      <button class="basic-btn view-btn mobile-view" routerLink="/mypage/order">이전 내역 보기</button>          
    </div>
    
	  <div class="section myqna">
	    <div class="section-header">
	      <div class="title">1:1 문의내역</div>
	      <div class="right-btn fr">
	        <a routerLink="/mypage/myqna" class="basic-btn sm-btn">1:1 문의하기 더보기</a>
	      </div> 
	    </div>
	    
	    <table class="list-table">
	    <colgroup class="pc-view">
	      <col style="width: 6%">
	      <col style="width: 12%">
	      <col style="width: 47%">
	      <col style="width: 10%">
	      <col style="width: 10%">
	      <col style="width: 15%">
	    </colgroup>    
	  
	    <thead>
	    <tr>
	      <th>번호</th>
	      <th>유형</th>
	      <th>제목</th>
	      <th>문의날짜</th>
	      <th>상태</th>
	      <th>관리</th>
	    </tr>    
	    </thead>
	    <tbody>  
        <tr *ngFor="let row of boardQuestionListLimit" id="tr_{{row.board_seq}}">
          <td (click)="viewDown(row)">{{row.board_seq}}</td>
          <td (click)="viewDown(row)">{{row.group_id}}</td>
          <td class="tl" (click)="viewDown(row)">
            <span *ngIf="row.depth=='1' && row.board_id=='question'">└&nbsp;</span>
            <span *ngIf="row.depth=='2' && row.board_id=='question'">　└&nbsp;</span>
            <span *ngIf="row.depth=='3' && row.board_id=='question'">　　└&nbsp;</span>
            <span *ngIf="row.depth=='4' && row.board_id=='question'">　　　└&nbsp;</span>
            <span *ngIf="row.depth=='5' && row.board_id=='question'">　　　　└&nbsp;</span>  
            {{row.subject}}
            <p class="mobile-view">{{row.write_date.substr(0,10)}}</p>
          </td>
          <td (click)="viewDown(row)">{{row.write_date.substr(0,10)}}</td>
          <td (click)="viewDown(row)">
            <span class="badge badge-success p5" *ngIf="row.depth == '0' && row.replyCount == 1">처리중</span>
            <span class="badge badge-primary p5" *ngIf="row.depth == '0' && row.replyCount > 1">답변완료</span>
          </td>
          <td>
            <button *ngIf="row.status == false" class="basic-btn sm-btn sm-btn1" (click)="delete(row)">삭제</button>
            <button *ngIf="row.status == false" class="basic-btn sm-btn sm-btn1" (click)="modify(row)">수정</button>
          </td>
        </tr>
        <tr *ngIf="boardQuestionListLimit.length < 1">
          <td colspan="6">등록된 문의내역이 없습니다.</td>
        </tr>
	    </tbody>
	    </table> 

	    <button class="basic-btn view-btn mobile-view" (click)="goList()">문의내역 전체보기</button>
       
	  </div>
	  
    <div class="section">
      <div class="section-header">
        <div class="title">찜 리스트</div>
        <div class="right-btn fr">
          <a routerLink="/mypage/favorite" class="basic-btn sm-btn">더보기</a>
        </div>
      </div>
	    <ul class="list clearfix">
	      <li *ngFor="let row of keepList">
	        <a class="cp" (click)="goKeepProduct(row.product_seq)">
	          <div class="img"><img src="{{row.thumbnail_url}}"></div>
	          <div class="name">{{row.product_name | nohtml}}</div>
	          <div class="price">{{row.consumer_price | number}}<span>원</span></div>
	        </a>
	      </li>
	    </ul>          

      <button class="basic-btn view-btn mobile-view" routerLink="/mypage/favorite">찜 리스트 전체보기</button>      
    </div>  	  
    
    <div class="section pc-view">
      <div class="section-header">
        <div class="title">진행 중인 이벤트</div>
        <div class="right-btn fr">
          <a routerLink="/event" class="basic-btn sm-btn">더보기</a>
        </div> 
      </div>
      <ul class="list clearfix">
        <li *ngFor='let row of eventList' class="event">
          <a class="cp" (click)="goEachEvent(row)">
            <div class="img"><img [src]="row.image_url" alt="{{row.event_name}}" /></div>
            <div class="name" style="font-size: 12px;">{{row.event_name}}</div>
          </a>
        </li>
      </ul>          
    </div>          
  </div>   
</div>

<app-footer></app-footer>