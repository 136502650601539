import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SBoardService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 게시판 정보 리스트 가져오기
  getBoardInfoId(board_id): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfoId'
      }, {
        board_id: board_id
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 정보 가져오기
  getBoardView(board_id, board_seq, view_check ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getBoardView'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        view_check: view_check
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 정보 가져오기
  getBoardViewIndex(board_id, board_index ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getBoardViewIndex'
      }, {
        board_id: board_id,
        board_index: board_index
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 저장
  setWrite( form ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'setWrite'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 답급
  setReply( form ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'setReply'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 게시판 리스트 가져오기
  getBoardList( params ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getBoardList'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시판 리스트 가져오기
  getMainBoardList( params ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getMainBoardList'
      }, params
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 메인 게시판 리스트 가져오기
  getBoardMainList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getBoardMainList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 메인 게시판 리스트 가져오기
  getBoardListLimit( params ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getBoardListLimit'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 삭제
  deleteBoard( board_id, board_seq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'deleteBoard'
      }, {
        board_id: board_id,
        board_seq: board_seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 첨부파일 삭제
  deleteUploadFile( board_id, board_seq, seq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'deleteUploadFile'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  getProductGuide(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getProductGuide'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  getMemberJoinGuide(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getMemberJoinGuide'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공지 댓글 리스트 가져오기
  getComment( board_index: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'getComment'
      }, {
        board_index: board_index
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 게시글 저장
  setComment( form ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'setComment'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 공지 댓글 삭제
  deleteComment( comment_index: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'deleteComment'
      }, {
        comment_index: comment_index
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 공지 댓글 수정
  updateComment( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'board',
        version: '1.0',
        action: 'updateComment'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
}
