/*******************************************************************************
  설  명 : 마이페이지 - 구매내역/배송조회 교환요청 모달
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbActiveModal, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';

import { RestfulService } from '@app/service/restful.service';
import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { SOrderService } from '@app/service/order.service';
import { SProductService } from '@app/service/product.service';
import { SmsService } from '@app/service/sms.service';
import { AuthService } from '@app/service/auth.service';

import { exchangeNoticeComponent } from '@app/page/mypage/mypage-exchange/exchangeNotice/exchangeNotice.component';

const URL = config.apiImageUploadUrl;

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};
@Component({
  selector: 'app-mypage-exchange',
  templateUrl: './mypage-exchange.component.html',
  styleUrls: ['./mypage-exchange.component.scss']
})
export class MypageExchangeComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() rowData: any;
  @Input() orderData: any;
  @Input() memberOrder: any;
  @Input() hp: any;

  public isLogIn: boolean;
  isLogin$: Observable<boolean>; // 로그인여부확인

  public form: FormGroup;
  public formErrors = {};

  public memberInfo: any = {};

  public rowDetail: any;
  public orderDetail: any;
  public pay_delivery: any = 0;

  public imageList: any = [];
  public detailList: any = [];
  public retTypeList: any = [];

  public baseURL = config.baseUrl;

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public delivery: any = '';

  public daumAddressOptions =  {
    class: ['btn', 'overlap-btn', 'h33']
  };

  public orgOrderAmt: any = 0;
  public orderSeq: any = '';
  public orderDate: any = '';

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      order_seq:[ '', [Validators.required] ],
      order_detail:[ [], [Validators.required]],
      req_qty:[ '', [] ],
      req_reason:[ '', [Validators.required] ],
      req_type:[ '', [Validators.required] ],
      req_pay_type:[ '1000', [] ],
      comment:[ '', [] ],
      upload: [ [], [] ],
      info_receive_address_zipcode: [ '', [Validators.required] ],
      info_receive_address_main: [ '', [Validators.required] ],
      info_receive_address_sub: [ '', [Validators.required] ],
      req_qty_master: [ '', [] ],
      pay_delivery: [ '', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private restful: RestfulService,
    private CommonService: CommonService,
    private sOrderService: SOrderService,
    private modalService: NgbModal,
    private sProductService: SProductService,
    private smsService: SmsService,
    private authService: AuthService,

  ) {
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.getCommonListCode();
    this.getConfigDelivery();

    if ( this.memberOrder === true ) {
      this.rowDetail = this.rowData.detail;
      this.orderDetail = this.orderData;

      this.orderSeq = this.rowData.seq;
      this.orderDate = this.rowData.order_date;

      this.rowDetail.map( rows => {
        rows.product.map( rows1 => {
          this.detailList.push({
            seq: rows1.seq,
            req_qty: ''
          });
        });
      });

      this.form.patchValue({
        order_seq: this.rowData.seq,
        order_detail: this.detailList,
        info_receive_address_zipcode: this.orderData.r_zipcode,
        info_receive_address_main: this.orderData.r_address,
        info_receive_address_sub: this.orderData.r_address_detail
      });

      this.memberOrder === false;
    } else {
      this.rowDetail = this.rowData;
      this.orderDetail = this.orderData;

      this.orderSeq = this.rowData[0].seq;
      this.orderDate = this.rowData[0].order_date;

      this.rowDetail.map( rows => {
        rows.product.map( rows1 => {
          this.detailList.push({
            seq: rows1.seq,
            req_qty: ''
          });
        });
      });

      this.form.patchValue({
        order_seq: this.rowData[0].seq,
        order_detail: this.detailList,
        info_receive_address_zipcode: this.orderData.r_zipcode,
        info_receive_address_main: this.orderData.r_address,
        info_receive_address_sub: this.orderData.r_address_detail
      });
    }

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = true;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );
      
      if( response.result ) {
        
        this.imageList.push(
          {
            filename: response.filename,
            origin_filename: response.origin_filename,
            size: response.size,
            filepath: response.url,
            thumbnail_org: response.thumbnail_org,
            thumbnail_path: response.thumbnail_path,
            url: this.baseURL + response.url
          }
        );
          
        this.form.patchValue({
          upload: this.imageList
        });
      
        this.toastrService.success( response.message, '');
      } else {
        this.toastrService.error( response.message, '');
      }
        
      // indicator 표시 숨김
      this.restful.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증
    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonListCode() {
    // 반품 방식
    this.CommonService.getCommonListCode( 'RTY' ).then( response => {
      if( response.ResultCode ) {
        this.retTypeList = response.data;
      } else {
        this.toastrService.error( response.message, '');
      }
    });
  }
    
  /*******************************************************************************
    설  명 : 선택된 이미지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  imageDelete(row) {
    this.imageList = this.imageList.filter( rows => rows.filename != row.filename );

    this.form.patchValue({
      upload: this.imageList
    });
  }

  /*******************************************************************************
    설  명 : 다음 우편번호찾기 API
  *******************************************************************************/ 
  setDaumAddressApi(data) {
    this.form.patchValue({
      info_receive_address_zipcode : data.zip,
      info_receive_address_main : data.addr
    });
  }

  /*******************************************************************************
    설  명 : 배송료 가져오기
  *******************************************************************************/
  async getConfigDelivery() {
    this.sProductService.getConfigDelivery().then( response => {
      if( response.ResultCode ) {
        this.delivery = response.data;

        // 배송지가 제주도일 때 교환배송비 추가
        if( this.orderData.r_zipcode.substr(0,2) == '63' || this.orderData.r_zipcode.substr(0,2) == '69' ) {
          this.delivery.delivery_amt = parseInt(this.delivery.delivery_amt) + parseInt(this.delivery.delivery_overcharge);
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
  }

  /*******************************************************************************
    설  명 : 교환요청 수량 option 생성 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  makeFakeArray(row) {
    const fakeArray = [];
    for( let i = 1; i <= row.qty - row.used_req_qty; i++ ) {   
      fakeArray.push(i);
    }

    return fakeArray;
  }

  /*******************************************************************************
    설  명 : 교환요청 수량 option event 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedQty(event: any, row: any) {
    this.detailList.map( rows => {
      if ( rows.seq == row.seq ) {
        rows.req_qty = event.target.value;
      }
    });
    
    this.form.patchValue({
      order_detail: this.detailList
    });

    this.setCalc();
  }

  /*******************************************************************************
    설  명 : 총 교환 비용 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalc() {
    let order_detail: any = this.form.controls.order_detail.value;
    let req_reason: any = this.form.controls.req_reason.value;
    let req_type: any = this.form.controls.req_type.value;

    let calc_cnt: any = 0;  // 배송비 계산 시

    let req_qty: any = 0;
    order_detail.forEach(row => {
      req_qty += parseInt( row.req_qty || 0 );
    });

    if( req_qty == 0 ) {
      this.toastrService.info('교환 신청 할 상품의 수량을 선택하시기 바랍니다.');
    }

    // 사유가 업체부담일 경우
    if( req_reason == '1' || req_reason == '2' ) {
      calc_cnt = 0;

    // 사유가 고객부담일 경우
    } else if( req_reason == '3' || req_reason == '4' ) {

      // 직접발송 시
      if( req_type == '2000' ) {
        calc_cnt = 1;
        
      // 회수신청 시
      } else {
        calc_cnt = 2;

      }
    }

    this.pay_delivery = parseInt(this.delivery.delivery_amt) * calc_cnt;

    this.form.patchValue({
      req_qty_master: req_qty,
      pay_delivery: this.pay_delivery,
    });
  }

  /*******************************************************************************
    설  명 : 교환요청 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      if( parseInt(this.form.controls.req_qty_master.value) < 1 ) {
        this.toastrService.error('교환할 상품의 신청수량을 선택하시기 바랍니다.');
      } else {
        if( confirm('교환요청을 하시겠습니까?') ) {
          this.sOrderService.setExchangeSave( this.form ).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success(response.ResultMessage);
              this.activeModal.close(true);
              this.exchangeNotice();
            } else {
              this.toastrService.error(response.ResultMessage);
            }
          });
        }
      }
    } else {
      this.utilService.showToast(this.form, this.formErrors);
    }

  }

  /*******************************************************************************
    설  명 : 교환확인공지
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  exchangeNotice() {
    const modalRef = this.modalService.open(exchangeNoticeComponent, options);

    modalRef.result.then((result) => {
    }, (reason) => {
    });  
  }

  /*******************************************************************************
    설  명 : 교환사유 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedOptionReason( event: any ) {
    this.setCalc();
  }
  
  /*******************************************************************************
    설  명 : 교환 방식 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedOptionType( event: any ) {
    this.setCalc();
  }

}
