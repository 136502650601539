<div class="member-page">
  <div class="member-inner">
    <div class="logo">
      <a routerLink="/"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
    </div>
    
    <form [formGroup]="formSMS">
      <div class="title">비밀번호찾기</div>
      <div class="info-txt"><p class="dot">·</p><p class="dot1">아이디 입력 시 영문 대소문자 및 공백을 잘 구분 입력 하였는지 확인 바랍니다.</p></div>
      <label>
        <span>아이디</span>
        <input type="text" formControlName="id" [ngClass]="{formErrors:formErrors['id']}">
        <div *ngIf="formErrors['id']" class="error-message">{{formErrors['id']}}</div>
      </label>
      <label class="tel" for="hp1">
        <span>전화번호</span>
        <select formControlName="hp1" [ngClass]="{formErrors:formErrors['hp1']}">
          <option value="010">010</option>
          <option value="011">011</option>
          <option value="016">016</option>
          <option value="017">017</option>
          <option value="018">018</option>
          <option value="019">019</option>
        </select>
        -
        <input type="text" formControlName="hp2" maxlength="4" [ngClass]="{formErrors:formErrors['hp2']}" placeholder="">
        -
        <input type="text" formControlName="hp3" maxlength="4" [ngClass]="{formErrors:formErrors['hp3']}" placeholder="">
        <button type="button" class="basic-btn sm-btn" (click)="authConfirm()" [disabled]="isAuth">인증번호받기</button>
      </label>
      <label>
        <span>인증번호 입력</span>
        <input type="text" formControlName="authNum" [ngClass]="{formErrors:formErrors['authNum']}">
        <div *ngIf="formErrors['authNum']" class="error-message">{{formErrors['authNum']}}</div>
      </label>

      <div class="guide-txt hidden">
        <div class="title">· 메일이 전송이 안되는 현상</div>
        <div>
          <p class="dot">-</p><p class="dot1">정상적으로 메일 발송이 이루어지고 있으나, 간혹 고객님의 컴퓨터 또는 사내 보안정책에 따라서<br> 메일발송이 지연/차단 되는 경우가 있습니다.</p>
          <p class="dot">-</p><p class="dot1">이럴 경우에는 부득이 보안정책이 없는 타 컴퓨터를 통해 이용해주시길 권해드립니다.</p>
        </div>
      </div>
        
      <button type="button" class="basic-btn red cp" (click)="submit()" [disabled]="isSubmit">확인</button>
    </form>
    
    <div class="member-footer">
      <ul class="clearfix">
        <li><a routerLink="/company/policy/service">이용약관</a></li>
        <li><a routerLink="/company/policy/privacy" class="bold">개인정보처리방침</a></li>
        <li><a routerLink="/customer/center">고객센터 1544-5022</a></li>
      </ul>
      <div>Copyright © 2008 <span class="bold">bikemart.co.kr</span> ALL RIGHTS RESERVED.</div>
    </div>    
  </div>
</div>
