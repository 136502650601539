<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>
  
  <div class="mypage-contents">
    <div class="section order-list">
      <div class="title page-title">구매내역/배송조회</div>

	    <div class="mypage-search-area form-inline">
	      <span class="bold pc-view">기간</span>

        <div class="btn-group ml10 mr10">
          <button *ngFor="let item of searchdate_group" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.searchdate_group==item.value}" (click)="onSearchDateClick(item.value)">{{item.title}}</button>
        </div>

	      <input type="text" id="sdate" [(ngModel)]="search.sdate" class="form-control form-control-small pc-view" ngbDatepicker [footerTemplate]="footerTemplate" #d1="ngbDatepicker" [readonly]="true" (click)="d1.toggle()" />
	      <ng-template #footerTemplate>
	        <hr class="my-0">
	        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d1, 1)">오늘</button>
	        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d1.close()">닫기</button>
	      </ng-template>
	      <span class="pl5 pr5 pc-view">~</span>
	      <input type="text" id="edate" [(ngModel)]="search.edate" class="form-control form-control-small ml5  pc-view" ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker" [readonly]="true" (click)="d2.toggle()" />
	      <ng-template #footerTemplate2>
	        <hr class="my-0">
	        <button type="button" class="btn btn-primary btn-small m-2 float-left" (click)="getToday(d2, 2)">오늘</button>
	        <button type="button" class="btn btn-secondary btn-small m-2 float-right" (click)="d2.close()">닫기</button>
	      </ng-template>
	      
	      <select (change)="selectedStatus($event)" class="form-control form-control-small ml10" >
	        <option value='' ng-selected="true">전체상태</option> <!-- selected 자동선택 기능 해결해야함-->
	        <option *ngFor="let row of orderStatusList" value="{{row.common_code}}">{{row.common_name}}</option>
	      </select>

        <input type="text" [(ngModel)]="search.product_seq" class="form-control form-control-small pc-view ml10" placeholder="상품번호"/>
	      
	      <button type="button" class="basic-btn sm-btn pc-view" (click)="getOrderList()">조회</button>

	    </div>

      <table class="list-table pc-view">
      <colgroup>
        <col style="width: 13%">
        <col style="width: 45%">
        <col style="width: 14%">
        <col style="width: 14%">
        <col style="width: 14%">
      </colgroup>    
  
      <thead>
      <tr>
        <th>주문일/주문번호</th>
        <th>상품정보</th>
        <th>주문금액</th>
        <th>상태</th>
        <th>확인/요청</th>
      </tr>
      </thead>
      
        <tbody>
          <ng-container *ngFor="let row of orderList; let i = index">
            <tr *ngFor="let details of row.detail;let j = index">
              <td *ngIf="j === 0" [attr.rowspan]="row.detail.length" class="date" >
                {{row.order_date}}<br/>{{row.seq}}
                <a class="basic-btn sm-btn round cp" (click)='goDetail(row.seq)'>주문상세보기</a>
              </td>
              <td class="info">
                <div class="img cp" (click)="goProduct(details)"><img src="{{details.thumbnail_url}}"></div>
                <div class="info1 cp">
                  <p (click)="goProduct(details)">{{details.product_name | nohtml}}</p>
                  <ng-container *ngFor="let rowDetail of details.product;let k = index;">
                    <p *ngIf="rowDetail.qty > 0 && rowDetail.refundQty <= 0" class="option" (click)="goProduct(details)">색상 : {{rowDetail.display_color_name}} / 사이즈 : {{rowDetail.display_size_name ? rowDetail.display_size_name : '단품'}} / {{rowDetail.qty}}개 / &nbsp;&nbsp;&nbsp;&nbsp; 반품,교환 가능한 수량 {{rowDetail.qty - rowDetail.used_req_qty}}</p>
                  </ng-container>
                  <div class="add-option" (click)="goProduct(details)">
                    <!-- <div *ngIf="row.event_seq != 0">추가옵션1 : </div> -->
                  </div>
                  <!-- <p class="amt">{{details.sum_total_amt | number}}원</p> -->
                  <a class="basic-btn sm-btn round cp review" *ngIf="details.product[0].review_seq == '0' && row.order_status == 9" (click)="review(details)"><span class="red">구매후기작성</span></a>
                </div>
              </td>
              <td *ngIf="j === 0" [attr.rowspan]="row.detail.length">
                <p class="order_amt">{{getPayAmt(row) | number}} 원</p>
              </td>
              <td *ngIf="j === 0" [attr.rowspan]="row.detail.length">
                {{row.order_status_name}}
                <!-- <p *ngIf="row.out_date">(발송일자 : {{row.out_date}})</p> -->
              </td>
              <td *ngIf="j === 0" [attr.rowspan]="row.detail.length">
                <ng-container *ngIf="row.order_status == 0">
                  <a class="basic-btn sm-btn round cp" (click)="cancel(row)"><span class="red">주문취소</span></a>
                </ng-container>
                <ng-container *ngIf="row.order_status == 8 && row.receipt_confirm == '0'">
                  <a class="basic-btn sm-btn round cp" (click)="received(row)"><span class="red">수취확인</span></a>
                </ng-container>
                <ng-container *ngIf="row.order_status == 8 && row.receipt_confirm == '1'">
                  <a class="basic-btn sm-btn round cp" (click)="purchaseConfirm(row)"><span class="red">구매확정</span></a>
                  <ng-container *ngIf="row.order_date.substr(0,10) >= '2021-09-12'">
                    <a class="basic-btn sm-btn round cp" (click)="return(row)">반품요청</a>    
                    <a class="basic-btn sm-btn round cp" (click)="exchange(row, i)">교환요청</a>
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="orderList.length < 1">
            <tr>
              <td colspan="5">내용이 없습니다</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
  
      <!-- 모바일 view -->
      <div class="list-group mobile-view" *ngFor="let row of orderList; let i = index">
        <div class="date clearfix">
          <div>{{row.order_date}}</div>
          <a (click)="goDetail(row.seq)" class="cp">상세보기</a>
        </div>
        <ul>
          <li>
            <div class="order-list1 clearfix">
              <div class="red">{{row.order_status_name}}</div>
              <!-- <span class="consign" *ngIf="row.out_date">(발송일자 : {{row.out_date}})</span> -->
              <span class="amt">{{getPayAmt(row) | number}} 원</span>
            </div>
            <ng-container *ngFor="let details of row.detail;let j = index">
              <div class=" info clearfix" >
                <div class="img" (click)="goProduct(details)"><img src="{{details.thumbnail_url}}"></div>
                <div class="info1">
                  <p class="info1-1" (click)="goProduct(details)">{{details.product_name | nohtml}}</p>
                  <ng-container *ngFor= "let rowDetail of details.product;let k = index;">
                    <p *ngIf="rowDetail.qty > 0 && rowDetail.refundQty == null" class="info1-2" (click)="goProduct(details)">색상 : {{rowDetail.display_color_name}} / 사이즈 : {{rowDetail.display_size_name ? rowDetail.display_size_name : '단품'}} / {{rowDetail.qty}}개 / 반품,교환 가능한 수량 {{rowDetail.qty - rowDetail.used_req_qty}}</p>
                  </ng-container>
                  <div class="add-option">
                    <!-- <div *ngIf="row.event_seq != 0">추가옵션1 : </div> -->
                  </div>
                  <!-- <p class="info1-3">{{details.sum_total_amt | number}}원</p>   -->
                  <a class="basic-btn sm-btn round cp mb5 review1" *ngIf="details.product[0].review_seq == '0' && row.order_status == '9'" (click)="review(details)"><span class="red">구매후기작성</span></a>
                </div>
              </div>
              <ng-container *ngIf="j == row.detail.length-1">
                <div class="btn-div" *ngIf="row.order_status == 0">
                  <a class="basic-btn sm-btn round cp mr5 mt10" (click)="cancel(row)"><span class="red">주문취소</span></a>  
                </div>
                <div class="btn-div" *ngIf="row.order_status == 8 && row.receipt_confirm == '0'">
                  <a class="basic-btn sm-btn round cp mb5" (click)="received(row)"><span class="red">수취확인</span></a>
                </div>
                <div class="btn-div" *ngIf="row.order_status == 8 && row.receipt_confirm == '1'">
                  <a class="basic-btn sm-btn round cp mb5 mr5" (click)="purchaseConfirm(row)"><span class="red">구매확정</span></a>
                  <div class="btn-div" *ngIf="row.order_date.substr(0,10) >= '2021-09-12'">
                    <a class="basic-btn sm-btn round cp mb5 mr5" (click)="return(row)">반품요청</a>    
                    <a class="basic-btn sm-btn round cp" (click)="exchange(row, i)">교환요청</a>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </li>	        
        </ul>  
      </div>

      <!-- 모바일 view -->
      
		  <div class="pagination-area" *ngIf="totalCount > 0">
		    <ngb-pagination
          [(page)]="search.pageNo"
          [pageSize]="search.pageRow"
          [maxSize]="5"
          [collectionSize]="totalCount"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
		      <ng-template ngbPaginationFirst>처음</ng-template>
		      <ng-template ngbPaginationLast>마지막</ng-template>
		      <ng-template ngbPaginationPrevious>이전</ng-template>
		      <ng-template ngbPaginationNext>다음</ng-template>
		    </ngb-pagination>
		  </div>   
		  
		  <div class="guide">
	      <div class="title">안내사항</div>
					<p class="dot">·</p><p class="dot1">입금 및 카드 결제완료된 주문 건에 한해서만 배송을 하므로 따로 주문서 취소를 할 필요는 없습니다.</p>
					<p class="dot">·</p><p class="dot1">주문서 리스트에서 상세보기를 하시면 배송조회, 현금영수증 발급 및 카드내역조회를 하실 수 있습니다.</p>
					<p class="dot">·</p><p class="dot1">현금영수증 발행 시 세금계산서 중복 발행은 불가합니다. 현금영수증 지출증빙용은 매입세금계산서와 동일하게 부가세 공제를 받을 수 있습니다.</p>
          <p class="dot">·</p><p class="dot1">현금영수증은 현금결제 완료 후 15일 내에만 신청이 가능합니다.</p>
					<p class="dot">·</p><p class="dot1">결제방법을 무통장입금으로 선택하였더라도 ‘카드변경결제’를 통해 주문서 재작성 없이 카드결제로 변경 이용하실 수 있습니다.</p>
					<p class="dot">·</p><p class="dot1">결제방법을 카드로 선택하였지만, 카드결제가 정상적으로 승인이 나지 않아 미결제되어 ‘카드결제실패’된 경우 ‘카드재결제’를 통해 주문서 재작성 없이 카드결제를 하실 수 있습니다.</p>
					<p class="dot">·</p><p class="dot1">서비스변경작업으로 배송조회 시 2006년 6월 이전데이터는 정확하지 않을 수 있습니다. 개별확인이 필요하시면 고객센터로 문의하여 주십시오</p>
		  </div>
		        
    </div>
         
  </div>   
</div>

<app-footer></app-footer>