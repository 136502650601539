import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SMemberService {



  public findIdResult: any = [];
  public findPwResult: any = '';
  public memberInfo: any;
  public pw_confirm: boolean = false;

  constructor(
    private restful: RestfulService,
  ) { }



  // 로그인정보 가져오기
  getMemberInfo(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMember'
      }, {
      }
    ).then( response => {
      if( response.ResultCode )
        this.memberInfo = response.data;
      else
        this.memberInfo = [];

      return response || [];
    })
     .catch(this.restful.handleErrorPromise);
  }

  // 회원 로그인
  setLogin( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setLogin'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 휴면회원 회원정보 복원 동의  
  setMemberDormantCheck( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setMemberDormantCheck'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }  

  // 회원 로그아웃
  setLogout(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setLogout'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 패스워드 검사
  checkPwConfirm( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'checkPwConfirm'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 중복아이디 검사
  checkIdDuplicate( id: string ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'checkIdDuplicate'
      }, {
        id: id
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 아이디 찾기
  setFindId( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setFindId'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 아이디 찾기
  setFindIdWithEmail( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setFindIdWithEmail'
      }, form.value
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 비밀번호 찾기
  setFindPw( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setFindPw'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 회원정보 저장
  setMemberSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setMemberSave'
      }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 회원탈퇴
  setMemberOut( form: any ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'setMemberOut'
    }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 사업자번호 중복 검사  
  checkBizNoDuplicate( biz_no: string ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'checkBizNoDuplicate'
      }, {
        bizNo: biz_no
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 업체회원전환/변경
  setMemberTransitionApplication( form: any ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'setMemberTransitionApplication'
    }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 휴면회원 해제
  setMemberDormantTransform( mem_no: any ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'setMemberDormantTransform'
    }, {
      mem_no: mem_no
    }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 회원 배송지 정보 가져오기
  getMemberAddressList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMemberAddressList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 회원 정보 및 기본배송지 정보 가져오기
  getMemberAddressInfo(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMemberAddressInfo'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 회원 배송지 저장
  setMemberAddressSave( form: any ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'setMemberAddressSave'
    }, form.value
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 기본 배송지로 설정
  setMemberDefaultAddressSave( seq: number ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'setMemberDefaultAddressSave'
    }, {
      addressSeq: seq
    }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 기본 배송지로 설정
  setMemberAddressDelete( seq: number ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'setMemberAddressDelete'
    }, {
      addressSeq: seq
    }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 회원 남은 마일리지 정보 가져오기
  getSumRemainedMileage(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getSumRemainedMileage'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  // 회원의 사용가능한 쿠폰 수량 가져오기
  getSumAvailableCoupon(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getSumAvailableCoupon'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  
  // 마일리지 사용 정보 가져오기
  getMileage( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMileage'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 사용가능 쿠폰리스트 가져오기 
  getCouponList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getCouponList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }


  // 지역 가져오기
  getRegion(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getRegion'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 동호회리스트 가져오기
  getSocietyList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getSocietyList'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 지역별 동호회리스트 가져오기
  getRegionSocietyList(region:any): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getRegionSocietyList'
      }, {region:region}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 동호회 추천코스 리스트 가져오기
  getSocietyCourseList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getSocietyCourseList'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

   // 동호회 추천코스 리스트 가져오기
   getSocietyCafeList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getSocietyCafeList'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  } 

  // 동호회 새소식 리스트 가져오기
  getSocietyNewsList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getSocietyNewsList'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }





  // 동호회 등록 
  setSocietyMember( society_seq: any ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'setSocietyMember'
    }, {
      society_seq: society_seq
    }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }  

  // 동호회 헤더 정보 가져오기
  getSocietyHeaderInfo(society_seq: any): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getSocietyHeaderInfo'
      }, {society_seq: society_seq}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }
  // 로그인 회원의 동호회 정보 가져오기
  getMemberSocietyInfo(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMemberSocietyInfo'
      }, {}
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

    // 동호회 회원리스트 가져오기
    getSocietyMemberList(): Promise<any> {
      return this.restful.get({
          program: 'shop',
          service: 'member',
          version: '1.0',
          action: 'getSocietyMemberList'
        }, {}
      ).then(this.restful.extractData)
        .catch(this.restful.handleErrorPromise);
    }

  // 동호회 등록 
  delSocietyMember( society_seq , mem_no: any ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'delSocietyMember'
    }, {
      society_seq: society_seq ,
      mem_no: mem_no 
    }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  } 



  // 동호회 등록 
  appSocietyMember( society_seq , mem_no: any ): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'member',
      version: '1.0',
      action: 'appSocietyMember'
    }, {
      society_seq: society_seq ,
      mem_no: mem_no 
    }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  } 





}
