/*******************************************************************************
  설  명 : 회사소개 - 바이크마트 등록 동호회 신청
  생성일 : 2024-03-29
  생성자 : 권세창
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CompanyService } from '@app/service/company.service';
import { AuthService } from '@app/service/auth.service';
import { SMemberService } from '@app/service/member.service';
import { CommonService } from '@app/service/common.service';

@Component({
  selector: 'app-company-society',
  templateUrl: './company-society.component.html',
  styleUrls: ['./company-society.component.scss']
})
export class CompanySocietyComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isLogIn: boolean;
    isLogin$: Observable<boolean>; // 로그인여부확인

  public form: FormGroup;
  public formErrors = {};

  public societyList: any = [];
  public memberInfo: any = {};
  public regionList: any = [];

  public isMobileApp: Boolean = false;

  public society_id = -1;


  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private memberService: SMemberService,
    private commonService: CommonService,
  ) {

      // 로그인 여부 가져오기
      this.isLogin$ = this.authService.isLoggedIn;

      this.isLogin$.subscribe( login => {
        this.isLogIn = login;
      });
    
    this.form = this.formBuilder.group({
      society_name: ['', [Validators.required]],
      region_cd: ['', [Validators.required]] , 
      society_description: ['',[Validators.required]],
      application_memo: ['', [Validators.required]] , 
      agreement: [false, [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.getSocietyList();
    this.getCommonList();

    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

  /*******************************************************************************
    설  명 : 가맹/입점문의 데이터 가져오기
  *******************************************************************************/
    getSocietyList() {
    this.memberService.getSocietyList().then( response => {
      if( response.ResultCode ) {
        this.societyList = response.data;
        this.toastrService.success(response.ResultMessage, '');
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }


  /*******************************************************************************
    설  명 : 동호회 등록 신청 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      console.log('개인정보동의확인') ; 
      console.log( this.form.value) ; 
      console.log( this.form.value.agreement) ; 
      if( this.form.value.agreement === false ) {
        this.toastrService.error('개인정보취급방침에 동의하셔야 등록이 가능합니다.', '동호회 등록 신청');
      } else {
        this.companyService.setSocietyApplication(this.form).then( response => {
          if (response.ResultCode) {
            this.toastrService.success(response.ResultMessage);
            this.form.reset();
          } else {
            this.toastrService.error('동호회 등록 신청에 실패하였습니다.', '동호회 등록 신청');
          };
        });
      }
    }
  }

    /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getCommonList() {
      // 지역구분
      this.commonService.getCommonListCode('ADDR').then( response => {
        if ( response.ResultCode ) {
          this.regionList = response.data;
        } else {
          this.regionList = [];
        }
      });
    }  

  /*******************************************************************************
    설  명 :  동호회설명 ellipsis
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ellipsisId(id) {
    if (this.society_id == id ) {
      this.society_id = -1;
    } else {
      this.society_id = id;
    }
  }  

}
