<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>
  
  <div class="mypage-contents">
    <div class="section">
      <div class="section-header">
        <div class="title page-title">1:1 문의하기</div>
        <div class="right-btn fr" [ngClass]="{hide:detailView}">
          <a routerLink="/mypage/myqna/write" class="basic-btn sm-btn">1:1 문의하기</a>
        </div> 
      </div>
      
      <table class="list-table pc-view">
      <colgroup>
        <col style="width: 15%">
        <col style="width: 53%">
        <col style="width: 18%">
        <col style="width: 14%">
      </colgroup>    
    
      <thead>
      <tr>
        <th>유형</th>
        <th>문의제목</th>
        <th>문의날짜</th>
        <th>답변상태</th>
      </tr>    
      </thead>

      <tbody>
      <tr *ngFor="let row of boardList" id="tr_{{row.board_seq}}" (click)="viewDown(row)">
        <td>{{row.group_id}}</td>
        <td class="tl">
          <span *ngIf="row.depth=='1' && row.board_id=='question'">└&nbsp;</span>
          <span *ngIf="row.depth=='2' && row.board_id=='question'">　└&nbsp;</span>
          <span *ngIf="row.depth=='3' && row.board_id=='question'">　　└&nbsp;</span>
          <span *ngIf="row.depth=='4' && row.board_id=='question'">　　　└&nbsp;</span>
          <span *ngIf="row.depth=='5' && row.board_id=='question'">　　　　└&nbsp;</span>   
          {{row.subject}}
        </td>
        <td>{{row.write_date.substr(0,10)}}</td>
        <td>
          <span class="badge badge-success p5" *ngIf="row.depth == '0' && row.replyCount == 1">진행중</span>
          <span class="badge badge-primary p5" *ngIf="row.depth == '0' && row.replyCount > 1">답변완료</span>
        </td>
      </tr>
      <tr *ngIf="boardList.length < 1">
        <td colspan="4">내용이 없습니다</td>
      </tr>
      </tbody>
      </table> 
      
      <div class="mobile-view">
        <table class="list-table">
        <colgroup>
          <col style="width: 25%">
          <col style="width: 75%">
        </colgroup>    
        <tbody>
          <tr *ngFor="let row of boardList" id="tr_{{row.board_seq}}" (click)="viewDown(row)">
            <td>
              <span class="badge badge-success p5" *ngIf="row.status == false">진행중</span>
              <span class="badge badge-primary p5" *ngIf="row.status == true">답변완료</span>
            </td>
            <td class="tl">
              <div>{{row.group_id}}</div>
              <div class="name">{{row.subject}}</div> 
              <div>{{row.write_date.substr(0,10)}}</div>
            </td>
          </tr>
          <tr *ngIf="boardList.length < 1">
            <td colspan="4">내용이 없습니다</td>
          </tr>
        </tbody>
        </table>
      </div>         
      
      <div class="pagination-area" [ngClass]="{hide:detailView}">
        <ngb-pagination
          [(page)]="params.no"
          [pageSize]="params.row"
          [collectionSize]="totalCount"
          [boundaryLinks]="true"
          (pageChange)="loadPage($event)"
          >
          <ng-template ngbPaginationFirst>처음</ng-template>
          <ng-template ngbPaginationLast>마지막</ng-template>
          <ng-template ngbPaginationPrevious>이전</ng-template>
          <ng-template ngbPaginationNext>다음</ng-template>
        </ngb-pagination>
      </div>      
    </div>    
  </div>   
</div>

<app-footer></app-footer>