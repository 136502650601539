import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SOrderService } from '@app/service/order.service';

declare global {
  interface Window {
    INIStdPay: any;
  }
}

@Component({
  selector: 'app-inipay-close',
  templateUrl: './inipay-close.component.html',
  styleUrls: ['./inipay-close.component.scss']
})
export class InipayCloseComponent implements OnInit {

  public tmpSeq: any = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private sOrderService: SOrderService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.tmpSeq = ( typeof params.tmpSeq == 'undefined' || params.tmpSeq == '' ) ? '' : params.tmpSeq;

      if( this.tmpSeq !== '' ) {
        this.setOrdertmpDetele();
      }
    });
  }

	ngAfterViewInit() {
		parent.INIStdPay.viewOff();
	}

  /*******************************************************************************
    설  명 : 주문/결제 정보 임시저장 테이블 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  setOrdertmpDetele() {
    this.sOrderService.setOrdertmpDetele( this.tmpSeq ).then( response => {} );
  }
}
