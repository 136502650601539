import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SCartService {

  cartBadge: BehaviorSubject<number> = new BehaviorSubject(0);

  get getCartBadge() {
    return this.cartBadge.asObservable();
  }

  constructor(
    private restful: RestfulService,
  ) { }

	/*******************************************************************************
		설  명 : 카트 배지 수량 표시
	*******************************************************************************/
	setCartBadge( data ) {
		this.cartBadge.next( data );
	}

	/*******************************************************************************
		설  명 : 카트 배지 수량 초기화
	*******************************************************************************/
	setInitCartBadge() {
		this.cartBadge.next(0);
	}

  // 장바구니 리스트 가져오기
  getCartList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'cart',
        version: '1.0',
        action: 'getCartList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 장바구니 상품 리스트 정보 가져오기
  getCartProductInfoList( data: any, couponCode: any , couponSeq:any): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'cart',
        version: '1.0',
        action: 'getCartProductInfoList'
      }, {
        cartList: data,
        coupon_code: couponCode , 
        membercoupon_seq : couponSeq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);

  }

  // 장바구니 담기
  setCartSave( params: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'cart',
        version: '1.0',
        action: 'setCartSave'
      }, {
        data: params
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 장바구니 한줄 삭제
  deleteCartRow( seqlist: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'cart',
        version: '1.0',
        action: 'deleteCartRow'
      }, {
        seqlist: seqlist
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 장바구니 한줄 삭제
  deleteCartOptionRow( cart_seq: any, product_property_seq: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'cart',
        version: '1.0',
        action: 'deleteCartOptionRow'
      }, {
        cart_seq: cart_seq,
        product_property_seq: product_property_seq
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 옵션변경/ 추가
  setOptionChange( params: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'cart',
        version: '1.0',
        action: 'setOptionChange'
      }, {
        data: params
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

}
