<app-header class="pc-view"></app-header>

<div class="inner clearfix">
  <!-- 왼쪽 마이페이지 메뉴 -->
  <app-mypage-menu class="pc-view"></app-mypage-menu>
  <app-mypage-header class="pc-view"></app-mypage-header>
  
  <div class="mypage-contents">
    <div class="page-header mobile-view">
      <a routerLink="/mypage/dashboard" class="back-btn"></a>
      <div class="title page-title">주문상세</div>
    </div>  
    <div class="guide mt30">
      <div class="title">안내사항</div>
      <p class="dot">·</p><p class="dot1">현금영수증은 현금결제 완료 후 15일 내에만 신청이 가능합니다.</p>
      <p class="dot">·</p><p class="dot1">구매확정 후 마일리지 적립 및 사용이 가능합니다. </p>
    </div>
    <div class="section order-list">
      <div class="title pc-view">구매 상세 내역</div>
      <div class="right-btn">
        <!-- <button class="basic-btn round" (click)="specificationsTransaction();">거래명세서</button> -->
        <button class="basic-btn round" (click)="goList()">목록으로</button>
        <button *ngIf="inicisSeq !== null" class="basic-btn round" style="width: 100px;" (click)="getReceipt()">매출전표(영수증)</button>
        <button class="basic-btn round" (click)="getOrderList()">주문내역서</button>
        <button *ngIf="checkCashReceipt(payCashInfo)" class="basic-btn round" (click)="goCashReceipt()">현금영수증 신청</button>
      </div>

      <table class="list-table pc-view">
      <colgroup>
        <col style="width: 13%">
        <col style="width: 46%">
        <col style="width: 11%">
        <col style="width: 15%">
        <col style="width: 15%">
      </colgroup>    
  
      <thead>
      <tr>
        <th>주문일<br/>주문번호</th>
        <th>상품정보 / 상품금액</th>
        <th>상태</th>
        <th>택배사정보</th>
        <th>결제정보</th>
      </tr>    
      </thead>
      
      <tbody>
        <tr *ngFor="let item of detailList; let i = index">
          <td *ngIf="i === 0" [attr.rowspan]="detailList.length" class="date">
            {{item.order_date}}<br/>{{item.seq}}
          </td>
          <td class="info">
            <div class="img cp" (click)="goProduct(item.product_seq)"><img [src]="item.thumbnail_url" alt="{{item.product_name}}" /></div>
            <div class="info1 cp" (click)="goProduct(item.product_seq)">
                <p>{{item.product_name | nohtml}}</p>
              <p class="amt">{{item.order_amt | number}}원</p>
              <ng-container *ngFor="let option of item.children">
                <p *ngIf="option.qty > 0 && option.refundQty <= 0 " class="option">색상 : {{option.display_color_name}} / 사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty}} 개</p>
              </ng-container>
              <!-- <div class="add-option">
                <div>추가옵션1 : </div>
              </div> -->
            </div>
          </td>
          <td *ngIf="i === 0" [attr.rowspan]="detailList.length">
            <span>{{orderInfo.order_status_name}}</span>
          </td>
          <td *ngIf="i === 0" [attr.rowspan]="detailList.length">
            <ng-container *ngFor="let row of deliveryList; let i = index;">
              <br *ngIf="i > 0" />
              <span>{{row.delivery_company_name}}</span><br/>
              <a class="cp" (click)="delivery(row)" style="text-decoration: underline;">{{row.delivery_no}}</a><br/>
              <span>({{row.out_date}})</span>
            </ng-container>
          </td>
          <td *ngIf="i === 0" [attr.rowspan]="detailList.length">
            <ng-container *ngIf="orderInfo.order_status == 0 && item.account_seq">
              <span class="bold">무통장입금</span><br/>
              {{item.bank_name}}<br/>
              {{item.bank_account}}<br/>
              (입금자명 : {{item.receipt_name}})<br/>
            </ng-container>
            <ng-container *ngFor="let item of payList">
              <span class="bold" [ngClass]="{cred: item.app_gbn=='2'}">{{item.pay_method_name}} {{item.pay_amt | number}} 원</span><br/>
              <ng-container *ngIf="item.pay_method == '0001'">
              {{item.card_name1}}<br *ngIf="item.card_name1" />
              {{item.card_no ? '(' + item.card_no + ')' : item.card_no}}<br *ngIf="item.card_no" />
              </ng-container>
              ({{item.pay_date}})<br/>
            </ng-container>
          </td>
	      </tr>
      </tbody>
    </table> 
      
      <!-- 모바일 view --> 
      <div class="list-group mobile-view">
        <div class="date clearfix">
          <div>{{orderInfo.order_date}}</div>
          <div>주문번호 : {{orderInfo.seq}}</div>
        </div>
        <ul>
          <li>
            <div class="order-list1 clearfix">
              <div class="red">{{orderInfo.order_status_name}}</div>
              <button *ngIf="inicisSeq == null" class="basic-btn sm-btn round cp mr5" style="width: 90px;" (click)="goCashReceipt();">현금영수증 신청</button>
              <button *ngIf="inicisSeq != null" class="basic-btn sm-btn round cp mr5" style="width: 95px;" (click)="getReceipt()">매출전표(영수증)</button>
            </div>
            <div *ngFor="let item of detailList; let i = index" class=" info clearfix">
              <div class="img" (click)="goProduct(item.product_seq)"><img [src]="item.thumbnail_url"></div>
              <div class="info1" (click)="goProduct(item.product_seq)">
                <p class="info1-1">{{item.product_name | nohtml}}</p>
                <ng-container *ngFor="let option of item.children">
                  <p *ngIf="option.qty > 0 && option.refundQty == null" class="info1-2">색상 : {{option.display_color_name}} / 사이즈 : {{option.display_size_name ? option.display_size_name : '단품'}} / {{option.qty}} 개</p>
                </ng-container>
		            <div class="add-option">
		              <!-- <div>추가옵션1 : 이월특가 마감임박KOMINE JK-054COOL M-JKT-CRETE여성용/남성용 -118000원 (추가 5% 할인)</div> -->
		            </div>                  
                <p class="info1-3">{{item.order_amt | number}}원</p>
              </div>          
            </div>
          </li>       
        </ul>  
        <div class="pay-method clearfix" *ngIf="orderInfo.order_status == 0 && orderInfo.account_seq">
          <div class="bold">무통장입금</div>
          <div>
            <p>{{orderInfo.bank_name}}</p>
            <p>{{orderInfo.bank_account}}</p>
            <p>(입금자명 : {{orderInfo.receipt_name}})</p>
          </div>
        </div>
        <div class="delivery-info clearfix">
          <div class="bold mb10">택배사정보</div>
          <div>
            <p *ngFor="let row of deliveryList; let i = index;">
              <a class="cp" (click)="delivery(row)" style="text-decoration: underline;">{{row.delivery_company_name}} {{row.delivery_no}} ({{row.out_date}})</a>
            </p>
          </div>
        </div>
      </div>

      <!-- 모바일 view -->      
      
      <div class="total-info">
        <ul>
          <li>
            <div class="title">총 주문금액</div>
            <div class="amt">{{total_order | number}}<span>원</span></div>
          </li>
          <li class="sign-plus">+</li>
          <li>
            <div class="title">배송비</div>
            <div class="amt">{{total_delivery | number}}<span>원</span></div>
          </li>
          <li class="sign-minus">-</li>
          <!-- <li>
            <div class="title">할인금액</div>
            <div class="amt red">{{discount_amt | number}}<span>원</span></div>
          </li> -->
          <li>
            <div class="title">마일리지 사용</div>
            <div class="amt red">{{(orderInfo.out_mileage ? orderInfo.out_mileage : 0) | number}}<span>원</span></div>
          </li>
          <li class="sign-equal">=</li>
          <li class="tot-amt">
            <div class="title">총 결제 금액</div>
            <div class="amt">{{total_order - (orderInfo.out_mileage * 1) + total_delivery*1 | number}}<span>원</span></div>
          </li>
        </ul>
      </div>         
    </div>
  
    <!-- 주문자 정보 -->
    <div class="section">
      <div class="title">주문자 정보</div>   

      <table class="info-table info-table1">
      <colgroup>
        <col class="col1">
        <col>
      </colgroup>  
      <tbody>
      <tr>
        <th>주문자 이름</th>
        <td>{{orderInfo.o_name}}</td>     
      </tr>
      <tr>  
        <th>입금자명</th>
        <td>{{orderInfo.receipt_name}}</td> 
      </tr>
      <tr>  
        <th>주문자 연락처</th>
        <td>연락처1 : {{orderInfo.o_hp}} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 연락처2 : {{orderInfo.o_hp2}}</td>
      </tr>
      <tr>  
        <th>주문자 이메일주소</th>
        <td>{{orderInfo.o_email}}</td>
      </tr>    
      </tbody>
      </table>
    </div>

    <!-- 배송지 정보 -->
    <div class="section">
      <div class="title">배송지 정보</div>   

      <table class="info-table info-table1">
      <colgroup>
        <col class="col1">
        <col>
      </colgroup>  
      <tbody>
      <tr>
        <th>받는 사람 이름</th>
        <td>{{orderInfo.r_name}}</td>     
      </tr>
      <tr>  
        <th>연락처</th>
        <td>연락처1: {{orderInfo.r_hp}} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 연락처2: {{orderInfo.r_hp2}}</td> 
      </tr>
      <tr>  
        <th class="vp">주소</th>
        <td>{{orderInfo.r_address}}&nbsp;&nbsp;{{orderInfo.r_address_detail}}</td> 
      </tr>
      <tr>  
        <th>배송시 요청사항</th>
        <td>{{orderInfo.r_comment}}</td>                            
      </tr> 
      </tbody>
      </table>
    </div>
  
    <div class="btn-area pc-view">
      <a class="basic-btn cp" (click)="goList()">목록</a>
    </div>      
  </div>    
</div>

<app-footer></app-footer>