<app-header></app-header>

<!-- <div class="inner" *ngIf="boardInfo.board_id=='notice'"> -->
<div class="inner">
    <div class="page-header clearfix">
    <div class="page-title">{{boardInfo.board_name}}</div>
    <div class="search-area">
      <input type="text" [(ngModel)]="params.text" (keypress)="searchList($event)" placeholder="검색어를 입력하세요" />
      <button type="button" class="btn1" (click)="getBoardList()">검색</button>
    </div>
  </div>

  <table class="list-table">
  <colgroup class="pc-view">
    <col style="width: 10%">
    <col style="width: 60%">
    <col style="width: 15%">
    <col style="width: 15%">
  </colgroup>

  <thead>
  <tr>
    <th>번호</th>
    <th>제목</th>
    <th>등록일</th>
    <th>조회수</th>
  </tr>
  </thead>

  <tbody>
    <tr *ngFor='let row of boardList' [ngClass]="{important:row.is_notice==1}">
      <td *ngIf="row.is_notice==1" class="red" >중요</td>
      <td *ngIf="row.is_notice==0" >{{row.board_seq}}</td>
      <td *ngIf="row.is_notice==1" class="tl title important" (click)="view(row)">
        {{row.subject}}
        <div class="mobile-view">{{row.write_date.substr(0,10)}}</div>
      </td>
      <td *ngIf="row.is_notice==0" class="tl title" (click)="view(row)">
        {{row.subject}}
        <div class="mobile-view">{{row.write_date.substr(0,10)}}</div>
      </td>
      <td>{{row.write_date.substr(0,10)}}</td>
      <td>{{row.hit}}</td>
    </tr>
    <tr *ngIf="boardList.length < 1">
      <td colspan="6" class="center">검색된 내용이 없습니다.</td>
    </tr>
  </tbody>
  </table>
</div>


<div class="inner" *ngIf="boardInfo.board_id=='faq'">
  <div class="page-header clearfix">
    <div class="page-title">자주하는 질문</div>
  </div>

  <div class="search-area1">
    <span class="pc-view">질문검색</span>
    <select [(ngModel)]="params.group" class="pc-view">
      <option value="subject" [selected]="params.group==='subject'">제목</option>
      <option value="contents" [selected]="params.group==='contents'">내용</option>
    </select>
    <input type="text" [(ngModel)]="params.text" (keypress)="searchList($event)" />
    <button type="button" class="find-btn" (click)="getBoardList()">검색</button>
  </div>

  <section *ngIf="boardInfo.lvt_group=='1'">
    <ul class="qna-kind clearfix pc-view">
      <li [ngClass]="{w180:groupTab.length > 8}" *ngFor="let item of groupTab" (click)="loadList(item)">
      <a data-toggle="tab" [ngClass]="{active:params.group_id==item}">{{item}}</a>
      </li>
    </ul>
    <div class="qna-kind mobile-view">
      <select (change)="groupSelect($event)">
        <option *ngFor="let item of groupTab" value="{{item}}">{{item}}</option>
      </select>
    </div>
  </section>

  <table class="list-table">
  <colgroup class="pc-view">
    <col style="width: 7%">
    <col style="width: 11%">
    <col style="width: 81%">
  </colgroup>

  <thead>
    <tr>
      <th>번호</th>
      <th>분류</th>
      <th>제목</th>
    </tr>
  </thead>

    <tbody>
      <tr *ngFor="let row of boardList" id="tr_{{row.board_seq}}">
        <td (click)="viewDown(row)">{{row.board_seq}}</td>
        <td (click)="viewDown(row)">{{row.group_id}}</td>
        <td class="tl title" (click)="viewDown(row)">{{row.subject}}</td>
      </tr>
      <tr *ngIf="boardList.length < 1">
        <td class="tl title mobile-view">내용이 없습니다</td>
        <td class="tl title pc-view" colspan="3">내용이 없습니다</td>
      </tr>
    </tbody>
  </table>

</div>

  <div class="pagination-area pc-view">
    <ngb-pagination
      [(page)]="params.no"
      [pageSize]="params.row"
      [maxSize]="maxSize"
      [collectionSize]="totalCount"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </div>

  <div class="pagination-area mobile-view">
    <ngb-pagination
      [(page)]="params.no"
      [pageSize]="params.row"
      [maxSize]="5"
      [collectionSize]="totalCount"
      [boundaryLinks]="true"
      (pageChange)="loadPage($event)"
      >
      <ng-template ngbPaginationFirst>처음</ng-template>
      <ng-template ngbPaginationLast>마지막</ng-template>
      <ng-template ngbPaginationPrevious>이전</ng-template>
      <ng-template ngbPaginationNext>다음</ng-template>
    </ngb-pagination>
  </div>



<app-footer></app-footer>