<ng-container *ngIf="isMobileApp === false">
  <app-header></app-header>
</ng-container>

<div class="society-page">
  <img src="/assets/images/society/society_info_pc.png" class="pc-view" alt="동호회란" />
  <img src="/assets/images/society/society_info_mobile.png" class="mobile-view" alt="동호회란" />
</div>

<ng-container *ngIf="isMobileApp === false">
  <app-footer></app-footer>
</ng-container>

