import { Component,  Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-society-detail-modal',
  templateUrl: './society-detail-modal.component.html',
  styleUrls: ['./society-detail-modal.component.scss']
})
export class SocietyDetailModalComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
    @Input() item: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {

    console.log(this.item);

  }

  closeModal() {
    this.activeModal.dismiss();
  }  

}
