/*******************************************************************************
  설  명 : 레이아웃 - 헤더(2022.08)
  생성일 : 
  생성자 : 
*******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as $ from 'jquery';

import { AuthService } from '@app/service/auth.service';
import { SCartService } from '@app/service/cart.service';
import { Globals } from '@app/service/globals.service';
import { SMemberService } from '@app/service/member.service';
import { SCategoryService } from '@app/service/category.service';
import { SProductService } from '@app/service/product.service';

@Component({
  selector: 'app-header202208',
  templateUrl: './header202208.component.html',
  styleUrls: ['./header202208.component.scss']
})
export class Header202208Component implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() isProject: boolean = false;
  @Output() goPageEmitter = new EventEmitter<any>();

  private subscription: Subscription = new Subscription;

  public isLogIn: boolean;

  public isLogin$: Observable<boolean>;   // 로그인여부확인

  public cartBadgeCnt: number = 0;      // 카트 배지 수량
  public bamaCouponCnt: number = 0;      // 바마쿠폰 수량

  public brandList: any = [];
  public categoryList: any = [];
  public purchasingAgentList: any = [];
  public bikePartsList: any = [];
  public searchTextList: any = [];
  public popularSearchText: any = [];
  public popularUpdateDate = '';

  public memberInfo: any = {};

  menuOpen: boolean = false; // 모바일 메뉴 보이기
  mcategoryIndex = 1; // 모바일 카테고리 선택

  public searchText: any = '';

  public remainedMileage = 0;

  categoryData = ['용품', '오토바이부품'];
  categoryData_depth1 = [
    '핸들용품',
    '커버',
    '배터리',
    '케미컬/세차용품',
    '오일',
    '도난방지',
    '스티커',
    '탱크패드',
    '백미러',
    '공구/파츠',
    '바이크악세사리',
    '엔진류',
    '차체파츠',
    '구동장치계열',
    '제동장치계열'
  ];
  categoryData_depth2 = [
    '핸들토시',
    '물병거치대',
    '핸드폰거치대',
    '핸들그립',
    '바앤드',
    '마스터실린더캡',
    '크로스바',
    '핸들가드',
    '스로틀어시스트',
    '백미러',
    '라이저',
    '케이블',
    '레버',
    '핸들시계'
  ];

  totalCategory = [
    // {label: '용품', value: '001', child: []},
    // {label: '오토바이부품', value: '002', child: []}
  ]

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sCartService: SCartService,
    private globals: Globals,
    private memberService: SMemberService,
    private categoryService: SCategoryService,
    private productService: SProductService,
    private toastrService: ToastrService,
    private deviceDetectorService: DeviceDetectorService,
  ) {
    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });

    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });


    // 최근 검색어 가져오기
    let data: any = this.globals.load( 'bikemart_searchText' );
    let searchText: any = ( data !== false ) ? data : [];
    let searchTextList = ( data !== false ) ? JSON.parse(searchText) : [];

    this.searchTextList = searchTextList.reverse();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {

    // 장바구니 수량 구독 체크
    this.sCartService.cartBadge.subscribe((data) => {
      this.cartBadgeCnt = data;
    });  


    // queryParams 바인딩
    this.subscription = this.activatedRoute.queryParams.subscribe(params => {
      this.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
    });

    // 장바구니 데이터 가져오기
    this.getCartList();
 

    // 전체 카테고리 리스트 가져오기
    this.getHeaderCategoryList();

    this.getSearchText();

    // 잔여 마일리지 가져오기
    this.getSumRemainedMileage();
     
    // 쿠폰수량가져오기
     this.getSumAvailableCoupon();  
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*******************************************************************************
    설  명 : 장바구니 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCartList() {
    // 회원인 경우
    // if( this.isLogIn ) {
    //   this.sCartService.getCartList( {} ).then( response => {
    //     if( response.ResultCode ) {
    //       this.sCartService.setCartBadge( response.data.length || 0 );
    //     } else {
    //       this.toastrService.error(response.ResultMessage, '장바구니' );
    //     }
    //   });

    // // 비회원인 경우 쿠키에서 가져온다.
    // } else {
      let data: any = this.globals.load( 'bikemart_cart' );
      let cart: any = ( data !== false ) ? data : [];
      let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

      this.sCartService.setCartBadge( cartList.length || 0 );
    // }
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  setLogout() {
    this.memberService.setLogout().then( response => {
      if( response['ResultCode'] == true ) {
        this.toastrService.success(response.ResultMessage, '');

        // 로그아웃 후 장바구니 수량 다시 가져오기
        this.getCartList();

        this.authService.logout('shop');
        } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    })
    .catch(response => {
    });

  }

  /*******************************************************************************
    설  명 : 페이지로 이동 수정자: 2/10 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goBoard( board_id ) {
    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {board_id: board_id, type: '', text: ''},
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 링크 이동
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  goLink( url: any, type: any, value: any ) {
    let params = {};

    switch( type ) {
      case 'brand':
        params = { brandSeq: value };
        break;
      case 'category':
        params = { categoryCode: value };
        break;
      default:
    }

    this.router.navigate(
      [ url ],
      { relativeTo: this.activatedRoute,
        queryParams: params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 브랜드 리스트 가져오기
  *******************************************************************************/
  getHeaderCategoryList() {
    this.categoryService.getHeaderCategoryList().then( response => {
      if( response.ResultCode ) {
        this.brandList = response.data.brand;
        this.categoryList = response.data.category;
        this.purchasingAgentList = response.data.purchasing_agent;
        this.bikePartsList = response.data.bike_parts;

        // this.totalCategory[0].child = this.trimCategory(response.data.category)
        // this.totalCategory[1].child = this.trimCategory(response.data.bike_parts)

        this.totalCategory = this.trimCategory(response.data.category);
        this.totalCategory.push({
          value: '002', label: '오토바이부품', child: this.trimCategory(response.data.bike_parts)
        });
      } else {
        this.toastrService.error(response.ResultMessage, '전체 카테고리 가져오기');
      }
    });
  }

  trimCategory(item: any) {
    return item.map((item: any) => ({
      value: item.category_code,
      label: item.category_name,
      child: item.children ? this.trimCategory(item.children) : []
    }))
  }

  // 인기 검색어 조회
  getSearchText() {
    this.productService.getSearchText().then(response => {
      if (response.ResultCode) {
        this.popularSearchText = response.data;
        this.popularUpdateDate = moment(response.date || Date.now()).format('YYYY-MM-DD HH:mm:ss');
      };
    });
  }

  /*******************************************************************************
    설  명 : 남은 마일리지 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSumRemainedMileage() {
    this.memberService.getSumRemainedMileage().then( response => {
      if ( response.ResultCode ) {
        this.remainedMileage = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 :사용가능 쿠폰 수량 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getSumAvailableCoupon() {
      this.memberService.getSumAvailableCoupon().then( response => {
        if ( response.ResultCode ) {
          this.bamaCouponCnt = response.data;
        }
      });
    }

  
  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.goProductSearch();
    }
  }

  /*******************************************************************************
    설  명 : 검색
  *******************************************************************************/
  goProductSearch() {

    if( this.searchText == '' ) {
      this.toastrService.warning('검색어를 입력하시기 바랍니다.', '');
    } else {
      // 스토리지 검색어 불러오기
      let data: any = this.globals.load( 'bikemart_searchText' );
      let searchText: any = ( data !== false ) ? data : [];
      let searchTextList = ( data !== false ) ? JSON.parse(searchText) : [];

      // 중복되지 않은 검색어만 저장
      if( searchTextList.indexOf(this.searchText) < 0 ) {
        searchTextList.push(this.searchText);

        // 스토리지에 검색어 저장
        this.globals.save( 'bikemart_searchText', searchTextList );
      }

      this.router.navigate(
        ['/product/list'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {
            searchText: this.searchText
          },
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );
    }
  }

  /*******************************************************************************
    설  명 : 메세지 출력
  *******************************************************************************/
  alertMessage(msg: any, head: any) {
    this.toastrService.info(msg, head);
  }

  getImageLink(categoryName) {
    switch(categoryName) {
      case '헬멧' : return "/assets/images/icon_helmet.png"
      case '자켓' : return "/assets/images/icon_jacket.png"
      case '부츠' : return "/assets/images/icon_boots.png"
      case '바지' : return "/assets/images/icon_pants.png"
      case '장갑' : return "/assets/images/icon_globe.png"
      case '슈트' :
      case '이너웨어' : return "/assets/images/icon_suite.png"
      case '우의' : return "/assets/images/icon_hood.png"
      case '프로텍트':
      case '보호대' : return "/assets/images/icon_leg_shield.png"
      case '헬멧악세사리' : return "/assets/images/icon_helmet_plus.png"
      case '라이더용가방' : return "/assets/images/icon_backpack.png"
      case '티셔츠' : return "/assets/images/icon_tshirt.png"
      case '마스크버프' : return "/assets/images/icon_mask_buff.png"
      case '라이더악세사리':
      case '기타' : return "/assets/images/icon_autobicycle_plus.png"
      case '블루투스' : return "/assets/images/icon_category10.png"
      case '블루투스영상' : return "/assets/images/icon_mach.png"
      case '커버' : return "/assets/images/icon_cover.png"
      case '도난방지' : return "/assets/images/icon_antisteal.png"
      case '핸들용품' : return "/assets/images/icon_handle1.png"
      case '배터리' : return "/assets/images/icon_battery.png"
      case '스티커패치' : return "/assets/images/icon_sticker.png"
      case '탱크패드' : return "/assets/images/icon_tankpad.png"
      case '오일' : return "/assets/images/icon_oil.png"
      case '케미컬케어용품' : return "/assets/images/icon_chemi.png"
      case '바이크용가방' : return "/assets/images/icon_bikebag.png"
      case '백미러' : return "/assets/images/icon_backmirror.png"
      case '공구' : return "/assets/images/icon_tool.png"
      case '바이크악세사리' :
      case '자전거용품' : return "/assets/images/icon_bikeacce.png"
      case '제동장치계열' : return "/assets/images/icon_break.png"
      case '필터파츠' : return "/assets/images/icon_filter.png"
      case '플러그' : return "/assets/images/icon_plug.png"
      case '구동장치계열' : return "/assets/images/icon_gear.png"
      case '전기장치계열' : return "/assets/images/icon_elec.png"
      case '차체파츠' : return "/assets/images/icon_parts.png"
      case '외장파츠' : return "/assets/images/icon_parts2.png"
      case '엔진류' : return "/assets/images/icon_engine.png"
      case '타이어휠' : return "/assets/images/icon_tire.png"
      case '머플러' : return "/assets/images/icon_mahura.png"
    }
  }
  
  /*******************************************************************************
    설  명 : 신상품 / 베스트 / 리뷰 클릭 시 이동
  *******************************************************************************/
  goPage(type: any): void {
    // if(location.pathname.includes('/main')) {
    //   if(type == 'bestReview') {
    //     (document.getElementById(type) as HTMLElement).scrollIntoView()
    //   } else this.goPageEmitter.emit(type)
    // } else this.router.navigate(['/main'])
    if( location.pathname !== '/main' ) {
      this.router.navigate(['/main']);

      if( type == 'best' || type == 'new' ) {
        setTimeout(() => {
          let element = document.getElementById('bestNew');
          if( element !== null ) (element as HTMLElement).scrollIntoView();  
        }, 1000);
      } else {
        setTimeout(() => {
          let element = document.getElementById(type);
          if( element !== null ) (element as HTMLElement).scrollIntoView();  
        }, 1000);
      }
    } else {
      if( type == 'best' || type == 'new' ) {
        this.goPageEmitter.emit(type);
      } else
        (document.getElementById(type) as HTMLElement).scrollIntoView();
    }
  }

  /*******************************************************************************
    설  명 : 동호회 안내 DIV 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  closeSociety(headerSocietyId) {
    console.log('클릭'); 
    const headerSociety = document.getElementById(headerSocietyId);
    headerSociety.style.display = 'none';
  }
}
