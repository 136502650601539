<div class="modal-header">
  <h4 class="modal-title fl">무이자할부안내</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="guide">
    <div class="title">유의사항</div>
    <p class="dot">·</p><p class="dot1">법인(기업)/체크/선불/기프트/은행계열카드 제외 (BC마크가 없는 NON BC카드 불가)</p>
    <p class="dot">·</p><p class="dot1">직계약 가맹점, 상점부담 무이자 가맹점, 특별제휴가맹점, 오프라인 가맹점, 신규 가맹점 등 일부 제외</p>
    <p class="dot">·</p><p class="dot1">본 행사 는 카드사 사정에 따라 변경 또는 중단될 수 있음</p>
    <p class="dot">·</p><p class="dot1">무이자 할부 결제 시 포인트, 마일리지 적립 제외</p>
    <p class="dot">·</p><p class="dot1">개인간 안전거래 이니P2P 서비스 제외</p>
    <p class="dot">·</p><p class="dot1">TASF 취급 수수료 거래 하나카드 제외</p>
    <p class="dot">·</p><p class="dot1">온라인 PG업종에 해당하는 무이자로 이 외 업종의 거래는 적용 불가 (제약, 등록금 등)</p>    
  </div> 
  
  <div class="section">
    <div class="img"><img src="/assets/images/installment_bg01.png" alt="무이자할부 안내이미지"></div>
    <div class="title"><span>EVENT01.</span>카드시 무이자 할부</div>
	  <table class="list-table">
	  <colgroup>
	    <col style="width: 15%">
	    <col style="width: 15%">
	    <col style="width: 15%">
	    <col style="width: 15%">
	    <col style="width: 40%">
	  </colgroup>    
	
	  <thead>
	  <tr>
	    <th>기간</th>
	    <th>카드사</th>
	    <th>할부적용금액</th>
	    <th>할부개월</th>
	    <th>신청방법</th>
	  </tr>    
	  </thead>
	  
	  <tbody>
	  <tr>
	    <td rowspan="5">10.1~10.31</td>
	    <td>하나카드</td>
	    <td>5만원 이상</td>
	    <td>2~3개월</td>
	    <td rowspan="5">별도 신청없이 적용</td>
	  </tr>
    <tr>
      <td>현대카드</td>
      <td>5만원 이상</td>
      <td>2~3개월</td>
    </tr>
    <tr>
      <td>국민카드</td>
      <td>5만원 이상</td>
      <td>2~3개월</td>
    </tr>
    <tr>
      <td>신한카드</td>
      <td>5만원 이상</td>
      <td>2~3개월</td>
    </tr>
    <tr>
      <td>삼성카드</td>
      <td>5만원 이상</td>
      <td>2~3개월</td>
    </tr>
	  </tbody>
	  </table>  

    <div class="title"><span>EVENT02.</span>부분 무이자 할부</div>
    <table class="list-table">
    <colgroup>
      <col style="width: 15%">
      <col style="width: 13%">
      <col style="width: 15%">
      <col style="width: 15%">
      <col style="width: 15%">
      <col style="width: 27%">
    </colgroup>    
  
    <thead>
    <tr>
      <th>기간</th>
      <th>카드사</th>
      <th>할부개월</th>
      <th>고객부담</th>
      <th>면제</th>
      <th>비고</th>
    </tr>    
    </thead>
    
    <tbody>
    <tr>
      <td rowspan="7">10.1~10.31</td>
      <td rowspan="3">삼성카드</td>
      <td>10개월</td>
      <td>1~3회차</td>
      <td>4~10회차</td>
      <td rowspan="5">별도 신청없이 적용</td>
    </tr>
    <tr>
      <td>12개월</td>
      <td>1~4회차</td>
      <td>5~12회차</td>
    </tr>
    <tr>
      <td>18개월</td>
      <td>1~6회차</td>
      <td>7~18회차</td>
    </tr>
    <tr>
      <td>신한카드</td>
      <td>10개월</td>
      <td>1~3회차</td>
      <td>4~10회차</td>
    </tr>
    <tr>
      <td>국민카드</td>
      <td>10개월</td>
      <td>1~3회차</td>
      <td>4~10회차</td>
    </tr>
    <tr>
      <td rowspan="2">비씨카드</td>
      <td>10개월</td>
      <td>1~3회차</td>
      <td>4~10회차</td>
      <td rowspan="2">비씨APP 및 ARS 사전 신청고객에 한함<br/><br/>(비씨카드: 1899-5772)<br/>*하나BC카드 제회</td>
    </tr>
    <tr>
      <td>12개월</td>
      <td>1~4회차</td>
      <td>5~12회차</td>
    </tr>
    </tbody>
    </table>  
  </div>  
</div>
<div class="modal-footer">
  <div class="btn-area">
   <button class="btn1" (click)="activeModal.dismiss()">닫기</button>
  </div>
</div>


