import { RouterModule, Route } from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  NgbModule,
  NgbActiveModal,
  NgbDateStruct,
  NgbDatepickerI18n,
  NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxSummernoteModule } from 'ngx-summernote';
import { LSelect2Module } from 'ngx-select2';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { KakaoMapsModule } from 'kakao-maps-sdk';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatCheckboxModule } from "@angular/material/checkbox";

/* 공용 파이프 모듈 */
import { PipesModule } from '@app/pipes/pipes.module';
import { AutoFocusDirective } from '@app/directive/auto-focus.directive';

import { AuthLoginCheck } from '@app/service/auth.login.check';
import { AuthGuard } from '@app/service/auth.guard';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { UrlService } from '@app/service/url.service';

import { MainComponent } from '@page/main/main.component';
import { Main202009Component } from '@page/main/main202009/main202009.component';
import { Main202202Component } from '@page/main/main202202/main202202.component';
import { Main202208Component } from '@page/main/main202208/main202208.component';
import { Header202202Component } from '@page/layout/header/header202202/header202202.component';
import { Header202208Component } from '@page/layout/header/header202208/header202208.component';
import { HeaderComponent } from '@page/layout/header/header.component';
import { FooterComponent } from '@page/layout/footer/footer.component';
import { GoodsCardComponent } from '@page/layout/goods-card/goods-card.component';

import { ProductComponent } from '@page/product/product.component';
import { ProductInfoComponent } from '@page/product/product-info/product-info.component';
import { ProductQuestionComponent } from '@page/product/product-question/product-question.component';
import { ProductReviewComponent } from '@page/product/product-review/product-review.component';

import { CartComponent } from '@page/cart/cart.component';
import { ProductOptionChangeComponent } from '@page/cart/product-option-change/product-option-change.component';
import { ProductCouponComponent } from '@page/cart/product-coupon/product-coupon.component';

import { OrderComponent } from '@page/order/order.component';
import { InstallmentGuideComponent } from '@page/order/installment-guide/installment-guide.component';
import { OrderCompleteComponent } from '@page/order/order-complete/order-complete.component';

import { EventComponent } from '@page/event/event.component';

import { CompanyComponent } from '@page/company/company.component';
import { CompanyHistoryComponent } from '@page/company/company-history/company-history.component';
import { CompanySupplyComponent } from '@page/company/company-supply/company-supply.component';
import { CompanyContactComponent } from '@page/company/company-contact/company-contact.component';
import { CompanyStoryComponent } from '@page/company/company-story/company-story.component';
import { ServiceComponent } from '@page/company/policy/service/service.component';
import { PurchaseAgencyComponent } from '@page/company/policy/purchase-agency/purchase-agency.component';
import { PrivacyComponent } from '@page/company/policy/privacy/privacy.component';

import { BoardComponent } from '@page/board/board.component';
import { BoardListComponent } from '@page/board/board-list/board-list.component';
import { BoardViewComponent } from '@page/board/board-view/board-view.component';
import { BoardWriteComponent } from '@page/board/board-write/board-write.component';
import { CustomerCenterComponent } from '@page/customer-center/customer-center.component';
import { CustomerFaqComponent } from '@page/customer-center/customer-faq/customer-faq.component';
import { MemberLoginComponent } from '@page/member/member-login/member-login.component';
import { MemberDormantTransformComponent } from '@page/member/member-login/member-dormant-transform/member-dormant-transform.component';
import { MemberFindIdComponent } from '@page/member/member-find-id/member-find-id.component';
import { MemberFindIdSuccessComponent } from '@page/member/member-find-id/member-find-id-success/member-find-id-success.component';
import { MemberFindIdFailComponent } from '@page/member/member-find-id/member-find-id-fail/member-find-id-fail.component';
import { MemberFindPwComponent } from '@page/member/member-find-pw/member-find-pw.component';
import { MemberFindPwSuccessComponent } from '@page/member/member-find-pw/member-find-pw-success/member-find-pw-success.component';
import { MemberFindPwFailComponent } from '@page/member/member-find-pw/member-find-pw-fail/member-find-pw-fail.component';
import { MemberJoinComponent } from '@page/member/member-join/member-join.component';
import { MemberJoinFormComponent } from '@page/member/member-join/member-join-form/member-join-form.component';
import { MemberJoinCompleteComponent } from '@page/member/member-join/member-join-complete/member-join-complete.component';

import { MypageComponent } from '@page/mypage/mypage.component';
import { MypageMenuComponent } from '@page/mypage/mypage-menu/mypage-menu.component';
import { MypageOrderComponent } from '@page/mypage/mypage-order/mypage-order.component';
import { MypageOrderDetailComponent } from '@page/mypage/mypage-order/mypage-order-detail/mypage-order-detail.component';
import { MypageReviewComponent } from '@page/mypage/mypage-review/mypage-review.component';
import { MypageReviewAddComponent } from '@page/mypage/mypage-review-add/mypage-review-add.component';
import { MypageNotReceivedComponent } from '@page/mypage/mypage-not-received/mypage-not-received.component';
import { MypageReceivedComponent } from '@page/mypage/mypage-received/mypage-received.component';
import { MypageReturnComponent } from '@page/mypage/mypage-return/mypage-return.component';
import { MypageExchangeComponent } from '@page/mypage/mypage-exchange/mypage-exchange.component';
import { MypageSpecificationsTransactionComponent } from '@page/mypage/mypage-specifications-transaction/mypage-specifications-transaction.component';
import { MypageCancelComponent } from '@page/mypage/mypage-cancel/mypage-cancel.component';
import { MypageMileageComponent } from '@page/mypage/mypage-mileage/mypage-mileage.component';
import { MypageCouponComponent } from '@page/mypage/mypage-coupon/mypage-coupon.component';
import { MypageFavoriteComponent } from '@page/mypage/mypage-favorite/mypage-favorite.component';
import { MypageModifyComponent } from '@page/mypage/mypage-modify/mypage-modify.component';
import { MypageCompanyChangeComponent } from '@page/mypage/mypage-company-change/mypage-company-change.component';
import { MemberOutComponent } from '@page/mypage/member-out/member-out.component';
import { MypageOutSuccessComponent } from '@page/mypage/member-out/mypage-out-success/mypage-out-success.component';
import { MypageNonmemberFormComponent } from '@page/mypage/mypage-nonmember-form/mypage-nonmember-form.component';
import { MypageNonmemberOrderComponent } from '@page/mypage/mypage-nonmember-order/mypage-nonmember-order.component';
import { MypageMyqnaComponent } from '@page/mypage/mypage-myqna/mypage-myqna.component';
import { MypageMyqnaWriteComponent } from '@page/mypage/mypage-myqna/mypage-myqna-write/mypage-myqna-write.component';
import { MypageHeaderComponent } from '@page/mypage/mypage-header/mypage-header.component';
import { AddComponent } from '@page/mypage/add/add.component';
import { DaumAddressComponent } from '@components/daum-address/daum-address.component';
import { MypageDestinationComponent } from '@page/mypage/mypage-destination/mypage-destination.component';
import { MypageDestinationAddComponent } from '@page/mypage/mypage-destination/mypage-destination-add/mypage-destination-add.component';
import { MypageMyqnaViewComponent } from '@page/mypage/mypage-myqna/mypage-myqna-view/mypage-myqna-view.component';
import { InipayCloseComponent } from '@page/order/inipay-close/inipay-close.component';
import { exchangeNoticeComponent } from '@app/page/mypage/mypage-exchange/exchangeNotice/exchangeNotice.component';
import { returnNoticeComponent } from '@page/mypage/mypage-return/returnNotice/returnNotice.component';
import { MypageOrderDetailCashreceiptComponent } from '@page/mypage/mypage-order/mypage-order-detail/mypage-order-detail-cashreceipt/mypage-order-detail-cashreceipt.component';
import { MypageCancelReasonComponent } from '@page/mypage/mypage-cancel/mypage-cancel-reason/mypage-cancel-reason.component';
import { NaverpayComponent } from '@components/naverpay/naverpay.component';
import { MypageOrderStatementComponent } from '@page/mypage/mypage-order/mypage-order-statement/mypage-order-statement.component';
import { ProductNonmemberComponent } from '@page/product/product-nonmember/product-nonmember.component';
import { ProductShareComponent } from '@page/product/product-info/product-share/product-share.component';
import { ViewUpdateComponent } from '@page/board/board-view/view-update/view-update.component';
import { MemberLogoutComponent } from '@page/member/member-logout/member-logout.component';
import { Event1Component } from '@page/event/event1/event1.component';
import { Event2Component } from '@page/event/event2/event2.component';
import { EventPrizeComponent } from '@page/event/event2/event-prize/event-prize.component';
import { StoryComponent } from '@page/company/company-story/story/story.component';
import { Event3Component } from '@page/event/event3/event3.component';
import { Event3PrizeComponent } from '@page/event/event3/event3-prize/event3-prize.component';
import { MembershipGradeComponent } from '@app/page/guide/membership-grade/membership-grade.component';
import { GoodsCard1Component } from '@page/layout/goods-card1/goods-card1.component';
import { Header202009Component } from './page/layout/header/header202009/header202009.component';
import { EventCouponComponent } from './page/event/event-coupon/event-coupon.component';
import { BrandComponent } from './page/brand/brand.component';
import { SenaRegistrationComponent } from './page/company/sena-registration/sena-registration.component';
import { MypageSocietyComponent } from './page/mypage/mypage-society/mypage-society.component';
import { MypageSocietyInfoComponent } from './page/mypage/mypage-society-info/mypage-society-info.component';
import { MypageSocietyHeaderComponent } from './page/mypage/mypage-society-info/mypage-society-header/mypage-society-header.component';
import { CompanySocietyComponent } from './page/company/company-society/company-society.component';
import { SocietyBenefitComponent } from './page/guide/society-benefit/society-benefit.component';
import { SocietyInfoComponent } from './page/guide/society-info/society-info.component';
import { SocietyCommunityComponent } from './page/society/society-community/society-community.component';
import { SocietyDetailModalComponent } from './page/society/society-community/society-detail-modal/society-detail-modal.component';
import { BoardGalleryComponent } from './page/board/board-gallery/board-gallery.component';
import { BoardAlbumComponent } from './page/board/board-album/board-album.component';
import { BoardAddComponent } from './page/board/board-add/board-add.component';
import { InputExComponent } from './components/input-ex/input-ex.component';
import { KakaoChatComponent } from './components/kakao-chat/kakao-chat.component';
import { MemberDormantCheckComponent } from './page/member/member-login/member-dormant-check/member-dormant-check.component';


/* 달력 컴포넌트 한글*/
const I18N_VALUES = {
  ko: {
    weekdays: ['일', '월', '화', '수', '목', '금', '토'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,

    AutoFocusDirective,

    MainComponent,
    HeaderComponent,
    FooterComponent,

    ProductComponent,
    ProductInfoComponent,
    ProductQuestionComponent,
    ProductReviewComponent,

    ProductOptionChangeComponent,
    ProductCouponComponent,
    CartComponent,

    OrderComponent,
    InstallmentGuideComponent,
    OrderCompleteComponent,
    EventComponent,
    CompanyComponent,
    CompanyHistoryComponent,
    CompanySupplyComponent,
    CompanyContactComponent,
    CompanyStoryComponent,
    ServiceComponent,
    PurchaseAgencyComponent,
    PrivacyComponent,
    BoardComponent,
    BoardListComponent,
    BoardViewComponent,
    BoardWriteComponent,
    CustomerCenterComponent,
    CustomerFaqComponent,
    MemberLoginComponent,
    MemberFindIdComponent,
    MemberFindIdSuccessComponent,
    MemberFindIdFailComponent,
    MemberFindPwComponent,
    MemberFindPwSuccessComponent,
    MemberFindPwFailComponent,
    MemberJoinComponent,
    MemberJoinFormComponent,
    MemberJoinCompleteComponent,
    MypageComponent,
    MypageMenuComponent,
    MypageHeaderComponent,
    MypageOrderComponent,
    MypageNotReceivedComponent,
    MypageReceivedComponent,
    MypageReturnComponent,
    MypageExchangeComponent,
    MypageSpecificationsTransactionComponent,
    MypageCancelComponent,
    MypageMileageComponent,
    MypageCouponComponent,
    MypageFavoriteComponent,
    MypageModifyComponent,
    MypageCompanyChangeComponent,
    MemberOutComponent,
    MypageOutSuccessComponent,
    MypageNonmemberFormComponent,
    MypageNonmemberOrderComponent,
    MypageOrderDetailComponent,
    MypageReviewComponent,
    MypageReviewAddComponent,
    MypageMyqnaComponent,
    MypageMyqnaWriteComponent,
    GoodsCardComponent,
    AddComponent,
    DaumAddressComponent,
    MypageDestinationComponent,
    MypageDestinationAddComponent,
    MypageMyqnaViewComponent,
    InipayCloseComponent,
    exchangeNoticeComponent,
    returnNoticeComponent,
    MemberDormantTransformComponent,
    MypageOrderDetailCashreceiptComponent,
    MypageOrderStatementComponent,
    MypageCancelReasonComponent,
    NaverpayComponent,
    ProductNonmemberComponent,
    ProductShareComponent,
    ViewUpdateComponent,
    MemberLogoutComponent,
    Event1Component,
    Event2Component,
    EventPrizeComponent,
    StoryComponent,
    Event3Component,
    Event3PrizeComponent,
    Header202202Component,
    Main202202Component,
    MembershipGradeComponent,
    MypageReviewAddComponent,
    Main202208Component,
    Header202208Component,
    GoodsCard1Component,
    Main202009Component,
    Header202009Component,
    EventCouponComponent,
    BrandComponent,
    SenaRegistrationComponent,
    MypageSocietyComponent,
    MypageSocietyInfoComponent,
    MypageSocietyHeaderComponent,
    CompanySocietyComponent,
    SocietyBenefitComponent,
    SocietyInfoComponent,
    SocietyCommunityComponent,
    SocietyDetailModalComponent,
    BoardGalleryComponent,
    BoardAlbumComponent,
    BoardAddComponent,
    InputExComponent,
    KakaoChatComponent,
    MemberDormantCheckComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right'
    }),
    PipesModule,
    LSelect2Module,
    HttpClientModule,
    DragDropModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatDividerModule,
    MatTreeModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatCheckboxModule,
    MatIconModule,
    SwiperModule,
    NgxImageZoomModule,
    CarouselModule,
    KakaoMapsModule
  ],
  providers: [
    NaverpayComponent,
    Title,
    DeviceDetectorService,
    RestfulService,
    UtilService,
    Globals,
    AuthGuard,
    AuthLoginCheck,
    UrlService,
    NgbActiveModal,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: MAT_DATE_LOCALE, useValue: 'ko-KR'},
    NgbDatepickerConfig,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ProductOptionChangeComponent,
    ProductCouponComponent,
    InstallmentGuideComponent,
    MypageNotReceivedComponent,
    MypageReceivedComponent,
    MypageReturnComponent,
    MypageExchangeComponent,
    MypageSpecificationsTransactionComponent,
    MypageReviewAddComponent,
    AddComponent,
    MypageDestinationComponent,
    MypageDestinationAddComponent,
    exchangeNoticeComponent,
    returnNoticeComponent,
    MemberDormantTransformComponent,
    MypageOrderDetailCashreceiptComponent,
    MypageCancelReasonComponent,
    ProductNonmemberComponent,
    ProductShareComponent,
    ViewUpdateComponent,
    EventPrizeComponent,
    Event3PrizeComponent,
    StoryComponent,
    SocietyDetailModalComponent
  ]
})
export class AppModule { }
