import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector'
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as $ from 'jquery';
import 'jqueryui';

import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { SEventService } from '@app/service/event.service';
import { SBoardService } from '@app/service/board.service';
import { SEOService } from '@app/service/seo.service';

const delay: any = 3000;

@Component({
  selector: 'app-main202009',
  templateUrl: './main202009.component.html',
  styleUrls: ['./main202009.component.scss']
})
export class Main202009Component implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public SwiperConfigInterface_banner: SwiperConfigInterface = {
    loop: true,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    spaceBetween: 0,
  };

  public SwiperConfigInterface_mbanner: SwiperConfigInterface = {
    loop: true,
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  public bannerIndex = 0; // 메인배너 index

  public allView: boolean = false; //main banner 모두보기
  public bestGoodsMore: boolean = false; //best100 상품펼치기
  public singleGoodsMore: boolean = false; //365꿀득템존 상품펼치기
  public honey365Selected: any = '';
  public eventCategoryCode: any = '';

  public baseURL = config.baseUrl;

  public search = {};

  public eventList: any = [];
  public senaList: any = [];
  public best100List: any = [];
  public honey365CategoryList: any = [];
  public honey365BrandList: any = [];
  public honey365OriginalList: any = [];
  public honey365List: any = [];
  public newList: any = [];
  public monthSoldList: any = [];
  public superSaleOrgList: any = [];
  public superSaleList: any = [];
  public rewardSaleList: any = [];
  public boardList: any = [];
  public popupList: any = [];

  public isLogIn: boolean;
  public isLogin$: Observable<boolean>; // 로그인여부확인

  // mobile 공지사항
  public params: any = {
    type: 'list',
    board_id: 'notice',
    group_id: '쇼핑몰',
    row: 5,
    no: 1,
    group: 'subject',
    text: ''
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private globals: Globals,
    private authService: AuthService,
    private sEventService: SEventService,
    private sBoardService: SBoardService,
    private cookieService: CookieService,
    private deviceDetectorService: DeviceDetectorService,
    public seoService: SEOService
  ) {
    this.seoService.updateTitle('바이크마트 - 오토바이용품 전문몰');

    // 로그인 여부 가져오기
    this.isLogin$ = this.authService.isLoggedIn;
    
    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 이벤트 배너 가져오기
    this.getEventBannerList();

    // 팝업 정보 가져오기
    // if( this.deviceDetectorService.isDesktop() ) {
      this.getBoardPopupList();
    // }

    // 메인 게시판 정보
    this.getMainBoardList();

    // 슈퍼세일 상품 정보
    this.getSuperSaleProductList();

    // 보상세일 상품 정보
    this.getRewardSaleProductList();
    
    // 새로나왔어요
    this.getProductNewList();

    // 베스트100
    this.getProductBest100List();

    // 꿀365 카테고리
    this.getProduct365CategoryList()

    // 꿀365 브랜드
    this.getProduct365BrandList();

    // 꿀365 상품
    this.getProduct365List();

    // 최근 한달 내 가장 많이 팔린 상품
    this.getProductMonthSoldList();

  }

  /*******************************************************************************
    설  명 : 이벤트 배너 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getEventBannerList() {
    this.sEventService.getEventBannerList().then( response => {
      if( response.ResultCode ) {
        this.eventList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 베스트100 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductBest100List() {
    this.sEventService.getEventProductList( { event_display_type: '2010' } ).then( response => {
      if( response.ResultCode ) {
        this.best100List = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 상품 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProduct365BrandList() {
    this.sEventService.getEventProductBrandList( { event_display_type: '2020' } ).then( response => {
      if( response.ResultCode ) {
        this.honey365BrandList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 이벤트 상품 브랜드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProduct365CategoryList() {
    this.sEventService.getEventProductCategoryList( { event_display_type: '2020' } ).then( response => {
      if( response.ResultCode ) {
        this.honey365CategoryList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 365꿀득템존 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProduct365List() {
    this.sEventService.getEventProductList( { event_display_type: '2020' } ).then( response => {
      if( response.ResultCode ) {
        this.honey365OriginalList = response.data;

        this.honey365List = this.honey365OriginalList;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 새로 나온 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductNewList() {
    this.sEventService.getEventProductList( { event_display_type: '2030' } ).then( response => {
      if( response.ResultCode ) {
        this.newList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 최근 한달 가장 많이 팔린 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductMonthSoldList() {
    this.sEventService.getEventProductList( { event_display_type: '2040' } ).then( response => {
      if( response.ResultCode ) {
        this.monthSoldList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSuperSaleProductList() {
    this.sEventService.getSuperSaleProductList( { event_type: '1000', category_code: this.eventCategoryCode } ).then( response => {
      if( response.ResultCode ) {
        this.superSaleOrgList = response.data;
        this.superSaleList = this.superSaleOrgList;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 슈퍼세일 리스트 카테고리 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSuperSaleEventCategory( value: any ) {
    this.eventCategoryCode = value;

    if( this.eventCategoryCode == '' ) {
      this.superSaleList = this.superSaleOrgList;
    } else {
      if( this.eventCategoryCode == '999999' ) {
        this.superSaleList = this.superSaleOrgList.filter(obj => 
          obj.parent_category_code != '001001' &&
          obj.parent_category_code != '001003' &&
          obj.parent_category_code != '001005' &&
          obj.parent_category_code != '001004' &&
          obj.parent_category_code != '001006'
        );
      } else {
        // this.superSaleList = this.superSaleOrgList.filter(obj => obj.parent_category_code == this.eventCategoryCode);
        this.router.navigate(
          ['/product/list'],
          {
            relativeTo: this.activatedRoute,
            queryParams: {
              categoryCode: this.eventCategoryCode
            },
            queryParamsHandling: '', // remove to replace all query params by provided
          }
        );
      }
    }
  }

  /*******************************************************************************
    설  명 : 보상세일 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRewardSaleProductList() {
    this.sEventService.getSuperSaleProductList( { event_type: '1001' } ).then( response => {
      if( response.ResultCode ) {
        this.rewardSaleList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }
    
  /*******************************************************************************
    설  명 : 365 꿀득템존 카테고리 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setHoney365CategorySelected( categorySeq: number ) {
    this.honey365Selected = categorySeq;

    this.honey365List = this.honey365OriginalList.filter(item => item.category_seq === categorySeq);
  }

  /*******************************************************************************
    설  명 : 365 꿀득템존 브랜드 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setHoney365BrandSelected( brandSeq: number ) {
    this.honey365Selected = brandSeq;

    this.honey365List = this.honey365OriginalList.filter(item => item.brand_seq === brandSeq);
  }
  
  /*******************************************************************************
    설  명 : 365 꿀득템존 브랜드 전체선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setAllHoney365BrandSelected() {
    this.honey365List = this.honey365OriginalList;
    this.honey365Selected = '';
  }

  /*******************************************************************************
    설 명 : 게시판 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMainBoardList() {
    this.sBoardService.getMainBoardList( this.params ).then( response => {
      this.boardList = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 팝업 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardPopupList() {
    this.sBoardService.getBoardListLimit( {board_id: 'popup', limit: '0'} ).then( response => {
      if( response.ResultCode ) {

        if( response.data.length > 0 ) {
          response.data.forEach( row => {
            if( this.getPopupToday(row.board_seq, row.layer_popup) ) {
              if( row.layer_popup == true ) {
                row.is_popup_preview = true;

                this.popupList.push(row);
              } else {
                let url = this.baseURL + '/api/popup/popup.php?board_id=' + row.board_id + '&board_seq=' + row.board_seq;

                window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width='+row.popup_width+', height='+(parseInt(row.popup_height)+60)+', left='+row.screen_x+', top=' + row.screen_y );
              }
            }
          });

          setTimeout( ()=> {
            $(function() {
              $(".popup-layer").draggable();
            });
          }, 0);            
        }
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPopupToday( boardSeq: number, layerPopup: any ) {
    if( layerPopup == 1 ) {
      let data: any = this.globals.load( 'popup' + boardSeq );

      if( data !== false ) {
        data = JSON.parse( data );
  
        let now: any = moment();
        data = moment( data ).format('YYYY-MM-DD hh:mm:ss');
  
        if( now.diff( data, 'hours' ) < 24 ) return false;
        else return true;
      } else return true;
    } else {
      let cookieData: any = this.cookieService.get( 'popup' + boardSeq );

      if( cookieData == 'hidden' ) return false;
      else return true;
    }
  }
  
  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPopupToday( row ) {
    this.globals.save( 'popup' + row.board_seq, moment().format('YYYY-MM-DD hh:mm:ss') );

    row.is_popup_preview = false;
  }
  
  /*******************************************************************************
    설  명 : 게시판 상세보기 (is_notice여부에 따른 a태그 사용) 수정자: 2/8 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  view( row: any ) {
    this.params.board_seq = row.board_seq;

    this.router.navigate(
    ['/board/view'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : swiper 인덱스 변경 이벤트에서 마지막 배너일 때 처음으로 되돌리기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeSwiperIndex( event: any ) {
    if( this.eventList.length === parseInt(event + 1) ) {
      setTimeout(() => {
        this.bannerIndex = 0;
      }, delay);
    }
  }

  /*******************************************************************************
    설  명 : 모바일에서 레이어 팝업 표시할 때 위치값 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changePopupLayerStyle(row: any, element: any) {
    if( this.deviceDetectorService.isDesktop() ) return row[ element ];
    else return 0;
  }
}
