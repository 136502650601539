<app-header></app-header>

<div class="event-page inner">
  <div class="event2">
    <!-- <div class="event-title">2021 23th 바이크마트 슈퍼세일 이벤트</div> -->
    <!-- <div class="event-title1"></div> -->
    <div class="event-quiz">
      <div class="quiz-txt" [innerHTML]="hint"></div>
      <!-- 매년 2회 바이크마트에서 실시하는 정기세일로<br/>
      바이크마트 회원에게 <span>최대 90%의 높은 할인율</span>로 구매할 수 있는<br class="pc-only"/>
      기회를 주는 파격적인 세일 -->
      <ul class="quiz clearfix">
        <li *ngFor="let item of questions">{{item}}</li>
      </ul>
    </div>
    <form [formGroup]="form">
      <div class="answer clearfix">
        <input type="text" placeholder="초성을 보고 답을 입력해주세요" formControlName="answer" [ngClass]="{formErrors:formErrors['answer']}">
        <button (click)="submit()">작성완료</button>
      </div>
    </form>
    
    <div class="info">
      <div class="clearfix"><span class="th">이벤트기간</span><span class="td">{{start_date}} ~ {{end_date}}까지</span></div>
      <div class="clearfix"><span class="th">참여방법</span><span class="td">로그인 후 상단 입력칸에 정답을 작성해주세요.</span></div>
      <div class="clearfix"><span class="th">당첨발표</span><span class="td">작성완료시 즉시지급</span></div>
    </div>

    <div class="giveaway">
      <div class="th">경품안내</div>
      <ul class="clearfix">
        <li>바이크마트 마일리지 2000P</li>
        <li>바이크마트 마일리지 5000P</li>
        <li>바이크마트 마일리지 10000P</li>
      </ul>
    </div>
    

  </div>
</div>

<app-footer></app-footer>
