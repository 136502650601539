import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SEventService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 이벤트 배너 이미지 가져오기 (리뉴얼용)
  getEventImageList(params: any): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getEventImageList'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 이벤트 정보 가져오기
  getEventDetail( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getEventDetail'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 배너 리스트 가져오기
  getEventBannerList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getEventBannerList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 이벤트카테고리 용 배너 리스트 가져오기
  getEventCategoryBannerList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getEventCategoryBannerList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 상품 브랜드 리스트 가져오기
  getEventProductBrandList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getEventProductBrandList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 상품 카테고리 리스트 가져오기
  getEventProductCategoryList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getEventProductCategoryList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
  
  // 슈퍼세일 상품 리스트 가져오기
  getSuperSaleProductList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getSuperSaleProductList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 상품 리스트 가져오기
  getEventProductList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getEventProductList'
      }, search
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 상품 리스트 가져오기
  getEachEventProductList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'event',
        version: '1.0',
        action: 'getEachEventProductList'
      }, search
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 퀴즈 (문제)
  getEventQuiz(): Promise<any> {
    return this.restful.get({
      program: 'shop',
      service: 'event',
      version: '1.0',
      action: 'getEventQuiz'
    }, {}
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 퀴즈 (정답)
  setEventQuiz(quiz_seq: any, form: any): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'event',
      version: '1.0',
      action: 'setEventQuiz'
    }, {quiz_seq: quiz_seq, ...form.value}
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 구매순위
  getEventOrder(): Promise<any> {
    return this.restful.get({
      program: 'shop',
      service: 'event',
      version: '1.0',
      action: 'getEventOrder'
    }, {}
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 쿠폰 등록
  setEventCoupon(form: any): Promise<any> {
    return this.restful.post({
      program: 'shop',
      service: 'event',
      version: '1.0',
      action: 'setEventCoupon'
    }, form.value
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 이벤트 쿠폰코드 체크
  getEventCouponCheck(couponCode: any): Promise<any> {
    return this.restful.get({
      program: 'shop',
      service: 'event',
      version: '1.0',
      action: 'getEventCouponCheck'
    }, {
      coupon_code: couponCode
    }
  ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

}
