<div class="modal-header">
  <h4 class="modal-title fl">교환신청 완료</h4>
  <button class="close" (click)="activeModal.dismiss()">&times;</button>
</div>

<div class="modal-body">
  <div class="txt1">* 상품이 업체로 도착되면 확인검수 후 진행됩니다.</div>
  <div class="txt2">도착예정일 수거 후<br/>(택배사정에 따라 3일~5일 이상 소요 됩니다.)</div>

  <div class="btn-area">
   <button class="btn1" (click)="activeModal.dismiss()">내용확인</button>
  </div>
</div>

