/*******************************************************************************
  설  명 : 고객센터
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { SBoardService } from '@app/service/board.service';

@Component({
  selector: 'app-customer-center',
  templateUrl: './customer-center.component.html',
  styleUrls: ['./customer-center.component.scss']
})
export class CustomerCenterComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public memberInfo: any = {};

  public params: any = {
    type: 'list',
    board_id: 'notice',
    group_id: '쇼핑몰',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };

  public boardList = [];
  public linecnt: any = [];

  public boardNoticeListLimit = [];
  public boardFaqListLimit = [];

  /*******************************************************************************
    설  명 : 생성자 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sBoardService: SBoardService,
    private authService: AuthService,
    private toastrService: ToastrService,
  ) {
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리 수정자: 2/18 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '' ) ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      this.getBoardInfo( this.params.board_id );
      this.getBoardNoticeList();
      this.getBoardFaqList();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기 수정자: 2/18 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.sBoardService.getBoardInfoId( board_id ).then( response => {
      if( response.data == null ) {
        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/main'] );
        return false;
      }

      this.boardInfo = response.data;

      if( this.params.type == '' ){
        switch( this.boardInfo.lvt_default ){
          default: case 'L': this.params.type = 'list';
        }
      } // 게시판 내용 부분 faq, question, list로 분류한 뒤 요청에 따라 불러옴.

      if( this.params.type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');

      this.getBoardList();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 정보 가져오기 수정자: 2/18 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.sBoardService.getBoardList( this.params ).then( response => {
      this.boardList = response.data;
      
      this.boardList = this.boardList.filter((item) => {
        return item.is_notice == '1'
      }); // 공지사항만 필터링

      this.deleteParams();

    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제 수정자: 2/18 김종현
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 페이지로 이동 수정자: 수정자: 2/18 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goBoard( board_id ) {
    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {board_id: board_id, type: ''},
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 공지사항 리스트 개수 limit 불러오기 수정자:2/18 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardNoticeList() {
    this.sBoardService.getBoardListLimit( {board_id: 'notice', limit: '5' } ).then( response => {
      this.boardNoticeListLimit = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 자주하는 질문 리스트 개수 limit 불러오기 수정자:2/18 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardFaqList() {
    this.sBoardService.getBoardListLimit( {board_id: 'faq', limit: '5' } ).then( response => {
      this.boardFaqListLimit = response.data;
    });
  }

    /*******************************************************************************
    설  명 : 게시판 상세보기 수정자: 2/18 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  view( row: any ) {
    this.params.board_seq = row.board_seq;

    this.router.navigate(
    ['/board/view'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상세보기 페이지 변동없이 내려보기 수정자:2/18 김종현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  viewDown( row: any ) {
    let child = $("#child_"+ row.board_seq);

    if( child.length < 1 ) {
      let node = '<tr id="child_'+ row.board_seq +'" class="viewdown"><td colspan="6" style="padding: 30px 0px 40px 0px;line-height: 150%;" class="tl">'+ row.contents_nl2br +'</td></tr>';
      $("#tr_" + row.board_seq).after( node );
    } else {
      $("#child_" + row.board_seq).remove();
    }
  }


}
