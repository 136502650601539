<div class="modal-header">
  <h4 class="modal-title fl">할인쿠폰 적용</h4>
  <!--<button class="close" (click)="activeModal.dismiss()">&times;</button>-->
</div>

<div class="modal-body">
  <div class="left-area">
    <ul>  
      <li *ngFor="let item_product of info_product; let idx = index;">
		    <div class="header clearfix">
		      <p>{{item_product.model}} / {{item_product.size}} / {{item_product.color}} / {{item_product.num | number}} 개</p>
		      <p>{{item_product.price * item_product.num | number}}<span> 원</span></p>      
		    </div>
		    <div class="coupon clearfix">
		      <div class="left">
		        <p>적용할 쿠폰</p>
		        <div class="radio">
              <label *ngFor = "let item_coupon of info_coupon_list;" (click) = "setCoupon(item_product, item_coupon)">{{item_coupon.name}}
                <input type="radio" name="radio{{idx}}" [checked] = "item_product.coupon_code && (item_product.coupon_code == item_coupon.code)" />
                <span class="checkmark"></span>
              </label>
		          <label>적용안함
		            <input type="radio" name="radio{{idx}}" (click) = "setCoupon(item_product, '')" [checked] = "!item_product.coupon_code" />
		            <span class="checkmark"></span>
		          </label>
		        </div>          
		      </div>
		      <div class="right">
		        <p>할인금액</p>
		        <p>{{item_product.coupon_sale | number }} 원</p>  
		      </div>      
		    </div>
      </li>
    </ul>
  </div>

  <div class="right-area">
    <ul class="clearfix">
      <li>
        <p>상품금액</p>
        <p>{{total_productprice | number}}<span> 원</span></p>
      </li>  
      <li>
        <p>할인금액</p>
        <p>(-) {{total_sale | number}}<span> 원</span></p>
      </li>  
    </ul>
    <div class="total">
      <p>할인 적용금액</p>
      <p>{{total_productprice - total_sale | number}}<span> 원</span></p>
    </div>
    <button class="btn1 red" (click) = "activeModal.close(info_product)">쿠폰적용</button>
  </div>

</div>


