<ng-container *ngIf="isMobileApp === false">
    <app-header></app-header>
  </ng-container>

  <div class="society-page">
    <img src="/assets/images/society/society_benefit_pc.jpg" class="pc-view" alt="동호회 혜택" />
    <img src="/assets/images/society/society_benefit_mobile.jpg" class="mobile-view" alt="동호회 혜택" />
  </div>

  <ng-container *ngIf="isMobileApp === false">
    <app-footer></app-footer>
  </ng-container>
