/*******************************************************************************
  설  명 : 마이페이지 - 구매후기작성
  생성일 : 2021-06-10
  생성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbInputDatepicker, NgbCalendar, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { SOrderService } from '@app/service/order.service';

import { MypageReviewAddComponent } from '@page/mypage/mypage-review-add/mypage-review-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-review',
  templateUrl: './mypage-review.component.html',
  styleUrls: ['./mypage-review.component.scss']
})
export class MypageReviewComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public search: any = {
    pageNo: 1,
    pageRow: 5,
    sdate: '',
    edate: '',
    dateGroup: ''
  };

  public totalCount: number = 0;

  public reviewList: any = [];
  public reviewImageList: any = [];
  
  public dateGroupList: any = [
    {title: '오늘', value: 0},
    {title: '1주일', value: -7},
    {title: '1개월', value: -30},
    {title: '3개월', value: -90},
    {title: '6개월', value: -180}
  ];  

  public today: string;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private calendar: NgbCalendar,
    private sOrderService: SOrderService,
    private modalService: NgbModal,
  ) { 
    this.today = moment().format('YYYY-MM-DD');
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getOrderReviewList();
  }
  
  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.search.program;
    delete this.search.service;
    delete this.search.version;
    delete this.search.action;
    delete this.search.token;
  }
  
  /*******************************************************************************
    설  명 : 내가 작성한 구매후기 리스트 불러오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderReviewList() {
    this.sOrderService.getOrderReviewList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.reviewList = response.data;
        this.totalCount = response.total;
        this.reviewImageList = response.image;
      } else {
        this.toastrService.error(response.ResultMessage);
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 구매후기 이미지 리스트 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getReviewImageList( row: any ) {
    if( this.reviewImageList === undefined ) return null;

    return this.reviewImageList.filter((obj: any) => obj.review_seq === row.seq);
  }
  
  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.search.sdate = this.calendar.getToday();
      obj.close();
    } else if( check == 2 ) {
      this.search.edate = this.calendar.getToday();
      obj.close();
    }
  }
  
  /*******************************************************************************
    설  명 : 검색 기간 버튼 클릭
    입력값 : 값
    리턴값 : 없음
  *******************************************************************************/
  onSearchDateClick( val ) {
    this.search.dateGroup = val;

    let dateModel: NgbDateStruct;

    dateModel = {
      year: parseInt( moment().format('YYYY') ),
      month: parseInt( moment().format('MM') ),
      day: parseInt( moment().format('DD') )
    };

    this.search.edate = dateModel;

    dateModel = {
      year: parseInt( moment().add(val, 'day').format('YYYY') ),
      month: parseInt( moment().add(val, 'day').format('MM') ),
      day: parseInt( moment().add(val, 'day').format('DD') )
    };

    this.search.sdate = dateModel;

    this.getOrderReviewList();
  }
  
  /*******************************************************************************
    설  명 : 리스트 상품 상세보기로 가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goProduct( row: any ) {
    let seq = row.product_seq;
    const url = '/product/info?productSeq=' + seq;
    window.open("about:blank").location.href = url;
  }

  /*******************************************************************************
  설  명 : 페이지 선택 처리
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  loadPage( page: number ) {
    this.search.pageNo = page;

    this.getOrderReviewList();
  }

  /*******************************************************************************
    설  명 : 구매후기 수정하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openReviewAdd( row: any ) {
    const modalRef = this.modalService.open(MypageReviewAddComponent, options);

    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.imageList = this.reviewImageList.filter((obj: any) => obj.review_seq === row.seq);

    modalRef.result.then((result) => {
      if( result ) {
        this.getOrderReviewList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 구매후기 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setReviewDelete( row: any ) {
    if (confirm('구매후기 삭제 시 재작성 및 복구가 불가합니다.\n\n정말 삭제하시겠습니까?')) {
      this.sOrderService.setDeleteOrderReview( row.seq ).then( response => {
        if ( response.ResultCode ){
          this.toastrService.success( response.ResultMessage );
          this.getOrderReviewList();
        } else {
          this.toastrService.error( response.ResultMessage );
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 구매후기 평균점수 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setGarde(row) {
    return 'calc(90px *' + row.grade + '/ 5)';
  }
  
}