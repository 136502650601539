<app-header [goPageEmitter]="goPageEmitterFunc"></app-header>
<app-kakao-chat></app-kakao-chat>

<!-- <div class="main-banner pc-view">
  <swiper [config]="SwiperConfigInterface_banner">
    <div *ngFor="let item of eventImageList.main">
      <a *ngIf="item.event_link" [href]="item.event_link"><img [src]="item.image_url" alt="{{item.event_name}}" /></a>
      <a *ngIf="!item.event_link" routerLink="/event" [queryParams]="{seq: item.seq}"><img [src]="item.image_url" [alt]="item.event_name" /></a>
    </div>
  </swiper>
  <div class="swiper-button-prev swiper-button-prev-main"></div>
  <div class="swiper-button-next swiper-button-next-main"></div>
</div> -->

<div class="pc-view">
  <owl-carousel-o [options]="customOptionsBanner" (dragging)="isDragging = $event.dragging">
    <ng-container *ngFor="let item of eventImageList.main">
      <ng-template carouselSlide>
        <a *ngIf="item.event_link" [href]="item.event_link"><img [src]="item.image_url" [alt]="item.event_name" /></a>
        <a *ngIf="!item.event_link" owlRouterLink="/event" [queryParams]="{seq: item.seq}" [stopLink]="isDragging"><img [src]="item.image_url" [alt]="item.event_name" /></a>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>

<!-- 모바일 메인배너 -->
<div class="mmain-banner mobile-view">
  <!-- <swiper [config]="SwiperConfigInterface_mbanner">
	  <div *ngFor="let item of eventImageList.main_m">
      <a *ngIf="item.event_link" [href]="item.event_link"><img [src]="item.image_url" [alt]="item.event_name" /></a>
      <a *ngIf="!item.event_link" routerLink="/event" [queryParams]="{seq: item.seq}"><img [src]="item.image_url" [alt]="item.event_name" /></a>
    </div>
  </swiper> -->
  <owl-carousel-o [options]="customOptionsBanner" (dragging)="isDragging = $event.dragging">
    <ng-container *ngFor="let item of eventImageList.main_m">
      <ng-template carouselSlide>
          <a *ngIf="item.event_link" [href]="item.event_link"><img [src]="item.image_url" [alt]="item.event_name" /></a>
          <a *ngIf="!item.event_link" owlRouterLink="/event" [queryParams]="{seq: item.seq}" [stopLink]="isDragging"><img [src]="item.image_url" [alt]="item.event_name" /></a>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>

<!-- 카테고리 -->
<div class="section1">
  <ul class="category-ul inner clearfix">
    <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001001'}"><span></span>헬멧<p>Helmets</p></a></li>
    <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001003'}"><span></span>자켓<p>Jackets</p></a></li>
    <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001005'}"><span></span>팬츠<p>Pants</p></a></li>
    <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001004'}"><span></span>부츠<p>Boots</p></a></li>
    <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001006'}"><span></span>장갑<p>Gloves</p></a></li>
    <li><a routerLink="/product/list" [queryParams]="{categoryCode: '001014'}"><span></span>블루투스<p>Bluetooth</p></a></li>
  </ul>
</div>

<!-- 3개 그룹 배너 -->
<div class="event-banner section1 pc-view">
  <ul class="inner clearfix">
    <li *ngFor="let item of eventImageList.three">
      <a [href]="item.event_link"><img [src]="item.image_url" alt="event"></a>
    </li>
  </ul>
</div>

<!-- 모바일 3개 그룹 배너 -->
<div class="event-banner mobile-view">
  <!-- <swiper [config]="SwiperConfigInterface_mevent">
    <div *ngFor="let item of eventImageList.three_m">
      <a [routerLink]="item.event_link"><img [src]="item.image_url" alt="event"></a>
    </div>
  </swiper>
  <div class="swiper-button-prev swiper-button-prev-eventm"></div>
  <div class="swiper-button-next swiper-button-next-eventm"></div> -->
  <owl-carousel-o [options]="customOptionsBanner">
    <ng-container *ngFor="let item of eventImageList.three_m">
      <ng-template carouselSlide>
        <div><a [href]="item.event_link"><img [src]="item.image_url" alt="event"></a></div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>

<!-- 이주의 추천아이템 -->
<div class="recommend section pc-view" *ngIf="eventImageList.thisweek.length">
  <div class="section-title">이주의 <span class="yellow">추천 아이템</span></div>
  <ul class="recommend-ul inner clearfix">
    <li *ngFor="let item of eventImageList.thisweek">
      <a routerLink="/product/info" [queryParams]="{productSeq: item.seq, eventSeq: item.event_seq}" [target]="linkTarget">
        <div class="goods-img">
          <img [src]="item.thumbnail_url" alt="{{item.product_name | nohtml}}" />
        </div>
        <div class="goods-info">
          <p class="name" [innerHTML]="item.product_name | safe:'html'"></p>
          <p class="price">
            <del *ngIf="comparePrice(item, 'parseInt(item.display_price) > parseInt(item.consumer_price)')">{{ item.display_price | number }}원</del>
            <span>{{ item.consumer_price | number }}원</span>
            <span class="sale" *ngIf="comparePrice(item, 'parseInt(item.display_price) > parseInt(item.consumer_price)')">{{ (item.display_price - item.consumer_price) / item.display_price | percent }}</span>
          </p>
        </div>
      </a>
    </li>
  </ul> 
</div>

<!-- 모바일 이주의 추천아이템 -->
<div class="recommend section mobile-view" *ngIf="eventImageList.thisweek.length">
  <div class="section-title">이주의 <span class="yellow">추천 아이템</span></div>
  <div class="recommend-list">
    <!-- <swiper [config]="SwiperConfigInterface_recommend">
      <div *ngFor="let _item of eventImageList.thisweek_m">
        <a *ngFor="let item of _item" routerLink="/product/info" class="clearfix" [queryParams]="{productSeq: item.seq, eventSeq: item.event_seq}" [target]="linkTarget">
          <div class="goods-img">
            <img [src]="item.thumbnail_url" alt="{{item.product_name | nohtml}}" />
          </div>
          <div class="goods-info">
            <p class="name" [innerHTML]="item.product_name | safe:'html'"></p>
            <p class="price">
              <del *ngIf="item.display_price > item.consumer_price">{{ item.display_price | number }}원</del>
              <span>{{ item.consumer_price | number }}원</span>
              <span class="sale" *ngIf="item.display_price > item.consumer_price">{{ (item.display_price - item.consumer_price) / item.display_price | percent }}</span>
            </p>
          </div>
        </a>
      </div>
    </swiper> -->
    <owl-carousel-o [options]="customOptionsBanner" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let _item of eventImageList.thisweek_m">
        <ng-template carouselSlide>
          <div *ngFor="let item of _item">
            <a owlRouterLink="/product/info" class="clearfix" [queryParams]="{productSeq: item.seq, eventSeq: item.event_seq}" [target]="linkTarget" [stopLink]="isDragging">
              <div class="goods-img">
                <img [src]="item.thumbnail_url" alt="{{item.product_name | nohtml}}" />
              </div>
              <div class="goods-info">
                <p class="name" [innerHTML]="item.product_name | safe:'html'"></p>
                <p class="price">
                  <del *ngIf="comparePrice(item, 'parseInt(item.display_price) > parseInt(item.consumer_price)')">{{ item.display_price | number }}원</del>
                  <span>{{ item.consumer_price | number }}원</span>
                  <span class="sale" *ngIf="comparePrice(item, 'parseInt(item.display_price) > parseInt(item.consumer_price)')">{{ (item.display_price - item.consumer_price) / item.display_price | percent }}</span>
                </p>
              </div>
            </a>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>

<!-- 베스트 & 신상품-->
<div id="bestNew">
  <div class="best-new section pc-view" *ngIf="best100List.length || newList.length">
    <div class="section-title">베스트 & 신상품</div>
    <div class="toggle-btn">
      <button [ngClass]="{on:toggleBN=='B'}" (click)="toggleBN='B'">BEST</button>
      <button [ngClass]="{on:toggleBN=='N'}" (click)="toggleBN='N'">NEW</button>
    </div>

    <ng-container *ngIf="best100List.length > 0 && toggleBN=='B'">
      <div class="best section mt40" [ngClass]="{more:bestGoodsMore}">
        <div class="goods-list inner clearfix">
          <app-goods-card1 *ngFor="let row of best100List | slice:0:( !bestGoodsMore ? 12 : best100List.length)" [productInfo]="row"></app-goods-card1>
        </div>  
        <div class="more-btn" (click)="bestGoodsMore = !bestGoodsMore;">{{ !bestGoodsMore ? '더보기' : '닫아두기' }}<span class="icon"></span></div>
      </div>
    </ng-container>

    <ng-container *ngIf="newList.length > 0 && toggleBN=='N'">
      <div class="new section mt40">
        <div class="goods-list inner clearfix" [ngClass]="{more:newGoodsMore}">
          <app-goods-card1 *ngFor="let row of newList | slice:0:( !newGoodsMore ? 12 : newList.length)" [productInfo]="row"></app-goods-card1>
        </div> 
        <div class="more-btn" (click)="newGoodsMore = !newGoodsMore;">{{ !newGoodsMore ? '더보기' : '닫아두기' }}</div>
      </div>
    </ng-container>
  </div>

  <!-- 베스트 & 신상품-->
  <div class="best-new section mobile-view customOwlCarouselO" *ngIf="eventImageList.bestProduct_m.length || eventImageList.bestProduct_m.length">
    <div class="section-title">베스트 & 신상품</div>
    <div class="toggle-btn">
      <button [ngClass]="{on:toggleBN=='B'}" (click)="toggleBN='B'">BEST</button>
      <button [ngClass]="{on:toggleBN=='N'}" (click)="toggleBN='N'">NEW</button>
    </div>

    <ng-container *ngIf="eventImageList.bestProduct_m.length > 0 && toggleBN == 'B'">
      <!-- <div class="best section mt40" [ngClass]="{more:bestGoodsMore}">
        <div class="goods-list inner clearfix" *ngFor="let _item of eventImageList.bestProduct_m">
          <app-goods-card1 *ngFor="let item of _item | slice:0:( !bestGoodsMore ? 12 : eventImageList.bestProduct_m.length)" [productInfo]="item"></app-goods-card1>
        </div>
        <div class="more-btn" (click)="bestGoodsMore = !bestGoodsMore;">{{ !bestGoodsMore ? '더보기' : '닫아두기' }}<span class="icon"></span></div>
      </div> -->

      <!-- <swiper [config]="SwiperConfigInterface_bestProduct_m">
        <div class="goods-list inner clearfix" *ngFor="let _item of eventImageList.bestProduct_m">
          <app-goods-card1 *ngFor="let item of _item | slice:0:( !bestGoodsMore ? 12 : eventImageList.bestProduct_m.length)" [productInfo]="item"></app-goods-card1>
        </div>
      </swiper> -->
      <!-- <div class="swiper-button-prev swiper-button-prev-bestProduct_m"></div>
      <div class="swiper-button-next swiper-button-next-bestProduct_m"></div> -->

      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let _item of eventImageList.bestProduct_m">
          <ng-template carouselSlide>
            <div class="goods-list inner clearfix">
              <app-goods-card1 *ngFor="let item of _item | slice:0:( !bestGoodsMore ? 12 : eventImageList.bestProduct_m.length)" [productInfo]="item"></app-goods-card1>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </ng-container>

    <ng-container *ngIf="eventImageList.newProduct_m.length > 0 && toggleBN == 'N'">
      <!-- <div class="new section mt40">
        <div class="goods-list inner clearfix" [ngClass]="{more:newGoodsMore}" *ngFor="let _item of eventImageList.newProduct_m">
          <app-goods-card1 *ngFor="let item of _item | slice:0:( !newGoodsMore ? 12 : eventImageList.newProduct_m.length)" [productInfo]="item"></app-goods-card1>
        </div> 
        <div class="more-btn" (click)="newGoodsMore = !newGoodsMore;">{{ !newGoodsMore ? '더보기' : '닫아두기' }}</div>
      </div> -->
      <!-- <swiper [config]="SwiperConfigInterface_newProduct_m">
        <div class="goods-list inner clearfix" *ngFor="let _item of eventImageList.newProduct_m">
          <app-goods-card1 *ngFor="let item of _item | slice:0:( !bestGoodsMore ? 12 : eventImageList.newProduct_m.length)" [productInfo]="item"></app-goods-card1>
        </div>
      </swiper> -->
      <!-- <div class="swiper-button-prev swiper-button-prev-newProduct_m" style="left: -30px;"></div>
      <div class="swiper-button-next swiper-button-next-newProduct_m" style="right: -30px;"></div> -->
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let _item of eventImageList.newProduct_m">
          <ng-template carouselSlide>
            <div class="goods-list inner clearfix">
              <app-goods-card1 *ngFor="let item of _item | slice:0:( !bestGoodsMore ? 12 : eventImageList.newProduct_m.length)" [productInfo]="item"></app-goods-card1>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </ng-container>

  </div>
</div>

<!-- sub banner -->
<div class="sub-banner pc-view" *ngIf="eventImageList.subA">
  <!-- <a routerLink="#">
    <img src="/assets/images/samp/sub_banner.png" alt="sub banner" />
  </a>   -->
  <ng-container *ngIf="eventImageList.subA.event_link.substr(0,4) == 'http'; else subAEventRouterLink">
    <a [href]="eventImageList.subA.event_link" target="_blank">
      <img [src]="eventImageList.subA.image_url" alt="sub banner" />
    </a>
  </ng-container>
  <ng-template #subAEventRouterLink>
    <a [routerLink]="eventImageList.subA.event_link">
      <img [src]="eventImageList.subA.image_url" alt="sub banner" />
    </a>
  </ng-template>
</div>
<div class="sub-banner mobile-view" *ngIf="eventImageList.subA_m">
  <ng-container *ngIf="eventImageList.subA_m.event_link.substr(0,4) == 'http'; else subA_mEventRouterLink">
    <a [href]="eventImageList.subA_m.event_link" target="_blank">
      <img [src]="eventImageList.subA_m.image_url" alt="sub banner" />
    </a>
  </ng-container>
  <ng-template #subA_mEventRouterLink>
    <a [routerLink]="eventImageList.subA_m.event_link">
      <img [src]="eventImageList.subA_m.image_url" alt="sub banner" />
    </a>
  </ng-template>
</div>

<!-- 카테고리별 베스트 -->
<div class="category-best section" *ngIf="eventImageList.categorybest.length">
  <div class="section-title">카테고리별 BEST</div>
  <div class="toggle-btn">
    <button *ngFor="let item of categorybestCategoryCodeArray; index as index;" [ngClass]="{on: toggleBest == index}" (click)="toggleBest = index; setCategorybestItem(item);">{{categorybestCategoryNameArray[index]}}</button>
    <!-- <button [ngClass]="{on:toggleBest=='1'}" (click)="toggleBest='1'">헬멧</button>
    <button [ngClass]="{on:toggleBest=='2'}" (click)="toggleBest='2'">자켓</button>
    <button [ngClass]="{on:toggleBest=='3'}" (click)="toggleBest='3'">바지</button>
    <button [ngClass]="{on:toggleBest=='4'}" (click)="toggleBest='4'">부츠</button>
    <button [ngClass]="{on:toggleBest=='5'}" (click)="toggleBest='5'">장갑</button>
    <button [ngClass]="{on:toggleBest=='6'}" (click)="toggleBest='6'">블루투스</button> -->
  </div>

  <ng-container>
    <div class="best section mt40" [ngClass]="{more: bestGoodsMore1}">
      <div class="goods-list inner clearfix">
        <app-goods-card1 *ngFor="let item of eventImageList.categorybestSelect | slice:0:( !bestGoodsMore1 ? 12 : eventImageList.categorybestSelect.length)" [productInfo]="item"></app-goods-card1>
      </div>
      <div class="more-btn" *ngIf="eventImageList.categorybestSelect.length > 12" (click)="bestGoodsMore1 = !bestGoodsMore1;">{{ !bestGoodsMore1 ? '더보기' : '닫아두기' }}<span class="icon"></span></div>
    </div>
  </ng-container>
</div>

<!-- sub banner -->
<div class="sub-banner pc-view" *ngIf="eventImageList.subB">
  <ng-container *ngIf="eventImageList.subB.event_link.substr(0,4) == 'http'; else subBEventRouterLink">
    <a [href]="eventImageList.subB.event_link" target="_blank">
      <img [src]="eventImageList.subB.image_url" alt="sub banner" />
    </a>
  </ng-container>
  <ng-template #subBEventRouterLink>
    <a [routerLink]="eventImageList.subB.event_link">
      <img [src]="eventImageList.subB.image_url" alt="sub banner" />
    </a>
  </ng-template>
</div>
<div class="sub-banner mobile-view" *ngIf="eventImageList.subB_m">
  <ng-container *ngIf="eventImageList.subB_m.event_link.substr(0,4) == 'http'; else subB_mEventRouterLink">
    <a [href]="eventImageList.subB_m.event_link" target="_blank">
      <img [src]="eventImageList.subB_m.image_url" alt="sub banner" />
    </a>
  </ng-container>
  <ng-template #subB_mEventRouterLink>
    <a [routerLink]="eventImageList.subB_m.event_link">
      <img [src]="eventImageList.subB_m.image_url" alt="sub banner" />
    </a>
  </ng-template>
</div>

<!-- BEST REVIEW -->
<div class="review section" id="bestReview">
  <div class="section-title">BEST REVIEW</div>
  <div class="reviews inner clearfix">
    <div class="review-list" *ngFor="let row of reviewImageList">
      <a routerLink="/product/info" [queryParams]="{productSeq: row.product_seq}">
        <div class="img">
          <img *ngIf="row.review_image_url" [src]="row.review_image_url" alt="리뷰 이미지" />
        </div>
        <!-- <div class="user">{{row.name}}</div> -->
        <div class="grade">
          <div class="grade-active" [ngStyle]="{'width': (row.grade * 20) + '%' }"></div>
          <div class="grade-back"></div>
        </div>
        <div class="review-txt">
          {{row.comment}}
        </div>
        <div class="goods clearfix">
          <div class="goods-img">
            <img *ngIf="row.product_image_url" [src]="row.product_image_url" alt="상품 이미지" />
          </div>
          <div class="goods-info">
            <p class="kind">{{row.category_name}}</p>  
            <p class="name">{{row.product_name}}</p>
            <p class="price">
              <ng-container *ngIf="row.display_price != row.consumer_price; else priceSame;">
                <span class="sale">{{ (row.display_price - row.consumer_price) / row.display_price | percent }}</span>
                <span>{{row.consumer_price | number}}원</span>  
                <del>{{row.display_price | number}}원</del>
              </ng-container>
              <ng-template #priceSame>
                <span class="sale"></span>
                <span>{{row.consumer_price | number}}원</span>  
                <del></del>
              </ng-template>
            </p>
          </div>  
        </div>
      </a>
    </div>
    <div class="cb"></div>
    <div class="review-list1 pc-view">
      <!-- <swiper [config]="SwiperConfigInterface_review">
        <div *ngFor="let row of reviewTextList" class="list">
          <a routerLink="/product/info" [queryParams]="{productSeq: row.product_seq}">
            <div class="goods-img">
              <img *ngIf="row.product_image_url" [src]="row.product_image_url" alt="상품 이미지" />
            </div>
            <div>
              <div class="user">{{row.name}}</div>
              <div class="review-txt">
                {{row.comment}}
             </div>
            </div>
          </a>
        </div>
      </swiper>  
      <div class="swiper-button-prev swiper-button-prev-review"></div>
      <div class="swiper-button-next swiper-button-next-review"></div> -->
      <owl-carousel-o [options]="customOptionsReview" (dragging)="isDragging = $event.dragging">
        <ng-container *ngFor="let row of reviewTextList">
          <ng-template carouselSlide>
            <div class="list">
              <a owlRouterLink="/product/info" [queryParams]="{productSeq: row.product_seq}" [stopLink]="isDragging">
                <div class="goods-img">
                  <img *ngIf="row.product_image_url" [src]="row.product_image_url" alt="상품 이미지" />
                </div>
                <div>
                  <div class="user">{{row.name}}</div>
                  <div class="review-txt">
                    {{row.comment}}
                </div>
                </div>
              </a>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
      
    <div class="review-list1 mobile-view">
      <owl-carousel-o [options]="customOptionsReview_m" (dragging)="isDragging = $event.dragging">
        <ng-container *ngFor="let row of reviewTextList">
          <ng-template carouselSlide>
            <div class="list">
              <a owlRouterLink="/product/info" [queryParams]="{productSeq: row.product_seq}" [stopLink]="isDragging">
                <div class="goods-img">
                  <img *ngIf="row.product_image_url" [src]="row.product_image_url" alt="상품 이미지" />
                </div>
                <div>
                  <div class="user">{{row.name}}</div>
                  <div class="review-txt">
                    {{row.comment}}
                </div>
                </div>
              </a>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>

  </div>
</div>

<!-- 인스타그램 -->
<!-- <div class="instagram section">
  <div class="section-title">INSTAGRAM</div>
  <a href="https://www.instagram.com/bikemart_info/" target="_blank" class="link-btn">바이크마트 인스타그램 바로가기<span class="icon"></span></a>
  <div class="div pc-view">
    <swiper [config]="SwiperConfigInterface_instagram">
      <div *ngFor="let item of [1,2,3,4,5,6,7,8,9]" class="instgram-list">
        <a href="https://www.instagram.com/bikemart_info/" target="_blank" class="instagram-list">
          <div class="img">
            <img src="/assets/images/samp/instagram01.png" alt="인스타그램" />
          </div>
        </a>
      </div>
    </swiper>  
    <div class="swiper-button-prev swiper-button-prev1"></div>
    <div class="swiper-button-next swiper-button-next1"></div>
  </div>
  <div class="div mobile-view clearfix">
    <div *ngFor="let item of [1,2,3,4,5,6,7,8,9]" class="instgram-list">
      <a href="https://www.instagram.com/bikemart_info/" target="_blank" class="instagram-list">
        <div class="img">
          <img src="/assets/images/samp/instagram01.png" alt="인스타그램" />
        </div>
      </a>
    </div>
  </div>  
</div> -->

<!-- youtube -->
<div class="youtube section" *ngIf="eventImageList.youtube.length">
  <div class="section-title">Youtube</div>
  <a href="https://www.youtube.com/channel/UCwwGEqdg6RHUJQdWZMB_nLA" target="_blank" class="link-btn">유튜브 채널 바로가기<span class="icon"></span></a>
  <div class="youtube-list inner clearfix">
    <!-- <div>
      <iframe width="782" height="790" src="https://www.youtube.com/embed/f7TQHXuw7S0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div>
      <iframe width="379" height="379" src="https://www.youtube.com/embed/VyGM-kX_U2o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div>
      <iframe width="379" height="379" src="https://www.youtube.com/embed/VyGM-kX_U2o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> -->

    <div *ngFor="let item of eventImageList.youtube; index as index;">
      <iframe [width]="index ? 379 : 782" [height]="index ? 379 : 790" [src]="'https://www.youtube.com/embed/' + item.event_link | safe:'resourceUrl'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

  </div>
</div>

<div class="instagram section">
  <div class="section-title">INSTAGRAM</div>
  <a href="https://www.instagram.com/bikemart_cm" target="_blank" class="link-btn">바이크마트 인스타그램 바로가기<span class="icon"></span></a>
  <div class="div inner">
    <script src="https://snapwidget.com/js/snapwidget.js"></script>
    <iframe src="https://snapwidget.com/embed/1019559" class="snapwidget-widget" allowtransparency="true" frameborder="0" scrolling="no" style="border:none; overflow:hidden; width:100%; height:70rem;"></iframe>
  </div>
</div>
<div class="pc-view mb200"></div>

<app-footer></app-footer>



<!-- 팝업 //-->
<section *ngFor="let row of popupList">
  <ng-container *ngIf="getPopupToday(row.board_seq)">
  <div id="popup{{row.board_seq}}">
    <div class="popup-layer" [ngStyle]="{'width':row.popup_width+'px', 'max-height':'100%', 'left':row.screen_x+'px', 'top':row.screen_y+'px'}" [ngClass]="{hidden: row.is_popup_preview == false}">
      <div class="popup-header"><span></span>알려드립니다!</div>
      <!-- <div class="popup-preview-contents" [innerHTML]="row.contents | safe: 'html'"></div> -->
      <div class="popup-preview-contents">
        <ng-container *ngIf="row.popup_link; else notLink">
          <a [href]="row.popup_link" target="_blank">
            <img [src]="row.upload_file" [alt]="row.subject" />
          </a>
        </ng-container>
        <ng-template #notLink>
          <img [src]="row.upload_file" [alt]="row.subject" />
        </ng-template>
      </div>

      <!-- 오늘 더 이상 열지 않기 처리 //-->
      <div class="popup-button-area">
        <div class="fl">
          <label class="popup-not-today">
            <input type="checkbox" name="checkBtn" (click)="setPopupToday(row)" /> 오늘 더 이상 보지 않음
          </label>
        </div>
        <div class="fr">
          <button type="button" class="btn btn-default f12" (click)="row.is_popup_preview=false">창닫기</button>
        </div>
        <div class="cb"></div>
      </div>
    </div>
  </div>
  </ng-container>
</section>
