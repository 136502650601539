import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOGDescription(desc: string) {
    this.meta.updateTag({ property: 'og:description', content: desc });
  }

  updateOGImage(image: string) {
    this.meta.updateTag({ property: 'og:image', content: image });
  }

  updateOGUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateKeywords(key: string) {
    this.meta.updateTag({ name: 'keywords', content: key });
  }

  updateSubject(desc: string) {
    this.meta.updateTag({ name: 'subject', content: desc });
  }
}