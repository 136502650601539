/*******************************************************************************
  설  명 : 회사소개 - 입점안내
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

import { UtilService } from '@app/service/util.service';
import { CompanyService } from '@app/service/company.service';

@Component({
  selector: 'app-company-supply',
  templateUrl: './company-supply.component.html',
  styleUrls: ['./company-supply.component.scss']
})
export class CompanySupplyComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public contactList: any = [];

  public isMobileApp: Boolean = false;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private companyService: CompanyService,
    private toastrService: ToastrService,
  ) {
    this.form = this.formBuilder.group({
      type: ['2001', []],
      associate_type: [null, []],
      name: ['', []],
      phone: ['',[Validators.required, Validators.pattern('^[0-9+-]{9,16}$')] ],
      email: [null, [Validators.pattern('^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$')] ],
      content: ['',[Validators.required]],
      agreement: [false, [Validators.required]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getContact();

    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

  /*******************************************************************************
    설  명 : 가맹/입점문의 데이터 가져오기
  *******************************************************************************/
  getContact() {
    this.companyService.getContact( {} ).then( response => {
      if( response.ResultCode ) {
        this.contactList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 입점 문의 등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      if( this.form.value.agreement === false ) {
        this.toastrService.error('개인정보취급방침에 동의하셔야 등록이 가능합니다.', '입점문의 등록');
      } else {
        this.companyService.setContact(this.form).then( response => {
          if (response.ResultCode) {
            this.toastrService.success(response.ResultMessage);
            this.form.reset();
            this.form.get('type').setValue('2001');
          } else {
            this.toastrService.error('입점문의 등록에 실패하였습니다.', '입점문의 등록');
          };
        });
      }
    }
  }


}
