<div class="member-page">
  <div class="member-inner">
    <div class="logo">
      <a routerLink="/"><img src="/assets/images/logo1.png?date=20210913" alt="바이크마트 로고"></a>
    </div>

    <div class="title">비밀번호찾기 결과</div>
    
    <div class="compleate-div">
      <div class="div1">
        <div class="img"></div>
        <div class="txt1">임시비밀번호가 발급되었습니다.</div>
        <div class="txt1">임시비밀번호는 {{password}} 입니다.</div>
      </div>
      <div class="btn-area">
        <a routerLink="/" class="basic-btn">메인</a>
        <a routerLink="/member/login" class="basic-btn login-btn">로그인</a>
      </div>
    </div>
    
        
    <div class="member-footer">
      <ul class="clearfix">
        <li><a routerLink="/company/policy/service">이용약관</a></li>
        <li><a routerLink="/company/policy/privacy" class="bold">개인정보처리방침</a></li>
        <li><a routerLink="/customer/center">고객센터 1544-5022</a></li>
      </ul>
      <div>Copyright © 2008 <span class="bold">bikemart.co.kr</span> ALL RIGHTS RESERVED.</div>
    </div>    
  </div>
</div>
    

