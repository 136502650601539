/*******************************************************************************
  설  명 : 장바구니
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { SCartService } from '@app/service/cart.service';
import { SProductService } from '@app/service/product.service';

import { ProductNonmemberComponent } from '@page/product/product-nonmember/product-nonmember.component';
import { ProductOptionChangeComponent } from '@page/cart/product-option-change/product-option-change.component';
import { ProductCouponComponent } from '@page/cart/product-coupon/product-coupon.component';
import { NaverpayComponent } from '@app/components/naverpay/naverpay.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '480',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '760',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public info_product: any = [];
  public info_coupon_list: any = [];

  public totalPrice: number = 0;
  public totalDiscount: number = 0;
  public totalDelivery: number  = 0;
  public totalOrderPrice: number = 0;
  public each_delivery_amt: any = 0; // 탑박스 개별 배송비

  public search: any = {};
  public boardData: any = {};
  public delivery: any = {};

  public isAllChecked: boolean = true;

  public isLogIn$: Observable<boolean>;
  public isLogIn: boolean;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private sCartService: SCartService,
    private sProductService: SProductService,
    private authService: AuthService,
    private globals: Globals,
    private naverpayComponent: NaverpayComponent,
  ) {
    this.isLogIn$ = this.authService.isLoggedIn;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    await this.getConfigDelivery();
    await this.getCartList();
    // this.getInfoCouponList();
  }
  
  /*******************************************************************************
    설  명 : 장바구니 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCartList() {

    let data: any = this.globals.load('bikemart_cart');
    let cart: any = ( data !== false ) ? data : [];
    let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

    if( cartList.length > 0 ) {
      await this.sCartService.getCartProductInfoList( JSON.stringify(cartList), '' , '').then( response => {
        if( response.ResultCode ) {
          this.info_product = response.data;
        } else {
          this.toastrService.error(response.ResultMessage, '장바구니' );
        }
      });
    }

    await this.totalCalc();

    let totalChecked = 0;
    this.info_product.forEach(item => {
      if( item.isChecked == 1 ) {
        totalChecked += 1;
      }
    });

    if( totalChecked == this.info_product.length ) {
      this.isAllChecked = true;
    } else {
      this.isAllChecked = false;
    }

    this.sCartService.setCartBadge( this.info_product.length || 0 );
  }

  /*******************************************************************************
    설  명 : 배송료 가져오기
  *******************************************************************************/
  async getConfigDelivery() {
    await this.sProductService.getConfigDelivery().then( response => {
      if( response.ResultCode ) {
        this.delivery = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
  }
  
  /*******************************************************************************
    설  명 : 개별 체크
  *******************************************************************************/
  setCheck( index: number ) {
    this.info_product[ index ].isChecked = ( this.info_product[ index ].isChecked == 0 ) ? 1 : 0;

    let totalChecked = 0;
    this.info_product.forEach(item => {
      if( item.isChecked == 1 ) {
        totalChecked += 1;
      }
    });

    if( totalChecked == this.info_product.length ) {
      this.isAllChecked = true;
    } else {
      this.isAllChecked = false;
    }

    this.totalCalc();
  }

  /*******************************************************************************
    설  명 : 전체 체크
  *******************************************************************************/
  setAllCheck(event) {
    this.isAllChecked = event.target.checked;

    this.info_product.forEach(obj => {
      obj.isChecked = this.isAllChecked;
    });

    this.totalCalc();
  }

  /*******************************************************************************
    설  명 : 장바구니 선택 삭제
  *******************************************************************************/
  setDeleteSelectCart() {
    if( this.info_product.length < 1 ) {
      this.toastrService.warning('삭제할 내역이 없습니다.', '장바구니 선택 삭제');
      return false;
    } else {
      let count = 0;
      for( let item of this.info_product ) {
        if( item.isChecked == true || item.isChecked == '1' ) {
          count++;
        }
      }
      if( count < 1 ) {
        this.toastrService.warning('삭제할 내역을 선택하세요.', '장바구니 선택 삭제');
        return false;
      }
    }

    if( confirm('선택한 상품을 장바구니에서 삭제하시겠습니까?') ) {
      // 회원인 경우 DB에서 삭제
      // if( this.isLogIn ) {
      //   let tmp = this.info_product;
      //   tmp = tmp.filter( rows => { return rows.isChecked == true });
      //   tmp = tmp.map( rows => { return rows.cart_seq });

      //   this.sCartService.deleteCartRow( tmp ).then( response => {
      //     if( response.ResultCode ) {
      //       this.toastrService.success(response.ResultMessage, '삭제되었습니다.' );
            
      //       this.getCartList();
      //     } else {
      //       this.toastrService.error(response.ResultMessage, '삭제가 되지 않았습니다.' );
      //     }
      //   });

      // // 비회원인 경우 쿠키에서 삭제
      // } else {
      let cartList = [];

      // 장바구니 리스트 삭제
      let tmp = [];
      this.info_product.forEach((obj, key) => {
        if( obj.isChecked == false && obj.isChecked == '0' ) {
          tmp.push(obj);
        }
      });

      this.info_product = tmp;

      this.info_product.forEach(obj => {
        let item: any = {
          product_seq: '',
          productList: [],
          giftList: []
        };
  
        item.product_seq = obj.product_seq;

        obj.children.forEach(obj2 => {
          item.productList.push({
            product_seq: obj2.product_seq,
            product_property_seq: obj2.product_property_seq,
            product_freegift_seq: obj2.product_freegift_seq,
            qty: obj2.qty,
            consumer_price: obj2.consumer_price,
            discount_rate: obj2.discount_rate,
            discount_amt: obj2.discount_amt,
          });
        });

        cartList.push(item);
      });

      this.globals.save( 'bikemart_cart', cartList );

      this.getCartList();
      // }
    }
  }

  /*******************************************************************************
    설명: 회원/ 비회원 장바구니 한줄 삭제
  *******************************************************************************/
  setDeleteCart(row: any, index: number) {
    if( confirm('해당 상품을 장바구니에서 삭제하시겠습니까?') ) {
      // 회원인 경우 DB에서 삭제
      // if( this.isLogIn ) {
      //   let cartSeqArr = [];
      //   cartSeqArr.push(row.cart_seq);

      //   this.sCartService.deleteCartRow( cartSeqArr ).then( response => {
      //     if( response.ResultCode ) {
      //       this.toastrService.success(response.ResultMessage, '장바구니 상품 삭제' );

      //       this.getCartList();
      //     } else {
      //       this.toastrService.error(response.ResultMessage, '장바구니 상품 삭제' );
      //     }
      //   });

      // // 비회원인 경우 쿠키에서 삭제
      // } else {
        let data: any = this.globals.load( 'bikemart_cart' );
        let cart: any = ( data !== false ) ? data : [];
        let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];
        
        // 쿠키 데이터 삭제
        if( cartList[index] ) {
          cartList.splice( index, 1 );
        }

        // 장바구니 리스트 삭제
        if( this.info_product[index] ) {
          this.info_product.splice( index, 1 );
        }

        this.globals.save( 'bikemart_cart', cartList );

        this.getCartList();
      // }
    }
  }

  /*******************************************************************************
    설  명 : 장바구니 옵션 삭제
  *******************************************************************************/
  setDeleteOption(row: any, index: number, option: any, index2: number) {
    if ( row.children.length == 1 ) {
      // this.toastrService.error( '상품의 옵션이 한 개는 있어야 합니다.', '장바구니 옵션 삭제' );
      this.setDeleteCart(row, index);
    } else {
      if( confirm('해당 옵션을 삭제하시겠습니까?') ) {
        // 회원인 경우 DB에서 삭제
        // if( this.isLogIn ) {
        //   let cart_seq = option.cart_seq;
        //   let product_property_seq = option.product_property_seq;

        //   if( this.isLogIn ) {
        //     this.sCartService.deleteCartOptionRow( cart_seq, product_property_seq ).then( response => {
        //       if( response.ResultCode ) {
        //         this.toastrService.success(response.ResultMessage, '장바구니 옵션 삭제' );

        //         this.getCartList();
        //       } else {
        //         this.toastrService.error(response.ResultMessage, '장바구니 옵션 삭제' );
        //       }
        //     });
            
        //   }
        // } else {
          // 비회원인 경우 쿠키에서 삭제
          let data: any = this.globals.load( 'bikemart_cart' );
          let cart: any = ( data !== false ) ? data : [];
          let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

          // 쿠키 데이터 삭제
          cartList.forEach((obj, key) => {
            if( key == index ) {
              obj.productList.forEach((obj2, key2) => {
                if( key2 == index2 ) {
                  cartList[key].productList.splice( key2, 1 );
                }
              });
            }
          });

          // 장바구니 리스트 삭제
          this.info_product.forEach((obj, key) => {
            if( key == index ) {
              obj.children.forEach((obj2, key2) => {
                if( key2 == index2 ) {
                  this.info_product[key].children.splice( key2, 1 );
                  this.info_product[key].sum_amt = this.info_product[key].sum_amt - (option.consumer_price * option.qty);
                  this.info_product[key].sum_mileage = this.info_product[key].sum_mileage - (option.mileage * option.qty); 
                  this.info_product[key].sum_qty = this.info_product[key].sum_qty - option.qty;
                  this.info_product[key].sum_total_amt = this.info_product[key].sum_total_amt - (option.consumer_price * option.qty);
                }
              });
            }
          });

          this.globals.save( 'bikemart_cart', cartList );
        // }
        
        this.totalCalc();
      }
    }
  }

  /*******************************************************************************
    설  명 : 장바구니 - 옵션변경/추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  setOptionChange( row: any, index: number ) {
    const modalRef = this.modalService.open(ProductOptionChangeComponent, options);

    // modalRef.componentInstance.rowInfo = row;
    modalRef.componentInstance.rowIndex = index;

    modalRef.result.then((result) => {
      if( result ) {
        this.getCartList();
      }
    }, (reason) => {
    });  
  }

  /*******************************************************************************
    설  명 : 개별 금액 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  totalCalc() {
    let totalPrice: number = 0;
    let totalDeliveryPrice: number = 0;
    this.each_delivery_amt = 0;

    this.info_product.forEach(obj => {
      if( obj.isChecked == true || obj.isChecked == '1' ) {
        totalPrice += parseInt(obj.sum_total_amt);

        // 입점사별 배송료
        if( this.delivery.delivery_use_yn == '0' ) {
          totalDeliveryPrice += parseInt(obj.delivery_price);
        }
      }
    });

    // 주문별 배송료
    if( this.delivery.delivery_use_yn == '1' ) this.totalDelivery = parseInt(this.delivery.delivery_amt);
    else this.totalDelivery = totalDeliveryPrice;

    // 무료배송 체크
    if( totalPrice > parseInt(this.delivery.delivery_free_condition) ) this.totalDelivery = 0;

    // 탑박스 개별 배송비 체크
    this.info_product.map( rows => {
      if ( rows.each_delivery_price == '1' ) {
        this.each_delivery_amt += rows.sum_qty * this.delivery.delivery_amt;
      } else {
        return;
      }
    });

    this.totalDelivery = ( totalPrice > 0 ) ? this.totalDelivery + parseInt(this.each_delivery_amt) : 0;

    this.totalPrice = totalPrice;
    this.totalOrderPrice = totalPrice + this.totalDelivery;

    // 네이버페이 모듈 설정
    const _productList = []
    this.info_product.filter(e => e['isChecked']).forEach(e => e['children'].forEach(e => _productList.push(e)))
    this.naverpayComponent.nPayModuleSet({
      productList: _productList,
      deliveryCharge : this.totalDelivery,
      totalPrice: this.totalOrderPrice
    })
  }

  /*******************************************************************************
    설  명 : 상품 찜하기 저장
  *******************************************************************************/
  setKeepProductSave() {
    // 회원일 경우만
    if( this.isLogIn ) {
      let productSeqArr = [];
      this.info_product.forEach(item => {
        if( item.isChecked == true || item.isChecked == '1' ) {
          productSeqArr.push(item.product_seq);
        }
      });

      this.sProductService.setKeepProductSave(productSeqArr, true).then( response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '선택 찜하기');
        } else {
          this.toastrService.error(response.ResultMessage, '선택 찜하기');
        }
      })
      .catch(response => {
        this.toastrService.success(response, '선택 찜하기');
      });

    } else {
      this.toastrService.error('로그인이 필요한 서비스입니다.', '선택 찜하기');
    }
  }
  
  /*******************************************************************************
    설  명 : 비회원 주문 시 표시
  *******************************************************************************/
  nonMemberOrder() {
    const modalRef = this.modalService.open(ProductNonmemberComponent, options);
  }
  
  /*******************************************************************************
    설  명 : 주문하기
  *******************************************************************************/
  setOrder() {
    if( this.info_product.length < 1 ) {
      this.toastrService.warning('장바구니에 담겨 있는 상품이 없습니다.', '주문하기');
    } else {
      let selectedList: any = [];
      let keyList: any = [];
      let isSoldout: boolean;

      this.info_product.forEach((item: any, key: number) => {
        if( item.isChecked == true || item.isChecked == '1' ) {
          selectedList.push(item);
          keyList.push(key);

          // 품절인 옵션이 존재하는지 체크 
          if( item.children.length > 0 ) {
            let soldoutList: any = item.children.filter((obj: any) => obj.use_yn == '0' || obj.is_soldout == '1' || obj.is_soldout == '2');
            if( soldoutList.length > 0 ) isSoldout = true;
          }
        }
      });

      if( selectedList.length < 1 ) {
        this.toastrService.warning('주문할 상품을 선택하시기 바랍니다.', '주문하기');
      } else if( isSoldout === true ) {
        this.toastrService.warning('선택한 상품중에 품절상태인 옵션이 있습니다.', '주문하기');
      } else {
        // 장바구니 데이터에도 isChecked 넣어주기
        let data: any = this.globals.load('bikemart_cart');
        let cart: any = ( data !== false ) ? data : [];
        let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

        cartList.forEach((item, key) => {
          keyList.forEach(key2 => {
            if( key === key2 ) {
              cartList[ key ].isChecked = true;
            }
          });
        });

        this.globals.save( 'bikemart_cart', cartList );

        sessionStorage.setItem('bikemart_order', JSON.stringify(selectedList));

        if( ! this.isLogIn ) {
          // 비회원인 경우 모달 창 띄우기
          this.nonMemberOrder();

        } else {
          this.router.navigate(
            ['/order'],
            {
              relativeTo: this.activatedRoute,
              queryParamsHandling: '',
            }
          );
        }
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 임시 쿠폰리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getInfoCouponList() {
    this.info_coupon_list.push({
      name: '오투오즈 단독 100% 할인 쿠폰',
      code : 'A123Z',
      method: 1, //0: 액면가, 1: 비율
      sale: 100 //할인량
    });
    this.info_coupon_list.push({
      name: '오투오즈 단독 100만원 할인 쿠폰',
      code : 'B123Z',
      method: 0, //0: 액면가, 1: 비율
      sale: 1000000 //할인량
    });
  }

  /*******************************************************************************
    설  명 : 할인쿠폰 적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  couponApply() {
    const modalRef = this.modalService.open(ProductCouponComponent, options1);
    
    modalRef.componentInstance.info_product = this.info_product;

    modalRef.result.then((result) => {
      this.totalCalc();
    }, (reason) => {
    });  
  }

  /*******************************************************************************
    설  명 : 상품 상세페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( product_seq: number ) {
    this.router.navigate(
    ['/product/info'],
    {
      relativeTo: this.activatedRoute,
      queryParams: { productSeq: product_seq },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
  
}
