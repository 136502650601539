import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-membership-grade',
  templateUrl: './membership-grade.component.html',
  styleUrls: ['./membership-grade.component.scss']
})
export class MembershipGradeComponent implements OnInit {

  public isMobileApp: Boolean = false;

  constructor(
    private location: Location,
  ) { }

  ngOnInit(): void {
    if( this.location.path().indexOf( '/mobile' ) > -1 ) {
      this.isMobileApp = true;
    }
  }

}
